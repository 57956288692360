import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const PlayIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="99"
    height="100"
    viewBox="0 0 99 100"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.2643 49.8724C82.2643 68.0511 67.5984 82.7878 49.5071 82.7878C31.424 82.7681 16.7696 68.0429 16.75 49.8724C16.75 31.6937 31.4159 16.957 49.5071 16.957C67.5984 16.957 82.2643 31.6937 82.2643 49.8724ZM61.0404 53.5041C61.9673 53.1289 62.5927 52.2467 62.6427 51.2439C62.5938 50.24 61.9681 49.3565 61.0404 48.9809L44.4298 40.6342C43.5745 40.1095 42.4983 40.1126 41.646 40.6422C40.7937 41.1718 40.3101 42.1379 40.3952 43.1412V59.3411C40.3453 60.3377 40.8214 61.2871 41.6481 61.8399C42.0486 62.0872 42.5102 62.2164 42.9803 62.2129C43.4852 62.2067 43.9816 62.0817 44.4298 61.8481L61.0404 53.5041Z"
        fill="white"
      />
      <mask
        id="mask0_16596_94704"
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="67"
        height="67"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.2643 49.8724C82.2643 68.0511 67.5984 82.7878 49.5071 82.7878C31.424 82.7681 16.7696 68.0429 16.75 49.8724C16.75 31.6937 31.4159 16.957 49.5071 16.957C67.5984 16.957 82.2643 31.6937 82.2643 49.8724ZM61.0404 53.5041C61.9673 53.1289 62.5927 52.2467 62.6427 51.2439C62.5938 50.24 61.9681 49.3565 61.0404 48.9809L44.4298 40.6342C43.5745 40.1095 42.4983 40.1126 41.646 40.6422C40.7937 41.1718 40.3101 42.1379 40.3952 43.1412V59.3411C40.3453 60.3377 40.8214 61.2871 41.6481 61.8399C42.0486 62.0872 42.5102 62.2164 42.9803 62.2129C43.4852 62.2067 43.9816 62.0817 44.4298 61.8481L61.0404 53.5041Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_16596_94704)"></g>
    </g>
  </svg>
);
