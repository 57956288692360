export const generateRequestUrl = (
  url: string,
  queryParams?: Record<string, string | number>,
): string => {
  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) =>
        value ? `${key}=${encodeURIComponent(value.toString())}` : undefined,
      )
      .filter(Boolean)
      .join("&");

    return `${url}?${queryString}`;
  }

  return url;
};
