import React, { ChangeEvent, FC } from "react";
import {
  Box,
  Table as MUITable,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

import { TableHead, TableRow } from "components/Table";
import { Loader } from "components";

import type { TableProps } from "./typed";
import { EmptyStateTableText } from "../TableRow/styled";

const RowsPerPageOptions = [15, 30];

export const Table: FC<TableProps> = ({
  cells,
  actions,
  isLoading,
  hideSortIcon,
  rows,
  rowsPerPageOptions,
  order,
  orderBy,
  page,
  rowsPerPage,
  withHeader,
  withPagination,
  allRowClickable,
  totalCount,
  handleRequestSort,
  setPage,
  setRowsPerPage,
  getClickHandler,
  isEmptyState,
  firstColumnWidth,
}) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <TableContainer>
        <MUITable aria-label="table">
          {withHeader ? (
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              cells={cells}
              hideSortIcon={hideSortIcon}
              withAction={Boolean(actions?.length)}
            />
          ) : null}
          {isLoading ? null : (
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  getClickHandler={getClickHandler}
                  row={row}
                  cells={cells}
                  actions={actions}
                  allRowClickable={allRowClickable}
                  firstColumnWidth={firstColumnWidth}
                />
              ))}
            </TableBody>
          )}
        </MUITable>
      </TableContainer>
      {isLoading ? (
        <Loader />
      ) : rows.length ? null : isEmptyState ? (
        <EmptyStateTableText>{isEmptyState}</EmptyStateTableText>
      ) : null}
      {withPagination ? (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          count={totalCount}
          component="div"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Box>
  );
};

Table.defaultProps = {
  isLoading: false,
  rowsPerPageOptions: RowsPerPageOptions,
  page: 0,
  rowsPerPage: 15,
  withHeader: true,
  withPagination: true,
  allRowClickable: false,
};
