import React, { FC } from "react";

import { ArrowDownIcon } from "icons";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitle,
} from "./styled";

interface IAccordion {
  expanded: boolean;
  title: string;
  handleExpand: (bool: boolean) => void;
  children: React.ReactNode;
}

export const Accordion: FC<IAccordion> = ({
  expanded,
  title,
  handleExpand,
  children,
}) => {
  return (
    <StyledAccordion
      disableGutters
      expanded={expanded}
      onChange={() => handleExpand(!expanded)}
    >
      <StyledAccordionSummary
        expandIcon={<ArrowDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledTitle>{title}</StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
