import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ButtonPlayIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.1384 36.3907C71.1384 55.9677 55.3491 71.838 35.8719 71.838C16.4035 71.8168 0.626525 55.9589 0.605469 36.3907C0.605469 16.8137 16.3948 0.943359 35.8719 0.943359C55.3491 0.943359 71.1384 16.8137 71.1384 36.3907ZM48.2887 40.3017C49.2866 39.8977 49.96 38.9476 50.0138 37.8677C49.9611 36.7866 49.2875 35.8351 48.2887 35.4307L30.4056 26.4418C29.4848 25.8768 28.3262 25.8801 27.4086 26.4505C26.491 27.0208 25.9704 28.0612 26.062 29.1417V46.5877C26.0083 47.661 26.5208 48.6834 27.4109 49.2787C27.8421 49.5451 28.339 49.6843 28.8451 49.6805C29.3887 49.6737 29.9231 49.5392 30.4056 49.2876L48.2887 40.3017Z"
      fill="white"
    />
  </svg>
);
