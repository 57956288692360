import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { InspirationToolProductFamily } from "types";
import { InspirationToolDraggedProductFamily } from "types";

import { inspirationToolProductFamilyUrlBuilder } from "./urlBuilder/inspirationToolProductFamilies";

export const inspirationToolProductFamiliesAPI = createApi({
  reducerPath: "inspirationToolProductFamiliesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["InspirationToolProductFamilies"],
  endpoints: build => ({
    getInspirationToolProductFamilies: build.query<
      InspirationToolProductFamily[],
      string | number
    >({
      query: args => ({
        url: inspirationToolProductFamilyUrlBuilder.getInspirationToolProductFamilies(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductFamilies"],
    }),
    getInspirationToolProductFamily: build.query<
      InspirationToolProductFamily,
      string
    >({
      query: args => ({
        url: inspirationToolProductFamilyUrlBuilder.getInspirationToolProductFamily(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductFamilies"],
    }),
    createInspirationToolProductFamily: build.mutation<
      InspirationToolProductFamily,
      InspirationToolProductFamily
    >({
      query: body => ({
        url: inspirationToolProductFamilyUrlBuilder.createInspirationToolProductFamily(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductFamilies"],
    }),
    deleteInspirationToolProductFamily: build.mutation<
      InspirationToolProductFamily,
      string
    >({
      query: args => ({
        url: inspirationToolProductFamilyUrlBuilder.deleteInspirationToolProductFamily(
          args,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["InspirationToolProductFamilies"],
    }),
    changeInspirationToolProductFamily: build.mutation<
      InspirationToolDraggedProductFamily,
      InspirationToolDraggedProductFamily
    >({
      query: (args?: InspirationToolDraggedProductFamily) => ({
        url: inspirationToolProductFamilyUrlBuilder.changeInspirationToolProductFamily(
          args.id,
        ),
        data: args.data,
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useGetInspirationToolProductFamiliesQuery,
  useGetInspirationToolProductFamilyQuery,
  useCreateInspirationToolProductFamilyMutation,
  useDeleteInspirationToolProductFamilyMutation,
  useChangeInspirationToolProductFamilyMutation,
} = inspirationToolProductFamiliesAPI;
