import { RefAttributes } from "react";
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from "swiper/react";
import { Image } from "mui-image";
import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const SwiperCarousel = styled(Swiper)<
  RefAttributes<SwiperRef> & SwiperProps
>({
  marginBottom: "20px",

  [BreakPointTheme.breakpoints.down("md")]: {
    height: "75%",
  },

  ".swiper-wrapper": {
    [BreakPointTheme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
});

export const StyledSwiperSlide = styled(SwiperSlide)({
  ".swiper-slide": {
    [BreakPointTheme.breakpoints.down("md")]: {
      height: "auto !important",
    },
  },
});

export const SwiperThumb = styled(Swiper)({
  ".swiper-slide": {
    border: "4px solid transparent",
    display: "flex",
    width: "180px !important",
  },
  ".mui-image-wrapper": {
    width: "180px !important",
  },
  ".swiper-slide-thumb-active": {
    ".mui-image-wrapper": {
      border: `4px solid ${theme.color.blue}`,
    },
  },
});

export const SwiperFullImage = styled(Image)({
  width: "100%",
  height: "600px !important",
  objectFit: "cover",

  [BreakPointTheme.breakpoints.down("md")]: {
    height: "auto !important",
  },
});

export const SwiperThumbImage = styled(Image)({
  width: "180px !important",
  height: "100px !important",
  objectFit: "cover",
  cursor: "pointer",
});
