import {
  styled,
  DialogTitle as MUIDialogTitle,
  DialogContent as MIUDialogContent,
  Box,
  Typography,
} from "@mui/material";

import { theme } from "theme";

import { DialogBlurBackdrop } from "styled";

export const DialogContent = styled(MIUDialogContent)({
  padding: "20px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  flex: 1,
  width: "100%",
});

export const Dialog = styled(DialogBlurBackdrop)<{
  height?: number;
  paddingBot?: number;
}>(({ height, paddingBot }) => ({
  ".MuiPaper-root": {
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "26px",
    width: "800px",
    maxWidth: "800px",

    ...(height && {
      height: `${height}px`,
    }),
    ...(paddingBot && {
      paddingBottom: `${paddingBot}px`,
    }),
  },
}));

export const DialogTitle = styled(MUIDialogTitle)({
  boxSizing: "border-box",
  width: "100%",
  color: theme.color.black,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.md,
  lineHeight: "125%",
  textAlign: "left",
  padding: 0,
  paddingBottom: "31px",
});

export const StyledButton = styled("button")({
  position: "absolute",
  padding: 0,
  margin: 0,
  background: "none",
  border: "none",
  cursor: "pointer",
  top: 27,
  right: 27,
});

export const DialogActions = styled(Box)({
  display: "flex",
  gap: "36px",
  width: "100%",
  justifyContent: "flex-end",
});

export const TextContent = styled(Typography)({
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "center",
});
