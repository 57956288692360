import { endpoints } from "constant";

export const carsUrlBuilder = {
  getCarRestorationUpdates: function ({
    id,
  }: {
    id?: string | number;
  } = {}): string {
    return `${endpoints.cars}${id}/restoration-updates/`;
  },
};
