import { styled } from "@mui/material";

export const Wrapper = styled("div")({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: "white",
  opacity: "0.5",
  zIndex: "100",
});
