import React, { FC, useMemo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { Action, Table } from "components/Table";
import { BinIcon, UploadIcon } from "icons";
import { ErrorText } from "styled";

import { Cells, EmptyError } from "./config";
import {
  TextDrag,
  TextError,
  TextLimit,
  TextUpload,
  UploadBox,
} from "./styled";

export interface IFileUploadInput {
  height?: string;
  name: string;
  files: File[];
  setFiles: (agrs: File[]) => void;
  types: string[];
  error: string;
  maxSize?: number;
}

export const FileUploadInput: FC<IFileUploadInput> = ({
  height,
  name,
  files,
  setFiles,
  types,
  error,
  maxSize = 200,
}) => {
  const [errors, setErrors] = useState(EmptyError);

  const actions = useMemo<Action[]>(
    () => [
      {
        label: "Delete",
        Icon: BinIcon,
        onClick: deletedFile =>
          setFiles(files.filter(file => file !== deletedFile)),
      },
    ],
    [files],
  );

  const handleChange = (newFiles: File) => {
    setErrors(EmptyError);
    setFiles([newFiles]);
  };

  const handleTypeError = err => setErrors({ ...errors, type: err });

  const handleSizeError = err => setErrors({ ...errors, fileSize: err });

  return (
    <>
      <FileUploader
        handleChange={handleChange}
        name={name}
        types={types}
        maxSize={maxSize}
        onTypeError={handleTypeError}
        onSizeError={handleSizeError}
      >
        <UploadBox height={height}>
          <UploadIcon />
          <TextUpload>
            {`Upload file `}
            <TextDrag component="span">or drag and drop</TextDrag>
          </TextUpload>
          <TextLimit>{`File Limit ${maxSize}MB`}</TextLimit>
        </UploadBox>
      </FileUploader>

      {errors?.size && <TextError>{errors?.size}</TextError>}

      {errors?.type && <TextError>{errors?.type}</TextError>}

      {errors?.fileSize && <TextError>{errors?.fileSize}</TextError>}

      {!!files?.length && (
        <Table
          rows={files}
          cells={Cells}
          actions={actions}
          withHeader={false}
          withPagination={false}
          firstColumnWidth={800}
        />
      )}

      {!!error && <ErrorText>{error}</ErrorText>}
    </>
  );
};
