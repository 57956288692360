import React, { FC } from "react";
import { useSelector } from "react-redux";

import { CLIENT_ROUTES, ROLES } from "constant";

import { getUserClientRole } from "redux/selectors";
import { Link, Links, StageTopic, Statement } from "./components";

import type { SpecificationsViewProps } from "./typed";
import { PageHeadingVideo } from "../PageHeadingVideo";

export const SpecificationsView: FC<SpecificationsViewProps> = ({
  pageContent: {
    video,
    statement_title,
    statement_copy,
    tool_title,
    tool_copy,
    tool_photos,
    board_title,
    board_copy,
    board_photos,
  },
  isPreview,
}) => {
  const clientRole = useSelector(getUserClientRole);

  const isCollaborator = clientRole === ROLES.Collaborator;
  return (
    <>
      <PageHeadingVideo isScrollIcon url={video} />

      <Links isPreview={isPreview} isCollaborator={isCollaborator} />

      <Statement title={statement_title} description={statement_copy} />

      {!isCollaborator && (
        <StageTopic
          title={tool_title}
          description={tool_copy}
          images={tool_photos}
          isPreview={isPreview}
        >
          <Link
            title="Inspiration Tool"
            path={CLIENT_ROUTES.INSPIRATION_TOOL.path}
            isPreview={isPreview}
          />
        </StageTopic>
      )}

      <StageTopic
        reversed
        title={board_title}
        description={board_copy}
        images={board_photos}
        isPreview={isPreview}
      >
        <Link
          title="Saved Boards"
          path={CLIENT_ROUTES.MOOD_BOARDS_LIBRARY.path}
          isPreview={isPreview}
        />
      </StageTopic>
    </>
  );
};
