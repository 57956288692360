import { styled, Typography } from "@mui/material";
import { theme } from "theme";

export const LoaderWrapper = styled("div")({
  marginBottom: "20px",
});

export const ResultsList = styled("div")({
  position: "fixed",
  top: "82px",
  left: "20px",
  margin: "10px 0",
  background: "white",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  maxHeight: "75vh",
  overflow: "scroll",
  zIndex: "999999",
  minWidth: "217px",
  border: "1px solid black",
  transition: ".3s",
});

export const ResultsWrapper = styled("div")({
  width: "100%",
  marginBottom: "10px",
  padding: "5px",
});

export const ResultTitle = styled(Typography)({
  margin: "5px 0px",
  padding: "0 5px",
  borderBottom: "1px solid black",
  fontSize: theme.fontSize.md,
  fontWeight: theme.fontWeight.semiBold,
  boxSizing: "border-box",
});

export const ResultItem = styled(Typography)({
  padding: "5px 5px",
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.base,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  boxSizing: "border-box",

  ":hover": {
    background: theme.color.grey[300],
  },
});

export const NoResults = styled(Typography)({
  marginTop: "10px",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: "500",
});
