interface IPredefinedLink {
  subStageName: string;
  linkTitle: string;
}

interface IPredefinedLinks {
  firstCarouselLinks: IPredefinedLink[];
  secondCarouselLinks: IPredefinedLink[];
}

const predefinedLinks: IPredefinedLinks = {
  firstCarouselLinks: [
    { subStageName: "Welcome", linkTitle: "Welcome!" },
    { subStageName: "Product Details", linkTitle: "Luxury Through Choice" },
    {
      subStageName: "Specifications",
      linkTitle: "Participating in Perfection",
    },
    { subStageName: "Inspection", linkTitle: "Arrival" },
    { subStageName: "Carbon Fiber", linkTitle: "Carbon Fiber" },
    { subStageName: "Final Assembly 1", linkTitle: "Final Assembly" },
    { subStageName: "Final Photoshoot", linkTitle: "Congratulations!" },
  ],
  secondCarouselLinks: [
    { subStageName: "Brand Videos", linkTitle: "The Pursuit of Perfect" },
    { subStageName: "Donor", linkTitle: "Your Donor Vehicle" },
    { subStageName: "Fabrication", linkTitle: "Fabrication" },
    { subStageName: "Paint", linkTitle: "Paint" },
    { subStageName: "End of Line", linkTitle: "End of Line" },
    { subStageName: "PX Handover", linkTitle: "Product Experience" },
  ],
};

const getLink = (
  substages: { id: number; name: string }[],
  carouselLinks: IPredefinedLink[],
) => {
  const substagesTitles = substages?.map(({ name }) => name);

  const linkData = carouselLinks?.find(({ subStageName }) =>
    substagesTitles?.includes(subStageName),
  );

  if (!linkData) {
    return undefined;
  }

  return {
    ...linkData,
    stageId: substages.find(({ name }) => name === linkData.subStageName).id,
  };
};

export const getCarouselLinks = (substages: { id: number; name: string }[]) => {
  return {
    first: getLink(substages, predefinedLinks.firstCarouselLinks),
    second: getLink(substages, predefinedLinks.secondCarouselLinks),
  };
};
