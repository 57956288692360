import { Box, styled } from "@mui/material";

export const LayoutGrid = styled(Box)<{ gridTemplate: boolean }>(
  ({ gridTemplate }) => ({
    display: "grid",
    gridTemplateColumns: "230px 1fr",
    ...(gridTemplate && {
      gridTemplateColumns: "60px 1fr",
    }),
  }),
);

export const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
