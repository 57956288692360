import { Box, styled } from "@mui/material";
import { BreakPointTheme } from "theme";

import { SidebarEnum } from "./typed";

export const LayoutGrid = styled(Box)({
  display: "block",
});

export const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const Backdrop = styled(Box)<{
  opened?: SidebarEnum.Left | SidebarEnum.Right;
}>(({ opened }) => ({
  display: "none",
  ...(opened && {
    content: '""',
    position: "fixed",
    display: "block",
    width: "100%",
    top: 0,
    bottom: 0,
    minHeight: "100%",
    background: "#00000066",
    zIndex: 99,
  }),

  [BreakPointTheme.breakpoints.down("md")]: {
    display: "none",
  },
}));
