import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { zipArchiveUrlBuilder } from "./urlBuilder/zipArchive";
import { ZipArchiveData } from "types";

export const zipArchiveAPI = createApi({
  reducerPath: "zipArchiveAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["zipArchive"],
  endpoints: build => ({
    getZipArchive: build.query<ZipArchiveData, string>({
      query: id => ({
        url: zipArchiveUrlBuilder.getZipArchive(id),
        method: "GET",
      }),
      providesTags: ["zipArchive"],
    }),
    orderZipPhotos: build.query<ZipArchiveData, { photos: string[] }>({
      query: data => ({
        url: zipArchiveUrlBuilder.orderZipPhotos(),
        method: "POST",
        data,
      }),
      providesTags: ["zipArchive"],
    }),
    orderZipDocuments: build.query<ZipArchiveData, { documents: string[] }>({
      query: data => ({
        url: zipArchiveUrlBuilder.orderZipDocuments(),
        method: "POST",
        data,
      }),
      providesTags: ["zipArchive"],
    }),
  }),
});

export const {
  useLazyGetZipArchiveQuery,
  useLazyOrderZipPhotosQuery,
  useLazyOrderZipDocumentsQuery,
} = zipArchiveAPI;
