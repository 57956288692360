import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const PlusIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 12C21.5 16.9703 17.4703 21 12.5 21C7.52975 21 3.5 16.9703 3.5 12C3.5 7.02975 7.52975 3 12.5 3C17.4703 3 21.5 7.02975 21.5 12Z"
      fill="#00B1A5"
    />
    <rect
      width="10"
      height="1.66667"
      transform="matrix(1 0 0 -1 7.5 12.832)"
      fill="white"
    />
    <rect
      width="10"
      height="1.66667"
      transform="matrix(-4.37114e-08 1 1 4.37114e-08 11.6665 7)"
      fill="white"
    />
  </svg>
);
