import React, { FC } from "react";

import { WelcomeProps } from "./typed";
import { WelcomeDescription, WelcomeTitle } from "./styled";

export const Welcome: FC<WelcomeProps> = ({ title, description }) => {
  return (
    <>
      <WelcomeTitle>{title}</WelcomeTitle>

      <WelcomeDescription>{description || ""}</WelcomeDescription>
    </>
  );
};
