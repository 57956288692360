import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ArrowLeftIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1056 4.66366L9.01939 12.7041C8.94185 12.7727 8.89746 12.8713 8.89746 12.9748C8.89746 13.0783 8.94185 13.1768 9.01939 13.2454L18.1056 21.2852"
      stroke="black"
    />
  </svg>
);
