import {
  styled,
  Dialog as MUIDialog,
  DialogContent as MIUDialogContent,
  DialogProps,
  Typography,
} from "@mui/material";

interface Props extends DialogProps {
  maxWidthStyle?: string;
}

import { DialogTitle as Title } from "../styled";
import { theme } from "theme";

export const Dialog = styled(MUIDialog)<Props>(({ maxWidthStyle }) => ({
  background: "rgba(0, 0, 0, 0.6)",
  backdropFilter: "blur(5px)",
  ".MuiPaper-root": {
    borderRadius: "10px",
    maxWidth: maxWidthStyle,
  },
}));

export const DialogTitle = styled(Title)({
  fontSize: "20px",
  lineHeight: "140%",
  padding: "20px 40px",
  textAlign: "left",
});

export const DialogContent = styled(MIUDialogContent)({
  padding: "12px 40px 30px",
});

export const ModalDesc = styled(Typography)({
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "center",
});
