import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  InspirationToolPageContent,
  InspirationToolPageContentData,
  UploadInspirationToolPageContentData,
} from "types";

import { inspirationToolPageContentUrlBuilder } from "./urlBuilder/inspirationToolPageContent";

export const inspirationToolPageContentAPI = createApi({
  reducerPath: "inspirationToolPageContentAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["InspirationToolPageContents"],
  endpoints: build => ({
    getInspirationToolPageContents: build.query<
      InspirationToolPageContent[],
      void
    >({
      query: () => ({
        url: inspirationToolPageContentUrlBuilder.getInspirationToolPageContents(),
        method: "GET",
      }),
      providesTags: ["InspirationToolPageContents"],
    }),
    getInspirationToolPageContent: build.query<
      InspirationToolPageContent,
      string
    >({
      query: args => ({
        url: inspirationToolPageContentUrlBuilder.getInspirationToolPageContent(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolPageContents"],
    }),
    updateInspirationToolPageContent: build.mutation<
      InspirationToolPageContent,
      InspirationToolPageContentData | UploadInspirationToolPageContentData
    >({
      query: body => ({
        url: inspirationToolPageContentUrlBuilder.updateInspirationToolPageContent(
          body.id,
        ),
        method: "PATCH",
        data: (body as UploadInspirationToolPageContentData).data || body,
      }),
      invalidatesTags: ["InspirationToolPageContents"],
    }),
  }),
});

export const {
  useGetInspirationToolPageContentsQuery,
  useGetInspirationToolPageContentQuery,
  useUpdateInspirationToolPageContentMutation,
} = inspirationToolPageContentAPI;
