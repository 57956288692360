import { Cell } from "components/Table";

export const Cells: Cell[] = [
  {
    id: "name",
    value: "name",
    label: "File Name",
  },
];

export const EmptyError = {
  size: "",
  type: "",
  fileSize: "",
};
