import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MoodBoard } from "types";

interface MoodBoardState {
  moodBoard: MoodBoard | null;
  moodBoardSelectedPhotos: any | null;
}

const initialState: MoodBoardState = {
  moodBoard: null,
  moodBoardSelectedPhotos: [],
};

export const moodBoardSlice = createSlice({
  name: "moodBoard",
  initialState,
  reducers: {
    setMoodBoard: (state, action: PayloadAction<MoodBoard | null>) => {
      state.moodBoard = action.payload;
    },
    setMoodBoardSelectedPhotos: (state, action: PayloadAction<any | null>) => {
      state.moodBoardSelectedPhotos = action.payload;
    },
  },
});

export const { setMoodBoard, setMoodBoardSelectedPhotos } =
  moodBoardSlice.actions;
export const moodBoardSliceReducer = moodBoardSlice.reducer;
