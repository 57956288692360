import {
  Box,
  ImageListItem,
  ImageListItemBar,
  styled,
  Typography,
} from "@mui/material";

import { theme } from "theme";

export const GalleryImage = styled(ImageListItem)({
  img: {
    borderRadius: "4px",
  },
  ".MuiImageListItem-img": {
    minHeight: "246px",
    maxHeight: "246px",
    "@media (max-width: 1600px)": {
      minHeight: "215px",
      maxHeight: "215px",
    },
    "@media (max-width: 1440px)": {
      minHeight: "190px",
      maxHeight: "190px",
    },
    "@media (max-width: 1200px)": {
      minHeight: "140px",
      maxHeight: "140px",
    },
    "@media (max-width: 1024px)": {
      minHeight: "120px",
      maxHeight: "120px",
    },
  },
  "&:hover .inspiration-tool-checkbox": {
    display: "inline-flex",
  },
});

export const GalleryBox = styled(Box)({
  margin: "20px 0px",
});

export const ImageListItemAction = styled(ImageListItemBar)({
  background: "transparent",
});

export const ImageListItemDescription = styled(ImageListItemBar)({
  background: "rgba(0, 0, 0, 0.8)",
  ".MuiImageListItemBar-actionIcon": {
    width: "100%",
  },
});

export const GalleryTitle = styled(Typography)({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "140%",
  color: theme.color.orange,
  marginBottom: "20px",
});
