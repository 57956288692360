import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const EditIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.7031" cy="13.813" r="12.6406" fill="white" stroke="black" />
    <rect
      x="17.2544"
      y="7.60571"
      width="3.75681"
      height="13.3267"
      transform="rotate(45 17.2544 7.60571)"
      stroke="black"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83072 17.0288L6.50269 21.0135L10.4874 19.6855L7.83072 17.0288Z"
      stroke="black"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9107 10.2615L17.2546 7.60547L17.6973 7.16279C18.4344 6.45093 19.6059 6.46111 20.3305 7.18567C21.0551 7.91024 21.0652 9.08181 20.3534 9.81885L19.9107 10.2615Z"
      stroke="black"
    />
  </svg>
);
