import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { carDocumentsUrlBuilder } from "./urlBuilder/carDocuments";
import {
  CarByID,
  CarDocumentCategoryRemove,
  CarDocumentRemove,
  CreateCarDoc,
  CreateCarDocType,
} from "types";

export const carDocumentsAPI = createApi({
  reducerPath: "carDocumentsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["CarDocuments"],
  endpoints: build => ({
    getCarDocuments: build.query({
      query: (args?: CarByID) => ({
        url: carDocumentsUrlBuilder.getCarDocuments(args),
        method: "GET",
      }),

      providesTags: ["CarDocuments"],
    }),
    createCarDocuments: build.mutation<CreateCarDoc, CreateCarDoc>({
      query: (args?: CreateCarDoc) => ({
        url: carDocumentsUrlBuilder.createCarDocuments(args),
        method: "POST",
        data: args.fileData,
      }),
      invalidatesTags: ["CarDocuments"],
    }),
    deleteCarDocuments: build.mutation<CarDocumentRemove, CarDocumentRemove>({
      query: args => ({
        url: carDocumentsUrlBuilder.deleteCarDocuments(args),
        method: "DELETE",
      }),
      invalidatesTags: ["CarDocuments"],
    }),
    deleteCarDocumentsCategory: build.mutation<
      CarDocumentCategoryRemove,
      CarDocumentCategoryRemove
    >({
      query: args => ({
        url: carDocumentsUrlBuilder.deleteCarDocumentsCategory(args),
        method: "DELETE",
      }),
      invalidatesTags: ["CarDocuments"],
    }),
    createCategoryCarDocuments: build.mutation<
      CreateCarDocType,
      CreateCarDocType
    >({
      query: (args?: CreateCarDocType) => ({
        url: carDocumentsUrlBuilder.createCategoryCarDocuments(args),
        method: "POST",
        data: args.data,
      }),
      invalidatesTags: ["CarDocuments"],
    }),
  }),
});

export const {
  useGetCarDocumentsQuery,
  useDeleteCarDocumentsMutation,
  useCreateCarDocumentsMutation,
  useCreateCategoryCarDocumentsMutation,
  useDeleteCarDocumentsCategoryMutation,
} = carDocumentsAPI;
