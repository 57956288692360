import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CalendarAddIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1935 17.6975H5.41213C5.01301 17.6975 4.68945 17.374 4.68945 16.9749V6.85744C4.68945 6.45832 5.01301 6.13477 5.41213 6.13477H18.4203C18.8194 6.13477 19.1429 6.45832 19.1429 6.85744V11.5548"
      stroke={props.strokeColor ?? "black"}
    />
    <path d="M8.30273 4.69043V8.3038" stroke={props.strokeColor ?? "black"} />
    <path d="M15.5298 4.69043V8.3038" stroke={props.strokeColor ?? "black"} />
    <path d="M4.68945 9.74902H19.1429" stroke={props.strokeColor ?? "black"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9747 21.3117C19.3694 21.3117 21.3108 19.3704 21.3108 16.9757C21.3108 14.581 19.3694 12.6396 16.9747 12.6396C14.58 12.6396 12.6387 14.581 12.6387 16.9757C12.6387 19.3704 14.58 21.3117 16.9747 21.3117Z"
      stroke={props.strokeColor ?? "black"}
    />
    <path d="M16.9751 14.8076V19.1437" stroke={props.strokeColor ?? "black"} />
    <path d="M19.1427 16.9756H14.8066" stroke={props.strokeColor ?? "black"} />
  </svg>
);
