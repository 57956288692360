import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const IndentRightIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.33008 17.0015H12.3338" stroke="black" />
    <path d="M4.32812 14.3335H12.3331" stroke="black" />
    <path d="M6.33008 11.665H12.3338" stroke="black" />
    <path d="M4.32812 8.99658H12.3331" stroke="black" />
    <path d="M6.33008 6.32812H12.3338" stroke="black" />
    <path d="M14.334 19.0029V4.99414" stroke="black" />
    <path
      d="M17.0021 9.66357L15.668 11.6648H19.6705"
      stroke="black"
      strokeLinejoin="round"
    />
    <path d="M17.0021 13.6663L15.668 11.665" stroke="black" />
  </svg>
);
