import { endpoints } from "constant";

export const inspirationToolProductFamilyUrlBuilder = {
  getInspirationToolProductFamilies: function (
    study?: string | number,
  ): string {
    return `${endpoints.inspirationToolProductFamilies}${
      study ? `?study=${study}` : ""
    }`;
  },
  getInspirationToolProductFamily: function (id: string | number): string {
    return `${endpoints.inspirationToolProductFamilies}${id}/`;
  },
  createInspirationToolProductFamily: function (): string {
    return endpoints.inspirationToolProductFamilies;
  },
  deleteInspirationToolProductFamily: function (id: string | number): string {
    return `${endpoints.inspirationToolProductFamilies}${id}/`;
  },
  changeInspirationToolProductFamily: function (id: string | number): string {
    return `${endpoints.inspirationToolProductFamilies}${id}/`;
  },
};
