import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const SynchronizeArrowIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.34326 9.90625L6.43152 13.0386L8.86783 10.2543" stroke="black" />
    <path
      d="M13.0445 18.6124C16.6966 18.6124 19.6573 15.6517 19.6573 11.9995C19.6573 8.34738 16.6966 5.38672 13.0445 5.38672C9.3923 5.38672 6.43164 8.34738 6.43164 11.9995V13.0395"
      stroke="black"
    />
  </svg>
);
