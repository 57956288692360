import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";

import { clientProfileUrlBuilder } from "./urlBuilder/clientProfile";
import {
  IClientProfileData,
  IClientProfileNotificationsDataUpdate,
} from "types/ClientProfile";

export interface IUserAvatarImages {
  id: number;
  avatar_image: string;
}

export const clientProfileAPI = createApi({
  reducerPath: "clientProfileAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["clientProfileData"],
  endpoints: build => ({
    getClientProfile: build.query<IClientProfileData, void>({
      query: () => ({
        url: clientProfileUrlBuilder.clientProfile(),
        method: "GET",
      }),
      providesTags: ["clientProfileData"],
    }),
    getAvatarImages: build.query<IUserAvatarImages[], void>({
      query: () => ({
        url: clientProfileUrlBuilder.clientAvatar(),
        method: "GET",
      }),
      providesTags: ["clientProfileData"],
    }),
    updateClientProfile: build.mutation<IClientProfileData, IClientProfileData>(
      {
        query: args => ({
          url: clientProfileUrlBuilder.clientProfile(),
          method: "PUT",
          data: args,
        }),
        invalidatesTags: ["clientProfileData"],
      },
    ),
    updateClientProfileNotifications: build.mutation<
      IClientProfileNotificationsDataUpdate,
      IClientProfileNotificationsDataUpdate
    >({
      query: args => ({
        url: clientProfileUrlBuilder.clientProfile(),
        method: "PATCH",
        data: args,
      }),
      invalidatesTags: ["clientProfileData"],
    }),
    updateClientPhoto: build.mutation<void, File>({
      query: file => ({
        url: clientProfileUrlBuilder.clientPhoto(),
        method: "PATCH",
        data: file,
      }),
      invalidatesTags: ["clientProfileData"],
    }),
    updateClientAvatar: build.mutation<void, number>({
      query: avatar => ({
        url: clientProfileUrlBuilder.clientPhoto(),
        method: "PATCH",
        data: { avatar_photo: avatar, photo: null },
      }),
      invalidatesTags: ["clientProfileData"],
    }),
  }),
});

export const {
  useGetClientProfileQuery,
  useLazyGetClientProfileQuery,
  useGetAvatarImagesQuery,
  useUpdateClientProfileMutation,
  useUpdateClientPhotoMutation,
  useUpdateClientAvatarMutation,
  useUpdateClientProfileNotificationsMutation,
} = clientProfileAPI;
