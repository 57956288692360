import React, { FC, useState } from "react";

import { Carousel, GalleryDialog } from "components";
import { ArrowLink, ImageCarousel } from "components/Clients";

import { IStageTopic } from "./typed";
import {
  TextContainer,
  TopicContainer,
  TopicDescription,
  TopicTitle,
} from "./styled";

export const StageTopic: FC<IStageTopic> = ({
  reversed,
  title,
  description,
  ImagesData,
  linkButton,
}) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(null);

  const openModalGallery = i => {
    setConfirmOpen(true);
    setCurrentSlide(i);
  };

  return (
    <>
      <TopicContainer reversed={reversed}>
        <TextContainer reversed={reversed}>
          <TopicTitle>{title || ""}</TopicTitle>

          <TopicDescription>{description || ""}</TopicDescription>

          {!!linkButton && (
            <div onClick={linkButton.onClick}>
              <ArrowLink title={linkButton.title} />
            </div>
          )}
        </TextContainer>

        {!!ImagesData?.length && (
          <ImageCarousel images={ImagesData} onClick={openModalGallery} />
        )}
      </TopicContainer>

      <GalleryDialog open={confirmOpen} setOpen={setConfirmOpen}>
        <Carousel initialSlide={currentSlide} images={ImagesData} />
      </GalleryDialog>
    </>
  );
};
