import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  InspirationToolProductCategory,
  InspirationToolProductCategoriesQueryParams,
  InspirationToolProductCategoryPhotoData,
  InspirationToolProductCategoryDeletePhotoData,
  InspirationToolProductCategoriesClientQueryParams,
} from "types";

import { inspirationToolProductCategoryUrlBuilder } from "./urlBuilder/inspirationToolProductCategories";

export const inspirationToolProductCategoriesAPI = createApi({
  reducerPath: "inspirationToolProductCategoriesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["InspirationToolProductCategories"],
  endpoints: build => ({
    getInspirationToolProductCategories: build.query<
      InspirationToolProductCategory[],
      InspirationToolProductCategoriesQueryParams
    >({
      query: args => ({
        url: inspirationToolProductCategoryUrlBuilder.getInspirationToolProductCategories(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductCategories"],
    }),
    getInspirationToolProductCategoriesClient: build.query<
      InspirationToolProductCategory[],
      InspirationToolProductCategoriesClientQueryParams
    >({
      query: args => ({
        url: inspirationToolProductCategoryUrlBuilder.getInspirationToolProductCategoriesClient(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductCategories"],
    }),
    getInspirationToolProductCategory: build.query<
      InspirationToolProductCategory,
      string
    >({
      query: args => ({
        url: inspirationToolProductCategoryUrlBuilder.getInspirationToolProductCategory(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductCategories"],
    }),
    createInspirationToolProductCategory: build.mutation<
      InspirationToolProductCategory,
      InspirationToolProductCategory
    >({
      query: body => ({
        url: inspirationToolProductCategoryUrlBuilder.createInspirationToolProductCategory(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductCategories"],
    }),
    updateInspirationToolProductCategory: build.mutation<
      InspirationToolProductCategory,
      InspirationToolProductCategory
    >({
      query: body => ({
        url: inspirationToolProductCategoryUrlBuilder.updateInspirationToolProductCategory(
          body.id,
        ),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductCategories"],
    }),
    deleteInspirationToolProductCategory: build.mutation<
      InspirationToolProductCategory,
      string
    >({
      query: args => ({
        url: inspirationToolProductCategoryUrlBuilder.deleteInspirationToolProductCategory(
          args,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["InspirationToolProductCategories"],
    }),
    setPhotoInspirationToolProductCategory: build.mutation<
      InspirationToolProductCategory,
      InspirationToolProductCategoryPhotoData[]
    >({
      query: body => ({
        url: inspirationToolProductCategoryUrlBuilder.setPhotoInspirationToolProductCategory(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductCategories"],
    }),
    deletePhotosInspirationToolProductCategory: build.mutation<
      void,
      InspirationToolProductCategoryDeletePhotoData
    >({
      query: body => ({
        url: inspirationToolProductCategoryUrlBuilder.deletePhotosInspirationToolProductCategory(),
        method: "DELETE",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductCategories"],
    }),
    deletePhotoInspirationToolProductCategory: build.mutation<void, string>({
      query: id => ({
        url: inspirationToolProductCategoryUrlBuilder.deletePhotosInspirationToolProductCategory(),
        method: "DELETE",
        data: {
          photos: [id],
        },
      }),
    }),
  }),
});

export const {
  useGetInspirationToolProductCategoriesQuery,
  useGetInspirationToolProductCategoriesClientQuery,
  useLazyGetInspirationToolProductCategoriesQuery,
  useLazyGetInspirationToolProductCategoriesClientQuery,
  useGetInspirationToolProductCategoryQuery,
  useCreateInspirationToolProductCategoryMutation,
  useUpdateInspirationToolProductCategoryMutation,
  useDeleteInspirationToolProductCategoryMutation,
  useSetPhotoInspirationToolProductCategoryMutation,
  useDeletePhotosInspirationToolProductCategoryMutation,
  useDeletePhotoInspirationToolProductCategoryMutation,
} = inspirationToolProductCategoriesAPI;
