import { styled, TableRow, TableCell, TableSortLabel } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { TableRowStyledProps } from "./typed";

export const TableRowStyled = styled(TableRow)<TableRowStyledProps>(
  ({
    lastElementFullWidth,
    withAction,
    handleSelectAll,
    collaboratorsTable,
    documentsTable,
    moodBoardsTable,
  }) => ({
    backgroundColor: theme.color.white,
    th: {
      padding: "7px 16px",

      span: {
        fontSize: theme.fontSize.base,
        fontWeight: theme.fontWeight.regular,
        lineHeight: "18px",
        marginRight: "5px",
      },
      ...(collaboratorsTable && {
        span: {
          fontSize: theme.fontSize.sm,
          fontWeight: theme.fontWeight.bold,
          lineHeight: "15.6px",
        },
      }),

      ...(documentsTable && {
        padding: "10px 0 13px 30px",

        [BreakPointTheme.breakpoints.down("lg")]: {
          padding: "19px 0 5px 21px",

          span: {
            fontSize: theme.fontSize.sm,
            fontWeight: theme.fontWeight.bold,
            lineHeight: "15.6px",
          },
        },

        [BreakPointTheme.breakpoints.down("md")]: {
          padding: "12px 0 13px 10px",

          span: {
            fontSize: theme.fontSize.base,
            fontWeight: theme.fontWeight.regular,
            lineHeight: "18px",
          },
        },
      }),

      ...(moodBoardsTable && {
        padding: "10px 0 12px 24px",

        [BreakPointTheme.breakpoints.down("lg")]: {
          padding: "19px 0 5px 20px",

          span: {
            fontSize: theme.fontSize.sm,
            fontWeight: theme.fontWeight.bold,
            lineHeight: "15.6px",
          },
        },

        [BreakPointTheme.breakpoints.down("md")]: {
          padding: "10px 0 12px 22px",

          span: {
            fontSize: theme.fontSize.base,
            fontWeight: theme.fontWeight.regular,
            lineHeight: "18px",
          },
        },
      }),
    },

    span: {
      fontWeight: "600",
      fontSize: theme.fontSize.sm,
      lineHeight: "140%",
      color: theme.color.black,
      "& .hover": {
        color: theme.color.black,
      },
      "& .focus": {
        color: theme.color.black,
      },
    },

    "& .MuiTableSortLabel-root": {
      color: `${theme.color.black} !important`,
    },

    ...(lastElementFullWidth && {
      ...(withAction
        ? handleSelectAll
          ? {
              "& th:nth-last-child(-n + 2)": {
                width: "40%",
              },
            }
          : {
              "& :nth-last-child(2)": {
                width: "60%",
              },
            }
        : {
            "& :last-child": {
              width: "60%",
            },
          }),
    }),

    textOverflow: "ellipsis",
  }),
);

export const TableCellStyled = styled(TableCell)({
  whiteSpace: "nowrap",
});

export const HeadingTableCell = styled(TableCellStyled)<{ fixed?: boolean }>(
  ({ fixed }) => ({
    ...(fixed && {
      minWidth: "200px",
    }),
  }),
);

export const StyledTableSortLabel = styled(TableSortLabel)<{
  collaboratorsTable?: boolean;
}>(({ collaboratorsTable }) => ({
  span: {
    fontSize: theme.fontSize.base,
    fontWeight: theme.fontWeight.regular,
    lineHeight: "18px",
  },

  ...(collaboratorsTable && {
    span: {
      fontSize: theme.fontSize.sm,
      fontWeight: theme.fontWeight.bold,
      lineHeight: "15.6px",
    },
  }),
}));
