import { Box, styled } from "@mui/material";

import { theme } from "theme";

export const HeadlineBlock = styled(Box)({
  position: "sticky",
  top: "84px",
  zIndex: 2,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 20px",
  background: theme.color.grey["300"],
});

export const HeadlineNameBlock = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.color.black,

  svg: {
    stroke: theme.color.black,
    marginRight: "4px",
    "path, rect": {
      stroke: theme.color.black,
    },
  },
});

export const HeadlineDateBlock = styled(Box)({
  fontSize: theme.fontSize.base,
  fontWeight: "600",
  width: "100%",
  maxWidth: "336px",
});
