import React, { useEffect, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { ProtectedAdminRoute, Loader, Layout } from "components";
import { LEFT_MENU_ITEMS_INFO } from "constant";
import { Layout as ClientLayout } from "components/Clients";
import { Layout as LoginLayout } from "components/Clients/Layout/Login";
import { isAdminViewSelected } from "redux/selectors";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { setCurrentUser } from "redux/slices";
import {
  ADMIN_HOME_ROUTE,
  ADMIN_ROUTES,
  CLIENT_HOME_ROUTE,
  CLIENT_ROUTES,
} from "constant";

import "./App.css";
import { useUserRole } from "hooks/useUserRole";

function App() {
  const isAdminView = useAppSelector(isAdminViewSelected);
  const { isGPN } = useUserRole();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      setCurrentUser(
        location.pathname.includes("/admin/") ? "admin" : "client",
      ),
    );
  });

  const getAdminRoutesKeys = () => {
    const keys = Object.keys(ADMIN_ROUTES);

    if (isGPN) {
      return keys.filter(key => !!ADMIN_ROUTES[key].gpnAccess);
    }

    if (!isAdminView) {
      return keys.filter(key => !ADMIN_ROUTES[key].adminView);
    }

    return keys;
  };

  const ADMIN_ROUTES_KEYS = getAdminRoutesKeys();

  return (
    <>
      <div className="App">
        <Routes>
          <Route>
            {Object.keys(CLIENT_ROUTES).map(key => {
              const { Component, menuItem, ...props } = CLIENT_ROUTES[key];
              const LayoutComponent = menuItem ? ClientLayout : LoginLayout;
              return (
                <Route
                  key={key}
                  {...props}
                  element={
                    <LayoutComponent {...props}>
                      <Suspense fallback={<Loader />}>
                        <Component />
                      </Suspense>
                    </LayoutComponent>
                  }
                />
              );
            })}

            <Route
              path="*"
              element={<Navigate replace to={CLIENT_HOME_ROUTE} />}
            />
          </Route>

          <Route>
            {ADMIN_ROUTES_KEYS.map(key => {
              const { Component, protectedRoute, menuItem, ...props } =
                ADMIN_ROUTES[key];

              return protectedRoute ? (
                <Route key={key} {...props} element={<ProtectedAdminRoute />}>
                  <Route
                    {...props}
                    element={
                      <Layout {...LEFT_MENU_ITEMS_INFO[menuItem]}>
                        <Suspense fallback={<Loader />}>
                          <Component />
                        </Suspense>
                      </Layout>
                    }
                  />
                </Route>
              ) : (
                <Route
                  key={key}
                  {...props}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Component />
                    </Suspense>
                  }
                />
              );
            })}

            <Route
              path="/admin/*"
              element={<Navigate replace to={ADMIN_HOME_ROUTE} />}
            />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
