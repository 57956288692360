import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const DownloadIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0051 16.5249V17.917C20.0047 19.07 19.0702 20.0045 17.9173 20.0049H6.08297C4.92988 20.0049 3.99512 19.0701 3.99512 17.917V16.5249"
      stroke="black"
    />
    <path d="M12 16.4451V3.99512" stroke="black" />
    <path d="M17.0944 11.3511L12.0003 16.4452L6.90625 11.3511" stroke="black" />
  </svg>
);
