import { endpoints } from "constant";

export const disclaimerUrlBuilder = {
  getDisclaimers: function (): string {
    return endpoints.disclaimers;
  },
  updateDisclaimer: function (id: string | number): string {
    return `${endpoints.disclaimers}${id}/`;
  },
};
