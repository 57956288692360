import React, { FC } from "react";
import { Box, Tooltip } from "@mui/material";

import type { TableRowProps } from "./typed";
import { TableCellAction, TableCellStyled, TableRowStyled } from "./styled";

const getValue = (value, row) =>
  value?.split(".").reduce((object, field) => object[field], row);

export const TableRow: FC<TableRowProps> = ({
  actions,
  row,
  cells,
  allRowClickable,
  getClickHandler,
  firstColumnWidth,
}) => (
  <TableRowStyled
    hover
    onClick={allRowClickable ? getClickHandler(row) : null}
    key={row.id}
    clickable={allRowClickable}
  >
    {cells.map(
      ({
        width,
        minWidth,
        maxWidth,
        value,
        defaultValue,
        id,
        clickable,
        Wrapper,
        getRowClickHandler,
      }) => (
        <TableCellStyled
          key={id}
          width={width}
          minWidth={minWidth}
          maxWidth={maxWidth}
          firstColumnWidth={firstColumnWidth}
          clickable={clickable}
          onClick={clickable ? getClickHandler(row) : null}
        >
          {Wrapper ? (
            <Wrapper
              value={getValue(value, row) || getValue(defaultValue, row)}
              item={row}
              onClick={getRowClickHandler ? getRowClickHandler(row) : null}
            />
          ) : (
            getValue(value, row) || getValue(defaultValue, row)
          )}
        </TableCellStyled>
      ),
    )}
    {actions ? (
      <TableCellAction width="10%">
        {actions.map(({ label, Icon, onClick }) => {
          if (row?.document_link && label === "Download") return null;

          return (
            <Tooltip key={label} title={label} placement="left-start">
              <Box>
                <Icon
                  title={label}
                  onClick={event => {
                    event.stopPropagation();
                    onClick(row);
                  }}
                />
              </Box>
            </Tooltip>
          );
        })}
      </TableCellAction>
    ) : null}
  </TableRowStyled>
);

TableRow.defaultProps = {
  allRowClickable: false,
  actions: null,
};
