import { styled, DialogTitle as MUIDialogTitle } from "@mui/material";
import { DialogBlurBackdrop } from "styled";
import { theme } from "theme";

export const Dialog = styled(DialogBlurBackdrop)<{
  height?: number;
  paddingBot: number;
}>(({ height, paddingBot }) => ({
  ".MuiPaper-root": {
    borderRadius: "0px",
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "15px",

    ...(height && {
      height: `${height}px`,
    }),
    ...(paddingBot && {
      paddingBottom: `${paddingBot}px`,
    }),
  },
}));

export const DialogTitle = styled(MUIDialogTitle)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.base,
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "center",
  padding: "15px 0px",
});

export const StyledButton = styled("button")({
  position: "absolute",
  top: "5px",
  right: "5px",
  padding: 0,
  margin: 0,
  background: "none",
  border: "none",
  cursor: "pointer",
});
