import { styled } from "@mui/material";

import { theme } from "theme";

import { PointAcceptIcon, PointCircleIcon } from "icons";

interface PointProps {
  isReversed: boolean;
}

export const PointContainer = styled("div")<PointProps>(({ isReversed }) => ({
  position: "relative",
  display: "flex",
  flexDirection: isReversed ? "row" : "row-reverse",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: isReversed ? 0 : "-5px",
}));

export const PointLineWrapper = styled("div")<{
  isReversed: boolean;
  isSelected: boolean;
}>(({ isReversed, isSelected }) => ({
  margin: isReversed ? "-5px 0.75px -6px -2px" : "1px -2.25px 0px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "rotate(90deg)",
  width: "31px",

  svg: {
    height: "31px",

    path: {
      stroke: isSelected ? theme.color.black : theme.color.black,
    },
  },
}));

export const PointWrapper = styled("div")<PointProps>(({ isReversed }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: isReversed ? "flex-start" : "flex-end",
}));

interface PointCircleProps {
  isCurrent: boolean;
  isCompleted: boolean;
  isSelected: boolean;
}

export const PointCircle = styled(PointCircleIcon)<PointCircleProps>(
  ({ isCurrent, isCompleted, isSelected }) => ({
    path: {
      stroke: isSelected ? theme.color.black : theme.color.black,
      fill: isCurrent
        ? theme.color.orange
        : isSelected
        ? theme.color.primary[800]
        : isCompleted
        ? theme.color.grey[1200]
        : theme.color.white,
    },

    height: "36px",
    width: "36px",
  }),
);

export const PointAccepted = styled(PointAcceptIcon)<PointProps>(
  ({ isReversed }) => ({
    position: "absolute",
    left: "50%",
    transform: " translateX(-50%)",

    ...(isReversed
      ? {
          left: "27%",
          bottom: "8px",
        }
      : {
          left: "72%",
          top: "12px",
        }),
  }),
);
