import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  Partner,
  PartnerData,
  PartnersQueryParams,
  PartnerClient,
  PartnerClientData,
  PartnerForClient,
} from "types";

import { partnerUrlBuilder } from "./urlBuilder/partners";

export const partnersAPI = createApi({
  reducerPath: "partnersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Partners"],
  endpoints: build => ({
    getPartners: build.query<Partner[], PartnersQueryParams | void>({
      query: (args?: PartnersQueryParams) => ({
        url: partnerUrlBuilder.getPartners(args),
        method: "GET",
      }),
      providesTags: ["Partners"],
    }),
    getPartnersForClient: build.query<PartnerForClient[], void>({
      query: () => ({
        url: partnerUrlBuilder.getPartnersForClient(),
        method: "GET",
      }),
      providesTags: ["Partners"],
    }),
    getPartner: build.query<Partner, string>({
      query: args => ({
        url: partnerUrlBuilder.getPartner(args),
        method: "GET",
      }),
      providesTags: ["Partners"],
    }),
    createPartner: build.mutation<Partner, PartnerData>({
      query: body => ({
        url: partnerUrlBuilder.createPartner(),
        method: "POST",
        data: body.data,
      }),
      invalidatesTags: ["Partners"],
    }),
    updatePartner: build.mutation<Partner, PartnerData>({
      query: body => ({
        url: partnerUrlBuilder.updatePartner(body.id),
        method: "PUT",
        data: body.data,
      }),
      invalidatesTags: ["Partners"],
    }),
    deletePartner: build.mutation<Partner, string>({
      query: args => ({
        url: partnerUrlBuilder.deletePartner(args),
        method: "DELETE",
      }),
      invalidatesTags: ["Partners"],
    }),
    createPartnerClient: build.mutation<PartnerClient, PartnerClientData>({
      query: body => ({
        url: partnerUrlBuilder.createPartnerClient(),
        method: "POST",
        data: body,
      }),
    }),
    updatePartnerClient: build.mutation<PartnerClient, PartnerClientData>({
      query: body => ({
        url: partnerUrlBuilder.updatePartnerClient(body.id),
        method: "PUT",
        data: body,
      }),
    }),
    deletePartnerClient: build.mutation<PartnerClient, string>({
      query: args => ({
        url: partnerUrlBuilder.deletePartnerClient(args),
        method: "DELETE",
      }),
      invalidatesTags: ["Partners"],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnersForClientQuery,
  useGetPartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
  useCreatePartnerClientMutation,
  useUpdatePartnerClientMutation,
  useDeletePartnerClientMutation,
} = partnersAPI;
