import { CarsByID } from "../../types/Cars";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/baseQuery";
import { CarByID } from "types";
import { timelineStagesUrlBuilder } from "./urlBuilder/timelineStages";

export interface ITimelineStages {
  stage_id: string;
  stage_name: string;
  is_active_stage: boolean;
  published_on: string;
}

export const timelineAPI = createApi({
  reducerPath: "timelineAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: build => ({
    getTimelineRoad: build.query<ITimelineStages[], CarsByID>({
      query: (args?: CarByID) => ({
        url: timelineStagesUrlBuilder.gettimelineStages(args),
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTimelineRoadQuery, useLazyGetTimelineRoadQuery } =
  timelineAPI;
