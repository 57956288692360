import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const DashboardIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5001 19.6715C16.7369 19.6715 20.1715 16.2369 20.1715 12.0001C20.1715 7.76324 16.7369 4.32861 12.5001 4.32861C8.26324 4.32861 4.82861 7.76324 4.82861 12.0001C4.82861 16.2369 8.26324 19.6715 12.5001 19.6715Z"
      stroke="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4434 12.9433C12.9199 13.4489 12.0878 13.4417 11.5731 12.927C11.0585 12.4124 11.0513 11.5803 11.5569 11.0568C12.0779 10.5358 16.9816 7.52124 16.9816 7.52124C16.9816 7.52124 13.9677 12.4223 13.4434 12.9433Z"
      stroke="white"
    />
    <path d="M6.82983 12H7.83046" stroke="white" />
    <path d="M8.49097 7.99097L9.19808 8.69808" stroke="white" />
    <path d="M12.5 6.32983V7.33046" stroke="white" />
    <path d="M18.1703 12H17.1697" stroke="white" />
    <path
      d="M18.2591 17.0699C16.5683 15.9032 14.554 15.2965 12.5001 15.3355C10.4463 15.2965 8.43195 15.9032 6.74121 17.0699"
      stroke="white"
    />
  </svg>
);
