import { keyframes, styled } from "@mui/material";

import { ButtonPlayIcon } from "icons";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled("div")({
  position: "relative",
  height: "inherit",

  opacity: 0,
  animation: `${fadeIn} .3s ease-in-out forwards`,
});

export const Button = styled(ButtonPlayIcon)<{ onClick: () => void }>({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  cursor: "pointer",
});

export const VimeoPlayerElement = styled("div")({
  height: "inherit",

  iframe: {
    transition: ".2s",
  },
});

export const TransparentBackground = styled("div")<{ thumbnail?: string }>(
  ({ thumbnail }) => ({
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",

    background: thumbnail
      ? `url(${thumbnail}) 50%/cover no-repeat`
      : "transparent",
  }),
);
