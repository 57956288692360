import React, { FC, useEffect } from "react";

import { LEFT_MENU_ITEMS_INFO, QUERY_OPTIONS, ROLES } from "constant";
import { isAdminViewSelected } from "redux/selectors";
import { setAdminView, updateAdminUser } from "redux/slices";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useUserRole } from "hooks/useUserRole";
import { useGetUserProfileQuery } from "redux/query/adminsAPI";
import { LightViewIcon } from "icons";

import {
  AppSidebar,
  Name,
  NavLink,
  Role,
  RoleButton,
  SidebarLinks,
  SidebarUserBox,
  SidebarWrapper,
} from "./styled";
import { SidebarProps } from "./typed";

export const Sidebar: FC<SidebarProps> = ({ activeSidebar }) => {
  const { isGPN, isAdmin } = useUserRole();
  const isAdminView = useAppSelector(isAdminViewSelected);
  const dispatch = useAppDispatch();
  const { data } = useGetUserProfileQuery(null, QUERY_OPTIONS);

  const getMenuItemsKeys = () => {
    const keys = Object.keys(LEFT_MENU_ITEMS_INFO);

    if (isGPN) {
      return keys.filter(key => !!LEFT_MENU_ITEMS_INFO[key].gpnAccess);
    }

    if (!isAdminView) {
      return keys.filter(key => !LEFT_MENU_ITEMS_INFO[key].adminView);
    }

    return keys;
  };

  const MENU_ITEMS_KEYS = getMenuItemsKeys();

  const handleSwitch = () => {
    dispatch(setAdminView(isAdminView ? "staff" : "admin"));
  };

  useEffect(() => {
    if (data) dispatch(updateAdminUser(data));
  }, [data]);

  const getRoleTitle = () => {
    if (!data?.role) {
      return "";
    }

    if (data.role === ROLES.GlobalPartner) {
      return "Global Partner";
    }

    return data.role;
  };

  const role = getRoleTitle();

  return (
    <AppSidebar>
      <SidebarWrapper className={activeSidebar ? "is-active" : ""}>
        <SidebarUserBox className={activeSidebar ? "is-active" : ""}>
          <>
            <Name>{`${data?.first_name || ""} ${data?.last_name || ""}`}</Name>
            <Role>{role}</Role>

            {!isGPN && (
              <RoleButton
                startIcon={<LightViewIcon />}
                onClick={handleSwitch}
                isAdminView={isAdminView}
                disabled={!isAdmin}
              >
                {isAdminView ? "Admin View" : "Staff View"}
              </RoleButton>
            )}
          </>
        </SidebarUserBox>
        <SidebarLinks>
          {MENU_ITEMS_KEYS.map(key => {
            const { name, path, icon } = LEFT_MENU_ITEMS_INFO[key];

            return (
              <NavLink
                key={path}
                to={path}
                className={activeSidebar ? "is-active" : ""}
              >
                {icon}
                {name}
              </NavLink>
            );
          })}
        </SidebarLinks>
      </SidebarWrapper>
    </AppSidebar>
  );
};
