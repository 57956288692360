import { endpoints } from "constant";

export const timelineStagesUrlBuilder = {
  gettimelineStages: function ({
    id,
  }: {
    id?: string | number;
  } = {}): string {
    return `${endpoints.cars}${id}/timeline/`;
  },
};
