import { IconButton, InputAdornment } from "@mui/material";
import React, { FC, useMemo } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { Input, InputLabel } from "../styled";

import { LightViewIcon } from "icons";

interface PasswordInputType extends ControllerRenderProps {
  label: string;
  placeholder?: string;
  fieldState: ControllerFieldState;
  variant?: "standard" | "filled" | "outlined";
  withHeaderLabel?: string;
  padding?: string;
}

export const PasswordInput: FC<PasswordInputType> = ({
  label,
  placeholder,
  fieldState,
  variant,
  withHeaderLabel,
  padding,
  ...field
}) => {
  const errorMessage = useMemo(
    () => fieldState.error?.message,
    [fieldState.error],
  );

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <>
      {withHeaderLabel && <InputLabel>{withHeaderLabel}</InputLabel>}
      <Input
        fullWidth
        label={label}
        variant={variant}
        padding={padding}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <LightViewIcon /> : <LightViewIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...field}
        placeholder={placeholder}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </>
  );
};

PasswordInput.defaultProps = {
  variant: "standard",
};
