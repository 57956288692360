import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ZoomInIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87117 11.8839C10.4975 11.8839 12.6266 9.75485 12.6266 7.12849C12.6266 4.50213 10.4975 2.37305 7.87117 2.37305C5.24481 2.37305 3.11572 4.50213 3.11572 7.12849C3.11572 9.75485 5.24481 11.8839 7.87117 11.8839Z"
      stroke="black"
    />
    <path d="M7.87109 4.53467V9.72243" stroke="black" />
    <path d="M10.4651 7.12842H5.27734" stroke="black" />
  </svg>
);
