import React, { FC } from "react";
import { Button } from "@mui/material";

import type { LegalDocument } from "types";
import { getFileNameFromUrl } from "utils/fileDownload";

import { DialogTitle } from "../styled";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Iframe,
  FullImage,
} from "./styled";
import type { PDFViewerProps } from "./typed";

export const PDFViewer: FC<PDFViewerProps> = ({
  open,
  setOpen,
  docUrl,
  largePreview,
}) => {
  const handleClose = () => setOpen(false);
  const url = docUrl || (open as LegalDocument)?.file;
  const fileName = getFileNameFromUrl(url);
  const isImage = fileName.match(/\.(jpg|jpeg|png)$/i);

  return (
    <Dialog
      fullWidth
      scroll="paper"
      largePreview={largePreview}
      open={Boolean(open)}
      onClose={handleClose}
    >
      <DialogTitle>Preview</DialogTitle>

      <DialogContent>
        {isImage ? (
          <FullImage component="img" image={url} />
        ) : (
          <Iframe src={docUrl || (open as LegalDocument)?.file}></Iframe>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
