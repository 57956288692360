import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

interface ISliderArrowIcon extends SvgIconConstituentValues {
  right?: boolean;
}

export const SliderArrowIcon: FC<ISliderArrowIcon> = ({ right, ...props }) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    {...props}
    style={{ rotate: right ? "180deg" : "" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.83425 0.870021L1.24276 5.818C1.19504 5.86021 1.16772 5.92085 1.16772 5.98455C1.16772 6.04825 1.19504 6.10889 1.24276 6.1511L6.83425 11.0986"
      stroke={props.strokeColor || "white"}
    />
  </svg>
);
