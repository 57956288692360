import React, { useState, useRef, FC } from "react";

import { ArrowDownIcon } from "icons";
import { IUniversalContentPoint } from "types";

import { TimelineVerticalSteps } from "./TimelineVerticalSteps";
import {
  AccordionContent,
  AccordionContentWrapper,
  AccordionWrapper,
  IconWrapper,
  Title,
  TitleWrapper,
} from "./styled";
import { PageBlockLoader } from "components/PageBlockLoader";

interface TimeLineStepsDropDownProps {
  isLoading?: boolean;
  selectedStage?: number;
  UniversalContentPoints: IUniversalContentPoint[];
  handleStageClick: (id: number | string) => void;
}

export const TimeLineStepsDropDown: FC<TimeLineStepsDropDownProps> = ({
  isLoading,
  selectedStage,
  UniversalContentPoints,
  handleStageClick,
}) => {
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState(819);

  const toggleList = () => {
    if (isOpen) {
      setContentHeight(0);
    } else {
      setContentHeight(contentRef.current.scrollHeight);
    }
    setIsOpen(!isOpen);
  };

  return (
    <AccordionWrapper>
      {isLoading && <PageBlockLoader />}
      <div>
        <div>
          <TitleWrapper>
            <Title>TimeLine</Title>

            <IconWrapper onClick={toggleList} isOpen={isOpen}>
              <ArrowDownIcon width="10" height="5" />
            </IconWrapper>
          </TitleWrapper>
        </div>

        <AccordionContentWrapper isOpen={isOpen} height={contentHeight}>
          <AccordionContent ref={contentRef}>
            <TimelineVerticalSteps
              selectedStageId={selectedStage}
              points={UniversalContentPoints}
              handleStageClick={handleStageClick}
            />
          </AccordionContent>
        </AccordionContentWrapper>
      </div>
    </AccordionWrapper>
  );
};
