import { styled, InputLabel, Select, MenuItem } from "@mui/material";

import { theme } from "theme";

export const SelectInput = styled(Select)<{
  padding?: string;
  selectColor?: string;
}>(({ padding, selectColor }) => ({
  borderRadius: "0px",

  "& .MuiSelect-select": {
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    borderRadius: "0px",
    ...(padding && {
      padding,
    }),
  },
  ":after": {
    borderColor: selectColor ? selectColor : theme.color.grey[500],
  },

  "& .MuiSelect-icon": {
    top: "calc(50% - 0.6em)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${
      selectColor ? selectColor : theme.color.grey[500]
    } !important`,
  },

  "& .MuiInputBase-root-MuiInput-root-MuiSelect-root:after": {
    borderBottom: `2px solid ${
      selectColor ? selectColor : theme.color.grey[500]
    } !important`,
  },
  "& label": {
    color: theme.color.grey[500],
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    lineHeight: "140%",
  },
  "& label.Mui-focused": {
    color: `${selectColor ? selectColor : theme.color.grey[500]}`,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: `${selectColor ? selectColor : theme.color.grey[500]}`,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
    "& fieldset": {
      borderColor: `${selectColor ? selectColor : theme.color.grey[500]}`,
    },
    "&:hover fieldset": {
      borderColor: `${selectColor ? selectColor : theme.color.grey[500]}`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `${selectColor ? selectColor : theme.color.grey[500]}`,
    },
  },

  "& .MuiPopover-paper": {
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
    marginTop: "6px",
  },
}));

export const SelectLabel = styled(InputLabel)({
  color: theme.color.grey[500],
  "&:after": {
    borderBottom: `2px solid ${theme.color.black}`,
  },

  "&.Mui-focused": {
    color: `${theme.color.grey[500]} !important`,
  },
});

export const StyledPlaceholder = styled("span")({
  fontSize: theme.fontSize.base,
  fontWeight: theme.fontWeight.regular,
  lineHeight: "140%",

  color: theme.color.grey[400],
});

export const StyledMenuItem = styled(MenuItem)({
  width: "100%",
  padding: "15px 20px",
  position: "relative",
});

export const CustomDevider = styled("div")({
  position: "absolute",
  bottom: "0",
  left: "20px",
  right: "20px",
  borderBottom: `1px solid ${theme.color.grey[200]}`,
});
