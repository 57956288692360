import { createApi } from "@reduxjs/toolkit/query/react";

import { clientAboutMeUrlBuilder } from "./urlBuilder/clientAboutMe";
import { axiosBaseQuery } from "utils/baseQuery";
import { IClientAboutMeData } from "types/ClientProfile";

export const clientAboutMeAPI = createApi({
  reducerPath: "clientAboutMeAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["clientAboutMeData"],
  endpoints: build => ({
    getClientAboutMe: build.query<IClientAboutMeData, number>({
      query: (id: number) => ({
        url: clientAboutMeUrlBuilder.aboutMe(id),
        method: "GET",
      }),
      providesTags: ["clientAboutMeData"],
    }),
    setAboutMeQuestion: build.mutation<
      void,
      { id: number; data: { [key: string]: string } }
    >({
      query: ({ id, data }) => ({
        url: clientAboutMeUrlBuilder.setAboutMeQuestion(id),
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["clientAboutMeData"],
    }),
  }),
});

export const { useGetClientAboutMeQuery, useSetAboutMeQuestionMutation } =
  clientAboutMeAPI;
