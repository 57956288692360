type Endpoints = {
  [key: string]: string;
};

export const endpoints: Endpoints = {
  cars: "api/cars/",
  clients: "api/clients/",
  stages: "api/stages",
  universalContent: "api/universal-content/",
  announcements: "api/announcements/",
  clientPortalLogin: "api/user/login/",
  photos: "api/photos/",
  events: "api/events/",
  event: "api/event/",
  admins: "api/user/admins/",
  microsoft: "microsoft/",
  legalDocuments: "api/legal-documents/",
  legalDocumentCategories: "api/legal-document-categories/",
  disclaimers: "api/disclaimer/",
  globalPartners: "api/global-partners/",
  globalPartnersForClient: "api/global-partners-for-client-list/",
  globalPartnerClients: "api/global-partner-clients/",
  adminProfile: "api/user/information/",
  inspirationToolPageContent: "api/inspiration-tool-page-content/",
  inspirationToolProductCategories: "api/product-categories/",
  inspirationToolProductCategoriesClient: "api/product-categories-client/",
  inspirationToolProductFamilies: "api/product-families/",
  inspirationToolProductStudies: "api/product-studies/",
  client: "api/client/",
  clientProfile: "api/client/profile/",
  clientPhoto: "api/client/photo/",
  avatarImages: "api/user/avatar-images/",
  clientAboutMe: "api/about-me/",
  clientCollaborators: "api/collaborators/",
  instagramMedia: "/media",
  portfolio: "api/portfolio/cars/",
  likeDislikePhoto: "api/like-dislike-photo/",
  zipArchive: "api/zip-archive/",
  zipPhotos: "api/photos/zip-archive/",
  zipDocs: "api/documents/zip-archive/",
  search: "api/search-page-results/",
  notifications: "api/notifications/",
  passwordReset: "api/password_reset/",
  validateToken: "api/password_reset/validate_token/",
  createNewPassword: "api/password_reset/confirm/",
  updatePassword: "api/user/update-password/",
  collaboratorClients: "api/collaborator-clients/",
  collaboratorProfile: "api/user/information/",
  globalPartnersClients: "api/clients-for-global-partner/",
};
