import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LightBrightIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7793_38830)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6437 19.171C10.697 19.08 10.7946 19.0254 10.8922 19.0254H13.1103C13.2168 19.0254 13.3055 19.08 13.3588 19.171C13.412 19.262 13.4209 19.3712 13.3676 19.4622L13.1724 19.8717C12.9772 20.2812 12.5602 20.5451 12.1166 20.5451H11.877C11.4245 20.5451 11.0164 20.2903 10.8212 19.8717L10.626 19.4622C10.5816 19.3712 10.5816 19.2529 10.6349 19.171H10.6437Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0013 7.58691C9.86298 7.58691 8.13281 9.36143 8.13281 11.5546C8.13281 12.7831 8.69179 13.9479 9.65004 14.685C9.72102 14.7396 9.76538 14.8306 9.76538 14.9307V15.2037C9.76538 15.4403 9.85411 15.6678 10.0227 15.8316C10.1824 15.9954 10.4042 16.0955 10.6349 16.0955H13.3677C13.8379 16.0955 14.2283 15.7042 14.2283 15.2219V14.9853C14.2106 14.8852 14.2549 14.7851 14.3348 14.7305C15.6568 13.7022 16.1981 11.9186 15.6657 10.3078C15.1333 8.68803 13.6605 7.60511 11.9924 7.60511L12.0013 7.58691Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.30348 11.7178H3.94336"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.0554 11.7178H17.6953"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.6699 17.5781H13.3317"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.6954 5.87598L16.0273 7.5868"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12 3.45508V5.87571"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.30469 5.87598L7.97275 7.5868"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7793_38830">
        <rect
          width="17"
          height="18"
          fill="white"
          transform="translate(3.5 3)"
        />
      </clipPath>
    </defs>
  </svg>
);
