import React, { FC } from "react";

import { HeadlineBlock, HeadlineDateBlock, HeadlineNameBlock } from "./styled";
import { HeadlineProps } from "./typed";

export const Headline: FC<HeadlineProps> = ({ icon, name, date }) => {
  return (
    <HeadlineBlock>
      <HeadlineNameBlock>
        {icon}
        {name}
      </HeadlineNameBlock>
      <HeadlineDateBlock>{date}</HeadlineDateBlock>
    </HeadlineBlock>
  );
};
