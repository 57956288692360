import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Comission } from "types/Comission";

interface ComissionsState {
  activeComission: Comission | null;
  comissions: Comission[] | null;
  activeStage: number | string | null;
  activeSubstage: number | string | null;
}

const initialState: ComissionsState = {
  activeComission: null,
  comissions: null,
  activeStage: null,
  activeSubstage: null,
};

export const comissionsSlice = createSlice({
  name: "comissions",
  initialState,
  reducers: {
    setComissions: (state, action: PayloadAction<Comission[]>) => {
      state.comissions = action.payload;
    },
    setActiveComission: (state, action: PayloadAction<Comission>) => {
      state.activeComission = action.payload;
    },
    setActiveStage: (state, action: PayloadAction<number | string>) => {
      state.activeStage = action.payload;
    },
    setActiveSubstage: (state, action: PayloadAction<number | string>) => {
      state.activeSubstage = action.payload;
    },
  },
});

export const {
  setActiveComission,
  setComissions,
  setActiveStage,
  setActiveSubstage,
} = comissionsSlice.actions;
export const comissionsSliceReducer = comissionsSlice.reducer;
