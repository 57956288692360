import { Box, styled } from "@mui/material";

import { BreakPointTheme } from "theme";

import {
  Description as DescriptionStyled,
  Title as TitleStyled,
} from "../styled";

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0 70px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    padding: "0 16px",
  },
});

export const Title = styled(TitleStyled)({
  textAlign: "center",
  width: "100%",
  maxWidth: "1000px",
});

export const Description = styled(DescriptionStyled)({
  textAlign: "center",
  width: "100%",
  textTransform: "none",
});
