import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

interface SvgProps {
  fill?: string;
}

export const ArrowDownSolidIconBlack: FC<
  SvgIconConstituentValues & SvgProps
> = ({ fill, ...props }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.171387L5 5.17139L10 0.171387H0Z" fill={fill} />
  </svg>
);

ArrowDownSolidIconBlack.defaultProps = {
  fill: "black",
};
