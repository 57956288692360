import { styled } from "@mui/material";

import { BreakPointTheme } from "theme";

export const IconContainter = styled("div")<{
  isOpen?: boolean;
  rotate?: string;
  end?: boolean;
}>(({ isOpen, rotate, end }) => ({
  position: !end ? "absolute" : "static",
  right: "-20px",
  bottom: "0px",
  display: isOpen ? "none" : "block",
  cursor: "pointer",
  transition: "all .3s ease",
  marginBottom: "-3px",

  svg: {
    transform: `rotate(${rotate}deg)`,
    path: {
      stroke: "red",
    },
  },
}));

export const TabsContainer = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  marginTop: "33px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    marginTop: "14px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const ActiveComissionWrapper = styled("div")({
  position: "absolute",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  gap: "10px",
  transition: "all .3s ease",
});

interface ComissionsSlideContainerProps {
  isOpen: boolean;
  itemsNumber: number;
  contentWidth: number;
  contentHeight: number;
}

export const ComissionsSlideContainer = styled(
  "div",
)<ComissionsSlideContainerProps>(
  ({ isOpen, contentWidth, contentHeight, itemsNumber }) => ({
    width: `${isOpen ? "100%" : "0"}`,
    height: `${itemsNumber < 5 ? "auto" : isOpen ? contentHeight + "px" : "0"}`,
    overflow: "hidden",
    whiteSpace: "nowrap",
    transition: "all 0.2s ease-in-out",

    [BreakPointTheme.breakpoints.down(1590)]: {
      height: `${
        itemsNumber < 4 ? "auto" : isOpen ? contentHeight + "px" : "0"
      }`,
    },

    [BreakPointTheme.breakpoints.down(1300)]: {
      height: `${
        itemsNumber < 3 ? "auto" : isOpen ? contentHeight + "px" : "0"
      }`,
    },

    [BreakPointTheme.breakpoints.down(962)]: {
      height: `${
        itemsNumber < 2 ? "auto" : isOpen ? contentHeight + "px" : "0"
      }`,
    },
  }),
);

export const ComissionsGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(5, auto)",
  justifyItems: "flex-start",
  gridColumnGap: "60px",
  gridRowGap: "16px",
  boxSizing: "border-box",
  padding: "0 30px 0 0",

  [BreakPointTheme.breakpoints.down(1590)]: {
    gridTemplateColumns: "repeat(4, auto)",
  },

  [BreakPointTheme.breakpoints.down(1300)]: {
    gridTemplateColumns: "repeat(3, auto)",
  },

  [BreakPointTheme.breakpoints.down(962)]: {
    gridTemplateColumns: "repeat(2, auto)",
  },
});

export const EmptyGridItem = styled("div")<{ width: number }>(({ width }) => ({
  width: width || "auto",
}));

export const GridItem = styled("div")({
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  gap: "10px",
});
