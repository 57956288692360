import { styled } from "@mui/material";

import { BreakPointTheme } from "theme";

import { BlockTitleTypography } from "styled";

export const Title = styled(BlockTitleTypography)({
  cursor: "pointer",
  marginBottom: "12px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: "50px",
    lineHeight: "64px",
  },
});

export const IconWrapper = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  cursor: "pointer",
  display: "flex",
  boxSizing: "border-box",
  padding: "20px 25px 25px 25px",

  ...(isOpen && {
    transform: "rotate(180deg)",
    paddingTop: "30px",
  }),
}));

export const TitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const AccordionContentWrapper = styled("div")<{
  height: number;
  isOpen: boolean;
}>(({ isOpen, height }) => ({
  height: isOpen ? `${height}px` : "0",
  overflow: "hidden",
  transition: "height 0.2s ease-in-out",
}));

export const AccordionWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "30px",
  position: "relative",

  [BreakPointTheme.breakpoints.down("md")]: {
    marginBottom: "12px",
  },

  [BreakPointTheme.breakpoints.up("md")]: {
    display: "none",
  },
});

export const AccordionContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingTop: "40px",
});
