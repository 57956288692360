import { ROLES } from "constant";

export const checkPermissions = (
  clientRole: string,
  isCollaboratorAccess: boolean | string,
  collaboratorPermissions: string[],
): boolean => {
  if (clientRole === ROLES.Client) return true;
  if (clientRole === ROLES.GlobalPartner) return true;

  if (!isCollaboratorAccess) return false;

  if (typeof isCollaboratorAccess === "boolean" && isCollaboratorAccess) {
    return true;
  }

  if (
    clientRole === ROLES.Collaborator &&
    collaboratorPermissions?.includes(isCollaboratorAccess?.toString())
  ) {
    return true;
  }
  return false;
};
