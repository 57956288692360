import React, { FC, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { params, thumbsParams } from "./config";
import {
  StyledSwiperSlide,
  SwiperCarousel,
  SwiperFullImage,
  SwiperThumb,
  SwiperThumbImage,
} from "./styled";
import { CarouselProps } from "./typed";
import { Loader } from "components/Loader";

export const Carousel: FC<CarouselProps> = ({ images, initialSlide }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <SwiperCarousel
        initialSlide={initialSlide}
        {...params}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {images.map(({ image }, idx) => (
          <StyledSwiperSlide key={`slide_${idx}`}>
            <SwiperFullImage
              key={image}
              easing={""}
              duration={0}
              src={image}
              showLoading={<Loader />}
            />
          </StyledSwiperSlide>
        ))}
      </SwiperCarousel>
      <SwiperThumb
        {...thumbsParams}
        slidesPerView={"auto"}
        onSwiper={setThumbsSwiper}
      >
        {images.map(({ image, image_thumbnail }, idx) => (
          <SwiperSlide key={`slide_${idx}`}>
            <SwiperThumbImage
              key={image}
              easing={""}
              duration={0}
              src={image_thumbnail || image}
              showLoading={<Loader />}
            />
          </SwiperSlide>
        ))}
      </SwiperThumb>
    </>
  );
};
