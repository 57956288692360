import { TextField, styled } from "@mui/material";

import { theme } from "theme";

export const InputStyled = styled(TextField)({
  "& div.MuiOutlinedInput-root": {
    border: `1px solid ${theme.color.black}`,
    borderRadius: "30px",
    height: "27px",
    width: "400px",
    padding: "0 11px",
  },
});
