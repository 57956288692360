import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const WenchIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7353 6.76623L17.5039 8.99829H15.5026V6.99704L17.734 4.76498C16.0566 3.94702 14.044 4.29411 12.7374 5.62669C11.4309 6.95926 11.1236 8.97835 11.9744 10.6393L5.22022 17.3942C4.71461 17.9177 4.72184 18.7498 5.23647 19.2645C5.7511 19.7791 6.58323 19.7863 7.10673 19.2807L13.8616 12.5258C15.5225 13.3762 17.5412 13.0686 18.8735 11.7622C20.2058 10.4558 20.5529 8.4435 19.7353 6.76623Z"
      stroke={props.strokeColor ?? "white"}
    />
  </svg>
);
