import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import { theme } from "theme";

export const Link = styled(NavLink)<{ currentPath: boolean }>(
  ({ currentPath }) => ({
    marginBottom: "17px",
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontSize.base,
    lineHeight: "16px",
    textTransform: "uppercase",
    textDecoration: "none",

    "&:hover": {
      color: theme.color.orange,
    },

    ...(currentPath
      ? {
          color: theme.color.orange,
        }
      : {
          color: theme.color.grey[900],
        }),
  }),
);

export const LinksWrapper = styled("div")<{
  myProfile?: boolean;
  collaborators?: boolean;
}>(({ myProfile, collaborators }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "369px",
  marginBottom: "23px",

  ...(myProfile && {
    marginBottom: "60px",
  }),

  ...(collaborators && {
    marginBottom: "23px",
  }),
}));
