import React, { FC } from "react";

import { PageBlockLoader } from "components";
import { ComissionsTabs, TimelineSteps } from "components/Clients";
import { IUniversalContentPoint } from "types";

import {
  Description,
  TabsWrapper,
  TimelineProgressWrapper,
  TimelineTitle,
  Wrapper,
} from "./styled";

interface ITimeLine {
  isLoading?: boolean;
  selectedStage?: number;
  UniversalContentPoints: IUniversalContentPoint[];
  handleStageClick?: (id: number | string) => void;
}

export const TimeLine: FC<ITimeLine> = ({
  isLoading,
  selectedStage,
  UniversalContentPoints,
  handleStageClick,
}) => {
  return (
    <Wrapper>
      <TabsWrapper>
        <TimelineTitle>Timeline</TimelineTitle>

        <ComissionsTabs />
      </TabsWrapper>

      <Description>
        Commission progress is tracked by Restoration Stage on the Timeline
        below. The Active Stage is marked in Orange, with Completed Stages in
        Black. Clicking on any Stage will provide Commission specific
        Restoration Stage information.
      </Description>

      {!!UniversalContentPoints?.length && (
        <TimelineProgressWrapper>
          {isLoading && <PageBlockLoader />}

          <TimelineSteps
            selectedStageId={!isLoading && selectedStage}
            points={UniversalContentPoints}
            handleStageClick={handleStageClick}
          />
        </TimelineProgressWrapper>
      )}
    </Wrapper>
  );
};
