import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const UploadIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7949_172194)">
      <path
        d="M29.525 15.06C28.505 9.885 23.96 6 18.5 6C14.165 6 10.4 8.46 8.525 12.06C4.01 12.54 0.5 16.365 0.5 21C0.5 25.965 4.535 30 9.5 30H29C33.14 30 36.5 26.64 36.5 22.5C36.5 18.54 33.425 15.33 29.525 15.06ZM21.5 19.5V25.5H15.5V19.5H11L18.5 12L26 19.5H21.5Z"
        fill="#8D8D8D"
      />
    </g>
    <defs>
      <clipPath id="clip0_7949_172194">
        <rect width="36" height="36" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
