import { endpoints } from "constant";

export const zipArchiveUrlBuilder = {
  getZipArchive: function (id: string): string {
    return `${endpoints.zipArchive}${id}/`;
  },
  orderZipPhotos: function () {
    return `${endpoints.zipPhotos}`;
  },
  orderZipDocuments: function () {
    return `${endpoints.zipDocs}`;
  },
};
