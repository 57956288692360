import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LightViewIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9989 12.0003C20.9989 12.0003 16.9698 17.0875 11.9985 17.0875C7.0271 17.0875 2.99805 12.0003 2.99805 12.0003C2.99805 12.0003 7.0271 6.91309 11.9985 6.91309C16.9698 6.91309 20.9989 12.0003 20.9989 12.0003Z"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9978 15.1308C13.7267 15.1308 15.1283 13.7292 15.1283 12.0002C15.1283 10.2712 13.7267 8.86963 11.9978 8.86963C10.2688 8.86963 8.86719 10.2712 8.86719 12.0002C8.86719 13.7292 10.2688 15.1308 11.9978 15.1308Z"
      stroke="black"
    />
    <path
      d="M11.9989 10.4351C12.8634 10.4351 13.5642 11.1359 13.5642 12.0003C13.5642 12.8648 12.8634 13.5656 11.9989 13.5656C11.1344 13.5656 10.4336 12.8648 10.4336 12.0003"
      stroke="black"
    />
  </svg>
);
