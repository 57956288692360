import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import {
  CreateUniversalContentStageDescDoc,
  DeleteDashboardImages,
  Photo,
  PostDashboard,
  WelcomeStatement,
  DashboardStageTopicTitles,
  ITimeline,
} from "types";

import { universalContentDashboardUrlBuilder } from "./urlBuilder/universalContentDashboard";

export const universalContentDashboardAPI = createApi({
  reducerPath: "universalContentDashboardAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["UniversalContentDashboard", "UniversalContentDashboard-Video"],
  endpoints: build => ({
    getDashboard: build.query<DashboardStageTopicTitles, number>({
      query: (arg?: number) => ({
        url: universalContentDashboardUrlBuilder.getDashboard(arg),
        method: "GET",
      }),
      providesTags: ["UniversalContentDashboard"],
    }),
    getWelcomeStatement: build.query<WelcomeStatement, ITimeline>({
      query: (arg?: ITimeline) => ({
        url: universalContentDashboardUrlBuilder.getWelcomeStatement(
          arg.stage_id,
          arg.car_model,
        ),
        method: "GET",
      }),
      providesTags: ["UniversalContentDashboard"],
    }),
    postDashboardPreview: build.mutation<PostDashboard, PostDashboard>({
      query: (args?: PostDashboard) => ({
        url: universalContentDashboardUrlBuilder.postDashboardPreview(args.id),
        method: "POST",
        data: args,
      }),
      invalidatesTags: ["UniversalContentDashboard"],
    }),
    getStageDescImages: build.query<Photo[], number>({
      query: (args?: number) => ({
        url: universalContentDashboardUrlBuilder.stageDescImages(args),
        method: "GET",
      }),
      providesTags: ["UniversalContentDashboard"],
    }),
    postStageDescImages: build.mutation<
      CreateUniversalContentStageDescDoc,
      CreateUniversalContentStageDescDoc
    >({
      query: (args?: CreateUniversalContentStageDescDoc) => ({
        url: universalContentDashboardUrlBuilder.stageDescImages(args.id),
        method: "POST",
        data: args.image,
      }),
      invalidatesTags: ["UniversalContentDashboard"],
    }),
    deleteStageDescImages: build.mutation<
      DeleteDashboardImages,
      DeleteDashboardImages
    >({
      query: (args?: DeleteDashboardImages) => ({
        url: universalContentDashboardUrlBuilder.deleteStageDescImages(
          args.stage_id,
          args.id,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["UniversalContentDashboard"],
    }),
    getStageTopicImages: build.query<Photo[], number>({
      query: (args?: number) => ({
        url: universalContentDashboardUrlBuilder.stageTopicImages(args),
        method: "GET",
      }),
      providesTags: ["UniversalContentDashboard"],
    }),
    postStageTopicImages: build.mutation<
      CreateUniversalContentStageDescDoc,
      CreateUniversalContentStageDescDoc
    >({
      query: (args?: CreateUniversalContentStageDescDoc) => ({
        url: universalContentDashboardUrlBuilder.stageTopicImages(args.id),
        method: "POST",
        data: args.image,
      }),
      invalidatesTags: ["UniversalContentDashboard"],
    }),
    deleteStageTopicImages: build.mutation<
      DeleteDashboardImages,
      DeleteDashboardImages
    >({
      query: (args?: DeleteDashboardImages) => ({
        url: universalContentDashboardUrlBuilder.deleteStageTopicImages(
          args.stage_id,
          args.id,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["UniversalContentDashboard"],
    }),
    getHeaderVideoUrl: build.query<{ video_link: string }, number>({
      query: (arg?: number) => ({
        url: universalContentDashboardUrlBuilder.getHeaderVideoUrl(arg),
        method: "GET",
      }),
      providesTags: ["UniversalContentDashboard-Video"],
    }),
    postHeaderVideoUrl: build.mutation<
      number,
      { id: number; data: { video_link: string; stage: number } }
    >({
      query: ({ id, data }) => ({
        url: universalContentDashboardUrlBuilder.getHeaderVideoUrl(id),
        method: "POST",
        data,
      }),
      invalidatesTags: ["UniversalContentDashboard-Video"],
    }),
  }),
});

export const {
  useGetWelcomeStatementQuery,
  useLazyGetWelcomeStatementQuery,
  useGetDashboardQuery,
  usePostDashboardPreviewMutation,
  useGetStageTopicImagesQuery,
  useDeleteStageDescImagesMutation,
  useDeleteStageTopicImagesMutation,
  useGetStageDescImagesQuery,
  usePostStageDescImagesMutation,
  usePostStageTopicImagesMutation,
  useGetHeaderVideoUrlQuery,
  usePostHeaderVideoUrlMutation,
} = universalContentDashboardAPI;
