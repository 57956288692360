import { endpoints } from "constant";

export const carDocumentsUrlBuilder = {
  getCarDocuments: function ({
    id,
  }: {
    id?: string | number;
  } = {}): string {
    return `${endpoints.cars}${id}/documents/`;
  },
  createCarDocuments: function ({
    id,
  }: {
    id?: number;
  } = {}): string {
    return `${endpoints.cars}${id}/documents/create/`;
  },
  deleteCarDocuments: function ({
    id,
    documentID,
  }: {
    id?: number;
    documentID?: string;
  } = {}): string {
    return `${endpoints.cars}${id}/documents/${documentID}/`;
  },
  deleteCarDocumentsCategory: function ({
    id,
    categoryID,
  }: {
    id?: number;
    categoryID?: string;
  } = {}): string {
    return `${endpoints.cars}${id}/documents/categories/${categoryID}/`;
  },
  createCategoryCarDocuments: function ({
    id,
  }: {
    id?: number;
  } = {}): string {
    return `${endpoints.cars}${id}/documents/categories/`;
  },
};
