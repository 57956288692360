import { Typography, styled } from "@mui/material";

import { theme } from "theme";

export const StepsContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "space-around",
});

export const Line = styled("div")({
  borderBottom: `3px solid ${theme.color.black}`,
  position: "absolute",
  width: "100%",
  top: "50%",
});

const StageFlexContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const Stage = styled(StageFlexContainer)<{ isReversed?: boolean }>(
  ({ isReversed }) => ({
    height: "200px",
    cursor: "pointer",

    ...(isReversed && {
      flexDirection: "column-reverse",
    }),

    "&:hover": {
      svg: {
        path: {
          fill: theme.color.grey[900],
        },
      },
    },
  }),
);

export const StageInfo = styled(StageFlexContainer)<{ isReversed: boolean }>(
  ({ isReversed }) => ({
    justifyContent: "flex-start",
    boxSizing: "border-box",
    height: "100px",
    paddingTop: isReversed ? "7px" : "10px",
  }),
);

export const StageTypography = styled(Typography)({
  fontSize: "13px",
  fontWeight: theme.fontWeight.semiBold,
  lineHeight: "24px",
  letterSpacing: "0px",
  textAlign: "center",
  color: theme.color.grey[1200],
});

export const StageNumber = styled(StageTypography)();

export const StageStatus = styled(StageTypography)();

export const StageTitle = styled(StageTypography)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    fontSize: theme.fontSize.md,
    color: theme.color.black,

    ...(isSelected && {
      fontWeight: theme.fontWeight.bold,
    }),
  }),
);

export const StageDate = styled(StageTypography)({
  lineHeight: "10px",
});
