import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { Disclaimer } from "types";

import { disclaimerUrlBuilder } from "./urlBuilder/disclaimers";

export const disclaimersAPI = createApi({
  reducerPath: "disclaimersAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Disclaimers"],
  endpoints: build => ({
    getDisclaimers: build.query<Disclaimer[], void>({
      query: () => ({
        url: disclaimerUrlBuilder.getDisclaimers(),
        method: "GET",
      }),
      providesTags: ["Disclaimers"],
    }),
    updateDisclaimer: build.mutation<Disclaimer, Disclaimer>({
      query: body => ({
        url: disclaimerUrlBuilder.updateDisclaimer(body.id),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Disclaimers"],
    }),
  }),
});

export const { useGetDisclaimersQuery, useUpdateDisclaimerMutation } =
  disclaimersAPI;
