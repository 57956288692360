import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";
import { theme } from "theme";

interface ColoredSvgIcon extends SvgIconConstituentValues {
  tableHead: boolean;
}

export const CheckBoxUnchekedIcon: FC<ColoredSvgIcon> = ({
  tableHead,
  ...props
}) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.671387"
      width="14"
      height="14"
      rx="3.5"
      fill={tableHead ? theme.color.grey[100] : "white"}
      stroke={tableHead ? theme.color.grey[100] : "#C8C8C8"}
    />
  </svg>
);
