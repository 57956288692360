import React, { FC } from "react";

import { Dialog } from "../styled";
import { DialogContent, DialogTitle, ModalDesc } from "./styled";
import { LinearLoader } from "components";

interface ArchiveDownloadBlockerModalProps {
  open: boolean;
}

export const ArchiveDownloadBlockerModal: FC<
  ArchiveDownloadBlockerModalProps
> = ({ open }) => (
  <Dialog open={open} maxWidth="sm" maxWidthStyle={"600px"} fullWidth>
    <DialogTitle>Download Files</DialogTitle>
    <DialogContent>
      <ModalDesc>
        We are generating the file for you and it will be downloaded to your
        store once it is ready.
      </ModalDesc>
    </DialogContent>

    <LinearLoader />
  </Dialog>
);
