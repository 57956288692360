import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const PencilIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="17.0986"
      y="5.83594"
      width="4.33604"
      height="15.3814"
      transform="rotate(45 17.0986 5.83594)"
      stroke="black"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.22224 16.7119L4.68945 21.311L9.28855 19.7782L6.22224 16.7119Z"
      stroke="black"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1652 8.90092L17.0996 5.83534L17.6105 5.32441C18.4612 4.50279 19.8134 4.51454 20.6497 5.35082C21.486 6.1871 21.4977 7.53931 20.6761 8.38999L20.1652 8.90092Z"
      stroke="black"
    />
  </svg>
);
