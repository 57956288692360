import React, { FC, useRef } from "react";
import { Pagination, Navigation } from "swiper";

import { SliderArrowIcon } from "icons";

import {
  StyledButton,
  StyledSlide,
  StyledSwiper,
  SwiperContainer,
  SwiperWrapper,
} from "./styled";
import type { IImageCarousel } from "./typed";

export const ImageCarousel: FC<IImageCarousel> = ({
  images,
  loop,
  className,
  onClick,
  variant,
}) => {
  const swiperRef = useRef<any>(null);

  return (
    <SwiperContainer variant={variant}>
      <SwiperWrapper variant={variant}>
        <StyledSwiper
          spaceBetween={30}
          loop={loop}
          variant={variant}
          {...(className
            ? {
                navigation: {
                  prevEl: `.prev-${className}`,
                  nextEl: `.next-${className}`,
                },
              }
            : null)}
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
        >
          {images.map(({ image_thumbnail, image }, i) => (
            <StyledSlide
              key={i}
              bgImage={image_thumbnail || image}
              onClick={() => onClick(i)}
            />
          ))}
        </StyledSwiper>
        <StyledButton
          prev
          className={`prev-${className}`}
          onClick={className ? null : () => swiperRef.current?.slidePrev()}
        >
          <SliderArrowIcon />
        </StyledButton>
        <StyledButton
          next
          className={`next-${className}`}
          onClick={className ? null : () => swiperRef.current?.slideNext()}
        >
          <SliderArrowIcon right />
        </StyledButton>
      </SwiperWrapper>
    </SwiperContainer>
  );
};

ImageCarousel.defaultProps = {
  loop: true,
};
