import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const StarIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14.4023" cy="14.1719" r="14" fill="#00B1A5" />
    <path
      d="M24.3224 10.3898C24.1346 9.8897 23.6537 9.55741 23.1155 9.55583H17.8904C17.7082 9.55596 17.5458 9.442 17.4853 9.27159L15.6097 4.00132C15.4216 3.50249 14.9408 3.17188 14.4037 3.17188C13.8665 3.17188 13.3857 3.50249 13.1976 4.00132L13.1933 4.01494L11.322 9.27159C11.2616 9.44171 11.0996 9.5556 10.9177 9.55583H5.69097C5.14902 9.55543 4.66482 9.8915 4.47978 10.3965C4.29475 10.9015 4.44848 11.4673 4.86433 11.8118L9.31424 15.4712C9.45048 15.5833 9.50332 15.7668 9.44729 15.9333L7.57771 21.4912C7.40042 22.0185 7.58768 22.5989 8.04073 22.9263C8.49378 23.2538 9.10846 23.2529 9.5606 22.9243L14.1496 19.5883C14.3006 19.4786 14.5059 19.4786 14.6569 19.5883L19.2441 22.9234C19.6961 23.2532 20.3115 23.2548 20.7652 22.9274C21.2189 22.6 21.4064 22.0189 21.2287 21.4912L19.3592 15.9299C19.3031 15.7634 19.356 15.5798 19.4922 15.4678L23.9507 11.8033C24.3612 11.4559 24.5095 10.8917 24.3224 10.3898Z"
      fill="white"
    />
  </svg>
);
