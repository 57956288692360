import React, { FC, useMemo } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";

import { Input } from "./styled";

interface PhoneInputType extends ControllerRenderProps {
  label?: string;
  placeholder?: string;
  fieldState: ControllerFieldState;
}

export const PhoneInput: FC<PhoneInputType> = ({
  label,
  placeholder,
  fieldState,
  ...field
}) => {
  const errorMessage = useMemo(
    () => fieldState.error?.message,
    [fieldState.error],
  );
  return (
    <Input
      fullWidth
      defaultCountry="US"
      label={label}
      variant="standard"
      {...field}
      placeholder={placeholder}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};
