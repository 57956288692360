import React, { FC } from "react";

import { STAGE_STATUS } from "constant";
import { IUniversalContentPoint } from "types";

import { Point } from "../Point";

import {
  EmptyGridItem,
  GridContainer,
  GridItem,
  Line,
  Stage,
  StageDate,
  StageInfo,
  StageNumber,
  StageStatus,
  StageTitle,
  Wrapper,
} from "./styled";

interface TimelineVerticalStepsProps {
  selectedStageId?: number;
  points: IUniversalContentPoint[];
  handleStageClick: (id: number | string) => void;
}

export const TimelineVerticalSteps: FC<TimelineVerticalStepsProps> = ({
  selectedStageId,
  points,
  handleStageClick,
}) => {
  const handleClick = (id: number | string) => {
    handleStageClick(id);
  };

  return (
    <Wrapper>
      <GridContainer>
        <EmptyGridItem area={"first"} />
        {points?.map(({ title, id, isCurrent, status, date }, i) => {
          const isReversed = !!(i % 2);

          return (
            <GridItem key={id} area={i}>
              <Stage isReversed={isReversed} onClick={() => handleClick(id)}>
                <Point
                  isReversed={isReversed}
                  isCurrent={isCurrent}
                  isSelected={selectedStageId === id}
                  isCompleted={status === STAGE_STATUS.COMPLETED}
                />

                <StageInfo isReversed={isReversed}>
                  <StageNumber>{`STAGE ${i + 1}`}</StageNumber>

                  {title && (
                    <StageTitle isSelected={selectedStageId === id}>
                      {title}
                    </StageTitle>
                  )}

                  {status && <StageStatus>{status}</StageStatus>}

                  {date && <StageDate>{date}</StageDate>}
                </StageInfo>
              </Stage>
            </GridItem>
          );
        })}

        <EmptyGridItem area={"last"} />
      </GridContainer>

      <Line />
    </Wrapper>
  );
};
