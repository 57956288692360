import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  EventAttendee,
  EventAttendeesQueryParams,
  EventAttendeesParams,
  EventAttendeesListData,
  UploadEventAttendeesData,
} from "types";

import { eventAttendeeUrlBuilder } from "./urlBuilder/eventAttendees";

export const eventAttendeesAPI = createApi({
  reducerPath: "eventAttendeesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["EventAttendees"],
  endpoints: build => ({
    getEventAttendees: build.query<
      EventAttendeesListData,
      EventAttendeesQueryParams | void
    >({
      query: (args?: EventAttendeesQueryParams) => ({
        url: eventAttendeeUrlBuilder.getEventAttendees(args),
        method: "GET",
      }),
      providesTags: ["EventAttendees"],
    }),
    getEventAttendee: build.query<EventAttendee, EventAttendeesParams>({
      query: args => ({
        url: eventAttendeeUrlBuilder.getEventAttendee(args),
        method: "GET",
      }),
      providesTags: ["EventAttendees"],
    }),
    createEventAttendee: build.mutation<EventAttendee, EventAttendee>({
      query: body => ({
        url: eventAttendeeUrlBuilder.createEventAttendee(body.event),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["EventAttendees"],
    }),
    updateEventAttendee: build.mutation<EventAttendee, EventAttendee>({
      query: body => ({
        url: eventAttendeeUrlBuilder.updateEventAttendee({
          eventId: body.event,
          attendeeId: body.id,
        }),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["EventAttendees"],
    }),
    deleteEventAttendee: build.mutation<EventAttendee, EventAttendeesParams>({
      query: args => ({
        url: eventAttendeeUrlBuilder.deleteEventAttendee(args),
        method: "DELETE",
      }),
      invalidatesTags: ["EventAttendees"],
    }),
    downloadEventAttendees: build.mutation<BlobPart, string | number>({
      query: args => ({
        url: eventAttendeeUrlBuilder.downloadEventAttendees(args),
        method: "GET",
      }),
    }),
    downloadEventAttendeesTemplate: build.mutation<BlobPart, void>({
      query: () => ({
        url: eventAttendeeUrlBuilder.downloadEventAttendeesTemplate(),
        method: "GET",
      }),
    }),
    uploadEventAttendees: build.mutation<void, UploadEventAttendeesData>({
      query: body => ({
        url: eventAttendeeUrlBuilder.uploadEventAttendees(body.id),
        method: "POST",
        data: body.data,
      }),
      invalidatesTags: ["EventAttendees"],
    }),
  }),
});

export const {
  useGetEventAttendeesQuery,
  useGetEventAttendeeQuery,
  useCreateEventAttendeeMutation,
  useUpdateEventAttendeeMutation,
  useDeleteEventAttendeeMutation,
  useDownloadEventAttendeesMutation,
  useDownloadEventAttendeesTemplateMutation,
  useUploadEventAttendeesMutation,
} = eventAttendeesAPI;
