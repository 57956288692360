import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  Admin,
  AdminsQueryParams,
  AdminsListData,
  AdminProfile,
} from "types";

import { adminUrlBuilder } from "./urlBuilder/admins";

export const adminsAPI = createApi({
  reducerPath: "adminsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Admins", "AdminProfile"],
  endpoints: build => ({
    getUserProfile: build.query<AdminProfile, void>({
      query: () => ({
        url: adminUrlBuilder.adminProfile(),
        method: "GET",
      }),
      providesTags: ["AdminProfile"],
    }),
    getAdmins: build.query<AdminsListData, AdminsQueryParams | void>({
      query: (args?: AdminsQueryParams) => ({
        url: adminUrlBuilder.getAdmins(args),
        method: "GET",
      }),
      providesTags: ["Admins"],
    }),
    updateRoleAdmin: build.mutation<Admin, Admin>({
      query: body => ({
        url: adminUrlBuilder.updateAdmin(body.id),
        method: "PATCH",
        data: body,
      }),
      invalidatesTags: ["Admins", "AdminProfile"],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useGetAdminsQuery,
  useUpdateRoleAdminMutation,
} = adminsAPI;
