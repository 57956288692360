import React, { FC } from "react";

import { STAGE_STATUS } from "constant";
import { IUniversalContentPoint } from "types";

import { Point } from "./Point";
import {
  Line,
  Stage,
  StageInfo,
  StepsContainer,
  StageNumber,
  StageTitle,
  StageStatus,
  StageDate,
} from "./styled";

interface TimelineStepsProps {
  selectedStageId?: number;
  points: IUniversalContentPoint[];
  handleStageClick?: (id: number | string) => void;
}

export const TimelineSteps: FC<TimelineStepsProps> = ({
  selectedStageId,
  points,
  handleStageClick,
}) => {
  const handleClick = (id: number | string) => {
    handleStageClick(id);
  };
  return (
    <StepsContainer>
      {points?.map(({ title, id, isCurrent, status, date }, i) => {
        const isReversed = !!(i % 2);
        const DELIVERED = "DELIVERED";
        return (
          <Stage
            key={id}
            isReversed={isReversed}
            onClick={() => handleClick(id)}
          >
            <Point
              isReversed={isReversed}
              isCurrent={isCurrent}
              isSelected={selectedStageId === id}
              isCompleted={status === STAGE_STATUS.COMPLETED}
              pointName={title}
            />

            <StageInfo isReversed={isReversed}>
              <StageNumber>{`STAGE ${i + 1}`}</StageNumber>

              {title && (
                <StageTitle
                  isSelected={
                    selectedStageId === id || (isCurrent && title === DELIVERED)
                  }
                >
                  {title}
                </StageTitle>
              )}

              {status && <StageStatus>{status}</StageStatus>}

              {date && <StageDate>{date}</StageDate>}
            </StageInfo>
          </Stage>
        );
      })}

      <Line />
    </StepsContainer>
  );
};
