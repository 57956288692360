import { Typography, CircularProgress, styled } from "@mui/material";

import { theme } from "theme";

export const ArchivePopup = styled("div")({
  position: "fixed",
  left: "50%",
  bottom: "0",
  transform: "translateX(-50%)",
  transition: ".3s",
  padding: "10px 30px",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  background: theme.color.white,
  border: `1px solid ${theme.color.black}`,
  borderBottomWidth: 0,
  borderRadius: "4px 4px 0 0",
});

export const Message = styled(Typography)({
  color: theme.color.black,
  marginLeft: "10px",
});

export const Load = styled(CircularProgress)({
  color: theme.color.primary[600],
});
