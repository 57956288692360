import { endpoints } from "constant";

export const carStagesFavoritePhotosUrlBuilder = {
  getPhotos: function (carID: number): string {
    return `${endpoints.cars}${carID}/favorite-photos/`;
  },
  downloadAllFavoritePhotos: function (carID: number): string {
    return `${endpoints.cars}${carID}/favorite-photos/download/`;
  },
  downloadByStageFavoritePhotos: function (
    carID: number,
    stageID: number,
  ): string {
    return `${endpoints.cars}${carID}/stages/${stageID}/favorite-photos/download/`;
  },
};
