import { Box, styled } from "@mui/material";
import { BreakPointTheme } from "theme";

export const BoxPadding = styled(Box)<{ leftSpacing?: number }>(
  ({ leftSpacing }) => ({
    padding: "30px",
    ...(leftSpacing && {
      paddingLeft: `${leftSpacing}px`,
      [BreakPointTheme.breakpoints.between("sm", "md")]: {
        paddingLeft: "20px",
      },
    }),
    [BreakPointTheme.breakpoints.between("sm", "md")]: {
      padding: "20px",
    },
  }),
);

export const BoxRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const LoaderWrapper = styled("div")({
  position: "relative",
});
