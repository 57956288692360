import { endpoints } from "constant";

export const clientProfileUrlBuilder = {
  clientProfile: function (): string {
    return endpoints.clientProfile;
  },
  clientPhoto: function (): string {
    return endpoints.clientPhoto;
  },
  clientAvatar: function (): string {
    return endpoints.avatarImages;
  },
};
