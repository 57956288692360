export const mp4 = "mp4";
export const pdf = "pdf";
export const video = "video";
export const vimeo = "vimeo";
export const png = "png";
export const jpg = "jpg";
export const jpeg = "jpeg";

export const images = [png, jpg, jpeg];
export const videos = [video, vimeo, mp4];
export const pdfs = [pdf];
export const docs = ["doc", "docx", "xls", "xlsx"];
