import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  setActiveComission,
  setActiveStage,
  setComissions,
} from "redux/slices";

import { QUERY_OPTIONS, ROLES } from "constant";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { useLazyGetCollaboratorProfileQuery } from "redux/query/clientCollaboratorsAPI";
import { useGetStagesQuery } from "redux/query/stagesAPI";
import { useLazyGetClientProfileQuery } from "redux/query/clientProfileAPI";
import { useLazyGetClientQuery } from "redux/query/clientsAPI";

import {
  activeComissionsSelector, activeComissionStageSelector,
  сomissionsSelector,
} from "redux/selectors/clientComissions";
import { getCollaboratorUserId, getUserClientRole } from "redux/selectors";

import { LogoutPopup } from "./LogoutPopup";
import { BurgerMenu } from "./BurgerMenu";
import { ConnectMenu } from "./ConnectMenu";

import { AppSidebar } from "./styled";
import { Comission } from "types";

export const Sidebar: FunctionComponent<{
  opened: null | "left" | "right";
  closeSidebar?: () => void;
}> = ({ opened, closeSidebar }) => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const userRole = useAppSelector(getUserClientRole);
  const collaboratorUserId = useAppSelector(getCollaboratorUserId);
  const cars = useAppSelector(сomissionsSelector);
  const activeComission = useAppSelector(activeComissionsSelector);
  const activeStage = useAppSelector(activeComissionStageSelector);
  const [logoutPopup, setLogoutPopup] = useState<boolean>(false);

  const [getProfile, { data: userData }] = useLazyGetClientProfileQuery();
  const [getCollaboratorProfile] = useLazyGetCollaboratorProfileQuery();

  const [getClientData, { data: aboutMeData, isSuccess: isAboutMeSuccess }] =
    useLazyGetClientQuery();

  const { data: stages, isSuccess: isStagesSuccess } = useGetStagesQuery(
    {},
    QUERY_OPTIONS,
  );

  useEffect(() => {
    if (!userRole) return;

    if (userRole === ROLES.Client) {
      getProfile();
      return;
    }

    getCollaboratorProfile();
  }, [userRole]);

  useEffect(() => {
    if (!userData?.id) return;

    getClientData({
      id: userData?.id?.toString(),
    });
  }, [userData?.id]);

  useEffect(() => {
    if (!collaboratorUserId) return;

    getClientData({
      id: collaboratorUserId,
    });
  }, [collaboratorUserId]);

  const isMatch = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

  const refetchCars = async () => {
    if (userData?.id || collaboratorUserId) {
      const response = await getClientData({
        id: userData?.id?.toString() || collaboratorUserId,
      }).unwrap();

      if (isMatch(cars, response.cars)) {
        return;
      }
      dispatch(setComissions(response?.cars));
    }
  };

  useEffect(() => {
    refetchCars();
  }, [pathname]);

  useEffect(() => {
    if (
      isAboutMeSuccess &&
      isStagesSuccess &&
      aboutMeData?.cars?.length &&
      stages?.length
    ) {
      dispatch(setComissions(aboutMeData?.cars));
    }
  }, [isAboutMeSuccess, isStagesSuccess]);

  const getClosestToDeliveryComission = (
    comissions: Comission[],
  ): Comission => {
    if (!comissions?.length) return;
    const currentTime = new Date().getTime();

    return comissions.reduce((closest, current) => {
      if (!closest) return current;

      const closestDiff = Math.abs(
        new Date(closest.last_stage_date).getTime() - currentTime,
      );
      const currentDiff = Math.abs(
        new Date(current.last_stage_date).getTime() - currentTime,
      );
      return currentDiff < closestDiff ? current : closest;
    }, null);
  };

  //to save active commission and after reloading page
  useEffect(() => {
    if (!cars?.find(car => car?.id === activeComission?.id)) {
      const closestToDelivery = getClosestToDeliveryComission(
        aboutMeData?.cars,
      );

      dispatch(setActiveComission(closestToDelivery || aboutMeData?.cars[0]));

      const currentStage =
        stages?.length &&
        stages?.find(stage => stage?.name === closestToDelivery?.stage || stage?.name === activeComission?.stage);
      dispatch(setActiveStage(currentStage?.id));
    }
    if (!activeStage) {
      const closestToDelivery = getClosestToDeliveryComission(
          aboutMeData?.cars,
      );
      dispatch(setActiveComission(closestToDelivery || aboutMeData?.cars[0]));
      const currentStage =
          stages?.length &&
          stages?.find(stage => stage?.name || stage?.name === activeComission?.stage);
      dispatch(setActiveStage(currentStage?.id));
    }
  }, [cars, stages, aboutMeData]);

  return (
    <AppSidebar>
      <BurgerMenu
        opened={opened === "left"}
        closeSidebar={closeSidebar}
        setLogoutPopup={setLogoutPopup}
      />

      <ConnectMenu opened={opened === "right"} closeSidebar={closeSidebar} />

      {logoutPopup && (
        <LogoutPopup backgroundClick={() => setLogoutPopup(false)} />
      )}
    </AppSidebar>
  );
};
