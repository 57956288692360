import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";

import { clientCollaboratorsUrlBuilder } from "./urlBuilder/clientCollaborators";
import {
  ICollabotatorData,
  ICollabotatorsData,
  IGlobalPartner,
  IUpdateCollaborator,
} from "types";

export interface ICollaboratorProfile {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  photo: null | string;
  phone: string;
}

export interface IGetAllCollaboratorsData {
  page?: number;
  limit?: number;
  ordering?: string;
}

export const clientCollaboratorsAPI = createApi({
  reducerPath: "clientCollaboratorsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["clientCollaboratorsData"],
  endpoints: build => ({
    getAllCollaborators: build.query<
      ICollabotatorsData[],
      IGetAllCollaboratorsData
    >({
      query: () => ({
        url: clientCollaboratorsUrlBuilder.clientCollaborators(),
        method: "GET",
      }),
      providesTags: ["clientCollaboratorsData"],
    }),
    getAllGlobalPartners: build.query<
      IGlobalPartner[],
      IGetAllCollaboratorsData
    >({
      query: () => ({
        url: clientCollaboratorsUrlBuilder.clientGlobalPartners(),
        method: "GET",
      }),
      providesTags: ["clientCollaboratorsData"],
    }),
    getCollaboratorById: build.query({
      query: args => ({
        url: clientCollaboratorsUrlBuilder.clientCollaboratorsId(args.id),
        method: "GET",
        data: args,
      }),
      providesTags: ["clientCollaboratorsData"],
    }),
    createCollaborator: build.mutation<ICollabotatorData, ICollabotatorData>({
      query: data => ({
        url: clientCollaboratorsUrlBuilder.clientCollaborators(),
        method: "POST",
        data,
      }),
    }),
    updateCollaboratorById: build.mutation<
      IUpdateCollaborator,
      IUpdateCollaborator
    >({
      query: ({ id, data }) => ({
        url: clientCollaboratorsUrlBuilder.clientCollaboratorsId(id),
        method: "PATCH",
        data,
      }),
    }),
    deleteCollaboratorById: build.mutation<void, string | number>({
      query: id => ({
        url: clientCollaboratorsUrlBuilder.clientCollaboratorsId(id),
        method: "DELETE",
      }),
      invalidatesTags: ["clientCollaboratorsData"],
    }),
    getCollaboratorProfile: build.query<ICollaboratorProfile, void>({
      query: () => ({
        url: clientCollaboratorsUrlBuilder.getCollaboratorProfile(),
        method: "GET",
      }),
      providesTags: ["clientCollaboratorsData"],
    }),
  }),
});

export const {
  useGetAllGlobalPartnersQuery,
  useGetAllCollaboratorsQuery,
  useGetCollaboratorByIdQuery,
  useLazyGetCollaboratorProfileQuery,
  useCreateCollaboratorMutation,
  useUpdateCollaboratorByIdMutation,
  useDeleteCollaboratorByIdMutation,
} = clientCollaboratorsAPI;
