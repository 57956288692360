import { FC } from "react";
import { Box, styled, Typography } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";

import { theme } from "theme";

interface Props {
  component?: string | FC;
}

export const Title = styled(Typography)({
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  fontSize: theme.fontSize["5xl"],
  fontWeight: theme.fontWeight.regular,
  lineHeight: "58px",
  textTransform: "uppercase",
  color: theme.color.black,
  paddingBottom: "15px",
  width: "130px",
});

export const Description = styled(Typography)({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.md,
  lineHeight: "24px",
  textTransform: "capitalize",
  color: theme.color.black,
  paddingBottom: "10px",
});

export const Container = styled(Box)({
  width: "100%",
  maxWidth: "1150px",
  margin: "0 auto 60px",
});

export const LinkStyled = styled(Description)<Props & ButtonProps>({
  fontSize: theme.fontSize["2xl"],
  paddingTop: "45px",
  textDecoration: "none",
  display: "block",
  lineHeight: "36px",

  svg: {
    marginLeft: "15px",
    marginBottom: "3px",
  },
});
