import type { AxiosRequestConfig, AxiosError } from "axios";

import type { BaseQueryFn } from "@reduxjs/toolkit/query";

import instagramAxiosAPI from "./instagramAxiosAPI";

export const instagramBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, params }) => {
    try {
      const result = await instagramAxiosAPI({
        url: `${baseUrl}/${url}`,
        method,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
