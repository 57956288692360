import axios from "axios";
import { useRememberMe } from "hooks/useRememberMe";

import { getCurrentUserToken, getCurrentUser } from "redux/selectors";
import { userAdminLogout, userClientLogout } from "redux/slices";
import { store } from "store";

const { removeToken } = useRememberMe();

const API_URL = process.env.REACT_APP_BASE_URL;
const UNAUTHORIZED = 401;

const axiosAPI = axios.create({
  baseURL: API_URL,
});

axiosAPI.interceptors.request.use(config => {
  const userToken = getCurrentUserToken(store.getState());

  if (userToken) config.headers.Authorization = `Token ${userToken}`;

  return config;
});

axiosAPI.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    if (error.response.status === UNAUTHORIZED) {
      const { dispatch } = store;
      const userLogout =
        getCurrentUser(store.getState()) === "admin"
          ? userAdminLogout
          : userClientLogout;
      removeToken();
      dispatch(userLogout());
    }
    throw error;
  },
);

export default axiosAPI;
