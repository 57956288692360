import { FC } from "react";
import { Dialog as MUIDialog, CardMedia, styled } from "@mui/material";
import type { CardMediaProps } from "@mui/material/CardMedia";

import { theme } from "theme";

interface Props {
  component?: string | FC;
}

export const Dialog = styled(MUIDialog)({
  ".MuiPaper-root": {
    background: "none",
    boxShadow: "none",
    maxWidth: "100%",
  },
  ".MuiDialogContent-root": {
    padding: 0,
    overflow: "hidden",
    ".MuiBox-root": {
      width: "100%",
      maxHeight: "79vh",
      objectFit: "contain",
      margin: 0,
      "div, video": {
        objectFit: "contain",
      },
    },
  },
  button: {
    position: "absolute",
    top: "15px",
    right: "15px",
    background: theme.color.white,
    minWidth: "auto",
    minHeight: "auto",
    padding: "2px",

    span: {
      margin: 0,
    },

    "&:hover": {
      background: theme.color.rowTop,
    },
  },
});

export const FullImage = styled(CardMedia)<Props & CardMediaProps>({
  width: "100%",
  maxHeight: "79vh",
  objectFit: "contain",
});
