import { endpoints } from "constant";

import type { EventAttendeesQueryParams, EventAttendeesParams } from "types";

export const eventAttendeeUrlBuilder = {
  getEventAttendees: function ({
    page = 0,
    limit = 15,
    ordering = "",
    eventId,
  }: EventAttendeesQueryParams): string {
    return `${endpoints.event}${eventId}/attendees/?page=${
      page + 1
    }&page_size=${limit}&ordering=${ordering}`;
  },
  getEventAttendee: function ({
    eventId,
    attendeeId,
  }: EventAttendeesParams): string {
    return `${endpoints.event}${eventId}/attendees/${attendeeId}/`;
  },
  createEventAttendee: function (eventId: string | number): string {
    return `${endpoints.event}${eventId}/attendees/`;
  },
  updateEventAttendee: function ({
    eventId,
    attendeeId,
  }: EventAttendeesParams): string {
    return `${endpoints.event}${eventId}/attendees/${attendeeId}/`;
  },
  deleteEventAttendee: function ({
    eventId,
    attendeeId,
  }: EventAttendeesParams): string {
    return `${endpoints.event}${eventId}/attendees/${attendeeId}/`;
  },
  downloadEventAttendees: function (eventId: string | number): string {
    return `${endpoints.event}${eventId}/attendees-download/`;
  },
  downloadEventAttendeesTemplate: function (): string {
    return `${endpoints.event}attendees/template-download/`;
  },
  uploadEventAttendees: function (eventId: string | number): string {
    return `${endpoints.event}${eventId}/attendees-upload/`;
  },
};
