import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const RatingStarIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8771 7.38981C20.6893 6.8897 20.2084 6.55741 19.6702 6.55583H14.4451C14.2629 6.55596 14.1005 6.442 14.04 6.27159L12.1644 1.00132C11.9763 0.502495 11.4955 0.171875 10.9583 0.171875C10.4212 0.171875 9.94043 0.502495 9.7523 1.00132L9.74801 1.01494L7.87671 6.27159C7.81632 6.44171 7.65431 6.5556 7.47241 6.55583H2.24565C1.7037 6.55543 1.21951 6.8915 1.03447 7.3965C0.849433 7.9015 1.00316 8.46731 1.41902 8.81184L5.86893 12.4712C6.00517 12.5833 6.05801 12.7668 6.00198 12.9333L4.1324 18.4912C3.95511 19.0185 4.14236 19.5989 4.59541 19.9263C5.04846 20.2538 5.66315 20.2529 6.11529 19.9243L10.7043 16.5883C10.8553 16.4786 11.0606 16.4786 11.2116 16.5883L15.7988 19.9234C16.2508 20.2532 16.8661 20.2548 17.3199 19.9274C17.7736 19.6 17.9611 19.0189 17.7834 18.4912L15.9138 12.9299C15.8578 12.7634 15.9107 12.5798 16.0469 12.4678L20.5054 8.80333C20.9158 8.45595 21.0642 7.89165 20.8771 7.38981Z"
      fill="white"
    />
  </svg>
);
