import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const PointLineIcon: FC<SvgIconConstituentValues> = props => {
  return (
    <svg
      width="4"
      height="33"
      viewBox="0 0 4 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.85156 32.0853V0.849609"
        stroke="black"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
