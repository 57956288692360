import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ZoomOutIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.12849 11.8839C9.75485 11.8839 11.8839 9.75485 11.8839 7.12849C11.8839 4.50213 9.75485 2.37305 7.12849 2.37305C4.50213 2.37305 2.37305 4.50213 2.37305 7.12849C2.37305 9.75485 4.50213 11.8839 7.12849 11.8839Z"
      stroke="black"
    />
    <path d="M9.72243 7.12842H4.53467" stroke="black" />
  </svg>
);
