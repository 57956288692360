import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const SingleNeutralCircleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4996 19.671C16.7364 19.671 20.171 16.2364 20.171 11.9996C20.171 7.76275 16.7364 4.32812 12.4996 4.32812C8.26275 4.32812 4.82812 7.76275 4.82812 11.9996C4.82812 16.2364 8.26275 19.671 12.4996 19.671Z"
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4992 12.6663C14.065 12.6663 15.3343 11.397 15.3343 9.8312C15.3343 8.26541 14.065 6.99609 12.4992 6.99609C10.9334 6.99609 9.66406 8.26541 9.66406 9.8312C9.66406 11.397 10.9334 12.6663 12.4992 12.6663Z"
      stroke="currentColor"
    />
    <path
      d="M16.7131 16.3354C15.9424 14.706 14.3015 13.667 12.4991 13.667C10.6967 13.667 9.05582 14.706 8.28516 16.3354"
      stroke="currentColor"
    />
  </svg>
);
