import React, { forwardRef } from "react";

import { LabelSubTitle, LabelTitle, Wrapper } from "./styled";

interface IComissionCard {
  isActive?: boolean;
  title: string;
  subTitle: string;
  handleClick?: () => void;
}

export const ComissionCard = forwardRef<HTMLDivElement, IComissionCard>(
  ({ isActive, title, subTitle, handleClick }, ref) => {
    return (
      <Wrapper ref={ref} onClick={handleClick}>
        <LabelTitle isActive={isActive}>{title}</LabelTitle>

        <LabelSubTitle isActive={isActive}>{subTitle}</LabelSubTitle>
      </Wrapper>
    );
  },
);

ComissionCard.displayName = "ComissionCard";
