export const capitalize = (data: string[] | string): string | string[] => {
  if (Array.isArray(data)) {
    return data.map(el =>
      el
        .replace(/_/g, " ")
        .split(/\s+/)
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join(" "),
    );
  }
  return data
    .replace(/_/g, " ")
    .split(/\s+/)
    .map(word => word[0].toUpperCase() + word.substring(1))
    .join(" ");
};
