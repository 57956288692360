import { styled, TableRow, TableCell } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const TableRowStyled = styled(TableRow)<{
  lastElementFullWidth: boolean;
  withAction: boolean;
}>(({ lastElementFullWidth, withAction }) => ({
  td: {
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    lineHeight: "130%",
    color: theme.color.black,
    borderBottom: "none",
  },

  "&:nth-of-type(even)": {
    backgroundColor: theme.color.grey[100],
  },

  "&:nth-of-type(odd)": {
    backgroundColor: theme.color.white,
  },

  "&.MuiTableRow-hover:hover": {
    "&:nth-of-type(even)": {
      backgroundColor: theme.color.grey[100],
    },

    "&:nth-of-type(odd)": {
      backgroundColor: theme.color.white,
    },
  },

  "svg:hover": {
    cursor: "pointer",
  },

  ...(lastElementFullWidth && {
    ...(withAction
      ? {
          "& :nth-last-child(2)": {
            // width: "50%", temporary removed
          },
        }
      : {
          "& :last-child": {
            width: "50%",
          },
        }),
  }),
}));

export const TableCellAction = styled(TableCell)<{
  isFileLoading?: boolean;
  moodBoardsTable?: boolean;
}>(({ isFileLoading, moodBoardsTable }) => ({
  display: "table-cell",
  justifyContent: isFileLoading ? "flex-end" : "center",
  padding: "0 25px",
  whiteSpace: "nowrap",

  svg: {
    marginRight: "10px",
    "&:last-child": {
      marginRight: 0,
    },
  },

  ...(moodBoardsTable && {
    [BreakPointTheme.breakpoints.down("lg")]: {
      svg: {
        marginRight: "40px",
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  }),
}));

export const TableCellCheckbox = styled(TableCell)({
  display: "table-cell",
  width: "25px",
  alignItems: "center",
  justifyContent: "center",
  padding: "7px 16px",
});

export const TableCellData = styled(TableCell)<{
  isBold?: boolean;
  documentsTable?: boolean;
  moodBoardsTable?: boolean;
}>(({ isBold, documentsTable, moodBoardsTable }) => ({
  display: "table-cell",
  alignItems: "center",
  justifyContent: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "7px 16px",

  ...(isBold && {
    fontWeight: `${theme.fontWeight.bold} !important`,
    fontSize: `${theme.fontSize.sm} !important`,
    lineHeight: "15.6px !important",
  }),

  ...(documentsTable && {
    padding: "15px 0 13px 30px",

    [BreakPointTheme.breakpoints.down("lg")]: {
      padding: "15px 0 13px 21px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      padding: "17px 0 13px 11px",
    },
  }),

  ...(moodBoardsTable && {
    padding: "15px 0 13px 24px",

    [BreakPointTheme.breakpoints.down("lg")]: {
      padding: "16px 0 12px 20px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      padding: "15px 0 13px 22px",
    },
  }),
}));

export const LoaderContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});
