import React, { FC } from "react";

import { StyledDialog, StyledImg } from "./styled";

interface IImagePreview {
  open: boolean;
  fullWidth?: boolean;
  handleCloseContent: () => void;
  url: string;
}

export const ImagePreview: FC<IImagePreview> = ({
  open,
  fullWidth,
  handleCloseContent,
  url,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleCloseContent}
      fullWidth={fullWidth}
      maxWidth="lg"
    >
      <StyledImg src={url} alt="image" onClick={handleCloseContent} />
    </StyledDialog>
  );
};
