import { createTheme } from "@mui/material";

export const theme = {
  color: {
    primary: {
      100: "#E6F5FF",
      600: "#0AD2F1",
      700: "#31A1D0",
      800: "#00B1A5",
    },
    white: "#FFFFFF",
    black: "#000000",
    darkBlack: "#0D1214",
    lightGrey: "#D8D8D8",
    darkGrey: "#333333",
    grey: {
      100: "#EEEEEE",
      200: "#E7E7E7",
      300: "#D9D9D9",
      400: "#C8C8C8",
      500: "#8D8D8D",
      600: "#787878",
      900: "#333333",
      1000: "#9B9B9B",
      1100: "#818181",
      1200: "#838383",
      1300: "rgba(255, 255, 255, 0.8)",
      1400: "#454545",
    },
    success: {
      600: "#66C109",
      800: "#467C16",
    },
    error: {
      500: "#F64662",
      600: "#C93D51",
    },
    "6D": "#6D6D6D",
    blue: "#00B1A5",
    linkBg: "rgba(255, 255, 255, 1)",
    sidebarClientBg: "rgba(0, 0, 0, 0.55)",
    sidebarBg: "#1A262B",
    sideLinkBg: "#555555",
    orange: "#FF4F00",
    darkPin: "#333333",
    rowTop: "rgba(245, 245, 245, 1)",
    rowBottom: "#D8D8D8",
    borderDashed: "#DDDDDD",
    borderDark: "#0D1214",
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256],
  fontFamily: {
    SoehneSchmalLeicht: "SoehneSchmalLeicht",
    Roboto: "Roboto",
  },
  fontSize: {
    xs: "10px",
    sm: "12px",
    base: "14px",
    md: "16px",
    lg: "18px",
    xl: "22px",
    "2xl": "26px",
    "3xl": "30px",
    "4xl": "36px",
    "5xl": "62px",
    "6xl": "67px",
    "7xl": "70px",
    loginTitle: "52px",
    loginTitleSM: "42px",
    pageTitle: "52px",
    pageTitleSM: "42px",
  },
  fontWeight: {
    light: "300",
    regular: "400",
    semiBold: "500",
    bold: "600",
    extraBold: "700",
    extraBold900: "900",
  },
};

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    bg: true;
    lg: true;
    xl: false;
  }
}

export const BreakPointTheme = createTheme({
  breakpoints: {
    values: {
      xs: 280,
      sm: 375,
      md: 768,
      bg: 900,
      lg: 1024,
    },
  },
});
