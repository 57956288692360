import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const TabsArrowIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="13"
    height="21"
    viewBox="0 0 13 21"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7515 0.437727L1.55865 9.80439C1.47168 9.88429 1.42188 9.99909 1.42188 10.1197C1.42188 10.2403 1.47168 10.3551 1.55865 10.435L11.7515 19.8008"
      stroke="#FF4F00"
      strokeWidth="1.12179"
    />
  </svg>
);
