import { styled } from "@mui/system";

import { theme } from "theme";

export const Image = styled("div")<{
  photoURL: string;
  maxContent: boolean;
  isSelectMode: boolean;
  isSelected: boolean;
  isDrug: boolean;
}>(({ photoURL, maxContent, isDrug }) => ({
  position: "relative",
  background: `url(${photoURL}) center/cover no-repeat`,
  width: "100%",

  ...(isDrug
    ? {
        cursor: "grab",
      }
    : {
        cursor: "pointer",
      }),

  ...(maxContent
    ? {
        height: "160px",

        "@media (max-width: 1440px)": {
          height: "110px",
        },
        "@media (max-width: 1024px)": {
          height: "60px",
        },
      }
    : {
        height: "246px",
        "@media (max-width: 1600px)": {
          height: "215px",
        },
        "@media (max-width: 1440px)": {
          height: "190px",
        },
        "@media (max-width: 1200px)": {
          height: "140px",
        },
        "@media (max-width: 1024px)": {
          height: "120px",
        },
      }),
}));

export const FavoriteButton = styled("button")<{ isFavorite: boolean }>(
  ({ isFavorite }) => ({
    position: "absolute",
    top: "10px",
    left: "10px",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",

    ...(isFavorite
      ? {
          background: theme.color.primary[800],
        }
      : {
          background: theme.color.grey[400],
        }),
    svg: {
      left: "3px",
      top: "3px",
      position: "absolute",
    },

    "@media (max-width: 1400px)": {
      top: "5px",
      left: "5px",
      width: "20px",
      height: "20px",

      svg: {
        width: "16px",
        height: "16px",
        left: "1.5px",
        top: "2px",
      },
    },
    "@media (max-width: 1024px)": {
      top: "5px",
      left: "5px",
      width: "16px",
      height: "16px",

      svg: {
        width: "12px",
        height: "12px",
      },
    },
  }),
);

export const SelectIconContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",

  svg: {
    width: "60px",
    height: "60px",

    "@media (max-width: 1024px)": {
      width: "30px",
      height: "30px",
    },
  },
});

export const BackGround = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  background: theme.color.black,
  opacity: "0.5",
});
