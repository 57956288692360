import { FC } from "react";
import { styled, Box, Typography } from "@mui/material";
import type { LinkProps } from "react-router-dom";

import { theme } from "theme";

interface Props {
  component?: string | FC;
}

export const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "50px 0",
});

export const LinkStyled = styled(Typography)<Props & LinkProps>({
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.base,
  lineHeight: "16px",
  color: theme.color.darkGrey,
  textDecoration: "none",
  margin: "0 70px",
  textTransform: "uppercase",
});
