import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import type { Action } from "components/Table";
import { Table } from "components/Table";
import { BinIcon, UploadIcon } from "icons";
import { images } from "constant";
import { ErrorText } from "styled";

import { AdminModal } from "../AdminModal";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextContent,
} from "../styled";

import { Cells } from "./config";
import type { UploadModalProps } from "./typed";
import {
  NoticeButton,
  TextDrag,
  TextError,
  TextInfo,
  TextLimit,
  TextUpload,
  UploadBox,
} from "./styled";

const EmptyError = {
  size: "",
  type: "",
  fileSize: "",
};

export const UploadModal: FC<UploadModalProps> = ({
  children,
  open,
  title,
  approveButton,
  cancelButton,
  fileTypes,
  maxSize,
  maxFiles,
  multiple,
  maxWidth,
  withUpload,
  isSuccess,
  isLoading,
  error,
  setOpen,
  handleFileName,
  onConfirm,
}) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState(EmptyError);

  const uploadedFilesNumber = useRef(null);

  useEffect(() => {
    if (multiple) return;

    if (handleFileName && files.length) {
      handleFileName(files[0].name);
    }
  }, [files]);

  const handleFileDelete = deletedFile => {
    const filesArr = files.filter(file => file !== deletedFile);
    uploadedFilesNumber.current = filesArr.length;
    setFiles(filesArr);
  };

  const actions = useMemo<Action[]>(
    () => [
      {
        label: "Delete Image",
        Icon: BinIcon,
        onClick: handleFileDelete,
      },
    ],
    [files],
  );

  const handleChange = newFiles => {
    setErrors(EmptyError);

    if (multiple) {
      const restFiles = maxFiles - files.length;
      if (maxFiles && newFiles.length > restFiles) {
        setErrors({ ...EmptyError, size: `Only ${maxFiles} files accepted` });
        const filesArr = [
          ...files,
          ...Array.from(newFiles).slice(0, restFiles),
        ];
        uploadedFilesNumber.current = filesArr.length;
        setFiles(filesArr);
      } else {
        const filesArr = [...files, ...Array.from(newFiles)];
        setFiles(filesArr);
        uploadedFilesNumber.current = filesArr.length;
      }
    } else {
      setFiles([newFiles]);
      uploadedFilesNumber.current = [newFiles].length;
    }
  };

  const handleTypeError = err => setErrors({ ...errors, type: err });
  const handleSizeError = err => setErrors({ ...errors, fileSize: err });

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setErrors(EmptyError);
  };

  const handleConfirm = () => {
    onConfirm(files);

    if (!withUpload) {
      setOpen(false);
      setOpenNotice(true);
    }
  };

  const closeNotice = () => {
    setOpenNotice(false);
    handleClose();
  };

  useEffect(() => {
    if (isSuccess && withUpload) {
      setOpen(false);
      setOpenNotice(true);
    }
  }, [withUpload, isSuccess, isLoading]);

  return (
    <>
      <AdminModal
        open={open}
        title={title}
        approveButton={approveButton}
        cancelButton={cancelButton}
        isLoading={isLoading}
        disabledApproveButton={!files.length || (withUpload && isLoading)}
        maxWidth={maxWidth}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      >
        {children}
        <FileUploader
          multiple={multiple}
          handleChange={handleChange}
          name="file"
          types={fileTypes ?? undefined}
          maxSize={maxSize}
          onTypeError={handleTypeError}
          onSizeError={handleSizeError}
        >
          <UploadBox>
            <UploadIcon />
            <TextUpload>
              {`Upload file(s) `}
              <TextDrag component="span">or drag and drop</TextDrag>
            </TextUpload>
            <TextLimit>{`File Limit ${maxSize}MB`}</TextLimit>
          </UploadBox>
        </FileUploader>
        {fileTypes ? (
          <TextInfo>{`File types: ${fileTypes
            .map(type => `.${type}`)
            .join(", ")}`}</TextInfo>
        ) : null}
        {multiple && maxFiles ? (
          <TextInfo>{`Max files - ${maxFiles}`}</TextInfo>
        ) : null}
        <TextError>{errors.size}</TextError>
        <TextError>{errors.type}</TextError>
        <TextError>{errors.fileSize}</TextError>
        {files.length ? (
          <Table
            rows={files}
            cells={Cells}
            actions={actions}
            withHeader={false}
            withPagination={false}
            firstColumnWidth={500}
          />
        ) : null}
        {error ? <ErrorText>{error}</ErrorText> : null}
      </AdminModal>
      <Dialog open={openNotice} maxWidth="sm" fullWidth onClose={closeNotice}>
        <DialogTitle>Notice</DialogTitle>
        <DialogContent>
          <TextContent>{`You’ve uploaded ${uploadedFilesNumber.current} file(s).`}</TextContent>
        </DialogContent>
        <DialogActions>
          <NoticeButton onClick={closeNotice}>Ok</NoticeButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

UploadModal.defaultProps = {
  title: "Upload File(s)",
  fileTypes: images,
  maxSize: 200,
  maxFiles: 5,
  multiple: true,
};
