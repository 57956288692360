import {
  styled,
  TableHead,
  TableRow,
  TableCell,
  TableCellProps,
} from "@mui/material";

import { theme } from "theme";

interface CellProps extends TableCellProps {
  minWidth?: number | string;
}

export const MaterialTableHead = styled(TableHead)({
  backgroundColor: theme.color.grey[600],
});

export const TableRowStyled = styled(TableRow)({
  backgroundColor: theme.color.grey[600],
  span: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "140%",
    color: theme.color.white,
  },
  ".MuiTableCell-root .Mui-active, .MuiTableCell-root .MuiButtonBase-root:hover":
    {
      color: theme.color.white,
      svg: {
        opacity: 1,
        path: { fill: theme.color.white },
      },
    },
  ".MuiTableSortLabel-icon": {
    opacity: 0.5,
    marginLeft: "10px",
  },
});

export const TableCellStyled = styled(TableCell)<CellProps>(
  ({ width, minWidth }) => ({
    ...(width && {
      width,
      minWidth: minWidth || "90px",
    }),
  }),
);
