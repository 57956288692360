import { Box } from "@mui/material";
import React, { FC } from "react";
import { LinearProgress } from "./styled";

export const LinearLoader: FC = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};
