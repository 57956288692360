import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import image from "images/porscheGreen.jpg";

import { CLIENT_ROUTES } from "constant";

import {
  Container,
  Description,
  Section,
  TextBlock,
  Title,
  Wrapper,
} from "./styled";

interface ILayout {
  title?: string;
  description?: string;
  padding?: string;
  children: React.ReactNode;
}

export const Layout: FC<ILayout> = ({
  title,
  description,
  padding,
  children,
}) => {
  const location = useLocation();

  const isMobileHide = location.pathname !== CLIENT_ROUTES.CLIENT_LOGIN.path;
  return (
    <Wrapper>
      <Section isMobileHide={isMobileHide} background={image} />
      <Section height={"fit-content"} padding={padding}>
        <Container>
          <TextBlock>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </TextBlock>
          {children}
        </Container>
      </Section>
    </Wrapper>
  );
};
