import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CheckboxAdminIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      fill="#00B1A5"
      stroke="#00B1A5"
    />
    <path d="M4.2002 9L7.8002 12.6L14.4002 6" stroke="white" />
  </svg>
);
