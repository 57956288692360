import React, { FC, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  ControllerFieldState,
  ControllerRenderProps,
} from "react-hook-form/dist/types";

import { ErrorMessage } from "../styled";

import { Wrapper } from "./styled";

export interface RecaptchaType extends ControllerRenderProps {
  fieldState: ControllerFieldState;
}

export const Recaptcha: FC<RecaptchaType> = ({
  onChange,
  fieldState,
  ...field
}) => {
  const errorMessage = useMemo(
    () => fieldState.error?.message,
    [fieldState.error],
  );
  return (
    <Wrapper isError={Boolean(errorMessage)}>
      <ReCAPTCHA
        {...field}
        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
        onChange={onChange}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};
