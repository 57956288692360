import React, { FC } from "react";
import moment from "moment";

import { DATE_FORMATS } from "constant";
import { useAppDispatch } from "hooks/redux";
import { setSidebarOpened } from "redux/slices";
import { sidebarSelector } from "redux/selectors";

import { Header, Headline, Sidebar } from "./components";
import { LayoutGrid, MainContainer } from "./styled";
import { LayoutProps } from "./typed";
import { useSelector } from "react-redux";

export const Layout: FC<LayoutProps> = ({
  children,
  icon,
  name,
  customName,
}) => {
  const dispatch = useAppDispatch();
  const sidebarOpened = useSelector(sidebarSelector);

  const handleSidebar = () => dispatch(setSidebarOpened(!sidebarOpened));

  return (
    <LayoutGrid gridTemplate={!sidebarOpened}>
      <Sidebar activeSidebar={!sidebarOpened} />
      <MainContainer>
        <Header handleSidebar={handleSidebar} isActive={!sidebarOpened} />
        <Headline
          icon={icon}
          name={customName ? customName : name}
          date={moment().format(DATE_FORMATS.WEEK_MONTH_DAY_YEAR)}
        />
        {children}
      </MainContainer>
    </LayoutGrid>
  );
};
