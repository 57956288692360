import React, { FC } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { ADMIN_LOGIN } from "constant";
import { isUserAdminAuthenticated } from "redux/selectors";
import { useAppSelector } from "hooks/redux";

export const ProtectedAdminRoute: FC = () => {
  const isAuthenticated = useAppSelector(isUserAdminAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to={ADMIN_LOGIN} />;
};
