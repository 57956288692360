import {
  styled,
  DialogActions as MUIDialogActions,
  DialogContent as MIUDialogContent,
} from "@mui/material";

import { DialogTitle as Title } from "../styled";

export const DialogTitle = styled(Title)({
  fontSize: "20px",
  lineHeight: "140%",
  padding: "20px 40px",
  textAlign: "left",
});

export const DialogContent = styled(MIUDialogContent)({
  padding: "12px 40px 0",
});

export const DialogActions = styled(MUIDialogActions)({
  padding: "22px 40px 20px",
});
