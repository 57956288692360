import React, { ChangeEvent, FC } from "react";

import {
  Box,
  Table as MUITable,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

import { Loader } from "components";
import { BinIcon, DownloadIcon } from "icons";
import { ButtonOutlined } from "styled";

import { TableRow } from "../TableRow";
import { TableHead } from "../TableHead";

import type { TableProps } from "./typed";
import {
  DeleteButton,
  LoaderWrapper,
  StyledContainer,
  StyledPaper,
} from "./styled";

const RowsPerPageOptions = [15, 30];

export const ClientsTable: FC<TableProps> = ({
  collaboratorsTable,
  documentsTable,
  moodBoardsTable,
  isDisabled,
  isLoading,
  fixed,
  cells,
  rows,
  actions,
  rowsPerPageOptions,
  order,
  orderBy,
  page,
  rowsPerPage,
  isSelected,
  isSelectedAll,
  hideSortIcon,
  withoutActions,
  withHeader,
  withCheckBox,
  withPagination,
  lastElementFullWidth,
  minWidthDeleteButton,
  buttonsFontSize,
  handleSelect,
  handleSelectAll,
  handleSelectedDownload,
  handleSelectedDelete,
  handleRequestSort,
  setPage,
  setRowsPerPage,
  getClickHandler,
  children,
}) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Box>
      <StyledPaper>
        <TableContainer>
          <MUITable aria-label="table">
            {withHeader ? (
              <TableHead
                documentsTable={documentsTable}
                moodBoardsTable={moodBoardsTable}
                collaboratorsTable={collaboratorsTable}
                fixed={fixed}
                order={order}
                orderBy={orderBy}
                isSelectedAll={isSelectedAll}
                handleSelectAll={handleSelectAll}
                onRequestSort={handleRequestSort}
                cells={cells}
                withCheckBox={withCheckBox}
                hideSortIcon={hideSortIcon}
                withAction={Boolean(actions?.length)}
                lastElementFullWidth={lastElementFullWidth}
              />
            ) : null}
            {!isLoading && (
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    collaboratorsTable={collaboratorsTable}
                    moodBoardsTable={moodBoardsTable}
                    documentsTable={documentsTable}
                    withCheckBox={withCheckBox}
                    isSelected={isSelected}
                    handleSelect={handleSelect}
                    withoutActions={withoutActions}
                    getClickHandler={getClickHandler}
                    row={row}
                    cells={cells}
                    actions={actions}
                    lastElementFullWidth={lastElementFullWidth}
                  />
                ))}
              </TableBody>
            )}
          </MUITable>
          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}

          {!rows?.length && children}
        </TableContainer>
      </StyledPaper>
      <StyledContainer>
        {isSelected?.length > 1 && handleSelectedDelete && (
          <DeleteButton
            sx={{ margin: "10px 0" }}
            buttonsFontSize={buttonsFontSize}
            minWidth={minWidthDeleteButton}
            startIcon={<BinIcon />}
            onClick={handleSelectedDelete}
          >
            Delete selected
          </DeleteButton>
        )}
        {isSelected?.length > 1 && handleSelectedDownload && (
          <ButtonOutlined
            sx={{ margin: "10px 0" }}
            startIcon={<DownloadIcon />}
            onClick={handleSelectedDownload}
            disabled={isDisabled}
          >
            Download Selected
          </ButtonOutlined>
        )}
        {!isLoading && !!withPagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={rows.length}
            component="div"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </StyledContainer>
    </Box>
  );
};

ClientsTable.defaultProps = {
  isLoading: false,
  rowsPerPageOptions: RowsPerPageOptions,
  page: 0,
  rowsPerPage: 15,
  withHeader: true,
  withCheckBox: true,
  withPagination: false,
  lastElementFullWidth: false,
};
