import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const BurgerMenuIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="36"
    height="22"
    viewBox="0 0 36 22"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      className="burger"
      y1="1.5"
      x2="36"
      y2="1.5"
      stroke="black"
      strokeWidth="2"
    />
    <path className="burger" d="M0 11.5H36" stroke="black" strokeWidth="2" />
    <path className="burger" d="M0 20.5H36" stroke="black" strokeWidth="2" />
  </svg>
);
