import { endpoints } from "constant";

import type { ClientsQueryParams } from "types";

export const clientUrlBuilder = {
  getClients: function ({
    page = 1,
    limit = 15,
    search = "",
    name = "",
  }: ClientsQueryParams = {}): string {
    return `${endpoints.clients}?page=${
      page + 1
    }&page_size=${limit}&search=${search}&name=${name}`;
  },
  getCars: function ({
    page = 1,
    limit = 15,
    ordering = "",
    search = "",
  }: ClientsQueryParams = {}): string {
    return `${endpoints.cars}?page=${
      page + 1
    }&page_size=${limit}&search=${search}&ordering=${ordering}`;
  },
  getClient: function ({
    id,
  }: {
    id?: string;
  } = {}): string {
    return `${endpoints.clients}${id}/`;
  },
  getClientAboutMeInfo: function ({
    id,
  }: {
    id?: string;
  } = {}): string {
    return `${endpoints.client}${id}/about-me/`;
  },
  getCollaborators: function ({
    id,
    page = 1,
    limit = 15,
    ordering = "",
  }: {
    id?: string;
    page?: number;
    limit?: number;
    ordering?: string;
  } = {}): string {
    return `${endpoints.clients}${id}/collaborators?page=${
      page + 1
    }&page_size=${limit}&ordering=${ordering}`;
  },
};
