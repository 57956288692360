import { endpoints } from "constant";

import type { EventsQueryParams } from "types";

export const eventUrlBuilder = {
  getEvents: function ({
    page,
    limit,
    ordering = "",
    search = "",
    upcomingEvent = "",
    pastEvent = "",
    eventWithAttendees = "",
    startDate = "",
    endDate = "",
  }: EventsQueryParams = {}): string {
    return `${endpoints.events}?${
      page === undefined ? "" : `page=${page + 1}`
    }&page_size=${
      limit ?? ""
    }&search=${search}&ordering=${ordering}&upcoming_event=${upcomingEvent}&past_event=${pastEvent}&event_with_attendees=${eventWithAttendees}&start_date__gte=${startDate}&end_date__lte=${endDate}`;
  },
  getEvent: function (id: string | number): string {
    return `${endpoints.events}${id}/`;
  },
  createEvent: function (): string {
    return endpoints.events;
  },
  updateEvent: function (id: string | number): string {
    return `${endpoints.events}${id}/`;
  },
  deleteEvent: function (id: string | number): string {
    return `${endpoints.events}${id}/`;
  },
};
