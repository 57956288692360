import {
  styled,
  DialogContent as MIUDialogContent,
  DialogTitle as MUIDialogTitle,
  Typography,
} from "@mui/material";
import { DialogBlurBackdrop } from "styled";
import { BreakPointTheme, theme } from "theme";

export const DialogContent = styled(MIUDialogContent)({
  width: "461px",
  minHeight: "85px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  textAlign: "center",

  fontWeight: theme.fontWeight.regular,
  fontSize: "20px",
  lineHeight: "140%",

  [BreakPointTheme.breakpoints.down("md")]: {
    width: "auto",
    height: "auto",
  },
});

export const Dialog = styled(DialogBlurBackdrop)<{
  height?: number;
  paddingBot?: number;
}>(({ height, paddingBot }) => ({
  ".MuiPaper-root": {
    borderRadius: "10px",
    width: "565px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    ...(height && {
      height: `${height}px`,
    }),
    ...(paddingBot && {
      paddingBottom: `${paddingBot}px`,
    }),

    [BreakPointTheme.breakpoints.down("md")]: {
      width: "351px",
    },
  },
}));

export const DialogTitle = styled(MUIDialogTitle)({
  fontWeight: theme.fontWeight.extraBold,
  fontSize: "24px",
  lineHeight: "24px",
  color: theme.color.black,
  textAlign: "center",
  padding: "35px 0 10px",

  [BreakPointTheme.breakpoints.down("md")]: {
    maxWidth: "314px",
  },
});

export const ButtonsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
});

export const FirstButton = styled("div")({
  borderRight: "1px solid #6D6D6D",
});

export const Button = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderTop: "1px solid #6D6D6D",
  width: "100%",
  height: "52px",
});

export const ButtonText = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "140%",
  color: theme.color.black,
});
