import { endpoints } from "constant";

export const clientCollaboratorsUrlBuilder = {
  clientCollaborators: function (): string {
    return `${endpoints.clientCollaborators}`;
  },
  clientGlobalPartners: function (): string {
    return `${endpoints.globalPartnersForClient}`;
  },
  clientCollaboratorsId: function (id: string | number): string {
    return `${endpoints.clientCollaborators}${id && id}/`;
  },
  getCollaboratorProfile: function (): string {
    return `${endpoints.collaboratorProfile}`;
  },
};
