import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  Event,
  EventData,
  EventsQueryParams,
  EventsListData,
} from "types";

import { eventUrlBuilder } from "./urlBuilder/events";

export const eventsAPI = createApi({
  reducerPath: "eventsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Events"],
  endpoints: build => ({
    getEvents: build.query<EventsListData, EventsQueryParams | void>({
      query: (args?: EventsQueryParams) => ({
        url: eventUrlBuilder.getEvents(args),
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getEvent: build.query<Event, string>({
      query: args => ({
        url: eventUrlBuilder.getEvent(args),
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createEvent: build.mutation<Event, EventData>({
      query: body => ({
        url: eventUrlBuilder.createEvent(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Events"],
    }),
    updateEvent: build.mutation<Event, EventData>({
      query: body => ({
        url: eventUrlBuilder.updateEvent(body.id),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEvent: build.mutation<Event, string>({
      query: args => ({
        url: eventUrlBuilder.deleteEvent(args),
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventsAPI;
