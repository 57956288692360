import React, { FC } from "react";

import { CloseIcon } from "icons";
import { ButtonOutlined } from "styled";
import { VideoPreview } from "components/Clients/Specifications/components";

import { DialogContent } from "../styled";
import { Dialog, FullImage } from "./styled";
import { ImagePreviewProps } from "./typed";
import { PDFViewer } from "../PDFViewer";

export const ImagePreview: FC<ImagePreviewProps> = ({
  img,
  isVideo,
  isPdf,
  setOpen,
}) => {
  const handleClose = () => setOpen(false);

  if (isPdf) {
    return <PDFViewer open={!!img} docUrl={String(img)} setOpen={setOpen} />;
  }

  return (
    <Dialog open={Boolean(img)} onClose={handleClose}>
      <DialogContent>
        {isVideo ? (
          <VideoPreview video={String(img)} />
        ) : (
          <FullImage component="img" image={String(img)} />
        )}
        <ButtonOutlined startIcon={<CloseIcon />} onClick={handleClose} />
      </DialogContent>
    </Dialog>
  );
};
