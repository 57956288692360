import React, { FC } from "react";

import { Error, Box } from "./styled";

export const ErrorMessage: FC = () => {
  return (
    <Box>
      <Error>Something went wrong</Error>
    </Box>
  );
};
