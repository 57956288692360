import React from "react";

export const MapCarStepsIcon = props => {
  return (
    <svg
      width="1511"
      height="202"
      viewBox="0 0 1511 202"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1505.55 2.01226C1501.1 1.12723 1486.05 0.612305 1463.15 0.612305C1393.9 0.612305 1252.79 5.40755 1115.59 19.6967C1047.24 26.6321 982.142 34.71 920.503 43.8178C872.307 51.3647 837.555 57.5277 837.555 68.8078C837.555 86.6854 988.854 101.393 1088.69 125.434C1012.85 129.135 798.567 140.415 579.881 152.29C397.27 162.202 211.218 172.324 103.69 180.981C75.2716 183.073 48.4037 185.905 23.6346 189.365C2.91179 192.583 0.113446 195.286 0 196.429V196.558C0.0189077 196.847 0.226893 197.008 0.226893 197.008C0.226893 197.008 -2.07985 199.824 21.1577 201.16C29.1368 201.61 40.16 201.868 54.9458 201.868C79.9796 201.868 115.772 201.128 165.745 199.325C231.204 196.96 396.343 191.473 567.799 184.087C838.916 172.356 1139.02 156.345 1187.22 144.116C1187.22 144.116 1228.3 137.599 1177.59 127.461L1146.3 121.443C1110.39 114.588 1056.05 104.869 1009.03 94.892C950.453 82.5016 906.511 76.2259 906.511 67.1986C906.511 61.0678 984.846 49.4498 1083.15 38.1697C1198.16 25.023 1342.16 12.6004 1435.1 10.9269C1436.83 10.9269 1437.21 11.5384 1437.21 11.5384C1431.47 13.8234 1402.86 22.0944 1302.23 39.8432C1293.4 40.9535 1290.92 43.2707 1296.48 44.9925C1298.6 45.6844 1301.85 46.1671 1305.5 46.489C1312.76 47.197 1320.59 47.551 1328.38 47.551C1340.61 47.551 1352.71 46.6982 1362.34 45.0407C1388.88 39.7789 1504.66 15.9796 1510.42 5.56847V4.12024C1509.89 3.28348 1508.34 2.57546 1505.58 2.01226H1505.55ZM1113.29 131.934C1113.29 131.934 1132.1 135.829 1119.43 138.677V138.709C1070.46 149.764 294.941 189.848 106.016 191.022C104.276 191.022 102.934 190.749 102.934 190.411C102.934 190.25 103.331 190.105 103.69 189.992C171.852 175.397 973.672 137.679 1110.96 131.323C1111.74 131.516 1112.49 131.741 1113.27 131.934H1113.29Z"
        fill="#C9C9C9"
      />
    </svg>
  );
};
