import React, { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";

import { getUserClientRole } from "redux/selectors";

import { ColoredCheckedIcon, RatingStarIcon } from "icons";

import {
  BackGround,
  FavoriteButton,
  Image,
  SelectIconContainer,
} from "./styled";
import { ROLES } from "constant";

interface IContentCardDnD {
  id: string;
  index: number;
  onDrug?;
  thumbnail: string;
  photoURL: string;
  maxContent: boolean;
  isDrug?: boolean;
  isSelectMode: boolean;
  isFavorite: boolean;
  isSelected: boolean;
  setFavorite: (id: string) => void;
  openModalGallery: (id: number) => void;
  handleSelectImage: (id: string) => void;
}

export const ContentCardDnD: FC<IContentCardDnD> = ({
  id,
  index,
  onDrug,
  photoURL,
  thumbnail,
  maxContent,
  isSelectMode,
  isFavorite,
  isDrug,
  isSelected,
  setFavorite,
  openModalGallery,
  handleSelectImage,
}) => {
  const clientRole = useSelector(getUserClientRole);

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (
        clientOffset.y < hoverBoundingRect.bottom &&
        clientOffset.y > hoverBoundingRect.top
      ) {
        onDrug(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => isDrug,
  });

  drag(drop(ref));

  //---
  const selectImage = (id: string) =>
    isSelectMode ? handleSelectImage(id) : openModalGallery(index);

  return (
    <Image
      ref={ref}
      data-handler-id={handlerId}
      maxContent={maxContent}
      photoURL={thumbnail || photoURL}
      onClick={() => selectImage(id)}
      isDrug={isDragging}
      isSelectMode={isSelectMode}
      isSelected={isSelected}
    >
      {clientRole !== ROLES.Collaborator && isSelected && (
        <>
          <BackGround />
          <SelectIconContainer>
            <ColoredCheckedIcon />
          </SelectIconContainer>
        </>
      )}
      {clientRole !== ROLES.Collaborator && (
        <FavoriteButton
          isFavorite={isFavorite}
          onClick={e => {
            e.stopPropagation();
            setFavorite(id);
          }}
        >
          <RatingStarIcon />
        </FavoriteButton>
      )}
    </Image>
  );
};
