import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { CarStage, CarStageQuery } from "types";

import { carStagesTimelineUrlBuilder } from "./urlBuilder/carStagesTimeline";

export const carStagesTimelineAPI = createApi({
  reducerPath: "carStagesTimelineAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["carStagesTimeline"],
  endpoints: build => ({
    getCarStages: build.query<CarStage[], CarStageQuery>({
      query: args => ({
        url: carStagesTimelineUrlBuilder.getCarStages(args),
        method: "GET",
      }),
      providesTags: ["carStagesTimeline"],
    }),
    createCarStages: build.mutation({
      query: args => ({
        url: carStagesTimelineUrlBuilder.createCarStages(args),
        method: "PATCH",
        data: args.data,
      }),
      invalidatesTags: ["carStagesTimeline"],
    }),
    deleteCarStageImages: build.mutation({
      query: args => ({
        url: carStagesTimelineUrlBuilder.deleteCarStageImages(args),
        method: "DELETE",
        data: args,
      }),
      invalidatesTags: ["carStagesTimeline"],
    }),
  }),
});

export const {
  useGetCarStagesQuery,
  useLazyGetCarStagesQuery,
  useCreateCarStagesMutation,
  useDeleteCarStageImagesMutation,
} = carStagesTimelineAPI;
