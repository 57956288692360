import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";
import { theme } from "theme";

interface ColoredSvgIcon extends SvgIconConstituentValues {
  orange: boolean;
}

export const CheckBoxIcon: FC<ColoredSvgIcon> = ({ orange, ...props }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.671875"
      width="14"
      height="14"
      rx="3.5"
      fill={orange ? theme.color.orange : theme.color.primary[800]}
      stroke={orange ? theme.color.orange : theme.color.primary[800]}
    />
    <path d="M3.5 7.67188L6.5 10.6719L12 5.17188" stroke="white" />
  </svg>
);
