import { FC, PropsWithChildren } from "react";

import { SvgIconConstituentValues } from "icons";

export interface LayoutProps extends PropsWithChildren {
  name?: string;
  Icon?: FC<SvgIconConstituentValues>;
  path?: string;
}

export enum SidebarEnum {
  Left = "left",
  Right = "right",
}
