import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { userClientLogout } from "redux/slices";
import { useAppDispatch } from "hooks/redux";
import { useRememberMe } from "hooks/useRememberMe";

import { CLIENT_ROUTES } from "constant";

import {
  Button,
  ButtonsContainer,
  ButtonText,
  Container,
  ContentContainer,
  FirstButton,
  Header,
  Title,
  Wrapper,
} from "./styled";

export interface ILogoutPopup {
  backgroundClick: () => void;
}

export const LogoutPopup: FC<ILogoutPopup> = ({ backgroundClick }) => {
  const navigate = useNavigate();
  const { removeToken } = useRememberMe();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    removeToken();
    dispatch(userClientLogout());
    navigate(CLIENT_ROUTES.CLIENT_LOGIN.path);
  };

  return (
    <Wrapper onClick={backgroundClick}>
      <Container
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ContentContainer>
          <Header>NOTICE</Header>

          <Title>
            Select “Yes” to log out or “No” to continue in your client portal.
          </Title>

          <ButtonsContainer>
            <FirstButton>
              <Button onClick={backgroundClick}>
                <ButtonText>No</ButtonText>
              </Button>
            </FirstButton>

            <Button onClick={handleLogout}>
              <ButtonText>Yes</ButtonText>
            </Button>
          </ButtonsContainer>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};
