import { styled } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

import { theme } from "theme";

export const Input = styled(MuiTelInput)({
  marginBottom: "20px",
  "& label": {
    color: theme.color.grey[500],
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    lineHeight: "140%",
  },
  "& label.Mui-focused": {
    color: theme.color.grey[500],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.color.grey[500],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.color.grey[500],
    },
    "&:hover fieldset": {
      borderColor: theme.color.grey[500],
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.color.grey[500],
    },
  },
});
