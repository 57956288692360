import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ArrowLinkIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4805 4.40625L26.0131 12.9389C26.0552 12.9809 26.0552 13.0491 26.0131 13.0911L17.4805 21.6238"
      stroke="black"
      strokeWidth="1.0761"
    />
    <path
      d="M26.088 13.0146L0.261719 13.0146"
      stroke="black"
      strokeWidth="1.0761"
    />
  </svg>
);
