import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  Announcement,
  AnnouncementData,
  AnnouncementsQueryParams,
  AnnouncementsListData,
  AnnouncementDetails,
} from "types";

import { announcementUrlBuilder } from "./urlBuilder/announcements";

export const announcementsAPI = createApi({
  reducerPath: "announcementsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Announcements"],
  endpoints: build => ({
    getAnnouncements: build.query<
      AnnouncementsListData,
      AnnouncementsQueryParams | void
    >({
      query: (args?: AnnouncementsQueryParams) => ({
        url: announcementUrlBuilder.getAnnouncements(args),
        method: "GET",
      }),
      providesTags: ["Announcements"],
    }),
    getAnnouncement: build.query<AnnouncementDetails, string>({
      query: args => ({
        url: announcementUrlBuilder.getAnnouncement(args),
        method: "GET",
      }),
      providesTags: ["Announcements"],
    }),
    createAnnouncement: build.mutation<AnnouncementDetails, AnnouncementData>({
      query: body => ({
        url: announcementUrlBuilder.createAnnouncement(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    updateAnnouncement: build.mutation<Announcement, AnnouncementData>({
      query: body => ({
        url: announcementUrlBuilder.updateAnnouncement(body.id),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    deleteAnnouncement: build.mutation<Announcement, string>({
      query: args => ({
        url: announcementUrlBuilder.deleteAnnouncement(args),
        method: "DELETE",
      }),
      invalidatesTags: ["Announcements"],
    }),
    createAnnouncementDocument: build.mutation<
      AnnouncementDetails,
      { data: FormData; id: number | string }
    >({
      query: ({ data, id }) => ({
        url: announcementUrlBuilder.createAnnouncementDocument(id),
        method: "POST",
        data,
      }),
      invalidatesTags: ["Announcements"],
    }),
    deleteAnnouncementDocument: build.mutation<
      AnnouncementDetails,
      { id: number | string; docId: number | string }
    >({
      query: ({ id, docId }) => ({
        url: announcementUrlBuilder.deleteAnnouncementDocument(id, docId),
        method: "DELETE",
      }),
      invalidatesTags: ["Announcements"],
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetAnnouncementQuery,
  useLazyGetAnnouncementQuery,
  useCreateAnnouncementDocumentMutation,
  useDeleteAnnouncementDocumentMutation,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementsAPI;
