import React, { FC } from "react";

import { CLIENT_ROUTES } from "constant";

import { Link, LinksWrapper } from "./styled";

export interface ClientsPageLinksProps {
  myProfile?: boolean;
  collaborators?: boolean;
}

export const ClientsPageLinks: FC<ClientsPageLinksProps> = ({
  myProfile,
  collaborators,
}) => {
  return (
    <LinksWrapper myProfile={myProfile} collaborators={collaborators}>
      <Link currentPath={myProfile} to={CLIENT_ROUTES.MY_PROFILE.path}>
        My Profile
      </Link>
      <Link currentPath={collaborators} to={CLIENT_ROUTES.COLLABORATORS.path}>
        My Collaborators
      </Link>
    </LinksWrapper>
  );
};
