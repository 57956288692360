import { CLIENT_ROUTES } from "constant";
import { IFooterData } from "./typed";
import { PERMISSIONS } from "constant/permissions";

export const config: IFooterData = {
  copyright: "©Singer 2023",
  sitemap: {
    title: "Sitemap",
    links: [
      {
        id: 12,
        linkTitle: "Homepage",
        link: CLIENT_ROUTES.HOME.path,
        isCollaboratorAccess: true,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 13,
        linkTitle: "Specifications",
        link: CLIENT_ROUTES.SPECIFICATIONS.path,
        isCollaboratorAccess: PERMISSIONS.SPECIFICATIONS,
        isDesktop: true,
        isMobile: false,
      },
      {
        id: 20,
        linkTitle: "Mood Boards",
        link: CLIENT_ROUTES.MOOD_BOARDS_LIBRARY.path,
        isCollaboratorAccess: PERMISSIONS.SPECIFICATIONS,
        isMobile: true,
        isDesktop: false,
      },
      {
        id: 14,
        linkTitle: "Portfolios",
        link: CLIENT_ROUTES.PORTFOLIO.path,
        isCollaboratorAccess: true,
        isDesktop: true,
        isMobile: true,
      },

      {
        id: 15,
        linkTitle: "Commissions",
        link: CLIENT_ROUTES.HOME.path,
        isCollaboratorAccess: true,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 16,
        linkTitle: "Documents",
        link: CLIENT_ROUTES.DOCUMENTS.path,
        isCollaboratorAccess: PERMISSIONS.DOCUMENTS,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 17,
        linkTitle: "Announcements",
        link: CLIENT_ROUTES.ANNOUNCEMENTS.path,
        isCollaboratorAccess: false,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 18,
        linkTitle: "Timeline",
        link: CLIENT_ROUTES.TIMELINE.path,
        isCollaboratorAccess: true,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 19,
        linkTitle: "Global Partners",
        link: CLIENT_ROUTES.PARTNERS.path,
        isCollaboratorAccess: false,
        isDesktop: true,
        isMobile: true,
      },
      {
        id: 24,
        linkTitle: "Events",
        link: CLIENT_ROUTES.EVENTS_LIST.path,
        isCollaboratorAccess: false,
        isDesktop: true,
        isMobile: true,
      },
    ],
  },
};
