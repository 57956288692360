import { styled, Typography, Box as MUIBox } from "@mui/material";

import { theme } from "theme";

export const Error = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "center",
  padding: "30px",
});

export const Box = styled(MUIBox)({
  marginTop: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
