import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const SaveIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8107 20.7597C21.8107 21.1588 21.4872 21.4823 21.088 21.4823H5.91188C5.51276 21.4823 5.18921 21.1588 5.18921 20.7597V8.41206C5.18925 8.22041 5.26542 8.03662 5.40095 7.90113L8.2295 5.07258C8.36499 4.93705 8.54878 4.86088 8.74043 4.86084H21.088C21.4872 4.86084 21.8107 5.18439 21.8107 5.58351V20.7597Z"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07985 13.5327H18.92C19.3191 13.5327 19.6426 13.8563 19.6426 14.2554V21.4821H7.35718V14.2554C7.35718 13.8563 7.68073 13.5327 8.07985 13.5327Z"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.80249 4.86084H18.1972V9.19688C18.1972 9.596 17.8737 9.91955 17.4746 9.91955H9.52516C9.12604 9.91955 8.80249 9.596 8.80249 9.19688V4.86084Z"
      stroke="black"
    />
    <rect
      x="15.3066"
      y="6.30615"
      width="1.44535"
      height="2.16802"
      stroke="black"
    />
    <path d="M9.52515 15.7012H14.2225" stroke="black" />
    <path d="M9.52515 17.8691H17.4746" stroke="black" />
  </svg>
);
