import { Tab, Tabs, styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    marginBottom: "15px",
  },
  [BreakPointTheme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const StyledTab = styled(Tab)<{ alignLeft: boolean }>(
  ({ alignLeft }) => ({
    ...(alignLeft && {
      minWidth: "0",
      paddingLeft: "0",
      paddingRight: "0",
      marginRight: "70px",
    }),

    "&.Mui-selected": {
      borderWidth: "0px 0px 0px 0px",
      border: "0px",
      borderColor: theme.color.orange,
      cursor: "pointer",
    },
  }),
);

export const StyledTabs = styled(Tabs)({
  ".MuiTabs-indicator": {
    height: 0,
  },
});

export const Count = styled("div")({
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.base,
  lineHeight: "130%",
  color: theme.color.black,
});
