import { createApi } from "@reduxjs/toolkit/query/react";

import { IInstagramPosts } from "types/InstagramPosts";
import { instagramBaseQuery } from "utils/instagram/instagramBaseQuery";
import { instagramPostsUrlBuilder } from "./urlBuilder/instagramPosts";

export const instagramAPI = createApi({
  reducerPath: "instagramAPI",
  baseQuery: instagramBaseQuery({
    baseUrl: process.env.REACT_APP_INSTAGRAM_BASE_URL,
  }),
  tagTypes: ["Instagram"],
  endpoints: build => ({
    getInstagramPosts: build.query<IInstagramPosts, { limit?: number }>({
      query: args => ({
        url: instagramPostsUrlBuilder.posts(args),
        method: "GET",
      }),
      providesTags: ["Instagram"],
    }),
  }),
});

export const { useGetInstagramPostsQuery } = instagramAPI;
