import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { Photo } from "types";

import { photoUrlBuilder } from "./urlBuilder/photos";

export const photosAPI = createApi({
  reducerPath: "photosAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Photos"],
  endpoints: build => ({
    savePhoto: build.mutation<Photo, FormData>({
      query: body => ({
        url: photoUrlBuilder.savePhoto(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["Photos"],
    }),
  }),
});

export const { useSavePhotoMutation } = photosAPI;
