import React, { FC, useRef, useState } from "react";

import { useGetStagesQuery } from "redux/query/stagesAPI";
import { activeComissionsSelector, сomissionsSelector } from "redux/selectors";
import { setActiveComission, setActiveStage } from "redux/slices";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { QUERY_OPTIONS } from "constant";
import { ArrowDownIcon } from "icons";

import { ComissionCard } from "../ComissionCard";
import {
  AccordionContent,
  AccordionContentWrapper,
  AccordionWrapper,
  IconWrapper,
  Title,
  TitleWrapper,
} from "./styled";

export const ComissionsDropDown: FC = () => {
  const dispatch = useAppDispatch();
  const activeComission = useAppSelector(activeComissionsSelector);
  const comissions = useAppSelector(сomissionsSelector);

  const { data: stages } = useGetStagesQuery({}, QUERY_OPTIONS);

  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const toggleList = () => {
    if (isOpen) {
      setContentHeight(0);
    } else {
      setContentHeight(contentRef.current.scrollHeight);
    }
    setIsOpen(!isOpen);
  };

  const setCar = (id: number): void => {
    const car = comissions?.find(car => car.id === id);

    const currentStage =
      stages?.length && stages?.find(stage => stage?.name === car?.stage);

    dispatch(setActiveStage(currentStage?.id));
    dispatch(setActiveComission(car));
  };

  const cars = comissions?.map(car => ({
    id: car?.id,
    labelTitle: car?.model,
    labelSubTitle: car?.name,
  }));

  const firstComission = isOpen
    ? cars[0]
    : {
        id: activeComission?.id,
        labelTitle: activeComission?.model,
        labelSubTitle: activeComission?.name,
      };

  const comissionsList = cars?.slice(1);

  if (!comissions?.length) {
    return null;
  }

  return (
    <AccordionWrapper>
      <div>
        <div>
          <TitleWrapper>
            <Title>Comissions</Title>

            <IconWrapper onClick={toggleList} isOpen={isOpen}>
              <ArrowDownIcon width="10" height="5" />
            </IconWrapper>
          </TitleWrapper>

          <ComissionCard
            isActive={firstComission?.id === activeComission?.id}
            title={firstComission?.labelTitle}
            subTitle={firstComission?.labelSubTitle}
            handleClick={() => isOpen && setCar(firstComission?.id)}
          />
        </div>

        <AccordionContentWrapper isOpen={isOpen} height={contentHeight}>
          <AccordionContent ref={contentRef}>
            {comissionsList.map(car => (
              <ComissionCard
                key={car?.id}
                isActive={car?.id === activeComission?.id}
                title={car?.labelTitle}
                subTitle={car?.labelSubTitle}
                handleClick={() => setCar(car?.id)}
              />
            ))}
          </AccordionContent>
        </AccordionContentWrapper>
      </div>
    </AccordionWrapper>
  );
};
