import { styled, Dialog } from "@mui/material";
import { BreakPointTheme } from "../theme";

export const DialogBlurBackdrop = styled(Dialog)({
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(5px)",
  },

  img: {
    maxHeight: "calc(100vh - 64px)",
    [BreakPointTheme.breakpoints.down("lg")]: {
      maxHeight: "500px",
      objectFit: "contain",
    },
  },
});
