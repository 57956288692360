import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from "@mui/material";

import { theme } from "theme";

export const StyledAccordion = styled(Accordion)({
  margin: "10px 0",
  boxShadow: "none",
  ":before": {
    height: 0,
  },

  ".MuiAccordion-root.Mui-expanded": {
    margin: 0,
  },

  "MuiAccordionDetails-root": {
    padding: "0",
  },
});

export const StyledAccordionSummary = styled(AccordionSummary)({
  padding: "0",
  borderTop: `1px solid ${theme.color.grey[300]}`,
  borderBottom: `1px solid ${theme.color.grey[300]}`,
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  boxSizing: "border-box",
  padding: "10px 0",
  maxHeight: "339px",
  overflow: "auto",
});

export const StyledTitle = styled(Typography)({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.md,
  lineHeight: "22px",
  color: theme.color.grey[900],
});
