import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { ClientsListData, ClientsQueryParams, IClientData } from "types";

import { clientUrlBuilder } from "./urlBuilder/clients";

interface ClientByID {
  id: string;
}

interface ClientByIDData {
  results: Array<{ question: string; answer: string }>;
}

interface CollaboratorsQuery {
  id: string;
  page?: number;
  limit?: number;
  ordering?: string;
}

export const clientsAPI = createApi({
  reducerPath: "clientsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Clients"],
  endpoints: build => ({
    getClients: build.query<ClientsListData, ClientsQueryParams | void>({
      query: (args?: ClientsQueryParams) => ({
        url: clientUrlBuilder.getClients(args),
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
    getCars: build.query<ClientsListData, ClientsQueryParams | void>({
      query: (args?: ClientsQueryParams) => ({
        url: clientUrlBuilder.getCars(args),
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
    getClientAboutMeInfo: build.query<ClientByIDData, ClientByID | void>({
      query: (args?: ClientByID) => ({
        url: clientUrlBuilder.getClientAboutMeInfo(args),
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
    getCollaborators: build.query<ClientsListData, CollaboratorsQuery | void>({
      query: (args?: CollaboratorsQuery) => ({
        url: clientUrlBuilder.getCollaborators(args),
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
    getClient: build.query<IClientData, ClientByID>({
      query: (args?: ClientByID) => ({
        url: clientUrlBuilder.getClient(args),
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetCarsQuery,
  useGetClientAboutMeInfoQuery,
  useGetCollaboratorsQuery,
  useGetClientQuery,
  useLazyGetClientQuery,
} = clientsAPI;
