import { Box, styled, Typography } from "@mui/material";

import { theme } from "theme";

export const HeaderApp = styled("nav")({
  padding: "9px 34px",
  height: "66px",
  background: theme.color.white,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  zIndex: "99",
  top: 0,
});

export const HeaderItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

export const HeaderIcon = styled(Box)<{ active?: boolean }>(({ active }) => ({
  cursor: "pointer",

  ...(active && {
    transform: "scaleX(-1)",
  }),
}));

export const UserBox = styled(Box)({
  "&.active": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "10px",
  },
  display: "none",
  cursor: "initial",
});

export const Name = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "120%",
  color: theme.color.black,
  letterSpacing: "-0.01em",
});

export const Role = styled(Name)({
  fontSize: theme.fontSize.base,
});
