import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const PdfDocumentIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="98"
    height="121"
    viewBox="0 0 98 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 1545">
      <g id="Group 1544">
        <g id="Group">
          <path
            id="Vector"
            d="M24.9512 77.6656C24.9512 75.685 23.5769 74.5039 21.1521 74.5039C20.1619 74.5039 19.4914 74.6016 19.1406 74.6951V81.0496C19.5559 81.1431 20.0665 81.1743 20.7702 81.1743C23.3548 81.1743 24.9512 79.8685 24.9512 77.6656Z"
            fill="white"
            fillOpacity="0.9"
          />
          <path
            id="Vector_2"
            d="M39.9644 74.5703C38.8785 74.5703 38.1768 74.6641 37.7617 74.7613V88.8347C38.177 88.9322 38.8476 88.9322 39.4539 88.9322C43.859 88.9633 46.732 86.5385 46.732 81.4005C46.7632 76.9325 44.1452 74.5703 39.9644 74.5703Z"
            fill="white"
            fillOpacity="0.9"
          />
          <path
            id="Vector_3"
            d="M67.853 0.9375H19.9537C12.9561 0.9375 7.26077 6.63678 7.26077 13.6305V60.5668H6.02103C3.19675 60.5668 0.90625 62.8551 0.90625 65.6818V96.701C0.90625 99.5275 3.1965 101.816 6.02103 101.816H7.26077V107.503C7.26077 114.505 12.9561 120.196 19.9537 120.196H85.3103C92.3035 120.196 97.9995 114.505 97.9995 107.503V30.9784L67.853 0.9375ZM14.3244 71.2797C15.8234 71.0264 17.9304 70.8355 20.8989 70.8355C23.8988 70.8355 26.0369 71.4081 27.4735 72.5583C28.8458 73.6422 29.7719 75.4313 29.7719 77.5368C29.7719 79.6418 29.0702 81.4314 27.7934 82.6436C26.1326 84.2069 23.6766 84.9087 20.8036 84.9087C20.1642 84.9087 19.5911 84.877 19.1429 84.8149V92.5066H14.3244V71.2797ZM85.3103 112.423H19.9537C17.2442 112.423 15.0378 110.217 15.0378 107.503V101.816H75.9635C78.7883 101.816 81.0785 99.5275 81.0785 96.701V65.6818C81.0785 62.8551 78.7883 60.5668 75.9635 60.5668H15.0378V13.6305C15.0378 10.9249 17.2445 8.71851 19.9537 8.71851L64.9449 8.67151V25.3018C64.9449 30.1594 68.8862 34.1044 73.7475 34.1044L90.0387 34.0577L90.222 107.503C90.222 110.217 88.0195 112.423 85.3103 112.423ZM32.8789 92.4092V71.2797C34.6661 70.9953 36.9954 70.8355 39.4536 70.8355C43.5389 70.8355 46.1881 71.5684 48.2639 73.1314C50.4977 74.7921 51.9009 77.4393 51.9009 81.2399C51.9009 85.3566 50.402 88.1987 48.3259 89.9527C46.0609 91.8356 42.6131 92.7285 38.4009 92.7285C35.8784 92.7285 34.0912 92.5687 32.8789 92.4092ZM67.713 79.8993V83.8559H59.9882V92.5066H55.1054V70.9953H68.2547V74.9831H59.9882V79.8993H67.713Z"
            fill="white"
            fillOpacity="0.9"
          />
        </g>
      </g>
    </g>
  </svg>
);
