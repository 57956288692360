import React from "react";

import {
  CalendarIcon,
  LawIcon,
  LegalIcon,
  LightBrightIcon,
  MultipleManIcon,
  NewsPaperFoldIcon,
  SingleNeutralCircleIcon,
  WenchIcon,
} from "icons";
import { PERMISSIONS } from "./permissions";
import { CLIENT_ROUTES } from "./routes";
import { instagramLink } from "./common";

type Subpath = {
  name: string;
  path: string;
  isMobile?: boolean;
  isDesktop?: boolean;
  isCollaborator?: boolean | string;
};

export type MenuItem = {
  [key: string]: {
    name: string;
    customName?: string;
    path?: string;
    subpaths?: Subpath[];
    links?: Subpath[];
    externalLink?: string;
    dynamicSubpaths?: boolean;
    icon?: React.ReactNode;
    adminView?: boolean;
    gpnAccess?: boolean;
    isCollaboratorAccess?: boolean | string;
  };
};

export const LEFT_MENU_ITEMS_INFO: MenuItem = {
  CLIENTS: {
    name: "Clients",
    path: "/admin/clients",
    icon: <MultipleManIcon />,
    gpnAccess: true,
  },
  UNIVERSAL: {
    name: "Universal",
    customName: "Universal Content",
    path: "/admin/universal",
    icon: <LawIcon />,
  },
  EVENTS: {
    name: "Events",
    path: "/admin/events",
    icon: <CalendarIcon />,
    gpnAccess: true,
  },
  ANNOUNCEMENTS: {
    name: "Announcements",
    path: "/admin/announcements",
    icon: <NewsPaperFoldIcon />,
  },
  INSPIRATION_TOOL: {
    name: "Inspiration Tool",
    path: "/admin/inspiration_tool",
    icon: <LightBrightIcon />,
  },
  PARTNERS: {
    name: "Partners",
    path: "/admin/partners",
    icon: <WenchIcon />,
  },
  LEGAL_PAGES: {
    name: "Legal Pages",
    path: "/admin/legal_pages",
    icon: <LegalIcon />,
  },
  ADMIN: {
    name: "Admin",
    path: "/admin/admin_page",
    icon: <SingleNeutralCircleIcon />,
    adminView: true,
  },
};

export const CLIENT_LEFT_MENU_ITEMS_INFO: MenuItem = {
  HOME: {
    name: "Home",
    path: "/home",
    isCollaboratorAccess: true,
  },
  COMISSIONS: {
    name: "Commissions",
    isCollaboratorAccess: true,
  },
  TIMELINE: {
    name: "Timeline",
    path: "/timeline",
    isCollaboratorAccess: true,
  },
  DOCUMENTS: {
    name: "Documents",
    path: "/documents",
    isCollaboratorAccess: PERMISSIONS.DOCUMENTS,
  },
  DESIGN: {
    name: "Design",
    path: "",
    links: [
      {
        name: "Specifications",
        path: "/specifications",
        isMobile: true,
        isCollaborator: PERMISSIONS.SPECIFICATIONS,
      },
      {
        name: "Portfolio",
        path: "/portfolio",
        isCollaborator: true,
      },
      {
        name: "Mood Boards",
        path: CLIENT_ROUTES.MOOD_BOARDS_LIBRARY.path,
        isDesktop: true,
        isCollaborator: true,
      },
    ],
  },
};

export const CLIENT_RIGHT_MENU_ITEMS_INFO: MenuItem = {
  PROFILE: {
    name: "Profile",
    path: "/my_profile",
  },
  NOTIFICATIONS: {
    name: "Notifications",
  },
  EVENTS: {
    name: "Events",
    path: "/events/",
    isCollaboratorAccess: false,
    subpaths: [
      {
        name: "Annual Calendar",
        path: "year",
      },
      {
        name: "Monthly Calendar",
        path: "month",
      },
      {
        name: "Upcoming Events",
        path: "list",
      },
    ],
  },
  ANNOUNCEMENTS: {
    name: "Announcements",
    path: "/announcements",
    isCollaboratorAccess: false,
  },
  PARTNERS: {
    name: "Global Partners",
    path: "/global_partners",
    isCollaboratorAccess: true,
  },
  SOCIAL: {
    name: "Social posts",
    externalLink: instagramLink,
  },
};
