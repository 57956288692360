import {
  styled,
  CircularProgress as MUICircularProgress,
  Box as MUIBox,
  LinearProgress as MUILinearProgress,
} from "@mui/material";

import { theme } from "theme";
import { ILoader } from "./typed";

export const CircularProgress = styled(MUICircularProgress)<ILoader>(
  ({ size }) => ({
    color: theme.color.primary[600],
    ...(size === "small" && {
      width: "15px !important",
      height: "15px !important",
    }),
  }),
);

export const LinearProgress = styled(MUILinearProgress)({
  color: theme.color.primary[600],
});

export const Box = styled(MUIBox)<ILoader>(({ size }) => ({
  marginTop: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ...(size === "small" && {
    marginTop: 0,
  }),
}));
