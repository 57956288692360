import { endpoints } from "constant";

import type { AnnouncementsQueryParams } from "types";
import { generateRequestUrl } from "utils/generateRequestUrl";

export const announcementUrlBuilder = {
  getAnnouncements: function ({
    page,
    limit,
    ordering = "",
    search = "",
    upcomingAnnouncement = "",
    pastAnnouncement = "",
  }: AnnouncementsQueryParams = {}): string {
    const queryParams = {
      page: page === undefined ? "" : page + 1,
      page_size: limit,
      ordering: ordering,
      upcoming_announcement: upcomingAnnouncement,
      past_announcement: pastAnnouncement,
      search: search,
    };

    return generateRequestUrl(endpoints.announcements, queryParams);
  },
  getAnnouncement: function (id: string | number): string {
    return `${endpoints.announcements}${id}/`;
  },
  createAnnouncement: function () {
    return endpoints.announcements;
  },
  updateAnnouncement: function (id: string | number): string {
    return `${endpoints.announcements}${id}/`;
  },
  deleteAnnouncement: function (id: string | number): string {
    return `${endpoints.announcements}${id}/`;
  },
  createAnnouncementDocument: function (id: string | number): string {
    return `${endpoints.announcements}${id}/documents/`;
  },
  deleteAnnouncementDocument: function (
    id: string | number,
    docId: string | number,
  ): string {
    return `${endpoints.announcements}${id}/documents/${docId}/`;
  },
};
