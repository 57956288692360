import React, { FC, useState } from "react";

import { VideoPlayer } from "components/Clients";

import { Wrapper, Button } from "./styled";
import { VideoPreviewProps } from "./typed";

export const VideoPreview: FC<VideoPreviewProps> = ({ video }) => {
  const [playing, setPlaying] = useState(true);

  const handlePlay = () => setPlaying(true);

  return (
    <Wrapper playing={playing}>
      <VideoPlayer url={video} playing={playing} loop controls />

      {playing ? null : <Button onClick={handlePlay} />}
    </Wrapper>
  );
};
