import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import {
  DraggedImages,
  ICreatePortfolioPDF,
  ICreatePortfolioPDFResponse,
  IDeletePortfolioPDF,
  IPortfolio,
  IPortfolioPDFs,
  Photo,
} from "types";

import { portfolioUrlBuilder } from "./urlBuilder/portfolio";

export const portfolioAPI = createApi({
  reducerPath: "portfolioAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Portfolio", "PortfolioPDF"],
  endpoints: build => ({
    getCarPortfolio: build.query<IPortfolio[], { id: number | string }>({
      query: args => ({
        url: portfolioUrlBuilder.getPortfolio(args),
        method: "GET",
      }),
      providesTags: ["Portfolio"],
    }),

    getPDFPortfolio: build.query<
      IPortfolioPDFs,
      { carId: number | string; id: number | string }
    >({
      query: ({ carId, id }) => ({
        url: portfolioUrlBuilder.portfolioPdf({ carId, id }),
        method: "GET",
      }),
      providesTags: ["PortfolioPDF"],
    }),

    getPDFPortfolios: build.query<IPortfolioPDFs[], { carId: number | string }>(
      {
        query: ({ carId }) => ({
          url: portfolioUrlBuilder.portfoliosPdf({ carId }),
          method: "GET",
        }),
        providesTags: ["PortfolioPDF"],
      },
    ),

    setLikeDislikePhoto: build.mutation<Photo, { id: number | string }>({
      query: args => ({
        url: portfolioUrlBuilder.setDislikePhoto(),
        method: "POST",
        data: { photo: args.id },
      }),
    }),

    setDraggedPhotos: build.mutation<void, DraggedImages>({
      query: ({ carId, stageId, data }) => ({
        url: portfolioUrlBuilder.setDraggedPhoto({ carId, stageId }),
        method: "PATCH",
        data,
      }),
    }),

    createPdf: build.mutation<ICreatePortfolioPDFResponse, ICreatePortfolioPDF>(
      {
        query: ({ carId, data }) => ({
          url: portfolioUrlBuilder.portfoliosPdf({ carId }),
          method: "POST",
          data,
        }),
        invalidatesTags: ["PortfolioPDF"],
      },
    ),

    deletePdf: build.mutation<void, IDeletePortfolioPDF>({
      query: ({ carId, portfolioId }) => ({
        url: portfolioUrlBuilder.deletePdf({ carId, portfolioId }),
        method: "DELETE",
      }),
      invalidatesTags: ["PortfolioPDF"],
    }),
  }),
});

export const {
  useGetCarPortfolioQuery,
  useSetLikeDislikePhotoMutation,
  useSetDraggedPhotosMutation,
  useGetPDFPortfoliosQuery,
  useLazyGetPDFPortfoliosQuery,
  useLazyGetPDFPortfolioQuery,
  useCreatePdfMutation,
  useDeletePdfMutation,
} = portfolioAPI;
