import React, { FC, useState } from "react";

import { Carousel, GalleryDialog } from "components";
import { ImageCarousel } from "components/Clients";

import { Title, Description } from "../styled";
import { TextContainer, TopicContainer } from "./styled";
import type { StageTopicProps } from "./typed";

export const StageTopic: FC<StageTopicProps> = ({
  title,
  description,
  images,
  reversed,
  isPreview,
  children,
}) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(null);

  const openModalGallery = i => {
    setConfirmOpen(true);
    setCurrentSlide(i);
  };

  return (
    <>
      <TopicContainer reversed={reversed}>
        <TextContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {children}
        </TextContainer>
        <ImageCarousel
          images={images}
          loop={false}
          className={images.length && images[0].id}
          onClick={isPreview ? null : openModalGallery}
        />
      </TopicContainer>
      {isPreview ? null : (
        <GalleryDialog open={confirmOpen} setOpen={setConfirmOpen}>
          <Carousel initialSlide={currentSlide} images={images} />
        </GalleryDialog>
      )}
    </>
  );
};
