import React, { FC } from "react";

import { Loader } from "components/Loader";

import { Wrapper } from "./styled";

export const PageBlockLoader: FC = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};
