import React, { FC } from "react";
import { Link as LinkComponent } from "react-router-dom";

import { ArrowIcon } from "icons";

import { LinkStyled } from "../styled";
import type { LinkProps } from "./typed";

export const Link: FC<LinkProps> = ({ title, path, isPreview }) => (
  <LinkStyled
    {...(isPreview
      ? null
      : {
          component: LinkComponent,
          to: path,
        })}
  >
    {title}
    <ArrowIcon width="24px" />
  </LinkStyled>
);
