import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ArrowIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3536 4.52543C15.5488 4.33017 15.5488 4.01359 15.3536 3.81832L12.1716 0.636342C11.9763 0.44108 11.6597 0.44108 11.4645 0.636342C11.2692 0.831604 11.2692 1.14819 11.4645 1.34345L14.2929 4.17188L11.4645 7.0003C11.2692 7.19557 11.2692 7.51215 11.4645 7.70741C11.6597 7.90267 11.9763 7.90267 12.1716 7.70741L15.3536 4.52543ZM-4.37114e-08 4.67188L15 4.67188L15 3.67188L4.37114e-08 3.67188L-4.37114e-08 4.67188Z"
      fill="black"
    />
  </svg>
);
