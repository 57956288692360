import { endpoints } from "constant";

export const clientUrlBuilder = {
  resetPassword: function () {
    return `${endpoints.passwordReset}`;
  },
  createNewPassword: function () {
    return `${endpoints.createNewPassword}`;
  },
  updatePassword: function () {
    return `${endpoints.updatePassword}`;
  },
  validateToken: function () {
    return `${endpoints.validateToken}`;
  },
  getCollaboratorClients: function () {
    return `${endpoints.collaboratorClients}`;
  },
  setCollaboratorClients: function (id: number | string) {
    return `${endpoints.client}${id}/active-collaborator-client/`;
  },
  getGlobalPartnerClients: function () {
    return `${endpoints.globalPartnersClients}`;
  },
  setGlobalPartnerClients: function (id: number | string) {
    return `${endpoints.client}${id}/active-global-partner-client/`;
  },
};
