import React, { FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  NoticeButton,
  TextContent,
} from "./styled";

interface INotice {
  openNotice: null | number;
  onClose: (arg: number | null) => void;
  text: string;
}

export const Notice: FC<INotice> = ({ openNotice, text, onClose }) => {
  return (
    <Dialog
      open={!!openNotice}
      maxWidth="sm"
      fullWidth
      onClose={() => onClose(null)}
    >
      <DialogTitle>Notice</DialogTitle>

      <DialogContent>
        <TextContent>{text}</TextContent>
      </DialogContent>

      <DialogActions>
        <NoticeButton onClick={() => onClose(null)}>Ok</NoticeButton>
      </DialogActions>
    </Dialog>
  );
};
