import { styled, Typography } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  cursor: "pointer",
});

export const LabelTitle = styled(Typography)<{ isActive?: boolean }>(
  ({ isActive }) => ({
    fontWeight: theme.fontWeight.extraBold,
    fontSize: theme.fontSize.base,
    textTransform: "uppercase",
    textAlign: "center",
    lineHeight: "21px",
    whiteSpace: "nowrap",

    [BreakPointTheme.breakpoints.down("lg")]: {
      fontSize: "13px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      textTransform: "capitalize",
      fontSize: theme.fontSize.base,
    },

    ...(isActive
      ? {
          color: theme.color.orange,
        }
      : {
          color: theme.color.black,
        }),
  }),
);

export const LabelSubTitle = styled(LabelTitle)({
  fontSize: "20px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: "17px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    fontSize: "20px",
    textTransform: "uppercase",
  },
});
