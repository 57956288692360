import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { MoodBoard, MoodBoardQueryParams } from "types";

import { moodBoardUrlBuilder } from "./urlBuilder/moodBoards";

export const moodBoardsAPI = createApi({
  reducerPath: "moodBoardsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["MoodBoards"],
  endpoints: build => ({
    getMoodBoards: build.query<MoodBoard[], string | number>({
      query: args => ({
        url: moodBoardUrlBuilder.getMoodBoards(args),
        method: "GET",
      }),
      providesTags: ["MoodBoards"],
    }),
    getMoodBoard: build.query<MoodBoard, MoodBoardQueryParams>({
      query: args => ({
        url: moodBoardUrlBuilder.getMoodBoard(args),
        method: "GET",
      }),
      providesTags: ["MoodBoards"],
    }),
    createMoodBoard: build.mutation<MoodBoard, MoodBoard>({
      query: body => ({
        url: moodBoardUrlBuilder.createMoodBoard(body),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["MoodBoards"],
    }),
    updateMoodBoard: build.mutation<MoodBoard, MoodBoard>({
      query: body => ({
        url: moodBoardUrlBuilder.updateMoodBoard(body),
        method: "PUT",
        data: body,
      }),
      invalidatesTags: ["MoodBoards"],
    }),
    deleteMoodBoard: build.mutation<MoodBoard, MoodBoardQueryParams>({
      query: args => ({
        url: moodBoardUrlBuilder.deleteMoodBoard(args),
        method: "DELETE",
      }),
      invalidatesTags: ["MoodBoards"],
    }),
  }),
});

export const {
  useGetMoodBoardsQuery,
  useLazyGetMoodBoardsQuery,
  useGetMoodBoardQuery,
  useLazyGetMoodBoardQuery,
  useCreateMoodBoardMutation,
  useUpdateMoodBoardMutation,
  useDeleteMoodBoardMutation,
} = moodBoardsAPI;
