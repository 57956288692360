import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const TimeClockCircleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 19.673C16.2364 19.673 19.671 16.2384 19.671 12.0015C19.671 7.76471 16.2364 4.33008 11.9996 4.33008C7.76275 4.33008 4.32812 7.76471 4.32812 12.0015C4.32812 16.2384 7.76275 19.673 11.9996 19.673Z"
      stroke="black"
    />
    <path d="M12 8.33203V12.001L16.0025 15.6699" stroke="black" />
  </svg>
);
