import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { BlockDescriptionTypography, BlockTitleTypography } from "styled";

export const WelcomeTitle = styled(BlockTitleTypography)({
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize["5xl"],
  lineHeight: "79px",
  letterSpacing: "0em",
  textAlign: "center",
  marginBottom: "20px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: "42px",
    lineHeight: "54px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    fontSize: "44px",
    lineHeight: "58px",
    marginBottom: "9px",
  },
});

export const WelcomeDescription = styled(BlockDescriptionTypography)({
  width: "100%",
  maxWidth: "785px",
  textAlign: "center",
  minHeight: "90px",

  [BreakPointTheme.breakpoints.down("md")]: {
    minHeight: "auto",
  },
});
