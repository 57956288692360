import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const SearchIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62409 15.4611C12.8478 15.4611 15.4611 12.8478 15.4611 9.62409C15.4611 6.40041 12.8478 3.78711 9.62409 3.78711C6.40041 3.78711 3.78711 6.40041 3.78711 9.62409C3.78711 12.8478 6.40041 15.4611 9.62409 15.4611Z"
      stroke={props.strokeColor ?? "black"}
    />
    <path
      d="M17.2127 17.2117L13.752 13.751"
      stroke={props.strokeColor ?? "black"}
    />
  </svg>
);
