import React, { FC, useState } from "react";

import { IndentRightIcon, LogoSingerIcon, LogoutIcon } from "icons";
import { ConfirmDialog } from "components";

import { useGetUserProfileQuery } from "redux/query/adminsAPI";
import { userAdminLogout } from "redux/slices";
import { useAppDispatch } from "hooks/redux";

import { QUERY_OPTIONS } from "constant";

import {
  HeaderApp,
  HeaderIcon,
  HeaderItem,
  Name,
  Role,
  UserBox,
} from "./styled";
import { HeaderProps } from "./typed";
import { useRememberMe } from "hooks/useRememberMe";

export const Header: FC<HeaderProps> = ({ handleSidebar, isActive }) => {
  const dispatch = useAppDispatch();
  const { removeToken } = useRememberMe();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { data } = useGetUserProfileQuery(null, QUERY_OPTIONS);

  const openModal = () => setConfirmOpen(true);
  const handleConfirm = () => {
    removeToken();
    dispatch(userAdminLogout());
  };

  return (
    <HeaderApp>
      <HeaderItem>
        <HeaderIcon onClick={handleSidebar} active={isActive}>
          <IndentRightIcon className={isActive && "active"} />
        </HeaderIcon>
        <UserBox className={isActive && "active"}>
          <Name>{`${data?.first_name} ${data?.last_name || ""}`}</Name>
          <Role>{data?.role || ""}</Role>
        </UserBox>
      </HeaderItem>
      <HeaderItem>
        <LogoSingerIcon />
      </HeaderItem>
      <HeaderItem onClick={openModal}>
        <LogoutIcon />
      </HeaderItem>

      <ConfirmDialog
        messages={["Are you sure you want to logout?"]}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </HeaderApp>
  );
};
