import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LegalIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 5.99707V18.3381" stroke="white" />
    <path d="M5.16211 14.3352L7.4969 9.35547L9.83169 14.3352" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49761 16.6698C8.12147 16.6853 8.7244 16.4443 9.16567 16.003C9.60695 15.5618 9.84794 14.9588 9.8324 14.335H5.16282C5.14728 14.9588 5.38827 15.5618 5.82954 16.003C6.27081 16.4443 6.87375 16.6853 7.49761 16.6698Z"
      stroke="white"
    />
    <path d="M19.8395 10.3326L17.5047 5.27148L15.1699 10.3326" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5035 12.6678C16.8796 12.6833 16.2767 12.4423 15.8354 12.0011C15.3941 11.5598 15.1531 10.9569 15.1687 10.333H19.8383C19.8538 10.9569 19.6128 11.5598 19.1715 12.0011C18.7303 12.4423 18.1273 12.6833 17.5035 12.6678Z"
      stroke="white"
    />
    <path d="M13.498 6.9273L18.1716 4.99609" stroke="white" />
    <path d="M6.12695 9.9096L13.4969 6.92773" stroke="white" />
    <path d="M9.49805 18.3379H15.5018" stroke="white" />
  </svg>
);
