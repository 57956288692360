import React, { FC } from "react";
import { useSelector } from "react-redux";

import { getUserClientRole } from "redux/selectors";

import { ColoredCheckedIcon, RatingStarIcon } from "icons";

import {
  BackGround,
  FavoriteButton,
  Image,
  RatingStarWrapper,
  SelectIconContainer,
} from "./styled";
import { ROLES } from "constant";

interface IContentCard {
  id: string;
  dataKey?: number;
  index: number;
  thumbnail: string;
  photoURL: string;
  maxContent: boolean;
  isDrug?: boolean;
  isSelectMode: boolean;
  isFavorite: boolean;
  isSelected: boolean;
  setFavorite: (id: string, dataKey?: number) => void;
  openModalGallery: (id: number, dataKey?: number) => void;
  handleSelectImage: (id: string) => void;
}

export const ContentCard: FC<IContentCard> = ({
  id,
  dataKey,
  index,
  thumbnail,
  photoURL,
  maxContent,
  isSelectMode,
  isFavorite,
  isDrug,
  isSelected,
  setFavorite,
  openModalGallery,
  handleSelectImage,
}) => {
  const clientRole = useSelector(getUserClientRole);

  const selectImage = (id: string) =>
    isSelectMode
      ? handleSelectImage(id)
      : openModalGallery(index, dataKey && dataKey);

  return (
    <Image
      maxContent={maxContent}
      photoURL={thumbnail || photoURL}
      onClick={() => selectImage(id)}
      isDrug={isDrug}
      isSelectMode={isSelectMode}
      isSelected={isSelected}
    >
      {isSelected && (
        <>
          <BackGround />

          <SelectIconContainer>
            <ColoredCheckedIcon />
          </SelectIconContainer>
        </>
      )}

      {clientRole !== ROLES.Collaborator && (
        <FavoriteButton
          onClick={e => {
            e.stopPropagation();
            setFavorite(id, dataKey);
          }}
        >
          <RatingStarWrapper isFavorite={isFavorite}>
            <RatingStarIcon />
          </RatingStarWrapper>
        </FavoriteButton>
      )}
    </Image>
  );
};
