import { styled, keyframes } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { BlockDescriptionTypography, BlockTitleTypography } from "styled";

const mountAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const scrollAnimation = keyframes`
  0% { transform: translateY(-200%); }
  100% { transform: translateY(500%); }
`;

export const Title = styled(BlockTitleTypography)({
  lineHeight: "89px",
  fontSize: theme.fontSize["7xl"],
  color: theme.color.white,
});

export const Description = styled(BlockDescriptionTypography)({
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.color.white,
});

export const CopiesContainer = styled("div")({
  position: "absolute",
  top: "33%",
  left: "68px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    top: "72px",
    left: "40px",
  },
});

export const ScrollIconContainer = styled("div")({
  position: "absolute",
  bottom: "25px",
  left: "50%",
  transform: "translateX(-50%)",
  height: "90px",
  width: "2px",
  overflow: "hidden",

  [BreakPointTheme.breakpoints.down("lg")]: {
    display: "none",
  },
});

export const ScrollIconWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});

export const ScrollIconBackground = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  background: theme.color.white,
  opacity: "0.3",
});

export const StyledSection = styled("div")({
  background: theme.color.black,
  transition:
    "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
  animation: `${mountAnimation} 1.25s`,
  position: "relative",
  width: "100%",
  height: "100vh",
  overflow: "hidden",

  [BreakPointTheme.breakpoints.down("lg")]: {
    height: "432px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const BackGroundOverlay = styled("div")({
  backgroundColor: theme.color.black,
  opacity: 0.1,
  transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  position: "absolute",
  boxSizing: "border-box",
  overflow: "hidden",
});

export const BackVideoContainer = styled("div")({
  lineHeight: 1.5,
  color: theme.color.grey[900],
  fontSize: 16,
  fontWeight: 300,
  boxSizing: "border-box",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  position: "absolute",
  overflow: "hidden",
  zIndex: 0,
  transition: "opacity 1s",
  pointerEvents: "none",
});

export const BackgroundVideoEmbed = styled("div")({
  lineHeight: 1.5,
  color: theme.color.grey[900],
  fontFamily: '"Roboto", Sans-serif',
  fontSize: 16,
  fontWeight: 300,
  hyphens: "manual",
  pointerEvents: "none",
  boxSizing: "border-box",
  maxWidth: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

export const ScrollIcon = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  transform: "translateX(-50%)",
  height: 35,
  width: "100%",
  background: theme.color.white,
  animation: `${scrollAnimation} 2.25s infinite`,
  transition: "0.3s",
});

interface VideoContainersProps {
  height: number;
  width: number;
}

const videoContainersStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  maxWidth: "none",
};

export const IframeContaner = styled("iframe")<VideoContainersProps>(
  ({ height, width }) => ({
    videoContainersStyles,
    height: height ? height : "auto",
    width: width ? width : "auto",
  }),
);

export const VideoContainer = styled("video")<VideoContainersProps>(
  ({ height, width }) => ({
    videoContainersStyles,
    height: height ? height : "auto",
    width: width ? width : "auto",
  }),
);
