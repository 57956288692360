import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ArrowUpIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4122 12.8842L10.2273 6.32339C10.1745 6.26741 10.0987 6.23535 10.0191 6.23535C9.93944 6.23535 9.86364 6.26741 9.81088 6.32339L3.62646 12.8842"
      stroke="black"
    />
  </svg>
);
