import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Month = string;
type Year = string;

type Date = {
  month: Month;
  year: Year;
};

const initialState: Date = {
  month: ("0" + (new Date().getMonth() + 1)).slice(-2),
  year: new Date().getFullYear().toString(),
};

export const setDateSlice = createSlice({
  name: "setDate",
  initialState,
  reducers: {
    setMonthAction: (state, action: PayloadAction<Month>) => {
      state.month = action.payload;
    },
    setYearAction: (state, action: PayloadAction<Year>) => {
      state.year = action.payload;
    },
  },
});

export const { setMonthAction, setYearAction } = setDateSlice.actions;
export const dateSliceReducer = setDateSlice.reducer;
