import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CheckboxUncheckedAdminIcon: FC<
  SvgIconConstituentValues
> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      fill="white"
      stroke="#BFD4E4"
    />
  </svg>
);
