import React, { FC } from "react";
import { ImageList } from "@mui/material";

import { GalleryProps } from "./typed";
import {
  GalleryBox,
  GalleryImage,
  GalleryTitle,
  ImageListItemAction,
  ImageListItemDescription,
} from "./styled";

export const Gallery: FC<GalleryProps> = ({
  cols,
  gap,
  title,
  list,
  onClick,
  position,
  ActionIcon,
  Description,
  actionPosition,
  onChange,
}) => (
  <GalleryBox>
    <GalleryTitle>{title}</GalleryTitle>
    <ImageList cols={cols} gap={gap}>
      {list.map(({ id, image, title, created_at }, index) => (
        <GalleryImage key={id} onClick={() => onClick(index)}>
          <img src={image} loading="lazy" />
          {ActionIcon && (
            <ImageListItemAction
              actionIcon={<ActionIcon id={id} />}
              position={position}
              actionPosition={actionPosition}
              onChange={onChange}
            />
          )}
          {Description && (
            <ImageListItemDescription
              actionIcon={<Description title={title} created_at={created_at} />}
              position="bottom"
              actionPosition="left"
            />
          )}
        </GalleryImage>
      ))}
    </ImageList>
  </GalleryBox>
);

Gallery.defaultProps = {
  gap: 20,
  cols: 3,
  position: "top",
};
