import React, { FC, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { FileUploader } from "react-drag-drop-files";
import { images } from "constant";
import { UploadIcon, CloseIcon, ZoomOutIcon, ZoomInIcon } from "icons";
import { getCroppedImg, readFile, urltoFile } from "helpers";
import { ButtonOutlined } from "styled";

import { DialogTitle } from "../styled";

import type { CroppedAreaPixelsType, ImageCroppModalProps } from "./typed";
import {
  Dialog,
  DialogContent,
  UploadBox,
  TextUpload,
  TextDrag,
  TextError,
  DialogCroppContent,
  StyledSlider,
  CroppControls,
  CroppContainer,
  СroppWrapper,
  StyledButton,
  CroppControlsWrapper,
} from "./styled";

const EmptyError = {
  size: "",
  type: "",
  fileSize: "",
};

export const ImageCroppModal: FC<ImageCroppModalProps> = ({
  open,
  title,
  fileTypes,
  maxSize,
  setOpen,
  handleUpdateAvatar,
}) => {
  const [file, setFile] = useState<string>("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  const [croppedAreaPixels, setCroppedAreaPixels] =
    useState<CroppedAreaPixelsType | null>(null);

  const [errors, setErrors] = useState(EmptyError);

  const handleChange = async (file: Blob) => {
    setErrors(EmptyError);
    const imageDataUrl = await readFile(file);
    setFile(imageDataUrl as string);
  };
  const handleTypeError = err => setErrors({ ...errors, type: err });
  const handleSizeError = err => setErrors({ ...errors, fileSize: err });
  const handleClose = () => {
    setOpen(false);
    setFile("");
    setErrors(EmptyError);
  };

  const onCropComplete = useCallback(
    (_croppedArea, croppedAreaPixels: CroppedAreaPixelsType) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const onSave = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(file, croppedAreaPixels);

      const newFile = await urltoFile(croppedImage, "image.jpeg", "image/jpeg");

      const formData = new FormData();
      formData.append("photo", newFile);

      handleUpdateAvatar(formData);
      handleClose();
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <StyledButton onClick={handleClose}>
        <CloseIcon />
      </StyledButton>

      {!file ? (
        <DialogContent>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            maxSize={maxSize}
            onTypeError={handleTypeError}
            onSizeError={handleSizeError}
          >
            <UploadBox>
              <UploadIcon />
              <TextUpload>
                {`Upload file `}
                <TextDrag component="span">or drag and drop</TextDrag>
                <TextDrag>{`File Limit ${maxSize}MB`}</TextDrag>
              </TextUpload>
              <TextError>{errors.size}</TextError>
              <TextError>{errors.type}</TextError>
              <TextError>{errors.fileSize}</TextError>
            </UploadBox>
          </FileUploader>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogCroppContent>
            <СroppWrapper>
              <CroppContainer>
                <Cropper
                  style={{ cropAreaStyle: { color: "white", opacity: ".5" } }}
                  image={file}
                  crop={crop}
                  cropShape="round"
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CroppContainer>
              <CroppControls>
                <CroppControlsWrapper>
                  <div>
                    <ZoomOutIcon width="25" height="25" />
                  </div>
                  <div style={{ width: "65%" }}>
                    <StyledSlider
                      size="small"
                      value={zoom}
                      min={1}
                      max={5}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(_e, zoom) => setZoom(zoom as number)}
                    />
                  </div>
                  <div>
                    <ZoomInIcon width="25" height="25" />
                  </div>
                </CroppControlsWrapper>
                <ButtonOutlined onClick={onSave}>Save</ButtonOutlined>
              </CroppControls>
            </СroppWrapper>
          </DialogCroppContent>
        </>
      )}
    </Dialog>
  );
};

ImageCroppModal.defaultProps = {
  title: "Edit Profile Photo",
  fileTypes: images,
  maxSize: 5,
  maxFiles: 1,
};
