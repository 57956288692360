import React, { forwardRef, useMemo } from "react";

import { Input, InputLabel } from "../styled";

import { TextAreaType } from "./typed";

export const TextArea = forwardRef<HTMLInputElement, TextAreaType>(
  (
    {
      label,
      placeholder,
      fieldState,
      error,
      variant,
      withHeaderLabel,
      inputType,
      rows = 5,
      padding,
      spacing,
      height = "154px",
      ...field
    },
    ref,
  ) => {
    const errorMessage = useMemo(() => {
      if (fieldState.error?.message) {
        return fieldState.error?.message;
      }
      if (error?.creatingError?.data[field.name]) {
        return error?.creatingError?.data[field.name][0];
      }
      if (error?.updatingError?.data[field.name]) {
        return error?.updatingError?.data[field.name][0];
      }
    }, [fieldState.error, error]);

    return (
      <div>
        {withHeaderLabel && <InputLabel>{withHeaderLabel}</InputLabel>}
        <Input
          {...field}
          ref={ref}
          height={height}
          multiline
          rows={rows}
          fullWidth
          label={label}
          variant={variant}
          padding={padding}
          spacing={spacing}
          type={inputType}
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </div>
    );
  },
);

TextArea.displayName = "TextArea";

TextArea.defaultProps = {
  variant: "standard",
};
