import { NavLink as Link } from "react-router-dom";
import { Box, Button, ButtonProps, styled, Typography } from "@mui/material";

import { theme } from "theme";

interface Props {
  isAdminView?: boolean;
}

export const AppSidebar = styled(Box)({
  height: "100%",
  minHeight: "100vh",
  left: 0,
  background: theme.color.sidebarBg,
});

export const SidebarWrapper = styled(Box)({
  position: "sticky",
  top: "24px",
  maxWidth: "230px",
  width: "100%",
  "&.is-active": {
    maxWidth: "60px",
  },
});

export const SidebarUserBox = styled(Box)({
  textAlign: "center",
  color: theme.color.white,
  padding: "8px 30px 18px",

  "&.is-active": {
    justifyContent: "center",
    display: "flex",
    minHeight: "30px",
    padding: "40px 30px 25px",
    p: {
      display: "none",
    },
    button: {
      fontSize: 0,
      width: "36px",
      minWidth: "36px",
      svg: {
        marginRight: "-8px",
      },
    },
  },
});

export const SidebarLinks = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const NavLink = styled(Link)({
  color: theme.color.white,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecoration: "none",
  height: "100%",
  padding: "14px 39px 14px 11px",
  fontSize: theme.fontSize.lg,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  border: "1px solid #0D1214",

  svg: {
    stroke: theme.color.white,
    marginRight: "4px",

    "path, rect": {
      stroke: theme.color.white,
    },
  },

  "&:hover": {
    background: theme.color.sideLinkBg,
  },

  "&.active": {
    background: theme.color.sideLinkBg,
    borderRight: `3px solid ${theme.color.orange}`,
  },
  "&.is-active": {
    display: "block",
    lineHeight: 0,
    paddingLeft: "12px",
    fontSize: 0,
  },
});

export const Name = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "120%",
  color: theme.color.white,
  letterSpacing: "-0.01em",
});

export const Role = styled(Name)({
  fontSize: theme.fontSize.base,
});

export const RoleButton = styled(Button)<ButtonProps & Props>(
  ({ isAdminView }) => ({
    border: `2px solid ${theme.color.white}`,
    borderRadius: "30px",
    background: theme.color.blue,
    textTransform: "none",
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    lineHeight: "140%",
    color: theme.color.white,
    minHeight: "36px",
    alignItems: "center",
    width: "140px",
    height: "fit-content",
    padding: "3px 15px",
    marginTop: 15,
    span: {
      marginRight: 5,
      "svg path": {
        stroke: theme.color.white,
      },
    },
    "&:hover": {
      backgroundColor: theme.color.blue,
      opacity: 0.9,
    },
    ...(isAdminView
      ? null
      : {
          color: theme.color.black,
          background: theme.color.white,
          "span svg path": {
            stroke: theme.color.black,
          },
          "&:hover": {
            backgroundColor: theme.color.white,
            opacity: 0.9,
          },
          "&:disabled": {
            color: theme.color.black,
          },
        }),
  }),
);
