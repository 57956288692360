import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LawIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="3.51803"
      height="3.51803"
      rx="1"
      transform="matrix(0.699281 0.714847 -0.699281 0.714847 12.2832 2)"
      stroke="white"
    />
    <rect
      width="3.51803"
      height="3.51803"
      rx="1"
      transform="matrix(0.699281 0.714847 -0.699281 0.714847 5.74414 15.9805)"
      stroke="white"
    />
    <rect
      width="3.51803"
      height="3.51803"
      rx="1"
      transform="matrix(0.699281 0.714847 -0.699281 0.714847 19.4199 15.9805)"
      stroke="white"
    />
    <path d="M1.5 11.3857L23.5 11.3857" stroke="white" />
    <path d="M19.3379 16.1425L19.3379 11.3857" stroke="white" />
    <path d="M12.2031 11.3857L12.2031 6.62891" stroke="white" />
    <path d="M5.66211 16.1425L5.66211 11.3857" stroke="white" />
  </svg>
);
