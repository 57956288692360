import React, { FC } from "react";
import { Button } from "@mui/material";

import {
  Dialog,
  TextContent,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "../styled";
import type { ConfirmDialogProps } from "./typed";

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  title,
  messages,
  cancelButton,
  confirmButton,
  setOpen,
  onConfirm,
}) => {
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose && handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {messages.map((message, index) => (
          <TextContent key={index}>{message}</TextContent>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelButton}</Button>
        <Button onClick={handleConfirm}>{confirmButton}</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  title: "Alert",
  messages: ["Are you sure?"],
  cancelButton: "No",
  confirmButton: "Yes",
};
