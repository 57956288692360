import {
  styled,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from "@mui/material";

import { theme } from "theme";
import { Box } from "@mui/system";

interface RowProps extends TableRowProps {
  clickable?: boolean;
  firstColumnWidth?: number;
}

interface CellProps extends TableCellProps {
  clickable?: boolean;
  firstColumnWidth?: number;
  minWidth?: number | string;
  maxWidth?: number | string;
}

export const TableRowStyled = styled(TableRow)<RowProps>(
  ({ clickable, firstColumnWidth }) => ({
    maxWidth: "100%",
    "td, td span": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "140%",
      color: theme.color.black,
      wordBreak: "break-word",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "450px",
      whiteSpace: "nowrap",
      borderBottom: "none",
    },
    "first-of-type": {
      width: firstColumnWidth,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.color.rowBottom,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.color.rowTop,
    },
    "&.MuiTableRow-hover:hover": {
      backgroundColor: theme.color.grey[200],
    },
    "svg:hover": {
      cursor: "pointer",
    },
    ...(clickable && {
      cursor: "pointer",
    }),
  }),
);

export const TableCellStyled = styled(TableCell)<CellProps>(
  ({ clickable, firstColumnWidth, width, minWidth, maxWidth }) => ({
    ...(clickable && {
      cursor: "pointer",
    }),
    ...(width && {
      width,
      minWidth,
      maxWidth,
    }),
    ...(firstColumnWidth && {
      width: firstColumnWidth,
    }),
  }),
);

export const TableCellAction = styled(TableCell)({
  display: "flex",
  gap: "5px",
  justifyContent: "start",
  minWidth: "90px",
});

export const EmptyStateTableText = styled(Box)({
  justifyContent: "center",
  display: "flex",
  minHeight: "100px",
  alignItems: "center",
});
