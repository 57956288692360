import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const BinIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0036 6.99609L16.7948 18.4759C16.7235 19.1548 16.1513 19.6703 15.4687 19.6707H8.53101C7.84816 19.6707 7.27554 19.155 7.20418 18.4759L5.99609 6.99609"
      stroke={props.strokeColor ?? "black"}
    />
    <path d="M4.32812 6.99609H19.671" stroke={props.strokeColor ?? "black"} />
    <path
      d="M8.99805 6.99646V4.99521C8.99805 4.62679 9.29671 4.32812 9.66513 4.32812H14.3347C14.7031 4.32812 15.0018 4.62679 15.0018 4.99521V6.99646"
      stroke={props.strokeColor ?? "black"}
    />
    <path d="M12 9.99805V17.0024" stroke={props.strokeColor ?? "black"} />
    <path
      d="M15.0015 9.99805L14.668 17.0024"
      stroke={props.strokeColor ?? "black"}
    />
    <path
      d="M8.99805 9.99805L9.33159 17.0024"
      stroke={props.strokeColor ?? "black"}
    />
  </svg>
);
