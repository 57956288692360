import {
  styled,
  DialogTitle as MUIDialogTitle,
  DialogContent as MIUDialogContent,
} from "@mui/material";
import { DialogBlurBackdrop } from "styled";
import { theme } from "theme";

export const DialogContent = styled(MIUDialogContent)({
  padding: "0 20px",
  // width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
});

export const Dialog = styled(DialogBlurBackdrop)<{
  height?: number;
  paddingBot: number;
}>(({ height, paddingBot }) => ({
  ".MuiPaper-root": {
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "15px",

    ...(height && {
      height: `${height}px`,
    }),
    ...(paddingBot && {
      paddingBottom: `${paddingBot}px`,
    }),
  },
}));

export const DialogTitle = styled(MUIDialogTitle)<{ avatar?: boolean }>(
  ({ avatar }) => ({
    boxSizing: "border-box",
    width: "100%",
    color: theme.color.black,

    ...(avatar
      ? {
          fontWeight: theme.fontWeight.regular,
          fontSize: theme.fontSize.base,
          lineHeight: "140%",
          textAlign: "center",
          padding: "15px 0px",
        }
      : {
          fontWeight: theme.fontWeight.bold,
          fontSize: theme.fontSize.md,
          lineHeight: "125%",
          textAlign: "left",
          padding: "25px 20px",
        }),
  }),
);

export const StyledButton = styled("button")<{ avatar?: boolean }>(
  ({ avatar }) => ({
    position: "absolute",
    padding: 0,
    margin: 0,
    background: "none",
    border: "none",
    cursor: "pointer",

    ...(avatar
      ? {
          top: "5px",
          right: "5px",
        }
      : {
          top: "15px",
          right: "15px",
        }),
  }),
);
