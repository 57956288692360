import { styled, TextField } from "@mui/material";
import { theme } from "theme";

export const InputStyled = styled(TextField)<{ sidebarSearch?: boolean }>(
  ({ sidebarSearch }) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",

    "& div.MuiOutlinedInput-root": {
      borderRadius: "30px",
      height: "27px",
      width: "calc(100% - 32px *2)",
      padding: "0 11px",
      background: "#2E3537",
      color: "white",
      display: "flex",
      justifyContent: "center",

      "& input": {
        padding: "0",
      },
    },
    ...(sidebarSearch && {
      "& div.MuiOutlinedInput-root": {
        border: "1px solid transparent",
        height: "27px",
        width: "calc(100% - 22px *2)",
        padding: "0",
        color: "white",
        display: "flex",
        justifyContent: "center",
        background: "transparent",
        "& input": {
          padding: "0",
          "&::placeholder": {
            textTransform: "uppercase",
            padding: "0",
            color: theme.color.grey["1200"],
            fontWeight: theme.fontWeight.bold,
          },
        },
        "& fieldset": {
          width: "143px",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderRadius: 0,
          borderBottom: `1px solid ${theme.color.white} !important`,
        },
      },
    }),
  }),
);
