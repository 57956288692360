import { endpoints } from "constant";

export const clientAboutMeUrlBuilder = {
  aboutMe: function (id: number | string): string {
    return `${endpoints.client}${id}/about-me/`;
  },
  setAboutMeQuestion: function (id: number | string): string {
    return `${endpoints.client}${id}/about-me/`;
  },
};
