import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { AuthRequestData, AuthUserAdmin, AuthCallbackBody } from "types";

import { authUrlBuilder } from "./urlBuilder/auth";

export const authUserAdminAPI = createApi({
  reducerPath: "authUserAdminAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: build => ({
    authRequest: build.query<AuthRequestData, void>({
      query: () => ({
        url: authUrlBuilder.authRequest(),
        method: "GET",
      }),
    }),
    authCallback: build.mutation<AuthUserAdmin, AuthCallbackBody>({
      query: body => ({
        url: authUrlBuilder.authCallback(),
        method: "POST",
        data: body,
      }),
    }),
  }),
});

export const { useAuthRequestQuery, useAuthCallbackMutation } =
  authUserAdminAPI;
