import { Typography, styled } from "@mui/material";

import { theme } from "theme";

const StageFlexContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const Stage = styled(StageFlexContainer)<{ isReversed?: boolean }>(
  ({ isReversed }) => ({
    cursor: "pointer",

    flexDirection: isReversed ? "row-reverse" : "row",
    justifyContent: "flex-start",
    width: "100%",

    "&:hover": {
      svg: {
        path: {
          fill: theme.color.grey[900],
        },
      },
    },
  }),
);

export const StageInfo = styled(StageFlexContainer)<{ isReversed: boolean }>(
  ({ isReversed }) => ({
    justifyContent: "flex-start",
    boxSizing: "border-box",
    width: "100%",
    paddingTop: isReversed ? "7px" : "10px",
  }),
);

export const StageTypography = styled(Typography)({
  fontSize: "13px",
  fontWeight: theme.fontWeight.semiBold,
  lineHeight: "24px",
  letterSpacing: "0px",
  textAlign: "center",
  color: theme.color.grey[1200],
});

export const StageNumber = styled(StageTypography)();

export const StageStatus = styled(StageTypography)();

export const StageTitle = styled(StageTypography)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    fontSize: theme.fontSize.md,
    color: theme.color.black,

    ...(isSelected && {
      fontWeight: theme.fontWeight.bold,
    }),
  }),
);

export const StageDate = styled(StageTypography)({
  lineHeight: "10px",
});

export const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

export const Line = styled("div")({
  position: "absolute",
  border: `1.5px solid ${theme.color.black}`,
  height: "100%",
  left: "50%",
  transform: "translateX(-50%)",
});

export const GridContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "170px 170px",
  gridAutoRows: "97px",
  alignItems: "center",
  gridTemplateAreas: `'first item0'
                      'item1 item0'
                      'item1 item2'
                      'item3 item2'
                      'item3 item4'
                      'item5 item4'
                      'item5 item6'
                      'last item6'
                      `,
});

export const EmptyGridItem = styled("div")<{ area: "first" | "last" }>(
  ({ area }) => ({
    gridArea: area,
  }),
);

export const GridItem = styled("div")<{ area: number }>(({ area }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  gridArea: `item${area}`,
}));
