import { styled, Typography } from "@mui/material";
import { BreakPointTheme, theme } from "theme";

export const Wrapper = styled("div")({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "100",
  background: "rgba(0, 0, 0, 0.6)",
  backdropFilter: "blur(5px)",
});

export const Container = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "565px",

  [BreakPointTheme.breakpoints.down("md")]: {
    width: "351px",
  },
});

export const ContentContainer = styled("div")({
  backgroundColor: theme.color.white,
  filter: "drop-shadow(9.5px 14px 24px rgba(0, 0, 0, 0.35))",
  border: "1px solid #6D6D6D",
  borderRadius: "10px",
  width: "565px",

  [BreakPointTheme.breakpoints.down("md")]: {
    width: "351px",
  },
});

export const Header = styled(Typography)({
  fontStyle: "normal",
  fontWeight: theme.fontWeight.extraBold,
  color: theme.color.black,
  fontSize: "24px",
  lineHeight: "24px",
  textAlign: "center",
  marginTop: "35px",
});

export const Title = styled(Typography)({
  height: "110px",
  maxWidth: "431px",
  fontStyle: "normal",
  fontWeight: theme.fontWeight.regular,
  color: theme.color.black,
  fontSize: "20px",
  lineHeight: "28px",
  textAlign: "center",
  margin: "10px auto 8px",

  [BreakPointTheme.breakpoints.down("md")]: {
    maxWidth: "314px",
    height: "75px",
  },
});

export const ButtonsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
});

export const FirstButton = styled("div")({
  borderRight: "1px solid #6D6D6D",
});

export const Button = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  borderTop: "1px solid #6D6D6D",
  width: "100%",
  height: "52px",
});

export const ButtonText = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "140%",
  color: theme.color.black,
});
