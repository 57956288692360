import {
  styled,
  Typography,
  DialogActions as MUIDialogActions,
  DialogContent as MIUDialogContent,
} from "@mui/material";

import { DialogBlurBackdrop } from "styled";

export const DialogTitle = styled(Typography)({
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: "600",
  padding: "26px 27px 18px",
  textAlign: "left",
});

export const DialogContent = styled(MIUDialogContent)({
  padding: "12px 27px 0",
});

export const DialogActions = styled(MUIDialogActions)({
  padding: "6px 27px 29px",

  "button:last-child": {
    marginLeft: "36px",
  },
});

export const Dialog = styled(DialogBlurBackdrop)<{ maxWidthStyle?: string }>(
  ({ maxWidthStyle }) => ({
    "& .MuiDialog-paper": {
      position: "relative",
      borderRadius: "0",

      ...(maxWidthStyle && {
        width: "100%",
        maxWidth: maxWidthStyle,
      }),
    },
  }),
);

export const StyledButton = styled("button")({
  position: "absolute",
  padding: 0,
  margin: 0,
  background: "none",
  border: "none",
  cursor: "pointer",

  top: "15px",
  right: "15px",
});
