import React, { FC } from "react";

import { SaveIcon, CloseIcon } from "icons";
import { ButtonOutlined } from "styled";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  StyledButton,
  DialogActions,
} from "../styled";

import type { ModalProps } from "./typed";

export const Modal: FC<ModalProps> = ({
  children,
  open,
  title,
  disabledApproveButton,
  cancelButton,
  approveButton,
  handleClose,
  handleConfirm,
}) => (
  <Dialog
    open={open}
    fullWidth
    onClose={handleClose}
    onKeyUp={e => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleConfirm();
      }
    }}
  >
    <StyledButton onClick={handleClose}>
      <CloseIcon />
    </StyledButton>

    <DialogTitle>{title}</DialogTitle>

    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <ButtonOutlined startIcon={<CloseIcon />} onClick={handleClose}>
        {cancelButton}
      </ButtonOutlined>

      <ButtonOutlined
        startIcon={<SaveIcon />}
        onClick={handleConfirm}
        disabled={disabledApproveButton}
      >
        {approveButton}
      </ButtonOutlined>
    </DialogActions>
  </Dialog>
);

Modal.defaultProps = {
  disabledApproveButton: false,
  cancelButton: "Cancel",
  approveButton: "Save",
  title: "",
};
