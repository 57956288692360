import { endpoints } from "constant";

import { SearchQueryParams } from "types/Search";

export const searchUrlBuilder = {
  getSearchResults: function ({
    page,
    limit,
    carId,
    ordering = "",
    search = "",
    offset = "",
  }: SearchQueryParams = {}): string {
    return `${endpoints.search}?search=${search}&car_id=${carId}${
      page === undefined ? "" : `&page=${page + 1}`
    }${limit ? `&page_size=${limit}` : ""}${offset ? `&offset=${offset}` : ""}${
      ordering && `&ordering=${ordering}`
    }`;
  },
};
