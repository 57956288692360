import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import { useGetTimelineRoadQuery } from "redux/query/timelineAPI";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useLazyGetClientQuery } from "redux/query/clientsAPI";
import { useLazyGetClientProfileQuery } from "redux/query/clientProfileAPI";
import { useLazyGetCollaboratorProfileQuery } from "redux/query/clientCollaboratorsAPI";
import {
  useDeleteNotificationMutation,
  useGetNotificationsQuery,
} from "redux/query/notificationsAPI";

import {
  activeComissionStageSelector,
  getCollaboratorPermissions,
  getCollaboratorUserId,
  getUserClientRole,
} from "redux/selectors";
import {
  setActiveComission,
  setActiveStage,
  setActiveSubstage,
} from "redux/slices";
import {
  activeComissionsSelector,
  сomissionsSelector,
} from "redux/selectors/clientComissions";

import { checkPermissions } from "utils/checkPermissions";

import { Comission, INotification } from "types";

import { CLIENT_ROUTES, MenuItem, QUERY_OPTIONS, ROLES } from "constant";

import { ICarStages } from "./typed";
import {
  Arrow,
  Divider,
  NavLink,
  NavLinkOption,
  NavLinkOptionName,
  NavLinkOptions,
  NavLinkWrapper,
  Notification,
  NotificationCounter,
  NotificationInfo,
  NotificationLabel,
  NotificationName,
  NotificationsContainer,
  NotificationsIndicator,
  NotificationsWrapper,
  Summary,
} from "./styled";

export const generateLinks = (list: MenuItem, opened?: boolean): ReactNode => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const collaboratorUserId = useAppSelector(getCollaboratorUserId);
  const clientRole = useAppSelector(getUserClientRole);
  const collaboratorPermissions = useAppSelector(getCollaboratorPermissions);
  const comissions: Comission[] = useAppSelector(сomissionsSelector);
  const activeComission: Comission = useAppSelector(activeComissionsSelector);
  const activeStage = useAppSelector(activeComissionStageSelector);

  const { data: stagesData } = useGetTimelineRoadQuery(
    { id: activeComission?.id },
    { ...QUERY_OPTIONS, skip: !activeComission?.id },
  );

  const [getCollaboratorProfile] = useLazyGetCollaboratorProfileQuery();

  const [getClientData, { data: aboutMeData }] = useLazyGetClientQuery();

  const [getProfile, { data: userData }] = useLazyGetClientProfileQuery();

  const { data: notificationsData } = useGetNotificationsQuery(
    {},
    QUERY_OPTIONS,
  );

  const [useDeleteNotification] = useDeleteNotificationMutation();

  useEffect(() => {
    if (!clientRole) return;

    if (clientRole === ROLES.Client) {
      getProfile();
      return;
    }
    getCollaboratorProfile();
  }, [clientRole]);

  useEffect(() => {
    if (userData?.id) {
      getClientData({
        id: userData.id.toString(),
      });
    }

    if (collaboratorUserId) {
      getClientData({
        id: collaboratorUserId,
      });
    }
  }, [userData, collaboratorUserId]);

  const initialDropdownsState = {
    commissions: false,
    stages: false,
    events: false,
    design: false,
    notifications: false,
  };

  const [dropdownItem, setDropdownItem] = useState<Record<string, boolean>>(
    initialDropdownsState,
  );

  const handleMenuDropdowns = (key: string, value: boolean) => {
    setDropdownItem({ ...dropdownItem, [key]: value });
  };

  useEffect(() => {
    if (!opened) {
      setDropdownItem(initialDropdownsState);
    }
  }, [opened]);

  const carStages: ICarStages[] = stagesData?.reduce((acc, el) => {
    if (acc?.find(el => el?.is_active_stage)) {
      return [...acc];
    }
    return [
      ...acc,
      { id: el?.stage_id, title: el?.stage_name, current: el?.is_active_stage },
    ];
  }, []);

  const handleNotificationClick = (notification: INotification) => {
    const type = notification.notification_type;
    switch (type) {
      case "event": {
        const newPath = CLIENT_ROUTES.EVENTS_DETAILS.path.replace(
          ":id",
          notification.options.event_id.toString(),
        );
        navigate(newPath);
        break;
      }
      case "new_photos": {
        dispatch(
          setActiveComission(
            comissions?.find(({ id }) => id === notification?.car_id),
          ),
        );
        dispatch(setActiveStage(notification?.options?.stage_id));
        dispatch(setActiveSubstage(notification?.options?.substage_id));
        navigate(CLIENT_ROUTES.TIMELINE.path);
        break;
      }
      case "new_documents": {
        navigate(CLIENT_ROUTES.DOCUMENTS.path);
        break;
      }
      case "stage_change": {
        dispatch(
          setActiveComission(
            comissions?.find(({ id }) => id === notification?.car_id),
          ),
        );
        dispatch(setActiveStage(notification?.options?.stage_id));

        navigate(CLIENT_ROUTES.TIMELINE.path);
      }
    }
    useDeleteNotification({ id: Number(notification.id) });
  };

  const openExternalLink = (externalLink: string) => {
    window.open(externalLink, "_blank");
  };

  const groupedNotifications = notificationsData?.results?.reduce(
    (result: Record<string, INotification[]>, notification: INotification) => {
      const notificationType = notification.notification_type;

      if (notification?.car_id === activeComission?.id) {
        if (!result[notificationType]) {
          result[notificationType] = [];
        }
        result[notificationType].push(notification);
      }
      return result;
    },
    {},
  );
  const notificationsCount: number = notificationsData?.results?.filter(
    el => el.car_id === activeComission?.id,
  )?.length;

  return (
    <>
      {Object.keys(list).map(key => {
        // Serhii Hudym: I added dynamicSubpaths prop to MenuItem type
        // so you can create dynamically generated subpaths for your menu item
        const {
          name,
          path,
          subpaths,
          isCollaboratorAccess,
          links,
          externalLink,
        } = list[key];

        const isShowItem = checkPermissions(
          clientRole,
          isCollaboratorAccess,
          collaboratorPermissions,
        );

        return (
          <>
            {name === "Commissions" && (
              <NavLinkWrapper>
                <Divider />
                <Summary
                  isActive={dropdownItem.commissions}
                  onClick={() => {
                    handleMenuDropdowns(
                      "commissions",
                      !dropdownItem.commissions,
                    );
                  }}
                >
                  {opened && name}
                  <Arrow
                    opened={dropdownItem.commissions}
                    sidebarOpened={opened}
                  />
                </Summary>

                {opened && dropdownItem.commissions && (
                  <NavLinkOptions>
                    {comissions?.map(({ id, name }) => (
                      <NavLinkOption
                        key={id}
                        isActive={id === activeComission?.id ? 1 : 0}
                        onClick={() => {
                          dispatch(
                            setActiveComission(
                              comissions.find(car => car.id === id),
                            ),
                          );
                        }}
                      >
                        <NavLinkOptionName>{name}</NavLinkOptionName>
                      </NavLinkOption>
                    ))}
                  </NavLinkOptions>
                )}
              </NavLinkWrapper>
            )}

            {name === "Timeline" && (
              <NavLinkWrapper>
                <Divider />
                <Summary
                  isActive={dropdownItem.timeline || pathname.includes(path)}
                  onClick={() => {
                    navigate(CLIENT_ROUTES.TIMELINE.path);
                  }}
                >
                  {opened && name}

                  {carStages?.length && (
                    <Arrow
                      opened={dropdownItem.timeline}
                      sidebarOpened={opened}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        handleMenuDropdowns("timeline", !dropdownItem.timeline);
                      }}
                    />
                  )}
                </Summary>

                {opened && dropdownItem.timeline && (
                  <NavLinkOptions>
                    {carStages?.map(el => (
                      <NavLinkOption
                        key={el.id}
                        isActive={el.id === activeStage ? 1 : 0}
                        onClick={() => {
                          dispatch(setActiveStage(el.id));
                          navigate(CLIENT_ROUTES.TIMELINE.path);
                        }}
                      >
                        <NavLinkOptionName>{el?.title}</NavLinkOptionName>
                      </NavLinkOption>
                    ))}
                  </NavLinkOptions>
                )}
              </NavLinkWrapper>
            )}

            {name === "Design" && (
              <NavLinkWrapper>
                <Summary
                  key={path}
                  isActive={dropdownItem.design}
                  onClick={() => {
                    handleMenuDropdowns("design", !dropdownItem.design);
                  }}
                >
                  {opened && name}
                  <Arrow
                    opened={dropdownItem.design}
                    sidebarOpened={opened}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      handleMenuDropdowns("design", !dropdownItem.design);
                    }}
                  />
                </Summary>

                {opened && dropdownItem.design && (
                  <NavLinkOptions>
                    {links?.map(elem => {
                      const isShowLink =
                        !elem.isCollaborator ||
                        checkPermissions(
                          clientRole,
                          elem.isCollaborator,
                          collaboratorPermissions,
                        );

                      if (isShowLink) {
                        return (
                          <NavLinkOption
                            key={path + elem.path}
                            onClick={() => {
                              navigate(path + elem.path);
                            }}
                            isMobile={elem.isMobile && isMobileOnly}
                            isDesktop={elem.isDesktop && !isMobileOnly}
                          >
                            <NavLinkOptionName>{elem.name}</NavLinkOptionName>
                          </NavLinkOption>
                        );
                      } else {
                        return null; // If the user doesn't have permission for the link, don't render it.
                      }
                    })}
                  </NavLinkOptions>
                )}
              </NavLinkWrapper>
            )}

            {name === "Notifications" && (
              <NavLinkWrapper>
                <Summary
                  key={path}
                  isActive={dropdownItem.notifications}
                  onClick={() => {
                    handleMenuDropdowns(
                      "notifications",
                      !dropdownItem.notifications,
                    );
                  }}
                >
                  {opened && name}
                  <NotificationsIndicator>
                    <NotificationCounter>
                      {notificationsCount}
                    </NotificationCounter>
                  </NotificationsIndicator>

                  {notificationsCount > 0 && (
                    <Arrow
                      opened={dropdownItem.notifications}
                      sidebarOpened={opened}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        handleMenuDropdowns(
                          "notifications",
                          !dropdownItem.notifications,
                        );
                      }}
                    />
                  )}
                </Summary>

                {opened &&
                  dropdownItem.notifications &&
                  notificationsData?.count > 0 && (
                    <NavLinkOptions>
                      <NotificationsWrapper>
                        <NotificationsContainer>
                          {Object.keys(groupedNotifications).map(
                            notificationType => (
                              <Notification key={notificationType}>
                                <NotificationInfo>
                                  <NotificationLabel>
                                    {notificationType.replace("_", " ")}
                                  </NotificationLabel>
                                  {groupedNotifications[notificationType].map(
                                    notification => (
                                      <NotificationName
                                        key={notification.id}
                                        onClick={() =>
                                          handleNotificationClick(notification)
                                        }
                                      >
                                        {notification.text}
                                      </NotificationName>
                                    ),
                                  )}
                                </NotificationInfo>
                              </Notification>
                            ),
                          )}
                        </NotificationsContainer>
                      </NotificationsWrapper>
                    </NavLinkOptions>
                  )}
              </NavLinkWrapper>
            )}

            {isShowItem && subpaths && (
              <NavLinkWrapper>
                <Divider />
                <Summary
                  key={path}
                  isActive={dropdownItem.events}
                  onClick={() => {
                    handleMenuDropdowns("events", !dropdownItem.events);
                    navigate(path + subpaths[2].path);
                  }}
                >
                  {opened && name}
                  <Arrow
                    opened={dropdownItem.events}
                    sidebarOpened={opened}
                    onClick={e => {
                      e.stopPropagation();
                      handleMenuDropdowns("events", !dropdownItem.events);
                    }}
                  />
                </Summary>

                {dropdownItem.events && (
                  <NavLinkOptions>
                    {subpaths.map(elem => (
                      <NavLinkOption
                        key={path + elem.path}
                        onClick={() => {
                          navigate(path + elem.path);
                        }}
                      >
                        <NavLinkOptionName>{elem.name}</NavLinkOptionName>
                      </NavLinkOption>
                    ))}
                  </NavLinkOptions>
                )}
              </NavLinkWrapper>
            )}

            {isShowItem && !subpaths && path && name !== "Timeline" && (
              <>
                <Divider />
                <NavLink key={path} to={path}>
                  {opened && name}
                </NavLink>
              </>
            )}
            {isShowItem && externalLink && (
              <>
                <Divider />
                <Summary
                  key={path}
                  onClick={() => openExternalLink(externalLink)}
                >
                  {opened && name}
                </Summary>
              </>
            )}
          </>
        );
      })}
    </>
  );
};
