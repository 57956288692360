import { Typography, styled } from "@mui/material";

import { theme } from "theme";

export const LabelTitle = styled(Typography)<{ isActive?: boolean }>(
  ({ isActive }) => ({
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSize.md,
    lineHeight: "140%",
    textAlign: "left",

    ...(isActive
      ? {
          color: theme.color.orange,
        }
      : {
          color: theme.color.black,
        }),
  }),
);
