import React, { FC } from "react";

import { DialogContent } from "../styled";
import { CloseCarouselIcon, ModalWithoutBackground } from "./styled";
import { GalleryDialogProps } from "./typed";

export const GalleryDialog: FC<GalleryDialogProps> = ({
  open,
  setOpen,
  children,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <ModalWithoutBackground
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
    >
      <DialogContent>
        <CloseCarouselIcon onClick={handleClose} />
        {children}
      </DialogContent>
    </ModalWithoutBackground>
  );
};
