import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { RemoveIcon } from "icons";

import { generateLinks } from "../utils";

import { CLIENT_RIGHT_MENU_ITEMS_INFO } from "constant";

import { BurgerMenuProps } from "../BurgerMenu/typed";
import { CloseIconBox, SidebarLinks, SidebarWrapper } from "../styled";

export const ConnectMenu = ({ opened, closeSidebar }: BurgerMenuProps) => {
  const location = useLocation();

  useEffect(() => {
    closeSidebar();
  }, [location]);

  return (
    <SidebarWrapper opened={opened} type={"right"}>
      <CloseIconBox onClick={closeSidebar} type={"right"}>
        <RemoveIcon />
      </CloseIconBox>

      <SidebarLinks>
        {generateLinks(CLIENT_RIGHT_MENU_ITEMS_INFO, opened)}
      </SidebarLinks>
    </SidebarWrapper>
  );
};
