import { styled } from "@mui/material";

import { theme } from "theme";

export const Wrapper = styled("div")<{ isError: boolean }>(({ isError }) => ({
  padding: "5px",
  ...(isError && {
    border: `1px ${theme.color.error[600]} solid`,
    borderRadius: "4px",
  }),
}));
