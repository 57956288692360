import React, { FC } from "react";

import { Box } from "@mui/material";

import { IComissionTabs } from "./typed";
import { Count, StyledTab, StyledTabs, Wrapper } from "./styled";

export const UserTabs: FC<IComissionTabs> = ({
  alignLeft,
  withCounter,
  variant,
  maxWidth,
  tabs,
  value,
  setValue,
  Component,
}) => {
  const handleChange = (_e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Wrapper>
      <Box sx={{ maxWidth: maxWidth }}>
        <StyledTabs
          scrollButtons="auto"
          variant={variant}
          value={value}
          onChange={handleChange}
        >
          {tabs?.map(tab => (
            <StyledTab
              key={tab?.id}
              alignLeft={alignLeft}
              value={tab?.id}
              label={
                <Component
                  title={tab?.labelTitle || ""}
                  subTitle={tab?.labelSubTitle || ""}
                  isActive={tab?.id === value}
                />
              }
            />
          ))}
        </StyledTabs>
      </Box>

      {withCounter && (
        <Count>
          {tabs?.findIndex(el => el.id === value) + 1}/{tabs?.length}
        </Count>
      )}
    </Wrapper>
  );
};

UserTabs.defaultProps = {
  maxWidth: "100%",
};
