import { instagramAPI } from "./redux/query/instagramAPI";
import { sidebarSliceReducer, studySliceReducer } from "./redux/slices";
import { clientCollaboratorsAPI } from "./redux/query/clientCollaboratorsAPI";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import { dateSliceReducer } from "redux/client/slices/date";
import { authUserSliceReducer } from "redux/slices";
import { clientsAPI } from "redux/query/clientsAPI";
import { announcementsAPI } from "redux/query/announcementsAPI";
import { authUserClientAPI } from "redux/client/query/authUserClientAPI";
import { photosAPI } from "redux/query/photosAPI";
import { eventsAPI } from "redux/query/eventsAPI";
import { eventAttendeesAPI } from "redux/query/eventAttendeesAPI";
import { adminsAPI } from "redux/query/adminsAPI";
import { legalDocumentsAPI } from "redux/query/legalDocumentsAPI";
import { disclaimersAPI } from "redux/query/disclaimersAPI";
import { authUserAdminAPI } from "redux/query/authUserAdminAPI";
import { partnersAPI } from "redux/query/partnersAPI";
import { carDocumentsAPI } from "redux/query/carDocumentsAPI";
import { carsAPI } from "redux/query/carsAPI";
import { inspirationToolPageContentAPI } from "redux/query/inspirationToolPageContentAPI";
import { inspirationToolProductCategoriesAPI } from "redux/query/inspirationToolProductCategoriesAPI";
import { inspirationToolProductFamiliesAPI } from "redux/query/inspirationToolProductFamiliesAPI";
import { inspirationToolProductStudiesAPI } from "redux/query/inspirationToolProductStudiesAPI";
import { stagesAPI } from "redux/query/stagesAPI";
import { universalContentTimelineAPI } from "redux/query/universalContentTimelineAPI";
import { universalContentDashboardAPI } from "redux/query/universalContentDashboardAPI";
import { clientProfileAPI } from "redux/query/clientProfileAPI";
import { clientAboutMeAPI } from "redux/query/clientAboutMeAPI";
import { comissionsSliceReducer } from "redux/slices/clientComissions";
import { carStagesTimelineAPI } from "./redux/query/carStagesTimelineAPI";
import { carStageFavoritePhotosAPI } from "./redux/query/carStageFavoritePhotosAPI";
import { zipArchiveAPI } from "./redux/query/zipArchive";
import { portfolioAPI } from "redux/query/portfolioAPI";
import { moodBoardsAPI } from "redux/query/moodBoardsAPI";
import { searchAPI } from "redux/query/searchAPI";
import { moodBoardSliceReducer } from "redux/slices/moodBoard";
import { timelineAPI } from "redux/query/timelineAPI";
import { notificationsAPI } from "redux/query/notificationsAPI";

const LOGOUT = ["authUser/userClientLogout", "authUser/userAdminLogout"];

const reducers = combineReducers({
  date: dateSliceReducer,
  authUser: authUserSliceReducer,
  sidebar: sidebarSliceReducer,
  study: studySliceReducer,
  comissions: comissionsSliceReducer,
  moodBoard: moodBoardSliceReducer,
  [searchAPI.reducerPath]: searchAPI.reducer,
  [timelineAPI.reducerPath]: timelineAPI.reducer,
  [authUserClientAPI.reducerPath]: authUserClientAPI.reducer,
  [clientsAPI.reducerPath]: clientsAPI.reducer,
  [announcementsAPI.reducerPath]: announcementsAPI.reducer,
  [photosAPI.reducerPath]: photosAPI.reducer,
  [eventsAPI.reducerPath]: eventsAPI.reducer,
  [carStagesTimelineAPI.reducerPath]: carStagesTimelineAPI.reducer,
  [eventAttendeesAPI.reducerPath]: eventAttendeesAPI.reducer,
  [carDocumentsAPI.reducerPath]: carDocumentsAPI.reducer,
  [carsAPI.reducerPath]: carsAPI.reducer,
  [adminsAPI.reducerPath]: adminsAPI.reducer,
  [legalDocumentsAPI.reducerPath]: legalDocumentsAPI.reducer,
  [disclaimersAPI.reducerPath]: disclaimersAPI.reducer,
  [authUserAdminAPI.reducerPath]: authUserAdminAPI.reducer,
  [partnersAPI.reducerPath]: partnersAPI.reducer,
  [inspirationToolPageContentAPI.reducerPath]:
    inspirationToolPageContentAPI.reducer,
  [inspirationToolProductCategoriesAPI.reducerPath]:
    inspirationToolProductCategoriesAPI.reducer,
  [inspirationToolProductFamiliesAPI.reducerPath]:
    inspirationToolProductFamiliesAPI.reducer,
  [inspirationToolProductStudiesAPI.reducerPath]:
    inspirationToolProductStudiesAPI.reducer,
  [stagesAPI.reducerPath]: stagesAPI.reducer,
  [universalContentTimelineAPI.reducerPath]:
    universalContentTimelineAPI.reducer,
  [universalContentDashboardAPI.reducerPath]:
    universalContentDashboardAPI.reducer,
  [clientProfileAPI.reducerPath]: clientProfileAPI.reducer,
  [clientAboutMeAPI.reducerPath]: clientAboutMeAPI.reducer,
  [clientCollaboratorsAPI.reducerPath]: clientCollaboratorsAPI.reducer,
  [moodBoardsAPI.reducerPath]: moodBoardsAPI.reducer,
  [instagramAPI.reducerPath]: instagramAPI.reducer,
  [carStageFavoritePhotosAPI.reducerPath]: carStageFavoritePhotosAPI.reducer,
  [zipArchiveAPI.reducerPath]: zipArchiveAPI.reducer,
  [portfolioAPI.reducerPath]: portfolioAPI.reducer,
  [notificationsAPI.reducerPath]: notificationsAPI.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (state, action) => {
  if (LOGOUT.includes(action.type)) {
    const { authUser } = state;
    state = { authUser };
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      clientsAPI.middleware,
      searchAPI.middleware,
      timelineAPI.middleware,
      announcementsAPI.middleware,
      authUserClientAPI.middleware,
      photosAPI.middleware,
      eventsAPI.middleware,
      eventAttendeesAPI.middleware,
      adminsAPI.middleware,
      legalDocumentsAPI.middleware,
      disclaimersAPI.middleware,
      authUserAdminAPI.middleware,
      partnersAPI.middleware,
      carStagesTimelineAPI.middleware,
      carDocumentsAPI.middleware,
      carsAPI.middleware,
      inspirationToolPageContentAPI.middleware,
      inspirationToolProductCategoriesAPI.middleware,
      inspirationToolProductFamiliesAPI.middleware,
      inspirationToolProductStudiesAPI.middleware,
      stagesAPI.middleware,
      universalContentTimelineAPI.middleware,
      universalContentDashboardAPI.middleware,
      clientProfileAPI.middleware,
      clientAboutMeAPI.middleware,
      clientCollaboratorsAPI.middleware,
      moodBoardsAPI.middleware,
      instagramAPI.middleware,
      portfolioAPI.middleware,
      notificationsAPI.middleware,
      carStageFavoritePhotosAPI.middleware,
      zipArchiveAPI.middleware,
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
