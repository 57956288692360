import React, { FC, useCallback, useRef } from "react";
import { ClickAwayListener } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { dateTransform } from "utils/dateTransform";

import { setActiveStage, setActiveSubstage } from "redux/slices";
import { useAppDispatch } from "hooks/redux";
import { useLazyGetStagesQuery } from "redux/query/stagesAPI";

import { Loader } from "components";

import { SearchResultsProps } from "./typed";
import {
  LoaderWrapper,
  NoResults,
  ResultItem,
  ResultsList,
  ResultsWrapper,
  ResultTitle,
} from "./styled";

export const SearchResults: FC<SearchResultsProps> = ({
  data,
  isFetching,
  search,
  setSearch,
}) => {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    setSearch("");
  }, []);

  const [getStages] = useLazyGetStagesQuery();

  const handleEventsDetails = (id: number) => {
    handleClose();
    navigate(`/events/details/${id}`);
  };

  const handleAnnouncementsDetails = (id: number) => {
    handleClose();
    navigate(`/announcements/${id}`);
  };

  const handleDocuments = useCallback(() => {
    handleClose();
    navigate(`/documents`);
  }, []);

  const handleGlobalPartners = useCallback(() => {
    handleClose();
    navigate(`/global_partners`);
  }, []);

  const handleMoodBoards = useCallback(() => {
    handleClose();
    navigate(`/specifications/mood-boards-library`);
  }, []);

  const handlePortfolio = useCallback(() => {
    handleClose();
    navigate(`/portfolio`);
  }, []);

  const handleRestorationStages = (id: number | string) => {
    handleClose();
    dispatch(setActiveStage(id));
    navigate(`/timeline`);
  };

  const handleRestorationSubstages = async (id: number | string) => {
    const stages = await getStages({}).unwrap();

    const stage = stages?.find(stage =>
      stage.substages.find(substage => substage.id === id),
    );
    handleClose();
    dispatch(setActiveStage(stage.id));
    dispatch(setActiveSubstage(id));
    navigate(`/timeline`);
  };

  const isAnnouncements = !!data?.announcement_results?.announcements?.length;

  const isEvents = !!data?.event_results?.events?.length;

  const isDocuments = !!data?.document_results?.documents?.length;

  const isGlobalPartners =
    !!data?.global_partner_results?.global_partners?.length;

  const isMoodBoards = !!data?.mood_board_results?.mood_boards?.length;

  const isStages = !!data?.restoration_stage_results?.stages?.length;

  const isSubstages = !!data?.restoration_substage_results?.stages?.length;

  const isPortfolios = !!data?.portfolio_results?.portfolio?.length;

  const isResults =
    isAnnouncements ||
    isEvents ||
    isDocuments ||
    isGlobalPartners ||
    isMoodBoards ||
    isStages ||
    isSubstages ||
    isPortfolios;
  if (!search) {
    return null;
  }

  return (
    <ClickAwayListener ref={ref} onClickAway={handleClose}>
      <ResultsList>
        {isFetching && (
          <ResultsWrapper>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </ResultsWrapper>
        )}

        {!isFetching && !!isResults && (
          <ResultsWrapper>
            {isAnnouncements && (
              <div>
                <ResultTitle>{"Announcements"}</ResultTitle>
                {data?.announcement_results?.announcements?.map(el => (
                  <ResultItem
                    key={el.id}
                    onClick={() => handleAnnouncementsDetails(el.id)}
                  >
                    <span>{el.title.replace(/(<([^>]+)>)/gi, "")}</span>
                    <span>{` (${dateTransform(el.published_date)})`}</span>
                  </ResultItem>
                ))}
              </div>
            )}

            {isEvents && (
              <div>
                <ResultTitle>{"Events"}</ResultTitle>
                {data?.event_results?.events?.map(el => (
                  <ResultItem
                    key={el.id}
                    onClick={() => handleEventsDetails(el.id)}
                  >
                    <span>{el.title}</span>
                    <span>{` (${dateTransform(el.start_date)} - ${dateTransform(
                      el.end_date,
                    )})`}</span>
                  </ResultItem>
                ))}
              </div>
            )}

            {isDocuments && (
              <div>
                <ResultTitle>{"Documents"}</ResultTitle>
                {data?.document_results?.documents?.map(el => (
                  <ResultItem key={el.id} onClick={handleDocuments}>
                    <span>{el.name}</span>
                    <span>{` (${dateTransform(el.updated_at)})`}</span>
                  </ResultItem>
                ))}
              </div>
            )}

            {isGlobalPartners && (
              <div>
                <ResultTitle>{"Global Partners"}</ResultTitle>
                {data?.global_partner_results?.global_partners?.map(el => (
                  <ResultItem key={el.id} onClick={handleGlobalPartners}>
                    {el.business_name}
                  </ResultItem>
                ))}
              </div>
            )}

            {isMoodBoards && (
              <div>
                <ResultTitle>{"Mood Boards"}</ResultTitle>
                {data?.mood_board_results?.mood_boards?.map(el => (
                  <ResultItem key={el.id} onClick={handleMoodBoards}>
                    {el.name}
                  </ResultItem>
                ))}
              </div>
            )}

            {isStages && (
              <div>
                <ResultTitle>{"Restoration Stages"}</ResultTitle>
                {data?.restoration_stage_results?.stages?.map(el => (
                  <ResultItem
                    key={el.stage_id}
                    onClick={() => handleRestorationStages(el.stage_id)}
                  >
                    {el.stage_name}
                  </ResultItem>
                ))}
              </div>
            )}

            {isSubstages && (
              <div>
                <ResultTitle>{"Restoration Substages"}</ResultTitle>
                {data?.restoration_substage_results?.stages?.map(el => (
                  <ResultItem
                    key={el.stage_id}
                    onClick={() => handleRestorationSubstages(el.stage_id)}
                  >
                    {el.stage_name}
                  </ResultItem>
                ))}
              </div>
            )}

            {isPortfolios && (
              <div>
                <ResultTitle>{"Portfolios"}</ResultTitle>
                {data?.portfolio_results?.portfolio?.map(el => (
                  <ResultItem key={el.id} onClick={handlePortfolio}>
                    {el.file_name}
                  </ResultItem>
                ))}
              </div>
            )}
          </ResultsWrapper>
        )}

        {!isResults && !isFetching && !!search && (
          <ResultsWrapper>
            <NoResults>No results</NoResults>
          </ResultsWrapper>
        )}
      </ResultsList>
    </ClickAwayListener>
  );
};
