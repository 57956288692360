import React, { FC } from "react";
import { Checkbox, styled, CheckboxProps } from "@mui/material";

import { CheckboxUncheckedAdminIcon, CheckboxAdminIcon } from "icons";
import { theme } from "theme";

export const CheckboxStyled = styled(Checkbox)({
  padding: 0,
  margin: 0,
  width: "24px",
  height: "24px",
  "& .MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
    color: `${theme.color.primary["700"]}`,
  },
});

const CheckboxComp: FC<CheckboxProps> = props => (
  <Checkbox
    {...props}
    icon={<CheckboxUncheckedAdminIcon />}
    checkedIcon={<CheckboxAdminIcon />}
  />
);

export const CheckboxWithIcons = styled(CheckboxComp)({
  padding: 0,
  margin: 0,
  width: "24px",
  height: "24px",
});
