import React, { FC, MouseEvent } from "react";

import { TableHead as MaterialTableHead, Box, TableCell } from "@mui/material";

import { visuallyHidden } from "@mui/utils";
import { ArrowDownSolidIconBlack } from "icons";
import { CheckBox } from "components/Clients/ClientsForm";

import { TableCellCheckbox } from "../TableRow/styled";

import type { TableHeadProps } from "./typed";
import {
  TableRowStyled,
  StyledTableSortLabel,
  HeadingTableCell,
} from "./styled";

export const TableHead: FC<TableHeadProps> = ({
  order,
  orderBy,
  cells,
  align,
  fixed,
  documentsTable,
  moodBoardsTable,
  collaboratorsTable,
  withAction,
  withCheckBox,
  hideSortIcon,
  lastElementFullWidth,
  isSelectedAll,
  handleSelectAll,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <MaterialTableHead>
      <TableRowStyled
        documentsTable={documentsTable}
        moodBoardsTable={moodBoardsTable}
        collaboratorsTable={collaboratorsTable}
        lastElementFullWidth={lastElementFullWidth}
        withAction={withAction}
        handleSelectAll={!!handleSelectAll}
      >
        {withCheckBox ? (
          handleSelectAll ? (
            <TableCellCheckbox align="left" width={25}>
              <CheckBox
                tableHead
                marginBot={1}
                checked={isSelectedAll}
                onChange={handleSelectAll}
                value={isSelectedAll}
              />
            </TableCellCheckbox>
          ) : (
            <TableCell align="left" width={25} />
          )
        ) : null}

        {cells.map(({ id, disablePadding, label, width }, i) => (
          <HeadingTableCell
            fixed={fixed && i === 0}
            key={id}
            align={align}
            padding={disablePadding ? "none" : "normal"}
            sortDirection={orderBy === id ? order : false}
            width={width}
          >
            <StyledTableSortLabel
              collaboratorsTable={collaboratorsTable}
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={hideSortIcon ? null : createSortHandler(id)}
              hideSortIcon={hideSortIcon}
              IconComponent={ArrowDownSolidIconBlack}
            >
              {label}

              {orderBy === id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </HeadingTableCell>
        ))}

        {withAction ? <TableCell align="right" width={70}></TableCell> : null}
      </TableRowStyled>
    </MaterialTableHead>
  );
};

TableHead.defaultProps = {
  hideSortIcon: false,
  align: "left",
};
