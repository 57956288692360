import { endpoints } from "constant";

export const inspirationToolProductStudyUrlBuilder = {
  getInspirationToolProductStudies: function (): string {
    return endpoints.inspirationToolProductStudies;
  },
  getInspirationToolProductStudy: function (id: string | number): string {
    return `${endpoints.inspirationToolProductStudies}${id}/`;
  },
  createInspirationToolProductStudy: function (): string {
    return endpoints.inspirationToolProductStudies;
  },
  deleteInspirationToolProductStudy: function (id: string | number): string {
    return `${endpoints.inspirationToolProductStudies}${id}/`;
  },
};
