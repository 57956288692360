import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import { stagesUrlBuilder } from "./urlBuilder/stages";

export const stagesAPI = createApi({
  reducerPath: "stagesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Stages"],
  endpoints: build => ({
    getStages: build.query({
      query: arg => ({
        url: stagesUrlBuilder.getStages(arg?.study),
        method: "GET",
      }),
      providesTags: ["Stages"],
    }),
  }),
});

export const { useGetStagesQuery, useLazyGetStagesQuery } = stagesAPI;
