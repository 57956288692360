import React, { FC, useEffect, useRef, useState } from "react";

import { useGetStagesQuery } from "redux/query/stagesAPI";
import { activeComissionsSelector, сomissionsSelector } from "redux/selectors";
import { setActiveComission, setActiveStage } from "redux/slices";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { QUERY_OPTIONS } from "constant";
import { TabsArrowIcon } from "icons";
import { ComissionCard } from "components/Clients";

import {
  ActiveComissionWrapper,
  ComissionsGrid,
  ComissionsSlideContainer,
  EmptyGridItem,
  GridItem,
  IconContainter,
  TabsContainer,
} from "./styled";

export const ComissionsTabs: FC = () => {
  const dispatch = useAppDispatch();
  const activeComission = useAppSelector(activeComissionsSelector);
  const comissions = useAppSelector(сomissionsSelector);

  const { data: stages } = useGetStagesQuery({}, QUERY_OPTIONS);

  const contentRef = useRef(null);
  const currentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [firstElementWidth, setFirstElementWidth] = useState();

  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(60);

  const toggleList = () => {
    if (isOpen) {
      setContentWidth(0);
      setContentHeight(60);
    } else {
      setContentWidth(contentRef.current.scrollWidth);
      setContentHeight(contentRef.current.scrollHeight);
    }
    setIsOpen(!isOpen);
  };

  const setCar = (id: number): void => {
    const car = comissions?.find(car => car.id === id);

    const currentStage =
      stages?.length && stages?.find(stage => stage?.name === car?.stage);

    dispatch(setActiveStage(currentStage?.id));
    dispatch(setActiveComission(car));
  };

  const cars = comissions?.map(car => ({
    id: car?.id,
    labelTitle: car?.model,
    labelSubTitle: car?.name,
  }));

  const firstComission = isOpen
    ? cars[0]
    : {
        id: activeComission?.id,
        labelTitle: activeComission?.model,
        labelSubTitle: activeComission?.name,
      };

  const comissionsList = cars?.slice(1);

  useEffect(() => {
    if (firstComission) {
      setFirstElementWidth(currentRef?.current?.scrollWidth);
    }
  }, [firstComission]);

  if (!comissions?.length) {
    return null;
  }

  return (
    <TabsContainer>
      <ActiveComissionWrapper>
        <ComissionCard
          ref={currentRef}
          isActive={firstComission?.id === activeComission?.id}
          title={firstComission?.labelTitle}
          subTitle={firstComission?.labelSubTitle}
          handleClick={() => setCar(firstComission?.id)}
        />
        {!!comissionsList.length && (
          <IconContainter isOpen={isOpen} rotate={"180"} onClick={toggleList}>
            <TabsArrowIcon />
          </IconContainter>
        )}
      </ActiveComissionWrapper>

      <ComissionsSlideContainer
        isOpen={isOpen}
        itemsNumber={comissionsList?.length}
        contentWidth={contentWidth}
        contentHeight={contentHeight}
      >
        <ComissionsGrid ref={contentRef}>
          <EmptyGridItem width={firstElementWidth} />
          {comissionsList.map((car, idx) => (
            <GridItem key={car.id}>
              <ComissionCard
                isActive={car?.id === activeComission?.id}
                title={car?.labelTitle}
                subTitle={car?.labelSubTitle}
                handleClick={() => setCar(car.id)}
              />
              {idx === comissionsList.length - 1 && (
                <IconContainter onClick={toggleList}>
                  <TabsArrowIcon />
                </IconContainter>
              )}
            </GridItem>
          ))}
        </ComissionsGrid>
      </ComissionsSlideContainer>
    </TabsContainer>
  );
};
