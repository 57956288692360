import { Checkbox, FormControlLabel, styled, Tooltip } from "@mui/material";

import { theme } from "theme";

export const StyledCheckbox = styled(Checkbox)<{
  orange?: boolean;
  checked?: boolean;
  label?: string;
}>(() => ({
  padding: 0,
  margin: 0,
  width: "20px",
  height: "20px",
  borderRadius: "4px",
}));

export const CheckBoxContainer = styled("div")<{ marginBot?: number }>(
  ({ marginBot }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: "20px",

    ...(marginBot && {
      marginBottom: `${marginBot}px`,
    }),
  }),
);

export const Label = styled("label")({
  fontSize: theme.fontSize.base,
  fontWeight: theme.fontWeight.regular,
  lineHeight: "140%",
  color: theme.color.grey[900],
});

export const CheckBoxLabelWrapper = styled("div")({
  marginLeft: "10px",
});

export const Description = styled("div")({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.base,
  lineHeight: "140%",
  color: theme.color.grey[400],
  marginLeft: "27px",
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: "0px !important",

  "& .MuiFormControlLabel-root": {
    marginLeft: "0px !important",
  },
  "& .MuiFormControlLabel-label": {
    fontSize: theme.fontSize.md,
    fontWeight: theme.fontWeight.light,
    lineHeight: "140%",
    color: theme.color.grey[900],
    marginLeft: "7px",
    whiteSpace: "nowrap",
  },
});

export const StyledTooltip = styled(Tooltip)({
  tooltip: {
    bgcolor: theme.color.grey[100],
    color: theme.color.black,

    "& .MuiTooltip-arrow": {
      color: theme.color.grey[100],
    },
  },
});
