import { createApi } from "@reduxjs/toolkit/query/react";

import { ISearchResults } from "types";

import { axiosBaseQuery } from "utils/baseQuery";

import { searchUrlBuilder } from "./urlBuilder/search";

export const searchAPI = createApi({
  reducerPath: "searchAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Search"],
  endpoints: build => ({
    getSearchResults: build.query<
      ISearchResults,
      { search: string; carId: string | number }
    >({
      query: args => ({
        url: searchUrlBuilder.getSearchResults(args),
        method: "GET",
      }),
      providesTags: ["Search"],
    }),
  }),
});

export const { useLazyGetSearchResultsQuery } = searchAPI;
