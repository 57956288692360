import { endpoints } from "constant";

import type { MoodBoardQueryParams, MoodBoard } from "types";

export const moodBoardUrlBuilder = {
  getMoodBoards: function (carId: string | number): string {
    return `${endpoints.cars}${carId}/mood-boards/`;
  },
  getMoodBoard: function ({ carId, id }: MoodBoardQueryParams): string {
    return `${endpoints.cars}${carId}/mood-boards/${id}/`;
  },
  createMoodBoard: function ({ carId }: MoodBoard): string {
    return `${endpoints.cars}${carId}/mood-boards/`;
  },
  updateMoodBoard: function ({ carId, id }: MoodBoard): string {
    return `${endpoints.cars}${carId}/mood-boards/${id}/`;
  },
  deleteMoodBoard: function ({ carId, id }: MoodBoardQueryParams): string {
    return `${endpoints.cars}${carId}/mood-boards/${id}/`;
  },
};
