import { styled, TextField, Typography } from "@mui/material";

import { theme } from "theme";

export const Input = styled(TextField)<{
  padding?: string;
  height?: string;
  spacing?: string;
  dashed?: boolean;
  empty?: boolean;
}>(({ padding, height, spacing, dashed, empty }) => ({
  marginBottom: "20px",

  ...(spacing && {
    margin: spacing,
  }),

  "& input": {
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    border: empty
      ? "none"
      : dashed
      ? theme.color.borderDashed
      : theme.color.grey[500],
    ...(padding && {
      padding,
    }),
  },

  "& label": {
    color: theme.color.grey[500],
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.base,
    lineHeight: "140%",
  },
  "& label.Mui-focused": {
    color: theme.color.grey[500],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.color.grey[500],
  },
  "& .MuiOutlinedInput-root": {
    border: empty
      ? "none"
      : dashed
      ? theme.color.borderDashed
      : theme.color.grey[500],
    "& fieldset": {
      borderColor: empty
        ? "none"
        : dashed
        ? theme.color.borderDashed
        : theme.color.grey[500],
      borderStyle: empty ? "none" : dashed ? "dashed" : "solid",

      ...(height && { height }),
    },

    "&:hover fieldset": {
      borderColor: dashed ? theme.color.borderDashed : theme.color.grey[500],
      borderStyle: dashed ? "dashed" : "solid",
    },

    "&.Mui-focused fieldset": {
      borderColor: dashed ? theme.color.borderDashed : theme.color.grey[500],
      borderStyle: dashed ? "dashed" : "solid",
    },
  },
}));

export const InputLabel = styled("label")({
  display: "block",
  marginBottom: "10px",
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.base,
  lineHeight: "130%",
  color: theme.color.black,
});

export const ErrorMessage = styled(Typography)<{
  textLeft?: boolean;
  margin?: string;
}>(({ textLeft, margin }) => ({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.sm,
  lineHeight: "140%",
  textAlign: "center",

  ...(margin && {
    margin,
  }),

  ...(textLeft && {
    textAlign: "left",
  }),

  color: theme.color.error[600],
}));
