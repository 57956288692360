import { RootState } from "store";

export const getUserAdminToken = (state: RootState) =>
  state.authUser.userAdmin?.token;

export const getUserAdminRole = (state: RootState) =>
  state.authUser.userAdmin?.role;

export const isUserAdminAuthenticated = (state: RootState) =>
  Boolean(state.authUser.userAdmin?.token);

export const isUserAdmin = (state: RootState) =>
  state.authUser.userAdmin?.role === "admin";

export const isAdminViewSelected = (state: RootState) =>
  state.authUser.adminView === "admin";

export const getUserClientToken = (state: RootState) =>
  state.authUser.userClient?.token;

export const getUserClientRole = (state: RootState) =>
  state.authUser.userClient?.role;

export const isUserClientAuthenticated = (state: RootState) =>
  Boolean(state.authUser.userClient?.token);

export const getCurrentUser = (state: RootState) => state.authUser.currentUser;

export const getCollaboratorUserId = (state: RootState) =>
  state?.authUser?.userClient?.collaboratorClientId;

export const getCollaboratorPermissions = (state: RootState) =>
  state?.authUser?.userClient?.collaboratorPermissions;

export const getCurrentUserToken = (state: RootState) =>
  getCurrentUser(state) === "admin"
    ? getUserAdminToken(state)
    : getUserClientToken(state);
