import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const PdfIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="119"
    height="120"
    viewBox="0 0 119 120"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <g clipPath="url(#clip0_16599_334521)">
        <path
          d="M43.3217 70.8806C43.3217 69.5679 42.4141 68.7852 40.8128 68.7852C40.1589 68.7852 39.716 68.8499 39.4844 68.9119V73.1234C39.7586 73.1853 40.0958 73.206 40.5606 73.206C42.2674 73.206 43.3217 72.3406 43.3217 70.8806Z"
          fill="white"
        />
        <path
          d="M53.2281 68.8281C52.511 68.8281 52.0475 68.8903 51.7734 68.9547V78.282C52.0477 78.3465 52.4906 78.3465 52.8909 78.3465C55.8001 78.3672 57.6975 76.7601 57.6975 73.3549C57.7181 70.3937 55.9891 68.8281 53.2281 68.8281Z"
          fill="white"
        />
        <path
          d="M71.6498 20.0273H40.0166C35.3953 20.0273 31.6341 23.8046 31.6341 28.4397V59.5471H30.8153C28.9502 59.5471 27.4375 61.0636 27.4375 62.9371V83.4953C27.4375 85.3685 28.95 86.8849 30.8153 86.8849H31.6341V90.6546C31.6341 95.2948 35.3953 99.067 40.0166 99.067H83.1787C87.797 99.067 91.5588 95.2947 91.5588 90.6546V39.9371L71.6498 20.0273ZM36.299 66.6471C37.2889 66.4793 38.6804 66.3527 40.6408 66.3527C42.622 66.3527 44.034 66.7322 44.9828 67.4945C45.8891 68.2129 46.5007 69.3986 46.5007 70.794C46.5007 72.1891 46.0372 73.3752 45.194 74.1786C44.0972 75.2147 42.4753 75.6798 40.5779 75.6798C40.1556 75.6798 39.7771 75.6588 39.4811 75.6177V80.7154H36.299V66.6471ZM83.1787 93.9152H40.0166C38.2272 93.9152 36.7701 92.4528 36.7701 90.6546V86.8849H77.006C78.8715 86.8849 80.384 85.3685 80.384 83.4953V62.9371C80.384 61.0636 78.8715 59.5471 77.006 59.5471H36.7701V28.4397C36.7701 26.6466 38.2274 25.1843 40.0166 25.1843L69.7292 25.1531V36.175C69.7292 39.3943 72.3321 42.0089 75.5425 42.0089L86.3014 41.978L86.4224 90.6545C86.4224 92.4528 84.9679 93.9152 83.1787 93.9152ZM48.5526 80.6508V66.6471C49.7328 66.4586 51.2711 66.3527 52.8945 66.3527C55.5925 66.3527 57.342 66.8384 58.7129 67.8743C60.1881 68.975 61.1148 70.7294 61.1148 73.2483C61.1148 75.9767 60.1249 77.8603 58.7539 79.0228C57.258 80.2706 54.981 80.8625 52.1993 80.8625C50.5334 80.8625 49.3532 80.7565 48.5526 80.6508ZM71.5573 72.3598V74.9821H66.4557V80.7154H63.2311V66.4586H71.915V69.1016H66.4557V72.3598H71.5573Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_16599_334521">
        <rect
          width="78.7598"
          height="79.0398"
          fill="white"
          transform="translate(20.1172 20.0273)"
        />
      </clipPath>
    </defs>
  </svg>
);
