import React, { FC } from "react";

import { SvgIconConstituentValues } from "./typed";

export const RemoveIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.671 4.32861L4.32812 19.6715"
      stroke="white"
      strokeOpacity="0.8"
    />
    <path
      d="M19.671 19.6715L4.32812 4.32861"
      stroke="white"
      strokeOpacity="0.8"
    />
  </svg>
);
