import React, { FC, useEffect, useState } from "react";

import { VideoTypes, processVideoUrl } from "utils/processVideoUrl";

import {
  BackGroundOverlay,
  BackVideoContainer,
  BackgroundVideoEmbed,
  CopiesContainer,
  Description,
  IframeContaner,
  ScrollIcon,
  ScrollIconBackground,
  ScrollIconContainer,
  ScrollIconWrapper,
  StyledSection,
  Title,
  VideoContainer,
} from "./styled";
import { Dimensions, PageHeadingVideoProps } from "./typed";

const calculateVideoDimensions = () => {
  const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;

  const videoHeight = (viewportWidth / 16) * 9;
  const videoWidth = viewportWidth;

  if (viewportWidth < 1024) {
    return {
      height: (videoWidth / 16) * 9,
      width: videoWidth,
    };
  }

  if (viewportHeight >= videoHeight) {
    return {
      height: viewportHeight,
      width: (viewportHeight / 9) * 16,
    };
  } else {
    return {
      height: (videoWidth / 16) * 9,
      width: videoWidth,
    };
  }
};

export const PageHeadingVideo: FC<PageHeadingVideoProps> = ({
  isScrollIcon,
  url,
}) => {
  const [dimensions, setDimensions] = useState<null | Dimensions>(null);

  const handleResize = () => {
    setDimensions(calculateVideoDimensions());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const media = processVideoUrl(url);

  return (
    <StyledSection>
      <BackVideoContainer>
        <BackgroundVideoEmbed />

        {media?.type === VideoTypes.Vimeo && (
          <IframeContaner
            src={`https://player.vimeo.com/video/${media.id}?h=474b22cc16&muted=1&autoplay=1&loop=1&transparent=0&background=1&controls=1&app_id=122963`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            data-ready="true"
            height={dimensions?.height}
            width={dimensions?.width}
          />
        )}

        {media?.type === VideoTypes.S3 && (
          <VideoContainer
            src={media.url}
            autoPlay
            muted
            loop
            height={dimensions?.height}
            width={dimensions?.width}
          >
            <source src={media.url} />
          </VideoContainer>
        )}

        {isScrollIcon && (
          <ScrollIconContainer>
            <ScrollIconWrapper>
              <ScrollIcon />
              <ScrollIconBackground />
            </ScrollIconWrapper>
          </ScrollIconContainer>
        )}

        <CopiesContainer>
          <Description>SINCE 2009</Description>

          <Title>DEFINITIVE DESIGN</Title>
        </CopiesContainer>
      </BackVideoContainer>

      <BackGroundOverlay />
    </StyledSection>
  );
};
