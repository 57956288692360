import React, { FC } from "react";
import { Link } from "react-router-dom";

import { SearchIcon } from "icons";
import { LinkOutlined, BoxRow } from "styled";

import { InputSearch } from "../components";
import type { HeaderSearchProps } from "./typed";

export const HeaderSearch: FC<HeaderSearchProps> = ({
  placeholder,
  search,
  startIcon,
  path,
  buttonTitle,
  isAdminView,
  setSearch,
}) => (
  <BoxRow>
    <InputSearch
      placeholder={placeholder}
      StartIcon={SearchIcon}
      value={search}
      changeHandler={setSearch}
    />
    {isAdminView ? (
      <LinkOutlined startIcon={startIcon} component={Link} to={path}>
        {buttonTitle}
      </LinkOutlined>
    ) : null}
  </BoxRow>
);

HeaderSearch.defaultProps = {
  placeholder: "Search",
};
