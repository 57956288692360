import React, { FC } from "react";

import { theme } from "theme";

import type { SvgIconConstituentValues } from "./typed";

export const NewsPaperFoldIcon: FC<SvgIconConstituentValues> = ({
  strokeColor,
  ...rest
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...rest}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8326 11.333C10.8326 11.5172 10.6832 11.6666 10.499 11.6666H7.16362C6.97941 11.6666 6.83008 11.5172 6.83008 11.333V7.9976C6.83008 7.81339 6.97941 7.66406 7.16362 7.66406H10.499C10.6832 7.66406 10.8326 7.81339 10.8326 7.9976V11.333Z"
      stroke={strokeColor}
    />
    <path d="M12.166 7.66406H14.1673" stroke={strokeColor} />
    <path d="M12.166 9.66602H14.1673" stroke={strokeColor} />
    <path d="M12.166 11.667H14.1673" stroke={strokeColor} />
    <path d="M6.83008 13.668H14.168" stroke={strokeColor} />
    <path d="M6.83008 15.6689H14.168" stroke={strokeColor} />
    <path
      d="M18.1698 19.0048H6.82938C5.72412 19.0048 4.82812 18.1089 4.82812 17.0036V5.66318C4.82812 5.29476 5.12679 4.99609 5.49521 4.99609H15.5015C15.8699 4.99609 16.1685 5.29476 16.1685 5.66318V17.0036C16.1685 18.1089 17.0645 19.0048 18.1698 19.0048C19.2751 19.0048 20.171 18.1089 20.171 17.0036V6.99734"
      stroke={strokeColor}
    />
    <path d="M18.1699 6.99707V17.3369" stroke={strokeColor} />
  </svg>
);

NewsPaperFoldIcon.defaultProps = {
  strokeColor: theme.color.white,
};
