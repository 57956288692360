import { endpoints } from "constant";

export const portfolioUrlBuilder = {
  getPortfolio: function ({
    id,
    ordering = "stage_id",
  }: {
    id?: string | number;
    ordering?: string;
  } = {}): string {
    return `${endpoints.portfolio}${id}/stages-photos/?ordering=${ordering}`;
  },

  setDislikePhoto: function (): string {
    return `${endpoints.likeDislikePhoto}`;
  },

  setDraggedPhoto: function ({
    carId,
    stageId,
  }: {
    carId?: string | number;
    stageId?: string | number;
  } = {}): string {
    return `${endpoints.portfolio}${carId}/stages/${stageId}/photos-rearrange/`;
  },

  portfoliosPdf: function ({
    carId,
  }: {
    carId?: string | number;
  } = {}): string {
    return `${endpoints.cars}${carId}/portfolios/`;
  },

  portfolioPdf: function ({
    carId,
    id,
  }: {
    carId?: string | number;
    id?: string | number;
  } = {}): string {
    return `${endpoints.cars}${carId}/portfolios/${id}/`;
  },

  deletePdf: function ({
    carId,
    portfolioId,
  }: {
    carId?: string | number;
    portfolioId?: string | number;
  } = {}): string {
    return `${endpoints.cars}${carId}/portfolios/${portfolioId}/`;
  },
};
