import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CalendarIcon: FC<SvgIconConstituentValues> = ({
  strokeColor,
  ...rest
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...rest}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.84375"
      y="5.73633"
      width="15.3139"
      height="13.9217"
      rx="1"
      stroke={strokeColor}
    />
    <path d="M6.93164 4.34375V6.43201" stroke={strokeColor} />
    <path d="M9.7168 4.34375V6.43201" stroke={strokeColor} />
    <path d="M12.5 4.34375V6.43201" stroke={strokeColor} />
    <path d="M15.2852 4.34375V6.43201" stroke={strokeColor} />
    <path d="M18.0703 4.34375V6.43201" stroke={strokeColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1961 9.21582H15.2843V12.0002H12.5V9.91191C12.5 9.52747 12.8116 9.21582 13.1961 9.21582Z"
      stroke={strokeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2852 9.21582H17.3734C17.7579 9.21582 18.0695 9.52747 18.0695 9.91191V12.0002H15.2852V9.21582Z"
      stroke={strokeColor}
    />
    <rect
      x="12.5"
      y="12.001"
      width="2.78435"
      height="2.78435"
      stroke={strokeColor}
    />
    <rect
      x="15.2852"
      y="12.001"
      width="2.78435"
      height="2.78435"
      stroke={strokeColor}
    />
    <rect
      x="12.5"
      y="14.7852"
      width="2.78435"
      height="2.78435"
      stroke={strokeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2852 14.7842H18.0695V16.8724C18.0695 17.2569 17.7579 17.5685 17.3734 17.5685H15.2852V14.7842Z"
      stroke={strokeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62773 12H9.71599V14.7843H6.93164V12.6961C6.93164 12.3116 7.24329 12 7.62773 12Z"
      stroke={strokeColor}
    />
    <rect
      x="9.7168"
      y="12.001"
      width="2.78435"
      height="2.78435"
      stroke={strokeColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93164 14.7842H9.71599V17.5685H7.62773C7.24329 17.5685 6.93164 17.2569 6.93164 16.8724V14.7842Z"
      stroke={strokeColor}
    />
    <rect
      x="9.7168"
      y="14.7852"
      width="2.78435"
      height="2.78435"
      stroke={strokeColor}
    />
  </svg>
);

CalendarIcon.defaultProps = {
  strokeColor: "white",
};
