import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/baseQuery";
import { notificationsURLBuilder } from "./urlBuilder/notifications";
import { INotificationsReq, INotificationsRes } from "types/Notifications";

export const notificationsAPI = createApi({
  reducerPath: "notificationsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["Notifications"],
  endpoints: build => ({
    getNotifications: build.query<INotificationsRes, INotificationsReq>({
      query: () => ({
        url: notificationsURLBuilder.getNotifications(),
        method: "GET",
      }),
      providesTags: ["Notifications"],
    }),
    deleteNotification: build.mutation<INotificationsRes, { id: number }>({
      query: args => ({
        url: notificationsURLBuilder.deleteNotification(args.id),
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const { useGetNotificationsQuery, useDeleteNotificationMutation } =
  notificationsAPI;
