import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LayersBackIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9748 4.84473H16.5882C16.9873 4.84473 17.3108 5.16828 17.3108 5.5674V16.4075C17.3108 16.8066 16.9873 17.1302 16.5882 17.1302H5.74806C5.34894 17.1302 5.02539 16.8066 5.02539 16.4075V12.7941"
      stroke={props.strokeColor ?? "black"}
    />
    <rect
      x="0.689453"
      y="0.508789"
      width="12.2855"
      height="12.2855"
      rx="1"
      stroke={props.strokeColor ?? "black"}
    />
  </svg>
);
