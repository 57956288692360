import { endpoints } from "constant";

export const inspirationToolPageContentUrlBuilder = {
  getInspirationToolPageContents: function (): string {
    return endpoints.inspirationToolPageContent;
  },
  getInspirationToolPageContent: function (id: string | number): string {
    return `${endpoints.inspirationToolPageContent}${id}/`;
  },
  updateInspirationToolPageContent: function (id: string | number): string {
    return `${endpoints.inspirationToolPageContent}${id}/`;
  },
};
