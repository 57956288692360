import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "./redux";
import { ADMIN_LOGIN, CLIENT_LOGIN, ROLES } from "constant";
import {
  isUserAdminAuthenticated,
  isUserClientAuthenticated,
} from "redux/selectors";
import { useRememberMe } from "./useRememberMe";
import { userAdminLogin, userClientLogin } from "redux/slices";
import { useDispatch } from "react-redux";

export const useProtectedRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { getToken, getGpnToken } = useRememberMe();

  const isAdminPage = pathname.includes("admin");

  const isAuthenticated = useAppSelector(
    isAdminPage ? isUserAdminAuthenticated : isUserClientAuthenticated,
  );

  useEffect(() => {
    if (isAuthenticated) return;

    if (isAdminPage) {
      if (getGpnToken()) {
        dispatch(
          userAdminLogin({ role: ROLES.GlobalPartner, token: getGpnToken() }),
        );
      } else {
        navigate(ADMIN_LOGIN);
      }
      return;
    }
    if (getToken()) {
      dispatch(userClientLogin({ token: getToken() }));
    } else {
      navigate(CLIENT_LOGIN);
    }
  }, [isAuthenticated]);
};
