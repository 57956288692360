import { Typography, styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const ClientsPageTitle = styled(Typography)<{
  marginTop?: number;
  marginBot?: number;
}>(({ marginBot, marginTop }) => ({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.pageTitle,
  lineHeight: "120%",
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  letterSpacing: "-0.01em",
  textTransform: "uppercase",
  color: theme.color.black,
  marginTop: "10px",
  marginBottom: "26px",

  ...(marginBot && {
    marginBottom: `${marginBot}px`,
  }),
  ...(marginTop && {
    marginTop: `${marginTop}px`,
  }),
  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: theme.fontSize.pageTitleSM,
  },
}));

export const ClientsPageThinTitle = styled(Typography)({
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  fontWeight: theme.fontWeight.regular,
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: theme.fontSize["5xl"],
  lineHeight: "62px",
  color: theme.color.black,
  padding: "20px 0",
});

export const ClientsPageThinDescription = styled(Typography)({
  width: "100%",
  maxWidth: "900px",
  margin: "0 auto",
  fontWeight: theme.fontWeight.light,
  textAlign: "center",
  fontSize: theme.fontSize.md,
  lineHeight: "140%",
  color: theme.color.black,
});

export const ErrorText = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: "0.75rem",
  lineHeight: 1.66,
  color: "#d32f2f",
  margin: "3px 14px 0",
});

export const BlockTitleTypography = styled(Typography)({
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  fontSize: theme.fontSize["5xl"],
  fontWeight: theme.fontWeight.regular,
  lineHeight: "58px",
  textTransform: "uppercase",
  letterSpacing: "0px",
  color: theme.color.black,

  [BreakPointTheme.breakpoints.down("md")]: {
    fontWeight: theme.fontWeight.light,
    fontSize: "44px",
    lineHeight: "58px",
  },
});

export const BlockDescriptionTypography = styled(Typography)({
  fontSize: theme.fontSize.md,
  fontWeight: theme.fontWeight.light,
  lineHeight: "22px",
  letterSpacing: "0em",
  color: theme.color.black,

  [BreakPointTheme.breakpoints.down("md")]: {
    fontWeight: theme.fontWeight.light,
    fontSize: theme.fontSize.md,
  },
});
