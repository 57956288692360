import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { Container } from "../styled";

export const TextContainer = styled("div")<{ reversed?: boolean }>(
  ({ reversed }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    width: "48%",
    boxSizing: "border-box",
    padding: "65px 10px 18px 10px",

    a: {
      fontSize: theme.fontSize.lg,
      paddingBottom: "40px",

      [BreakPointTheme.breakpoints.down("lg")]: {
        fontSize: theme.fontSize.md,
      },
    },

    [BreakPointTheme.breakpoints.down(1439)]: {
      paddingLeft: "4%",
    },

    ...(reversed && {
      paddingLeft: "2%",

      [BreakPointTheme.breakpoints.down(1439)]: {
        paddingLeft: "1%",
      },

      [BreakPointTheme.breakpoints.down("lg")]: {
        paddingLeft: "5px",
      },
    }),

    [BreakPointTheme.breakpoints.down("lg")]: {
      paddingTop: "34px",
      paddingBottom: "10px",

      paddingLeft: "5px",
    },
  }),
);

export const TopicContainer = styled(Container)<{ reversed?: boolean }>(
  ({ reversed }) => ({
    display: "flex",
    maxWidth: "1390px",

    margin: "0 auto 140px",

    [BreakPointTheme.breakpoints.down("lg")]: {
      maxWidth: "732px",
      margin: "0 auto 95px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      display: "none",
    },

    ...(reversed
      ? {
          flexDirection: "row-reverse",
        }
      : {
          flexDirection: "row",
        }),
    justifyContent: "space-between",
  }),
);
