import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AuthUserAdmin, AuthUserClient } from "types";

interface AuthUserState {
  userAdmin: AuthUserAdmin;
  userClient: AuthUserClient;
  currentUser: string;
  adminView: string;
}

const initialState: AuthUserState = {
  userAdmin: null,
  userClient: null,
  currentUser: "",
  adminView: "staff",
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.currentUser = action.payload;
    },
    setAdminView: (state, action: PayloadAction<string>) => {
      state.adminView = action.payload;
    },
    userAdminLogin: (state, action: PayloadAction<AuthUserAdmin>) => {
      state.userAdmin = action.payload;
      state.adminView = action.payload?.role;
    },
    updateAdminUser: (state, action: PayloadAction<AuthUserAdmin>) => {
      state.userAdmin = {
        ...state.userAdmin,
        ...action.payload,
      };
      state.adminView =
        action.payload?.role === "staff" ? "staff" : state.adminView;
    },
    userAdminLogout: state => {
      state.userAdmin = null;
      state.adminView = "staff";
    },
    userClientLogin: (state, action: PayloadAction<AuthUserClient>) => {
      state.userClient = action.payload;
    },
    userClientLogout: state => {
      state.userClient = null;
    },
  },
});

export const {
  setCurrentUser,
  setAdminView,
  userAdminLogin,
  updateAdminUser,
  userAdminLogout,
  userClientLogin,
  userClientLogout,
} = authUserSlice.actions;
export const authUserSliceReducer = authUserSlice.reducer;
