import React, { FC, MouseEvent } from "react";
import { Box, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { ArrowDownSolidIconBlack } from "icons";
import { theme } from "theme";

import { TableRowStyled, TableCellStyled, MaterialTableHead } from "./styled";
import type { TableHeadProps } from "./typed";

export const TableHead: FC<TableHeadProps> = ({
  order,
  orderBy,
  cells,
  withAction,
  align,
  hideSortIcon,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <MaterialTableHead>
      <TableRowStyled>
        {cells.map(
          ({ id, disablePadding, label, width, minWidth, disableSort }) => (
            <TableCellStyled
              key={id}
              align={align}
              padding={disablePadding ? "none" : "normal"}
              sortDirection={orderBy === id ? order : false}
              width={width}
              minWidth={minWidth}
            >
              {disableSort ? (
                <span />
              ) : (
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : "desc"}
                  onClick={hideSortIcon ? null : createSortHandler(id)}
                  hideSortIcon={hideSortIcon}
                  disabled={hideSortIcon}
                  IconComponent={props => (
                    <ArrowDownSolidIconBlack
                      {...props}
                      fill={theme.color.white}
                    />
                  )}
                >
                  {label}
                  {orderBy === id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCellStyled>
          ),
        )}
        {withAction ? (
          <TableCellStyled width="10%">
            <span>Action</span>
          </TableCellStyled>
        ) : null}
      </TableRowStyled>
    </MaterialTableHead>
  );
};

TableHead.defaultProps = {
  hideSortIcon: false,
  align: "left",
};
