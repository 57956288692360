import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface studyState {
  study: string;
}

const initialState: studyState = {
  study: "classic",
};

export const studySelector = createSlice({
  name: "study",
  initialState,
  reducers: {
    setStudyName: (state, action: PayloadAction<string>) => {
      state.study = action.payload;
    },
  },
});

export const { setStudyName } = studySelector.actions;
export const studySliceReducer = studySelector.reducer;
