import React, { ComponentType, lazy } from "react";
import {
  BookOpenIcon,
  CalendarIcon,
  DashboardIcon,
  FileDoubleIcon,
  LawIcon,
  NewsPaperFoldIcon,
  SvgIconConstituentValues,
  TaskListEditIcon,
  WenchIcon,
} from "icons";

const lazyRetry = function (componentImport) {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed"),
    );

    componentImport()
      .then(component => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};

export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_HOME_ROUTE = "/admin/clients";

export const ADMIN_ROUTES = {
  LOGIN: {
    exact: true,
    path: "/admin/login",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Login/Main")),
    ),
    protectedRoute: false,
  },
  GPN_NEW_PASSWORD: {
    exact: true,
    path: "/admin/new_password",
    title: "Create New Password",
    description:
      "Your password must include more than 8 characters, at least one Capital Letter, and a number.",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Login/CreateNewPassword")),
    ),
    protectedRoute: false,
  },
  GPN_RESET_PASSWORD: {
    exact: true,
    path: "/admin/reset_password",
    title: "Reset Password",
    description:
      "Please enter your email address below, and you will receive an email with instructions on how to reset your password",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Login/ResetPassword")),
    ),
    protectedRoute: false,
  },
  CLIENTS: {
    exact: true,
    path: "/admin/clients",
    menuItem: "CLIENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Clients/Main")),
    ),
    protectedRoute: true,
    gpnAccess: true,
  },
  CLIENT_VIEW: {
    exact: true,
    path: "/admin/clients/:id",
    menuItem: "CLIENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Clients/View")),
    ),
    protectedRoute: true,
    gpnAccess: true,
  },
  UNIVERSAL: {
    exact: true,
    path: "/admin/universal",
    menuItem: "UNIVERSAL",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/UniversalContent/Main")),
    ),
    protectedRoute: true,
  },
  UNIVERSAL_STAGE: {
    exact: true,
    path: "/admin/universal/:study/:id",
    menuItem: "UNIVERSAL",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/UniversalContent/View/Stage")),
    ),
    protectedRoute: true,
  },
  EVENTS: {
    exact: true,
    path: "/admin/events",
    menuItem: "EVENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Events/Main")),
    ),
    gpnAccess: true,
    protectedRoute: true,
  },
  VIEW_EVENT: {
    exact: true,
    path: "/admin/events/view/:id",
    menuItem: "EVENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Events/View")),
    ),
    gpnAccess: true,
    protectedRoute: true,
  },
  CREATE_EVENT: {
    exact: true,
    path: "/admin/events/create",
    menuItem: "EVENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Events/Create")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  UPDATE_EVENT: {
    exact: true,
    path: "/admin/events/update/:id",
    menuItem: "EVENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Events/Update")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  ANNOUNCEMENTS: {
    exact: true,
    path: "/admin/announcements",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Announcements/Main")),
    ),
    protectedRoute: true,
  },
  VIEW_ANNOUNCEMENT: {
    exact: true,
    path: "/admin/announcements/view/:id",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Announcements/View")),
    ),
    protectedRoute: true,
  },
  CREATE_ANNOUNCEMENT: {
    exact: true,
    path: "/admin/announcements/create",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Announcements/Create")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  UPDATE_ANNOUNCEMENT: {
    exact: true,
    path: "/admin/announcements/update/:id",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Announcements/Update")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  INSPIRATION_TOOL: {
    exact: true,
    path: "/admin/inspiration_tool",
    menuItem: "INSPIRATION_TOOL",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/InspirationTool/Main")),
    ),
    protectedRoute: true,
  },
  VIEW_INSPIRATION_TOOL: {
    exact: true,
    path: "/admin/inspiration_tool/:studyId/:familyId",
    menuItem: "INSPIRATION_TOOL",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/InspirationTool/View")),
    ),
    protectedRoute: true,
  },
  PARTNERS: {
    exact: true,
    path: "/admin/partners",
    menuItem: "PARTNERS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Partners/Main")),
    ),
    protectedRoute: true,
  },
  VIEW_PARTNER: {
    exact: true,
    path: "/admin/partners/view/:id",
    menuItem: "PARTNERS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Partners/View")),
    ),
    protectedRoute: true,
  },
  CREATE_PARTNER: {
    exact: true,
    path: "/admin/partners/create",
    menuItem: "PARTNERS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Partners/Create")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  UPDATE_PARTNER: {
    exact: true,
    path: "/admin/partners/update/:id",
    menuItem: "PARTNERS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Partners/Update")),
    ),
    adminView: true,
    protectedRoute: true,
  },
  LEGAL_PAGES: {
    exact: true,
    path: "/admin/legal_pages",
    menuItem: "LEGAL_PAGES",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/LegalPages/Main")),
    ),
    protectedRoute: true,
  },
  ADMIN: {
    exact: true,
    path: "/admin/admin_page",
    menuItem: "ADMIN",
    Component: lazy(() =>
      lazyRetry(() => import("pages/AdminPages/Admin/Main")),
    ),
    adminView: true,
    protectedRoute: true,
  },
};

export const CLIENT_LOGIN = "/login";
export const CLIENT_HOME_ROUTE = "/home";

type ClientRoutes = {
  [key: string]: {
    exact?: boolean;
    name?: string;
    path: string;
    menuItem?: string;
    title?: string;
    description?: string;
    padding?: string;
    Icon?: React.FC<SvgIconConstituentValues>;
    Component?: React.FC;
  };
};

export const CLIENT_ROUTES: ClientRoutes = {
  MY_PROFILE: {
    exact: true,
    path: "/my_profile",
    menuItem: "MY_PROFILE",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/MyProfile/Main")),
    ),
    Icon: DashboardIcon,
  },
  COLLABORATORS: {
    exact: true,
    path: "/my_profile/collaborators",
    menuItem: "COLLABORATORS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Collaborators/Main")),
    ),
    Icon: DashboardIcon,
  },
  ADD_COLLABORATORS: {
    exact: true,
    path: "/my_profile/collaborators/add_collaborator",
    menuItem: "ADD_COLLABORATORS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Collaborators/Add")),
    ),
    Icon: DashboardIcon,
  },
  UPDATE_COLLABORATORS: {
    exact: true,
    path: "/my_profile/collaborators/edit_collaborator/:id",
    menuItem: "UPDATE_COLLABORATORS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Collaborators/Add")),
    ),
    Icon: DashboardIcon,
  },
  CLIENT_LEGAL_PAGES: {
    exact: true,
    path: "/legal_pages/:id",
    menuItem: "CLIENT_LEGAL_PAGES",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Legal/Main"))),
    Icon: TaskListEditIcon,
  },
  CLIENT_LOGIN: {
    exact: true,
    path: "/login",
    title: "Login",
    description: "Welcome to your Singer Client Portal.",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/LoginPages/Main")),
    ),
  },
  CLIENT_NEW_PASSWORD: {
    exact: true,
    path: "/new_password",
    title: "Create New Password",
    description:
      "Your password must include more than 8 characters, at least one Capital Letter, and a number.",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/LoginPages/CreateNewPassword")),
    ),
  },
  CLIENT_RESET_PASSWORD: {
    exact: true,
    path: "/reset_password",
    title: "Reset Password",
    description:
      "Please enter your email address below, and you will receive an email with instructions on how to reset your password",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/LoginPages/ResetPassword")),
    ),
  },
  CLIENT_GPN: {
    exact: true,
    path: "/gpn",
    title: "Global Partner Network",
    description:
      "Please select which client’s portal you would like to access. Note you only have access to one client’s portal per login.",
    padding: "176px 0 0",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/LoginPages/GlobalPartnerNetwork")),
    ),
  },
  CLIENT_COLLABORATOR: {
    exact: true,
    path: "/collaborator_network",
    title: "Collaborator Network",
    description:
      "Please select which client’s portal you would like to access. Note you only have access to one client’s portal per login.",
    padding: "176px 0 0",
    Component: lazy(
      () => import("pages/Client/LoginPages/CollaboratorPartnerNetwork"),
    ),
  },
  HOME: {
    name: "Home",
    exact: true,
    path: "/home",
    menuItem: "CLIENTS_HOME",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Home/Main"))),
    Icon: DashboardIcon,
  },
  EVENTS_MONTH: {
    exact: true,
    name: "Events",
    path: "/events/month",
    menuItem: "EVENTS_MONTH",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Events/Month"))),
    Icon: CalendarIcon,
  },
  EVENTS_YEAR: {
    exact: true,
    name: "Events",
    path: "/events/year",
    menuItem: "EVENTS_YEAR",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Events/Year"))),
    Icon: CalendarIcon,
  },
  EVENTS_DETAILS: {
    exact: true,
    name: "Events",
    path: "/events/details/:id",
    menuItem: "EVENTS_DETAILS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Events/Details")),
    ),
    Icon: CalendarIcon,
  },
  EVENTS_LIST: {
    exact: true,
    name: "Events",
    path: "/events/list",
    menuItem: "EVENTS_LIST",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Events/List"))),
    Icon: CalendarIcon,
  },
  TIMELINE: {
    exact: true,
    path: "/timeline",
    menuItem: "TIMELINE",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Timeline/Main")),
    ),
    Icon: LawIcon,
  },
  DOCUMENTS: {
    exact: true,
    path: "/documents",
    menuItem: "DOCUMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Documents/Main")),
    ),
    Icon: FileDoubleIcon,
  },
  PARTNERS: {
    exact: true,
    path: "/global_partners",
    menuItem: "PARTNERS",
    Component: lazy(() => lazyRetry(() => import("pages/Client/Partners"))),
    Icon: WenchIcon,
  },
  PORTFOLIO: {
    exact: true,
    path: "/portfolio",
    menuItem: "PORTFOLIO",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Portfolio/Main")),
    ),
    Icon: BookOpenIcon,
  },
  PORTFOLIO_DOCS: {
    exact: true,
    path: "/portfolio/docs",
    menuItem: "PORTFOLIO DOCS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Portfolio/View/PortfolioDocs")),
    ),
    Icon: BookOpenIcon,
  },
  ANNOUNCEMENTS: {
    exact: true,
    path: "/announcements/",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Announcements")),
    ),
    Icon: NewsPaperFoldIcon,
  },
  ANNOUNCEMENTS_DETAILS: {
    exact: true,
    path: "/announcements/:id",
    menuItem: "ANNOUNCEMENTS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Announcements/Details")),
    ),
    Icon: NewsPaperFoldIcon,
  },
  SPECIFICATIONS: {
    exact: true,
    path: "/specifications",
    menuItem: "SPECIFICATIONS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Specifications/Main")),
    ),
    Icon: TaskListEditIcon,
  },
  INSPIRATION_TOOL: {
    exact: true,
    path: "/specifications/inspiration-tool",
    menuItem: "SPECIFICATIONS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Specifications/InspirationTool")),
    ),
  },
  MOOD_BOARDS_LIBRARY: {
    exact: true,
    path: "/specifications/mood-boards-library",
    menuItem: "SPECIFICATIONS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Specifications/MoodBoards")),
    ),
  },
  MOOD_BOARD_PREVIEW: {
    exact: true,
    path: "/specifications/mood-board-preview/:carId/:id",
    menuItem: "SPECIFICATIONS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Specifications/MoodBoardPreview")),
    ),
  },
  MOOD_BOARD_CREATE: {
    exact: true,
    path: "/specifications/mood-board-preview/",
    menuItem: "SPECIFICATIONS",
    Component: lazy(() =>
      lazyRetry(() => import("pages/Client/Specifications/MoodBoardPreview")),
    ),
  },
};
