export const useRememberMe = () => {
  const getToken = (): string => {
    return localStorage.getItem("token");
  };
  const saveToken = (value: string): void => {
    localStorage.setItem("token", value);
  };

  const removeToken = (): void => {
    localStorage.removeItem("token");
    localStorage.removeItem("gpn-token");
  };

  const getGpnToken = (): string => {
    return localStorage.getItem("gpn-token");
  };

  const saveGpnToken = (value: string): void => {
    localStorage.setItem("gpn-token", value);
  };

  return {
    saveToken,
    removeToken,
    getToken,
    getGpnToken,
    saveGpnToken,
  };
};
