import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const PointCircleIcon: FC<SvgIconConstituentValues> = props => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2074 32.6859C25.6062 32.6859 32.4147 25.8527 32.4147 17.4235C32.4147 8.99434 25.6062 2.16113 17.2074 2.16113C8.80861 2.16113 2 8.99434 2 17.4235C2 25.8527 8.80861 32.6859 17.2074 32.6859Z"
        fill="white"
        stroke="black"
        strokeWidth="2.43"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
