import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const BookOpenIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4999 9.00631L19.1708 5.6709V15.5984C19.1706 15.851 19.0278 16.0819 18.8019 16.1948L12.4999 19.3461L6.198 16.1948C5.97204 16.0819 5.82924 15.851 5.8291 15.5984V5.6709L12.4999 9.00631Z"
      stroke="white"
    />
    <path d="M17.8359 4.6543L12.4993 7.35465L7.1626 4.6543" stroke="white" />
    <path d="M12.5 9.00684V19.3466" stroke="white" />
  </svg>
);
