import { endpoints } from "constant";

import type {
  InspirationToolProductCategoriesClientQueryParams,
  InspirationToolProductCategoriesQueryParams,
} from "types";

export const inspirationToolProductCategoryUrlBuilder = {
  getInspirationToolProductCategories: function ({
    ordering = "",
    family,
  }: InspirationToolProductCategoriesQueryParams): string {
    return `${endpoints.inspirationToolProductCategories}?family=${family}&ordering=${ordering}`;
  },
  getInspirationToolProductCategoriesClient: function ({
    ordering = "",
    family,
    carId,
  }: InspirationToolProductCategoriesClientQueryParams): string {
    return `${endpoints.inspirationToolProductCategoriesClient}${carId}?family=${family}&ordering=${ordering}`;
  },
  getInspirationToolProductCategory: function (id: string | number): string {
    return `${endpoints.inspirationToolProductCategories}${id}/`;
  },
  createInspirationToolProductCategory: function (): string {
    return endpoints.inspirationToolProductCategories;
  },
  updateInspirationToolProductCategory: function (id: string | number): string {
    return `${endpoints.inspirationToolProductCategories}${id}/`;
  },
  deleteInspirationToolProductCategory: function (id: string | number): string {
    return `${endpoints.inspirationToolProductCategories}${id}/`;
  },
  setPhotoInspirationToolProductCategory: function (): string {
    return `${endpoints.inspirationToolProductCategories}set_photo_category/`;
  },
  deletePhotosInspirationToolProductCategory: function (): string {
    return `${endpoints.inspirationToolProductCategories}remove_photos/`;
  },
};
