import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const PointAcceptIcon: FC<SvgIconConstituentValues> = props => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73438 7.59749L7.7336 13.6184L20.0755 1.43652"
        stroke="white"
        strokeWidth="2.48"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
