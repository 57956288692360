import { endpoints } from "constant";

export const authUrlBuilder = {
  authRequest: function (): string {
    return `${endpoints.microsoft}auth-request/`;
  },
  authCallback: function (): string {
    return `${endpoints.microsoft}auth-callback/`;
  },
};
