import React, { FC } from "react";

import { CircularProgress, Box } from "./styled";
import { ILoader } from "./typed";

export const Loader: FC<ILoader> = ({ size }) => {
  return (
    <Box size={size}>
      <CircularProgress size={size} />
    </Box>
  );
};
