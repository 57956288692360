import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const LogoutIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5409 7.33L14.5409 4.99521C14.5409 4.62679 14.2297 4.32813 13.8459 4.32813L6.20086 4.32813C5.81702 4.32813 5.50586 4.62679 5.50586 4.99521L5.50586 19.004C5.50586 19.3724 5.81702 19.671 6.20086 19.671L13.8459 19.671C14.2297 19.671 14.5409 19.3724 14.5409 19.004L14.5409 16.6692"
      stroke={props.strokeColor ?? "black"}
    />
    <path d="M21.4902 12L8.28516 12" stroke={props.strokeColor ?? "black"} />
    <path
      d="M18.0156 15.3349L21.4906 11.9995L18.0156 8.66406"
      stroke={props.strokeColor ?? "black"}
    />
  </svg>
);
