import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ArrowDownIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.991583 0.383338L7.17656 6.94419C7.22931 7.00017 7.30511 7.03223 7.38474 7.03223C7.46437 7.03223 7.54017 7.00017 7.59293 6.94419L13.7773 0.383339"
      stroke="black"
    />
  </svg>
);
