import { ITimeline, ITimelineDocument } from "./../../types/UniversalContent";
import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import {
  DeleteUniversalContentDoc,
  IGetTimeLine,
  PostTimeline,
  PostWelcomeStatement,
} from "types";
import { universalContentTimelineUrlBuilder } from "./urlBuilder/universalContentTimeline";

export const universalContentTimelineAPI = createApi({
  reducerPath: "universalContentTimelineAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["UniversalContentTimeline"],
  endpoints: build => ({
    getWelcomeStatement: build.query<any, ITimeline>({
      query: (arg?: ITimeline) => ({
        url: universalContentTimelineUrlBuilder.getWelcomeStatement(
          arg.stage_id,
          arg.car_model,
        ),
        method: "GET",
      }),
      providesTags: ["UniversalContentTimeline"],
    }),
    postWelcomeStatement: build.mutation<
      PostWelcomeStatement,
      PostWelcomeStatement
    >({
      query: (arg?: PostWelcomeStatement) => ({
        url: universalContentTimelineUrlBuilder.postWelcomeStatement(
          arg.id,
          arg.model,
        ),
        method: "POST",
        data: arg,
      }),
      invalidatesTags: ["UniversalContentTimeline"],
    }),
    getTimelineDocuments: build.query<ITimelineDocument[], ITimeline>({
      query: args => ({
        url: universalContentTimelineUrlBuilder.getTimelineDocuments(
          args.stage_id,
          args.car_model,
        ),
        method: "GET",
      }),
      providesTags: ["UniversalContentTimeline"],
    }),
    postTimelineDocuments: build.mutation({
      query: (arg?: any) => ({
        url: universalContentTimelineUrlBuilder.uploadTimelineDocumentsDocuments(
          arg.id,
          arg.car_model,
        ),
        method: "POST",
        data: arg.fileData,
      }),
      invalidatesTags: ["UniversalContentTimeline"],
    }),
    patchTimelineDocuments: build.mutation({
      query: (arg?: any) => ({
        url: universalContentTimelineUrlBuilder.updateTimelineDocumentsDocuments(
          arg.id,
          arg.car_model,
          arg.docId,
        ),
        method: "PATCH",
        data: arg.fileData,
      }),
      invalidatesTags: ["UniversalContentTimeline"],
    }),
    deleteTimelineDocuments: build.mutation({
      query: (arg?: DeleteUniversalContentDoc) => ({
        url: universalContentTimelineUrlBuilder.deleteTimelineDocumentsDocuments(
          arg.stage_id,
          arg.car_model,
          arg.id,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["UniversalContentTimeline"],
    }),
    getTimeline: build.query<IGetTimeLine, ITimeline>({
      query: args => ({
        url: universalContentTimelineUrlBuilder.getTimeline(
          args.stage_id,
          args.car_model,
        ),
        method: "GET",
      }),
      providesTags: ["UniversalContentTimeline"],
    }),
    postTimelinePreview: build.mutation<PostTimeline, PostTimeline>({
      query: (args?: PostTimeline) => ({
        url: universalContentTimelineUrlBuilder.postTimelinePreview(
          args.id,
          args.car_model,
        ),
        method: "POST",
        data: args,
      }),
      invalidatesTags: ["UniversalContentTimeline"],
    }),
  }),
});

export const {
  useGetWelcomeStatementQuery,
  useGetTimelineDocumentsQuery,
  useLazyGetTimelineDocumentsQuery,
  useGetTimelineQuery,
  usePostTimelineDocumentsMutation,
  usePatchTimelineDocumentsMutation,
  useDeleteTimelineDocumentsMutation,
  usePostTimelinePreviewMutation,
  usePostWelcomeStatementMutation,
} = universalContentTimelineAPI;
