import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Box, styled, Typography } from "@mui/material";

import { BreakPointTheme, theme } from "theme";
import { ArrowLeftIcon } from "icons";

export const AppSidebar = styled(Box)({
  height: "100%",
  left: 0,
  background: theme.color.sidebarBg,
});

export const SidebarWrapper = styled(Box)<{
  opened?: boolean;
  type: "left" | "right";
}>(({ opened, type }) => ({
  position: "fixed",
  minHeight: "100%",
  zIndex: 100,
  background: theme.color.sidebarClientBg,
  backdropFilter: opened ? "blur(20px) saturate(1.8)" : "none",
  visibility: opened ? "visible" : "hidden",
  transition: ".2s linear all",
  opacity: opened ? 1 : 0,
  maxWidth: opened ? "301px" : "0",
  width: "100%",
  top: "0",
  overflow: "auto",
  maxHeight: "100vh",

  ...(type === "left" && {
    left: 0,
  }),
  ...(type === "right" && {
    right: 0,
  }),

  [BreakPointTheme.breakpoints.down("md")]: {
    maxWidth: opened ? "100%" : "0",
  },
}));

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const Name = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: theme.fontSize.lg,
  lineHeight: "120%",
  letterSpacing: "-0.01em",
  color: theme.color.white,
  marginTop: "6px",
});

export const ButtonContainer = styled("div")({
  color: theme.color.grey["1300"],
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecoration: "none",
  textTransform: "uppercase",
  height: "100%",
  padding: "14px 39px 14px 0",
  fontSize: theme.fontSize.md,
  fontWeight: theme.fontWeight.regular,
  lineHeight: "24px",
  position: "relative",
  "&:hover": {
    color: theme.color.white,
  },
});

export const NotificationsIndicator = styled("div")({
  background: "#00B1A5",
  border: "1.5px solid #00B1A5",
  borderRadius: "5px",
  padding: "0px 4px",
  marginLeft: "8px",
});

export const NotificationCounter = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: theme.fontSize.sm,
  lineHeight: "130%",
  textAlign: "center",
  color: theme.color.black,
});

export const ManagerItem = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: theme.fontSize.md,
  lineHeight: "140%",
  color: theme.color.white,
  a: {
    color: theme.color.white,
    textDecoration: "none",
  },
  "&:not(last-child)": {
    marginBottom: "5px",
  },
});

export const ManagerItemLink = styled(ManagerItem)({
  cursor: "pointer",
});

export const NotificationsWrapper = styled("div")({
  width: "100%",
});

export const NotificationsContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const Notification = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "16px",
  "&:last-child": {
    paddingBottom: "0",
  },
});

export const NotificationInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  cursor: "pointer",
});

export const NotificationLabel = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "130%",
  color: theme.color.white,
  marginBottom: "8px",
  textTransform: "uppercase",
});

export const NotificationName = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "130%",
  color: theme.color.white,
  marginLeft: "20px",
  maxWidth: "200px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const SidebarLinks = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "0 20px",
  gap: "2px",
});

export const NavLinkWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const NavLinkOptions = styled("div")({
  marginTop: "2px",
  marginLeft: "20px",
  paddingBottom: "17px",
  display: "flex",
  flexDirection: "column",

  div: {
    marginBottom: "19px",
  },

  "& div:last-child": {
    marginBottom: "0",
  },
});

export const NavLinkOption = styled("div")<{
  isMobile?: boolean;
  isDesktop?: boolean;
  isActive?: number | boolean;
  isCollaborator?: boolean | string;
}>(({ isMobile, isActive, isDesktop, isCollaborator }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "15px",
  cursor: "pointer",

  ...(isMobile && {
    display: "none",
  }),
  ...(isActive && {
    p: {
      color: theme.color.orange,
      fontWeight: theme.fontWeight.extraBold900,
    },
  }),

  ...(isDesktop && {
    display: "none",
  }),
  ...(isCollaborator && {
    display: "block",
  }),
}));

export const NavLinkOptionName = styled(Typography)({
  fontStyle: "normal",
  fontFamily: "inherit",
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.md,
  lineHeight: "20px",
  textTransform: "uppercase",
  color: theme.color.white,
});

export const NavLink = styled(Link)({
  color: theme.color.grey["1300"],
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecoration: "none",
  textTransform: "uppercase",
  height: "100%",
  padding: "14px 39px 14px 0",
  fontSize: theme.fontSize.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  position: "relative",

  "&:hover": {
    color: theme.color.white,
    fontWeight: theme.fontWeight.extraBold900,
  },

  "&.active": {
    color: theme.color.primary["800"],
    fontWeight: theme.fontWeight.extraBold900,
  },
});

export const Arrow = styled(ArrowLeftIcon)<{
  opened?: boolean;
  sidebarOpened?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}>(({ opened, sidebarOpened }) => ({
  transform: `rotate(${opened ? "90deg" : "270deg"})`,
  top: "13px",
  width: "16px",
  marginLeft: "16px",
  right: sidebarOpened ? "14px" : "-5px",
}));

export const Divider = styled("div")({
  width: "100%",
});

export const Summary = styled(Typography)<{
  isActive?: boolean;
}>(({ isActive }) => ({
  color: theme.color.grey["1300"],
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecoration: "none",
  height: "100%",
  padding: "14px 39px 14px 0",
  fontSize: theme.fontSize.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  position: "relative",
  textTransform: "uppercase",
  fontFamily: "inherit",

  svg: {
    stroke: theme.color.white,
    marginRight: "4px",

    "path, rect": {
      stroke: theme.color.white,
    },
  },

  "&:hover": {
    color: theme.color.white,
    fontWeight: theme.fontWeight.extraBold900,
  },

  "&.active": {
    color: theme.color.primary["800"],
    fontWeight: theme.fontWeight.extraBold900,
  },

  ...(isActive && {
    color: theme.color.primary["800"],
    fontWeight: theme.fontWeight.extraBold900,
  }),
}));

export const SearchContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%",
  marginBottom: "10px",
});

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const CloseIconBox = styled(Box)<{
  type: "left" | "right";
}>(({ type }) => ({
  padding: "10px 26px 24px",
  cursor: "pointer",
  display: "flex",
  ...(type === "left" && {
    justifyContent: "flex-start",
  }),
  ...(type === "right" && {
    justifyContent: "flex-end",
  }),
}));
