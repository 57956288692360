import { styled, Box } from "@mui/material";

import { ButtonPlayIcon } from "icons";
import { theme } from "theme";

export const Wrapper = styled(Box)<{ playing: boolean }>(({ playing }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "650px",
  backgroundColor: theme.color.sidebarBg,
  margin: "0 -30px",
  position: "relative",
  div: {
    width: "100%",
  },
  "@media (max-width: 1024px)": {
    height: "350px",
  },

  svg: {
    position: "absolute",
  },

  video: {
    objectFit: "cover",
    "&::-webkit-media-controls": {
      display: playing ? "flex" : "none",
    },
  },
}));

export const Button = styled(ButtonPlayIcon)<{ onClick: () => void }>({
  cursor: "pointer",
});
