import React, { FC } from "react";

import { PointLineIcon } from "icons";

import {
  PointAccepted,
  PointCircle,
  PointContainer,
  PointLineWrapper,
  PointWrapper,
} from "./styled";

interface PointProps {
  isReversed: boolean;
  isCurrent: boolean;
  isCompleted: boolean;
  isSelected: boolean;
  pointName: boolean | string;
}

export const Point: FC<PointProps> = ({
  isReversed,
  isCurrent,
  isCompleted,
  isSelected,
  pointName,
}) => {
  const DELIVERED = "DELIVERED";
  return (
    <PointWrapper isReversed={isReversed}>
      <PointContainer isReversed={isReversed}>
        <PointCircle
          isCurrent={isCurrent}
          isCompleted={isCompleted}
          isSelected={isSelected}
          pointName={isCurrent && pointName === DELIVERED}
        />

        {isCompleted && <PointAccepted isReversed={isReversed} />}
        {isCurrent && pointName === DELIVERED && (
          <PointAccepted isReversed={isReversed} />
        )}

        <PointLineWrapper isReversed={isReversed} isSelected={isSelected}>
          <PointLineIcon />
        </PointLineWrapper>
      </PointContainer>
    </PointWrapper>
  );
};
