import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ApprovedIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.25 13C23.25 18.3844 18.8844 22.75 13.5 22.75C8.11556 22.75 3.75 18.3844 3.75 13C3.75 7.61556 8.11556 3.25 13.5 3.25C18.8844 3.25 23.25 7.61556 23.25 13Z"
      fill="#00B1A5"
    />
    <path
      d="M18.5188 8.66602L11.4138 15.7794L8.48069 12.8547L7 14.3354L11.4159 18.7408L20 10.1467L18.5188 8.66602Z"
      fill="white"
    />
  </svg>
);
