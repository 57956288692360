import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/baseQuery";
import { carStagesFavoritePhotosUrlBuilder } from "./urlBuilder/carStagesFavoritePhotos";
import {
  FavoriteAllPhotos,
  FavoriteStagePhotos,
  FavoriteStageResponse,
} from "types";

export const carStageFavoritePhotosAPI = createApi({
  reducerPath: "carStageFavoritePhotosAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["carStageFavoritePhotos"],
  endpoints: build => ({
    getFavoritePhotos: build.query<FavoriteAllPhotos[], number>({
      query: args => ({
        url: carStagesFavoritePhotosUrlBuilder.getPhotos(args),
        method: "GET",
      }),
      providesTags: ["carStageFavoritePhotos"],
    }),
    downloadAllFavoritePhotos: build.query<FavoriteStageResponse, number>({
      query: args => ({
        url: carStagesFavoritePhotosUrlBuilder.downloadAllFavoritePhotos(args),
        method: "GET",
      }),
    }),
    downloadByStageFavoritePhotos: build.query<
      FavoriteStageResponse,
      FavoriteStagePhotos
    >({
      query: (args?: FavoriteStagePhotos) => ({
        url: carStagesFavoritePhotosUrlBuilder.downloadByStageFavoritePhotos(
          args.carID,
          args.stageID,
        ),
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetFavoritePhotosQuery,
  useLazyDownloadAllFavoritePhotosQuery,
  useLazyDownloadByStageFavoritePhotosQuery,
} = carStageFavoritePhotosAPI;
