import { Box, styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { LogoSingerNewIcon as LogoIcon } from "icons";

export const HeaderApp = styled("nav")<{
  switched?: boolean;
  dashboard?: boolean;
}>(({ switched, dashboard }) => ({
  padding: "9px 34px",
  height: "66px",
  background: theme.color.white,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  zIndex: "98",
  top: 0,

  [BreakPointTheme.breakpoints.up("md")]: {
    ...(dashboard && {
      [BreakPointTheme.breakpoints.up("lg")]: {
        position: "fixed",
        right: 0,
        left: 0,
        background: "transparent",
        color: theme.color.white,
        transition: ".1s linear all",

        svg: {
          "& .text": {
            fill: `${theme.color.white} !important`,
          },
          "& .shadow": {
            fill: `#2d2d2d !important`,
          },
          "& .burger": {
            stroke: `${theme.color.white} !important`,
          },
        },
      },
    }),
    ...(switched && {
      [BreakPointTheme.breakpoints.up("lg")]: {
        background: theme.color.white,
        color: theme.color.black,
      },
    }),
  },

  [BreakPointTheme.breakpoints.down("lg")]: {
    padding: "9px 20px",
    height: "44px",
  },
}));

export const HeaderItem = styled(Box)<{ centered?: boolean }>(
  ({ centered }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",

    ...(centered && {
      [BreakPointTheme.breakpoints.up(960)]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateX(-50%)translateY(-50%)",
      },
    }),
  }),
);

export const SidebarSwitch = styled("div")<{
  opened?: boolean;
  switched?: boolean;
}>(({ opened, switched }) => ({
  cursor: "pointer",

  ...(!opened && {
    transform: "scaleX(-1)",
  }),
  ...(switched && {
    svg: {
      color: theme.color.white,
    },
  }),

  [BreakPointTheme.breakpoints.down("lg")]: {
    svg: {
      width: "16px",
    },
  },
}));

export const Logo = styled(LogoIcon)<{
  onClick?: () => void;
}>({
  // filter: "drop-shadow( 1px 4px 1px rgba(0, 0, 0, 0.4))",
  cursor: "pointer",

  [BreakPointTheme.breakpoints.down("lg")]: {
    maxWidth: "117px",
    marginLeft: "40px",
    filter: "none",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    maxWidth: "97px",
    filter: "none",
    marginLeft: "0",
  },
});

export const ConnectButton = styled("div")({
  fontSize: "20px",
  fontWeight: theme.fontWeight.bold,

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontWeight: theme.fontWeight.regular,
    fontSize: "15px",
  },
});
