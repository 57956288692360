import { FC } from "react";
import { styled, Typography, Box, Button } from "@mui/material";
import type { TypographyProps } from "@mui/material/Typography";

import { theme } from "theme";

interface Props {
  component?: string | FC;
}

export const UploadBox = styled(Box)({
  height: "138px",
  background: theme.color.white,
  border: "1px dashed #5C5C5C",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const TextUpload = styled(Typography)({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "130%",
  color: theme.color.primary[700],
  textAlign: "center",
  marginTop: "5px",
});

export const TextDrag = styled(Typography)<Props & TypographyProps>({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: theme.color.grey[500],
  textAlign: "center",
});

export const TextLimit = styled(Typography)({
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#8D8D8D",
  textAlign: "center",
});

export const TextInfo = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "right",
});

export const TextError = styled(TextInfo)({
  color: theme.color.error[500],
});

export const NoticeButton = styled(Button)({
  borderRight: "none !important",
});
