import { endpoints } from "constant";

export const carStagesTimelineUrlBuilder = {
  getCarStages: function ({
    id,
  }: {
    id?: number;
  } = {}): string {
    return `${endpoints.cars}${id}/stages/`;
  },
  createCarStages: function ({
    id,
    stage_id,
  }: {
    id?: string;
    stage_id?: string;
  } = {}): string {
    return `${endpoints.cars}${id}/stages/${stage_id}/`;
  },
  deleteCarStageImages: function ({
    id,
    stage_id,
  }: {
    id?: string;
    stage_id?: string;
  } = {}): string {
    return `${endpoints.cars}${id}/stages/${stage_id}/`;
  },
};
