import { endpoints } from "constant";

import type { PartnersQueryParams } from "types";

export const partnerUrlBuilder = {
  getPartners: function ({ search = "" }: PartnersQueryParams = {}): string {
    return `${endpoints.globalPartners}?&search=${search}`;
  },
  getPartnersForClient: (): string => {
    return endpoints.globalPartnersForClient;
  },
  getPartner: function (id: string | number): string {
    return `${endpoints.globalPartners}${id}/`;
  },
  createPartner: function (): string {
    return endpoints.globalPartners;
  },
  updatePartner: function (id: string | number): string {
    return `${endpoints.globalPartners}${id}/`;
  },
  deletePartner: function (id: string | number): string {
    return `${endpoints.globalPartners}${id}/`;
  },
  createPartnerClient: function (): string {
    return endpoints.globalPartnerClients;
  },
  updatePartnerClient: function (id: string | number): string {
    return `${endpoints.globalPartnerClients}${id}/`;
  },
  deletePartnerClient: function (id: string | number): string {
    return `${endpoints.globalPartnerClients}${id}/`;
  },
};
