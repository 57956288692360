import React, { FC } from "react";
import { ControllerRenderProps } from "react-hook-form";

import {
  CheckBoxContainer,
  Description,
  StyledCheckbox,
  StyledFormControlLabel,
  StyledTooltip,
} from "./styled";
import { CheckBoxIcon, CheckBoxUnchekedIcon } from "icons";

interface CheckBoxType extends ControllerRenderProps {
  label?: string;
  marginBot?: number;
  tooltip?: string;
  checked?: boolean;
  disabled?: boolean;
  description?: string;
  tableHead?: boolean;
  orange?: boolean;
}

interface CheckBoxTableType {
  label?: string;
  tooltip?: string;
  marginBot?: number;
  checked?: boolean;
  disabled?: boolean;
  description?: string;
  tableHead?: boolean;
  orange?: boolean;
}

export const CheckBox: FC<CheckBoxType | CheckBoxTableType> = ({
  orange,
  tableHead,
  disabled,
  label,
  marginBot,
  tooltip,
  description,
  ...field
}) => {
  return (
    <CheckBoxContainer marginBot={marginBot}>
      <StyledTooltip
        title={tooltip}
        arrow
        followCursor={true}
        placement="right-start"
        disableFocusListener={!tooltip}
        disableHoverListener={!tooltip}
        disableInteractive={!!tooltip}
        disableTouchListener={!!tooltip}
      >
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              {...field}
              disabled={disabled}
              checked={field.checked}
              icon={<CheckBoxUnchekedIcon tableHead={tableHead} />}
              checkedIcon={<CheckBoxIcon orange={orange} />}
            />
          }
          label={label && label}
          labelPlacement="end"
        />
      </StyledTooltip>
      {description && <Description>{description}</Description>}
    </CheckBoxContainer>
  );
};
