import { Box as MUIBox, styled, Tab as MUITab, Tabs } from "@mui/material";

import { theme } from "theme";

interface Props {
  visual: string;
  paddingHorizontal?: string;
  customWidth?: string;
}

export const Box = styled(MUIBox)({
  width: "100%",
});

export const MUITabs = styled(Tabs)({
  ".MuiTabs-indicator": {
    height: 0,
  },
});

export const Tab = styled(MUITab)<Props>(
  ({ visual, paddingHorizontal, customWidth }) => ({
    padding: "12px 50px",
    height: "58px",
    minHeight: "58px",
    borderRadius: "10px 10px 0px 0px",
    background: theme.color.lightGrey,
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: theme.color["6D"],
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "140%",
    color: theme.color.black,
    textTransform: "none",
    "&.Mui-selected": {
      background: "#555555",
      borderWidth: "0px 5px 0px 0px",
      borderColor: theme.color.orange,
      color: theme.color.white,

      "svg.MuiTab-iconWrapper path": {
        stroke: theme.color.white,
      },
    },
    "svg.MuiTab-iconWrapper": {
      marginBottom: "2px",
      position: "absolute",
      right: "15px",
    },
    ...(visual === "cars" && {
      borderRadius: "0px",
      padding: "16px 24px",
      height: "54px",
      border: "1px solid #6D6D6D",
    }),
    ...(paddingHorizontal
      ? {
          paddingLeft: paddingHorizontal,
          paddingRight: paddingHorizontal,
        }
      : null),
    ...(customWidth && {
      width: customWidth,
    }),
  }),
);
