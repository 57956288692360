import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Grid } from "@mui/material";

import { useAppSelector } from "hooks/redux";
import { getCollaboratorPermissions, getUserClientRole } from "redux/selectors";
import { useGetLegalDocumentsQuery } from "redux/query/legalDocumentsAPI";
import { useGetDisclaimersQuery } from "redux/query/disclaimersAPI";

import { Loader } from "components/Loader";
import { LogoSingerIcon } from "icons";
import { CLIENT_ROUTES } from "constant";

import { capitalize } from "utils/capitalize";
import { getFileFormat } from "utils/getFileFormat";
import { fetchDownload } from "utils/fileDownload";
import { checkPermissions } from "utils/checkPermissions";

import { config } from "./config";
import {
  Container,
  ContainerCopyright,
  ContainerLogo,
  Copyright,
  FooterText,
  GridContainer,
  GridLinks,
  LinksTitle,
  StyledFileDownload,
  StyledLegalLink,
  StyledLink,
  Wrapper,
} from "./styled";

export const Footer: FC = () => {
  const clientRole = useAppSelector(getUserClientRole);
  const collaboratorPermissions = useAppSelector(getCollaboratorPermissions);

  const { data: documents, isFetching: isFetchingDocuments } =
    useGetLegalDocumentsQuery();

  const { data: disclaimer, isFetching: isFetchingDisclaimer } =
    useGetDisclaimersQuery();

  const { pathname } = useLocation();

  const legalLinks = documents?.results?.map(link => {
    const format = getFileFormat(link.file);

    if (!format) return null;
    return (
      <Grid key={link.id} item xs={3} md={1} lg={1}>
        {format === "pdf" ? (
          <StyledLegalLink target={"_blank"} href={`${link.file}`}>
            {capitalize([link.document_category])}
          </StyledLegalLink>
        ) : (
          <StyledFileDownload onClick={() => fetchDownload({ url: link.file })}>
            {capitalize([link.document_category])}
          </StyledFileDownload>
        )}
      </Grid>
    );
  });

  return (
    <Wrapper>
      {pathname === CLIENT_ROUTES.HOME.path && (
        <Container>
          {isFetchingDisclaimer ? (
            <Loader />
          ) : (
            disclaimer?.map(text => (
              <FooterText key={text.id}>{text.text}</FooterText>
            ))
          )}
        </Container>
      )}

      <ContainerLogo>
        <LogoSingerIcon />
      </ContainerLogo>

      <Container>
        <GridContainer
          container
          spacing={{ md: 1, lg: 2 }}
          columns={{ lg: 4, sm: 1 }}
        >
          {/* sitemap links */}
          <Grid item md={3} lg={3} sm={3}>
            <LinksTitle>{config.sitemap.title}</LinksTitle>

            <Grid container columns={{ sm: 3, xs: 2 }}>
              {config.sitemap.links.map(link => {
                const isAccess = checkPermissions(
                  clientRole,
                  link.isCollaboratorAccess,
                  collaboratorPermissions,
                );

                const isShowLink = isMobileOnly
                  ? link?.isMobile
                  : link?.isDesktop;

                if (isAccess && isShowLink)
                  return (
                    <Grid key={link.id} item xs={1}>
                      <StyledLink to={link.link}>{link.linkTitle}</StyledLink>
                    </Grid>
                  );
              })}
            </Grid>
          </Grid>

          {/* legal links */}
          {isFetchingDocuments ? (
            <Loader />
          ) : (
            <GridLinks item md={1} lg={1} sm={1}>
              <LinksTitle>{"Legal"}</LinksTitle>
              <Grid container columns={{ xs: 1, md: 1, lg: 1 }}>
                {legalLinks && legalLinks}
              </Grid>
            </GridLinks>
          )}
        </GridContainer>
      </Container>

      <ContainerCopyright>
        <Copyright>{config.copyright}</Copyright>
      </ContainerCopyright>
    </Wrapper>
  );
};
