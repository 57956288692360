import { Box, Paper, styled } from "@mui/material";
import { ButtonOutlined } from "styled";

export const StyledContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
});

export const StyledPaper = styled(Paper)({
  maxWidth: "100%",
  width: "100%",
  overflow: "hidden",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.07)",
});

export const LoaderWrapper = styled(Box)({
  height: "230px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const DeleteButton = styled(ButtonOutlined)<{
  minWidth?: string;
  buttonsFontSize?: string;
}>(({ minWidth, buttonsFontSize }) => ({
  ...(minWidth && {
    minWidth,
  }),
  ...(buttonsFontSize && {
    fontSize: buttonsFontSize,

    svg: {
      width: "32px",
      heigth: "32px",
    },
  }),
}));
