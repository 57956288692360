import { endpoints } from "constant";

export const universalContentTimelineUrlBuilder = {
  getWelcomeStatement: function (
    id?: number | string,
    car_model?: string,
  ): string {
    return `${endpoints.universalContent}${id}/${car_model}/welcome-statement/`;
  },
  postWelcomeStatement: function (
    id: number | string,
    car_model: string,
  ): string {
    return `${endpoints.universalContent}${id}/${car_model}/welcome-statement/`;
  },
  getTimelineDocuments: function (id: number, car_model: string): string {
    return `${endpoints.universalContent}${id}/${car_model}/timeline-corporate-documents/`;
  },
  uploadTimelineDocumentsDocuments: function (
    id: number,
    car_model: string,
  ): string {
    return `${endpoints.universalContent}${id}/${car_model}/timeline-corporate-documents/`;
  },
  updateTimelineDocumentsDocuments: function (
    id: number,
    car_model: string,
    docId: number,
  ): string {
    return `${endpoints.universalContent}${id}/${car_model}/timeline-corporate-documents/${docId}/`;
  },
  deleteTimelineDocumentsDocuments: function (
    stage_id: number,
    car_model: string,
    id: number,
  ): string {
    return `${endpoints.universalContent}${stage_id}/${car_model}/timeline-corporate-documents/${id}/`;
  },
  getTimeline: function (stage_id: number, car_model: string): string {
    return `${endpoints.universalContent}${stage_id}/${car_model}/timeline/`;
  },
  postTimelinePreview: function (id: number, car_model: string): string {
    return `${endpoints.universalContent}${id}/${car_model}/timeline/`;
  },
};
