import React, { FC } from "react";

import { CheckBox } from "components/Clients/ClientsForm";
import { Loader } from "components/Loader";

import type { TableRowProps } from "./typed";
import {
  TableRowStyled,
  TableCellAction,
  TableCellCheckbox,
  TableCellData,
  LoaderContainer,
} from "./styled";
import { dateTransform } from "utils/dateTransform";

export const TableRow: FC<TableRowProps> = ({
  actions,
  row,
  documentsTable,
  moodBoardsTable,
  collaboratorsTable,
  cells,
  withCheckBox,
  isSelected,
  withoutActions,
  lastElementFullWidth,
  handleSelect,
  getClickHandler,
}) => {
  const createActionCells = ({ label, Icon, onClick }) => (
    <Icon
      key={label}
      title={label}
      width={"18px"}
      height={"18px"}
      onClick={event => {
        event.stopPropagation();
        onClick(row.id, row);
      }}
    />
  );

  const actionsCells = withoutActions?.length
    ? !withoutActions.includes(row?.type) && actions?.map(createActionCells)
    : actions?.map(createActionCells);

  return (
    <TableRowStyled
      lastElementFullWidth={lastElementFullWidth}
      withAction={Boolean(actions)}
      hover
      onClick={getClickHandler ? getClickHandler(row) : null}
      key={row.id}
    >
      {withCheckBox && (
        <TableCellCheckbox align="left" width={25} height={25}>
          <CheckBox
            marginBot={1}
            checked={isSelected.includes(row.id)}
            onChange={() => handleSelect(row.id)}
            value={isSelected.includes(row.id)}
          />
        </TableCellCheckbox>
      )}

      {cells.map(({ width, date, value, id, Wrapper, getRowClickHandler }) => (
        <TableCellData
          key={id}
          width={width}
          isBold={collaboratorsTable && value === "name"}
          documentsTable={documentsTable}
          moodBoardsTable={moodBoardsTable}
        >
          {Wrapper ? (
            <Wrapper
              value={row[value]}
              item={row}
              onClick={getRowClickHandler ? getRowClickHandler(row) : null}
            />
          ) : date ? (
            dateTransform(row[value])
          ) : (
            row[value]
          )}
        </TableCellData>
      ))}

      {!!actions && (
        <TableCellAction
          align="right"
          width={70}
          isFileLoading={row?.file === null}
          moodBoardsTable={moodBoardsTable}
        >
          {row?.file === null || row?.isLoading ? (
            <LoaderContainer>
              <Loader size="small" />
            </LoaderContainer>
          ) : (
            <div>{actionsCells}</div>
          )}
        </TableCellAction>
      )}
    </TableRowStyled>
  );
};

TableRow.defaultProps = {
  actions: null,
};
