import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useProtectedRoute } from "hooks/useProtectedRoute";

import { Footer, Header, Sidebar } from "./components";
import { Backdrop, LayoutGrid, MainContainer } from "./styled";
import { SidebarEnum } from "./typed";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  useProtectedRoute();
  //scroll to top after change route
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [isOpen, setIsOpen] = useState<
    null | SidebarEnum.Left | SidebarEnum.Right
  >(null);

  const handleOpen = (open: SidebarEnum.Left | SidebarEnum.Right) => {
    setIsOpen(open);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  return (
    <>
      <LayoutGrid>
        <Sidebar opened={isOpen} closeSidebar={handleClose} />

        <Backdrop opened={isOpen} onClick={handleClose} />

        <MainContainer>
          <Header handleOpen={handleOpen} />
          {children}
          <Footer />
        </MainContainer>
      </LayoutGrid>
    </>
  );
};
