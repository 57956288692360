import { RefAttributes } from "react";
import { SwiperProps, SwiperRef } from "swiper/react";
import { Controller, FreeMode, Lazy, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";

export const params: RefAttributes<SwiperRef> & SwiperProps = {
  modules: [Controller, Navigation, Lazy, Thumbs],
  preloadImages: false,
  navigation: true,
  lazy: true,
  loop: true,
  spaceBetween: 10,
};
export const thumbsParams = {
  modules: [FreeMode, Navigation, Thumbs],
  freeMode: true,
  watchSlidesProgress: true,
  slideToClickedSlide: true,
  isActive: "border: 1px solid red",
  touchRatio: 1,
  loop: false,
  spaceBetween: 10,
  removeDuplicates: true,
};
