import { endpoints } from "constant";

export const instagramPostsUrlBuilder = {
  posts: function ({ limit = 2 }: { limit?: number }): string {
    return `${process.env.REACT_APP_INSTAGRAM_USER_ID}${
      endpoints.instagramMedia
    }?access_token=${
      process.env.REACT_APP_INSTAGRAM_API_ACCESS_TOKEN
    }&fields=media_url,permalink,timestamp,media_type,username${
      limit && `&limit=${limit}`
    }`;
  },
};
