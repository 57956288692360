import React, { FC } from "react";

import { ConfirmDialogProps } from "./typed";
import {
  Button,
  ButtonText,
  ButtonsContainer,
  DialogTitle,
  Dialog,
  DialogContent,
  FirstButton,
} from "./styled";

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  title,
  message,
  confirmButton,
  cancelButton,
  setOpen,
  onConfirm,
}) => {
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };
  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle>{title || "NOTICE"}</DialogTitle>

      <DialogContent>{message}</DialogContent>

      <ButtonsContainer>
        <FirstButton>
          <Button onClick={handleClose}>
            <ButtonText>{cancelButton}</ButtonText>
          </Button>
        </FirstButton>

        <Button onClick={handleConfirm}>
          <ButtonText>{confirmButton}</ButtonText>
        </Button>
      </ButtonsContainer>
    </Dialog>
  );
};
