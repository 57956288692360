import { RootState } from "store";
import { Comission } from "types";

export const сomissionsSelector = (state: RootState): Comission[] =>
  state.comissions.comissions;

export const activeComissionsSelector = (state: RootState): Comission =>
  state.comissions.activeComission;

export const activeComissionStageSelector = (state: RootState): number =>
  state.comissions.activeStage;

export const activeComissionSubstageSelector = (state: RootState) =>
  state.comissions.activeSubstage;
