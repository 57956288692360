import { FC } from "react";
import { styled } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";

import { BreakPointTheme, theme } from "theme";

import { BlockDescriptionTypography } from "styled";

interface Props {
  component?: string | FC;
}

export const LinkStyled = styled(BlockDescriptionTypography)<
  Props & ButtonProps
>({
  fontWeight: theme.fontWeight.light,
  textTransform: "capitalize",
  textDecoration: "none",
  display: "inline",
  lineHeight: "26px",
  color: theme.color.black,
  cursor: "pointer",

  [BreakPointTheme.breakpoints.down("lg")]: {
    lineHeight: "22px",
  },

  svg: {
    marginLeft: "10px",
    marginBottom: "-7px",

    [BreakPointTheme.breakpoints.down("lg")]: {
      marginLeft: "7px",
      marginBottom: "-1px",
      width: "13px",
      height: "13px",
    },
  },
});
