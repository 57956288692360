import React, { ChangeEvent, FC } from "react";
import InputAdornment from "@mui/material/InputAdornment";

import { InputStyled } from "./styled";
import { InputProps } from "./typed";

export const InputSearch: FC<InputProps> = ({
  placeholder,
  StartIcon,
  EndIcon,
  value,
  changeHandler,
}) => {
  return (
    <InputStyled
      placeholder={placeholder}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        changeHandler(event.target.value)
      }
      InputProps={{
        ...(StartIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <StartIcon />
                </InputAdornment>
              ),
            }
          : null),
        ...(EndIcon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <EndIcon />
                </InputAdornment>
              ),
            }
          : null),
      }}
    />
  );
};
