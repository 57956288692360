export enum VideoTypes {
  Vimeo = "vimeo",
  S3 = "s3",
}

interface Vimeo {
  type: VideoTypes.Vimeo;
  id: string;
}

interface S3 {
  type: VideoTypes.S3;
  url: string;
}

export const processVideoUrl = (url: string): Vimeo | S3 => {
  const vimeoMatch = url?.match(/vimeo\.com\/(\d+)/);

  if (vimeoMatch) {
    return { type: VideoTypes.Vimeo, id: vimeoMatch[1] };
  }
  if (url?.match(/s3\.amazonaws\.com\/(.+?)\/(.+)/)) {
    return { type: VideoTypes.S3, url };
  }

  window.console.log("Wrong url at processVideoUrl", url);
  return;
};
