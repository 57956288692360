import React, { FC } from "react";

import { ArrowLinkIcon } from "icons";

import { LinkStyled } from "./styled";

export interface ArrowLinkProps {
  title: string;
  onClick?: () => void;
}

export const ArrowLink: FC<ArrowLinkProps> = ({ title, onClick }) => (
  <LinkStyled onClick={onClick}>
    {title}
    <ArrowLinkIcon />
  </LinkStyled>
);
