import { Dialog as MUIDialog, styled } from "@mui/material";

import { CloseIcon } from "icons";

import { CarouselIconProps } from "./typed";
import { BreakPointTheme } from "theme";

export const ModalWithoutBackground = styled(MUIDialog)({
  background: "rgba(0, 0, 0, 0.6)",
  backdropFilter: "blur(5px)",
  ".MuiPaper-root": {
    borderRadius: "10px",
    background: "none",
    boxShadow: "none",

    [BreakPointTheme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  ".MuiDialogContent-root": {
    borderBottom: "none",
  },
});

export const CloseCarouselIcon = styled(CloseIcon)<CarouselIconProps>({
  position: "absolute",
  top: "20px",
  right: "40px",
  zIndex: 10,
  cursor: "pointer",
});
