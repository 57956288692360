import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const AvatarDefaultIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.0001 0.495361C22.3201 0.495361 0.666748 22.1487 0.666748 48.8287C0.666748 75.5087 22.3201 97.162 49.0001 97.162C75.6801 97.162 97.3334 75.5087 97.3334 48.8287C97.3334 22.1487 75.6801 0.495361 49.0001 0.495361ZM49.0001 14.9954C57.0234 14.9954 63.5001 21.472 63.5001 29.4954C63.5001 37.5187 57.0234 43.9954 49.0001 43.9954C40.9767 43.9954 34.5001 37.5187 34.5001 29.4954C34.5001 21.472 40.9767 14.9954 49.0001 14.9954ZM49.0001 83.6287C36.9167 83.6287 26.2351 77.442 20.0001 68.0654C20.1451 58.447 39.3334 53.1787 49.0001 53.1787C58.6184 53.1787 77.8551 58.447 78.0001 68.0654C71.7651 77.442 61.0834 83.6287 49.0001 83.6287Z"
      fill="#DDDDDD"
    />
  </svg>
);
