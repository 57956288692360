import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const PinIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 4.46875C14.7633 4.46875 17.0033 6.70873 17.0033 9.47188C17.0033 11.838 13.3344 17.731 12.281 19.3781C12.2197 19.4738 12.1139 19.5317 12.0002 19.5317C11.8865 19.5317 11.7807 19.4738 11.7194 19.3781C10.666 17.7317 6.99707 11.838 6.99707 9.47188C6.99707 6.70873 9.23705 4.46875 12.0002 4.46875Z"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 11.4732C13.1055 11.4732 14.0015 10.5772 14.0015 9.47195C14.0015 8.36669 13.1055 7.4707 12.0003 7.4707C10.895 7.4707 9.99902 8.36669 9.99902 9.47195C9.99902 10.5772 10.895 11.4732 12.0003 11.4732Z"
      stroke="black"
    />
  </svg>
);
