import React, { FC } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import { ButtonPlayIcon } from "icons";

export const VideoPlayer: FC<ReactPlayerProps> = props => {
  return (
    <ReactPlayer
      width="100%"
      height="100%"
      playIcon={<ButtonPlayIcon />}
      {...props}
    />
  );
};
