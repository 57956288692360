import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const ResetPasswordIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.70508 10.7317L6.96736 14.1251L9.60669 11.1087" stroke="black" />
    <path
      d="M14.1307 20.164C18.0872 20.164 21.2946 16.9566 21.2946 13.0001C21.2946 9.04357 18.0872 5.83618 14.1307 5.83618C10.1742 5.83618 6.9668 9.04357 6.9668 13.0001V14.1267"
      stroke="black"
    />
    <rect
      x="10.832"
      y="11.9478"
      width="6.50062"
      height="5.35345"
      rx="1"
      stroke="black"
    />
    <path
      d="M11.9785 10.6095C11.9785 9.44796 12.9201 8.50635 14.0817 8.50635C15.2432 8.50635 16.1848 9.44796 16.1848 10.6095V11.9479"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0816 14.4335C14.2928 14.4335 14.464 14.2623 14.464 14.0511C14.464 13.8399 14.2928 13.6687 14.0816 13.6687C13.8704 13.6687 13.6992 13.8399 13.6992 14.0511C13.6992 14.2623 13.8704 14.4335 14.0816 14.4335Z"
      stroke="black"
    />
    <path d="M14.082 14.4333V15.5805" stroke="black" />
  </svg>
);
