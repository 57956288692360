import { FC } from "react";
import { CardMedia, styled } from "@mui/material";
import type { CardMediaProps } from "@mui/material/CardMedia";

interface Props {
  component?: string | FC;
}

import {
  Dialog as DialogStyled,
  DialogContent as Content,
  DialogActions as Actions,
} from "../styled";

export const Dialog = styled(DialogStyled)<{ largePreview?: boolean }>(
  ({ largePreview }) => ({
    ".MuiPaper-root": {
      height: "calc(100% - 64px)",
      maxHeight: "600px",
      maxWidth: "800px",

      ...(largePreview && {
        maxHeight: "80vh",
        maxWidth: "80vw",
      }),
    },
  }),
);

export const DialogContent = styled(Content)({
  padding: "0 24px 15px",
  height: "100%",
});

export const Iframe = styled("iframe")({
  width: "100%",
  height: "100%",
});

export const DialogActions = styled(Actions)({
  button: {
    borderRight: "none",
  },
});

export const FullImage = styled(CardMedia)<Props & CardMediaProps>({
  width: "100%",
  maxHeight: "79vh",
  objectFit: "contain",
});
