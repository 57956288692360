import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

interface SvgProps {
  fill?: string;
}

export const ArrowUpSolidIcon: FC<SvgIconConstituentValues & SvgProps> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 14.0781L12 9.07812L7 14.0781L17 14.0781Z" fill={fill} />
  </svg>
);

ArrowUpSolidIcon.defaultProps = {
  fill: "#FF4F00",
};
