import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CloseIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    {...props}
    fill={props.fillColor ? props.fillColor : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="20.3164"
      y1="6.85355"
      x2="6.89945"
      y2="20.2705"
      stroke={props.fillColor ? props.fillColor : "black"}
    />
    <line
      x1="19.8906"
      y1="20.2696"
      x2="6.4736"
      y2="6.85258"
      stroke={props.fillColor ? props.fillColor : "black"}
    />
  </svg>
);
