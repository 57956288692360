import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const FileDoubleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8926 4.32812C16.0696 4.32816 16.2392 4.39847 16.3643 4.52358L18.6417 6.801C18.7668 6.92608 18.8371 7.09572 18.8372 7.27263V17.0027C18.8372 17.3711 18.5385 17.6698 18.1701 17.6698H8.8309C8.46248 17.6698 8.16382 17.3711 8.16382 17.0027V4.99521C8.16382 4.62679 8.46248 4.32812 8.8309 4.32812H15.8926Z"
      stroke={props.strokeColor ?? "white"}
    />
    <path
      d="M16.8359 17.6695V19.0037C16.8359 19.3721 16.5373 19.6708 16.1688 19.6708H6.82968C6.46126 19.6708 6.1626 19.3721 6.1626 19.0037V6.99618C6.1626 6.62776 6.46126 6.3291 6.82968 6.3291H8.16385"
      stroke={props.strokeColor ?? "white"}
    />
  </svg>
);
