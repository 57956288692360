import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { BlockDescriptionTypography, BlockTitleTypography } from "styled";

export const Container = styled("div")<{
  marginBot?: number;
  fullWidth?: boolean;
}>(({ marginBot, fullWidth }) => ({
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto 60px",

  ...(marginBot && {
    marginBottom: `${marginBot}px`,
  }),
  ...(fullWidth && {
    maxWidth: `auto`,
  }),
}));

export const TimelineTitle = styled(BlockTitleTypography)({
  fontSize: theme.fontSize["7xl"],
  lineHeight: "89px",
  marginRight: "40px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: "50px",
    lineHeight: "64px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    fontSize: "44px",
    lineHeight: "58px",
  },
});

export const Description = styled(BlockDescriptionTypography)({
  textAlign: "left",
  maxWidth: "600px",
  marginBottom: "110px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    marginBottom: "61px",
  },
});

export const Wrapper = styled(Container)({
  marginBottom: "140px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    marginBottom: "66px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const TabsWrapper = styled("div")({
  display: "flex",
  // gap: "40px",
  marginBottom: "40px",
});

export const TimelineProgressWrapper = styled("div")({
  position: "relative",
});
