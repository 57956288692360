import { getFileFormat } from "./getFileFormat";

export const fileDownload = (file: File) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const asyncFetchDownload = async ({
  url,
  name,
  hideLoader,
}: {
  url?: string;
  name?: string;
  hideLoader: () => void;
}): Promise<boolean> => {
  const fileName = name
    ? `${name}.${getFileFormat(url)}`
    : getFileNameFromUrl(url);

  try {
    const file = await fetch(url);

    const blob = await file.blob();

    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", fileName);

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);

    hideLoader();

    return true;
  } catch (error) {
    window.console.error(error);

    return false;
  }
};

export const fetchDownload = ({
  url,
  name,
}: {
  url?: string;
  name?: string;
}) => {
  const fileName = name
    ? `${name}.${getFileFormat(url)}`
    : getFileNameFromUrl(url);

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(window.console.error);
};

export const fetchDownloadWithLoader = (
  {
    url,
    name,
  }: {
    url?: string;
    name?: string;
  },
  loadingHandler: (loading: boolean) => void,
) => {
  const startTime = performance.now();

  const fileName = name
    ? `${name}.${getFileFormat(url)}`
    : getFileNameFromUrl(url);

  loadingHandler(true);

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const endTime = performance.now();
      const duration = endTime - startTime;
      window.console.log(`Request to ${url} took ${duration} ms`);
    })
    .catch(error => {
      window.console.error("Request failed:", error);

      const endTime = performance.now();
      const duration = endTime - startTime;
      window.console.log(`Request to ${url} failed after ${duration} ms`);
    })
    .finally(() => {
      loadingHandler(false);
    });
};

export const getFileNameFromUrl = (url: string) => {
  const splitValue = decodeURIComponent(url).split("?")[0].split("/");

  return url ? splitValue[splitValue.length - 1] : "";
};

export const getFileNameWithoutFormat = (url: string) => {
  const splitValue = decodeURIComponent(url).split("?")[0].split("/");
  return splitValue[splitValue.length - 1].split(".")[0];
};

export const csvDownload = (
  response: { data: BlobPart } | { error: unknown },
  fileName: string,
) => {
  if (response) {
    const blob = new Blob([(response as { data: BlobPart }).data], {
      type: "text/csv",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
