import { ImageCarouselVariants } from "components/Clients/Carousel/ImageCarousel/typed";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled } from "@mui/system";

import { BreakPointTheme, theme } from "theme";

export const SwiperContainer = styled("div")<{
  variant?: ImageCarouselVariants;
}>(({ variant }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "48%",
  cursor: "pointer",

  ...(variant === ImageCarouselVariants.eventDetails && {
    width: "100%",
    justifyContent: "flex-start",

    [BreakPointTheme.breakpoints.down(1320)]: {
      width: "100%",
    },
  }),
}));

export const SwiperWrapper = styled("div")<{ variant?: ImageCarouselVariants }>(
  ({ variant }) => ({
    position: "relative",
    width: "663px",

    [BreakPointTheme.breakpoints.down(1439)]: {
      width: "550px",
    },

    [BreakPointTheme.breakpoints.down(1200)]: {
      width: "465px",
    },

    [BreakPointTheme.breakpoints.down("lg")]: {
      width: "354px",
    },

    ...(variant === ImageCarouselVariants.eventDetails && {
      position: "relative",
      width: "900px",

      [BreakPointTheme.breakpoints.up(1320)]: {
        width: "900px",
      },

      [BreakPointTheme.breakpoints.down(1320)]: {
        width: "343px",
      },

      [BreakPointTheme.breakpoints.between("xs", "md")]: {
        width: "347px",
      },
    }),
  }),
);

export const StyledSlide = styled(SwiperSlide)<{ bgImage: string }>(
  ({ bgImage }) => ({
    ...(bgImage && {
      background: `url(${bgImage}) center/cover no-repeat`,
    }),
  }),
);

export const StyledSwiper = styled(Swiper)<{ variant?: ImageCarouselVariants }>(
  ({ variant }) => ({
    height: "663px",
    padding: "0 0 30px",

    [BreakPointTheme.breakpoints.down(1439)]: {
      height: "550px",
    },

    [BreakPointTheme.breakpoints.down(1200)]: {
      height: "465px",
    },

    [BreakPointTheme.breakpoints.down("lg")]: {
      height: "354px",
    },

    ...(variant === ImageCarouselVariants.eventDetails && {
      height: "504px",
      padding: "0 0 30px",
      ".swiper-wrapper": {
        backgroundColor: theme.color.grey[200],
      },

      [BreakPointTheme.breakpoints.down(1677)]: {
        height: "504px",
      },

      [BreakPointTheme.breakpoints.down(1320)]: {
        height: "192px",
      },

      [BreakPointTheme.breakpoints.down("md")]: {
        height: "194px",
      },
    }),

    ".swiper-wrapper": {
      backgroundColor: theme.color.grey[200],
    },

    "& .swiper-pagination": {
      position: "absolute",
      textAlign: "center",
      transition: ".3s opacity",
      transform: "translate3d(0,0,0)",
      zIndex: "10",
      bottom: "10px",
      left: "0",
      width: "100%",
    },

    "& .swiper-pagination-bullet": {
      cursor: "pointer",

      width: "8px",
      height: "8px",
      display: "inline-block",
      borderRadius: "50%",
      background: "#000",
      opacity: ".2",
      margin: "0 3px",
    },

    "& .swiper-pagination-bullet-active": {
      opacity: "1",
    },
  }),
);

({});

export const StyledButton = styled("button")<{
  next?: boolean;
  prev?: boolean;
}>(({ next, prev }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "1",
  height: "30px",
  width: "30px",
  border: "none",
  background: "#2d2d2d",
  cursor: "pointer",

  ...(next && {
    right: "-15px",
  }),
  ...(prev && {
    left: "-15px",
  }),
  "&:disabled": {
    background: theme.color.lightGrey,
  },

  [BreakPointTheme.breakpoints.down("lg")]: {
    top: "45%",
    height: "20px",
    width: "20px",
    padding: "0",

    ...(prev && {
      left: "-10px",
    }),

    ...(next && {
      right: "-10px",
    }),
  },
}));
