import React, { FC, useMemo, useState } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";

import { FormControl, FormHelperText } from "@mui/material";

import { ArrowUpIcon } from "icons";

import { InputLabel } from "../styled";

import {
  SelectInput,
  SelectLabel,
  StyledMenuItem,
  CustomDevider,
  StyledPlaceholder,
} from "./styled";

interface SelectType extends ControllerRenderProps {
  label: string;
  placeholder?: string;
  fieldState: ControllerFieldState;
  withHeaderLabel?: boolean;
  variant?: "standard" | "filled" | "outlined";
  selectColor?: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const Select: FC<SelectType> = ({
  label,
  placeholder,
  fieldState,
  options,
  variant,
  selectColor,
  withHeaderLabel,
  ...field
}) => {
  const [isShowDevider, setIsShowDevider] = useState(false);
  const errorMessage =
    fieldState && useMemo(() => fieldState?.error?.message, [fieldState.error]);
  return (
    <>
      <FormControl variant={variant} fullWidth error={!!errorMessage}>
        {withHeaderLabel && <InputLabel>{withHeaderLabel}</InputLabel>}
        {label && <SelectLabel id="select">{label}</SelectLabel>}
        <SelectInput
          fullWidth
          labelId="select"
          id="select"
          IconComponent={ArrowUpIcon}
          displayEmpty
          renderValue={
            field.value !== ""
              ? undefined
              : () => <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          }
          MenuProps={{
            disableAutoFocusItem: true,
            disablePortal: true,
            MenuListProps: {
              sx: {
                padding: "5px 0px 30px",
              },
            },
          }}
          {...field}
          selectColor={selectColor}
          placeholder={placeholder}
          error={!!errorMessage}
          onOpen={() => setIsShowDevider(true)}
          onClose={() => setIsShowDevider(false)}
        >
          {options.map(({ value, label }) => (
            <StyledMenuItem key={value} value={value}>
              {label}
              {isShowDevider && <CustomDevider />}
            </StyledMenuItem>
          ))}
        </SelectInput>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </>
  );
};

Select.defaultProps = {
  variant: "standard",
};
