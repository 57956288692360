type Formats = {
  [key: string]: string;
};

export const DATE_FORMATS: Formats = {
  WEEK_MONTH_DAY_YEAR: "dddd, MMMM DD, YYYY",
  MONTH_DAY_YEAR: "MM/DD/YY",
  MONTH_DAY_YEAR_TIME: "MM/DD/YY h:mm A",
  MONTH_FULL_DAY_YEAR_TIME: "MMMM DD, YYYY - h:mm A",
  HOURS_MINUTES_A: "h:mm A",
  HOURS_MINUTES: "HH:mm",
  YEAR_MONTH_DAY: "YYYY-MM-DD",
  YEAR_MONTH_DAY_TIME: "YYYY-MM-DD HH:mm:ss",
  SHORT_MONTH_DAY_YEAR: "MMM DD, YYYY",
  SHORT_MONTH_YEAR: "MMM YYYY",
  FULL_MONTH_DAY_YEAR: "MMMM DD, YYYY",
  HOURS_MINUTES_SECONDS: "HH:mm:ss",
};
