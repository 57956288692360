import { CarsByID, RestorationUpdate } from "./../../types/Cars";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/baseQuery";
import { CarByID } from "types";
import { carsUrlBuilder } from "./urlBuilder/cars";

export const carsAPI = createApi({
  reducerPath: "carsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["CarsById"],
  endpoints: build => ({
    getCarRestorationUpdates: build.query<RestorationUpdate[], CarsByID>({
      query: (args?: CarByID) => ({
        url: carsUrlBuilder.getCarRestorationUpdates(args),
        method: "GET",
      }),
      providesTags: ["CarsById"],
    }),
  }),
});

export const { useGetCarRestorationUpdatesQuery } = carsAPI;
