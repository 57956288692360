import React, { FC } from "react";

import { CloseIcon } from "icons";

import { ClientsModalProps } from "./typed";
import { Dialog, DialogContent, DialogTitle, StyledButton } from "./styled";

export const ClientsModal: FC<ClientsModalProps> = ({
  avatar,
  open,
  title,
  setOpen,
  height,
  children,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} paddingBot={25} height={height} onClose={handleClose}>
      <StyledButton avatar={avatar} onClick={handleClose}>
        <CloseIcon />
      </StyledButton>
      <DialogTitle avatar={avatar}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

ClientsModal.defaultProps = {
  title: "",
};
