import { endpoints } from "constant";

export const universalContentDashboardUrlBuilder = {
  getWelcomeStatement: function (
    id?: number | string,
    car_model?: string,
  ): string {
    return `${endpoints.universalContent}${id}/${car_model}/welcome-statement/`;
  },

  getDashboard: function (stage_id: number): string {
    return `${endpoints.universalContent}${stage_id}/dashboard/`;
  },
  stageDescImages: function (stage_id: number): string {
    return `${endpoints.universalContent}${stage_id}/stage-description-images/`;
  },
  deleteStageDescImages: function (
    stage_id: number,
    id: string | number,
  ): string {
    return `${endpoints.universalContent}${stage_id}/stage-description-images/${id}/`;
  },
  stageTopicImages: function (stage_id: number): string {
    return `${endpoints.universalContent}${stage_id}/stage-topic-images/`;
  },
  deleteStageTopicImages: function (
    stage_id: number,
    id: string | number,
  ): string {
    return `${endpoints.universalContent}${stage_id}/stage-topic-images/${id}/`;
  },
  postDashboardPreview: function (id: number): string {
    return `${endpoints.universalContent}${id}/dashboard/`;
  },

  getHeaderVideoUrl: function (id: number | string): string {
    return `${endpoints.universalContent}${id}/homepage-header-video/`;
  },
};
