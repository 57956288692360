import React, { FC } from "react";
import { Link } from "react-router-dom";

import { CLIENT_ROUTES } from "constant";

import { Wrapper, LinkStyled } from "./styled";
import { LinksProps } from "./typed";

export const Links: FC<LinksProps> = ({ isPreview, isCollaborator }) => {
  return (
    <Wrapper>
      {!isCollaborator && (
        <LinkStyled
          {...(isPreview
            ? null
            : {
                component: Link,
                to: CLIENT_ROUTES.INSPIRATION_TOOL.path,
              })}
        >
          Inspiration Tool
        </LinkStyled>
      )}
      <LinkStyled
        {...(isPreview
          ? null
          : {
              component: Link,
              to: CLIENT_ROUTES.MOOD_BOARDS_LIBRARY.path,
            })}
      >
        Mood Boards Library
      </LinkStyled>
    </Wrapper>
  );
};
