import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CLIENT_ROUTES } from "constant";
import { SidebarEnum } from "../../typed";

import { BurgerMenuIcon } from "icons";

import { HeaderProps } from "./typed";
import {
  ConnectButton,
  HeaderApp,
  HeaderItem,
  Logo,
  SidebarSwitch,
} from "./styled";

export const Header: FC<HeaderProps> = ({ handleOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const stickyHeader = useRef<HTMLDivElement>(null);

  const [isSwitchedHeader, setIsSwitchedHeader] = useState<boolean>(false);
  const [isDashboardPage, setIsDashboardPage] = useState<boolean>(false);

  const handleLeftSidebar = () => {
    handleOpen(SidebarEnum.Left);
  };

  const handleRightSidebar = () => {
    handleOpen(SidebarEnum.Right);
  };

  const route =
    pathname === CLIENT_ROUTES.HOME.path ||
    pathname === CLIENT_ROUTES.SPECIFICATIONS.path;

  useEffect(() => {
    const fixedTop = stickyHeader.current.offsetTop;
    const handleScroll = () => {
      const { scrollY } = window;
      setIsSwitchedHeader(scrollY > fixedTop);
    };
    if (route) {
      setIsDashboardPage(true);
      window.addEventListener("scroll", handleScroll);
    } else {
      setIsDashboardPage(false);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [route]);

  return (
    <HeaderApp
      switched={isSwitchedHeader}
      dashboard={isDashboardPage}
      ref={stickyHeader}
    >
      <HeaderItem>
        <SidebarSwitch onClick={handleLeftSidebar}>
          <BurgerMenuIcon />
        </SidebarSwitch>
      </HeaderItem>

      <Logo onClick={() => navigate(CLIENT_ROUTES.HOME.path)} />

      <HeaderItem>
        <ConnectButton onClick={handleRightSidebar}>CONNECT</ConnectButton>
      </HeaderItem>
    </HeaderApp>
  );
};
