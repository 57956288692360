import React, { forwardRef, useMemo } from "react";

import { Input, InputLabel } from "../styled";

import { TextInputType } from "./typed";

export const TextInput = forwardRef<HTMLInputElement, TextInputType>(
  (
    {
      label,
      placeholder,
      fieldState,
      error,
      dashed,
      variant,
      withHeaderLabel,
      inputType,
      padding,
      spacing,
      ...field
    },
    ref,
  ) => {
    const errorMessage = useMemo(() => {
      if (fieldState.error?.message) {
        return fieldState.error?.message;
      }
      if (error?.creatingError?.data[field.name]) {
        return error?.creatingError?.data[field.name][0];
      }
      if (error?.updatingError?.data[field.name]) {
        return error?.updatingError?.data[field.name][0];
      }
    }, [fieldState.error, error]);

    return (
      <div>
        {withHeaderLabel && <InputLabel>{withHeaderLabel}</InputLabel>}
        <Input
          {...field}
          ref={ref}
          fullWidth
          dashed={dashed}
          label={label}
          variant={variant}
          padding={padding}
          spacing={spacing}
          type={inputType}
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </div>
    );
  },
);

TextInput.displayName = "TextInput";

TextInput.defaultProps = {
  variant: "standard",
};
