import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CollaboratorsIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.83984 19.1536C2.83984 16.3803 5.09318 14.127 7.86651 14.127C10.6398 14.127 12.8932 16.3803 12.8932 19.1536H2.83984Z"
      stroke="black"
    />
    <path
      d="M12.6328 9.10156C13.4995 9.62156 14.4528 9.96823 15.4928 9.96823C16.1861 9.96823 16.8795 9.7949 17.4861 9.5349"
      stroke="black"
    />
    <path
      d="M12.0264 7.97412C13.0664 6.67412 14.973 6.50079 16.273 7.54079C17.573 8.58079 17.833 10.4875 16.793 11.7875C15.753 13.0875 13.933 13.3475 12.5464 12.3941C12.4597 12.3075 12.2864 12.2208 12.1997 12.0475"
      stroke="black"
    />
    <path d="M16.3596 19.1543H14.7129" stroke="black" />
    <path
      d="M12.9795 14.3008C14.5395 13.8674 16.1862 14.1274 17.4862 15.0808C17.7462 15.2541 17.9195 15.4274 18.0928 15.6008"
      stroke="black"
    />
    <path
      d="M5.2666 8.40625C6.73993 9.96625 8.99327 10.3996 10.9866 9.53292"
      stroke="black"
    />
    <path
      d="M7.86634 13.0003C9.59967 13.0003 10.8997 11.6136 10.8997 9.96693C10.8997 8.23359 9.51301 6.93359 7.86634 6.93359C6.13301 6.93359 4.83301 8.32026 4.83301 9.96693C4.83301 11.6136 6.21967 13.0003 7.86634 13.0003Z"
      stroke="black"
    />
    <path
      d="M20.5204 22.6208C22.427 22.6208 23.987 21.0608 23.987 19.1542C23.987 17.2475 22.427 15.6875 20.5204 15.6875C20.0004 15.6875 19.4804 15.7742 19.047 16.0342C17.8337 16.5542 17.0537 17.7675 17.0537 19.1542C17.0537 21.0608 18.6137 22.6208 20.5204 22.6208Z"
      stroke="black"
    />
    <path d="M20.5205 17.4199V20.8866" stroke="black" />
    <path d="M22.2538 19.1543H18.7871" stroke="black" />
  </svg>
);
