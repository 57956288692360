import React, { FC } from "react";

import { Title, Description, Wrapper } from "./styled";
import { StatementProps } from "./typed";

export const Statement: FC<StatementProps> = ({ title, description }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};
