import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { RemoveIcon } from "icons";
import { generateLinks } from "../utils";

import useDebounce from "hooks/useDebounce";

import { useSelector } from "react-redux";
import { useLazyGetSearchResultsQuery } from "redux/query/searchAPI";
import { activeComissionsSelector } from "redux/selectors/clientComissions";

import { CLIENT_LEFT_MENU_ITEMS_INFO } from "constant";
import { SearchResults } from "../SearchResults";

import { InputSearch } from "../InputSearch";
import {
  ButtonContainer,
  CloseIconBox,
  SearchContainer,
  SidebarLinks,
  SidebarWrapper,
  TopContainer,
} from "../styled";
import { BurgerMenuProps } from "./typed";

export const BurgerMenu: FC<BurgerMenuProps> = ({
  opened,
  closeSidebar,
  setLogoutPopup,
}) => {
  const [search, setSearch] = useState("");
  const [getResults, { data, isFetching }] = useLazyGetSearchResultsQuery();
  const commission = useSelector(activeComissionsSelector);

  const debouncedSearch = useDebounce<string>(search, 1000);

  const location = useLocation();

  useEffect(() => {
    if (search) {
      getResults({
        search: debouncedSearch,
        carId: commission?.id,
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    closeSidebar();
  }, [location]);

  return (
    <SidebarWrapper opened={opened} type={"left"}>
      <CloseIconBox onClick={closeSidebar} type={"left"}>
        <RemoveIcon />
      </CloseIconBox>

      <TopContainer>
        <SearchContainer>
          <InputSearch
            changeHandler={setSearch}
            value={search}
            sidebarSearch
            placeholder="Search"
          />
        </SearchContainer>

        <SearchResults
          data={data}
          isFetching={isFetching}
          search={search}
          setSearch={setSearch}
        />
      </TopContainer>

      <SidebarLinks>
        {generateLinks(CLIENT_LEFT_MENU_ITEMS_INFO, opened)}
        <ButtonContainer onClick={() => setLogoutPopup(true)}>
          Logout
        </ButtonContainer>
      </SidebarLinks>
    </SidebarWrapper>
  );
};
