import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const UploadBottomIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5051 16.5234V17.9156C20.5047 19.0685 19.5702 20.003 18.4173 20.0034H6.58297C5.42988 20.0034 4.49512 19.0687 4.49512 17.9156V16.5234"
      stroke="black"
    />
    <path d="M12.5 3.99414V16.4441" stroke="black" />
    <path d="M7.40625 9.08823L12.5003 3.99414L17.5944 9.08823" stroke="black" />
  </svg>
);
