import { FC } from "react";
import {
  styled,
  Typography,
  Box,
  DialogActions as MUIDialogActions,
  DialogContent as MIUDialogContent,
  Slider,
} from "@mui/material";
import type { TypographyProps } from "@mui/material/Typography";

import { theme } from "theme";

import { DialogBlurBackdrop } from "styled";

interface Props {
  component?: string | FC;
}

export const Dialog = styled(DialogBlurBackdrop)({
  ".MuiPaper-root": {
    borderRadius: "0px",
    height: "300px",
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const DialogContent = styled(MIUDialogContent)({
  padding: "0",
  width: "90%",
  height: "fit-content",
  flex: "none",
});
export const DialogCroppContent = styled(MIUDialogContent)({
  padding: "0",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
});

export const DialogActions = styled(MUIDialogActions)({
  padding: "22px 40px 20px",
});

export const UploadBox = styled(Box)({
  height: "138px",
  background: theme.color.white,
  border: `1px dashed ${theme.color.grey[300]}`,
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

export const TextUpload = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.base,
  lineHeight: "130%",
  color: theme.color.blue,
  textAlign: "center",
  marginTop: "5px",
});

export const TextDrag = styled(Typography)<Props & TypographyProps>({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: theme.color.grey[500],
  textAlign: "center",
});

export const TextLimit = styled(Typography)({
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "140%",
  color: "#8D8D8D",
  textAlign: "center",
});

export const TextInfo = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "right",
});

export const TextError = styled(TextInfo)({
  color: theme.color.error[500],
});

export const CroppContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: 200,
  background: "white",
});
export const CroppControls = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
});

export const StyledSlider = styled(Slider)({
  color: theme.color.black,
  width: "100%",

  "& .MuiSlider-root": {
    boxShadow: "none",
    padding: 0,
  },
  "& .Mui-active": {
    boxShadow: "none",
  },
  "& .MuiSlider-thumb:hover": {
    boxShadow: "none",
  },
});

export const СroppWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "0 10px 10px",
});

export const StyledButton = styled("button")({
  position: "absolute",
  top: "5px",
  right: "5px",
  padding: 0,
  margin: 0,
  background: "none",
  border: "none",
  cursor: "pointer",
});

export const CroppControlsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "70%",
  paddingTop: "3px",
});
