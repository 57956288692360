import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type {
  LegalDocument,
  LegalDocumentData,
  LegalDocumentsQueryParams,
  LegalDocumentsListData,
  LegalDocumentCategory,
} from "types";

import { legalDocumentUrlBuilder } from "./urlBuilder/legalDocuments";

export const legalDocumentsAPI = createApi({
  reducerPath: "legalDocumentsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["LegalDocuments", "LegalDocumentCategories"],
  endpoints: build => ({
    getLegalDocuments: build.query<
      LegalDocumentsListData,
      LegalDocumentsQueryParams | void
    >({
      query: (args?: LegalDocumentsQueryParams) => ({
        url: legalDocumentUrlBuilder.getLegalDocuments(args),
        method: "GET",
      }),
      providesTags: ["LegalDocuments"],
    }),
    getLegalDocument: build.query<LegalDocument, string>({
      query: args => ({
        url: legalDocumentUrlBuilder.getLegalDocument(args),
        method: "GET",
      }),
      providesTags: ["LegalDocuments"],
    }),
    createLegalDocument: build.mutation<LegalDocument, LegalDocumentData>({
      query: body => ({
        url: legalDocumentUrlBuilder.createLegalDocument(),
        method: "POST",
        data: body.data,
      }),
      invalidatesTags: ["LegalDocuments"],
    }),
    updateLegalDocument: build.mutation<LegalDocument, LegalDocumentData>({
      query: body => ({
        url: legalDocumentUrlBuilder.updateLegalDocument(body.id),
        method: "PUT",
        data: body.data,
      }),
      invalidatesTags: ["LegalDocuments"],
    }),
    deleteLegalDocument: build.mutation<LegalDocument, string>({
      query: args => ({
        url: legalDocumentUrlBuilder.deleteLegalDocument(args),
        method: "DELETE",
      }),
      invalidatesTags: ["LegalDocuments"],
    }),
    getLegalDocumentCategories: build.query<LegalDocumentCategory[], void>({
      query: () => ({
        url: legalDocumentUrlBuilder.getLegalDocumentCategories(),
        method: "GET",
      }),
      providesTags: ["LegalDocumentCategories"],
    }),
    createLegalDocumentCategory: build.mutation<
      LegalDocumentCategory,
      LegalDocumentCategory
    >({
      query: body => ({
        url: legalDocumentUrlBuilder.createLegalDocumentCategory(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["LegalDocumentCategories", "LegalDocuments"],
    }),
    deleteLegalDocumentCategory: build.mutation<LegalDocumentCategory, string>({
      query: args => ({
        url: legalDocumentUrlBuilder.deleteLegalDocumentCategory(args),
        method: "DELETE",
      }),
      invalidatesTags: ["LegalDocumentCategories", "LegalDocuments"],
    }),
  }),
});

export const {
  useGetLegalDocumentsQuery,
  useGetLegalDocumentQuery,
  useCreateLegalDocumentMutation,
  useUpdateLegalDocumentMutation,
  useDeleteLegalDocumentMutation,
  useGetLegalDocumentCategoriesQuery,
  useCreateLegalDocumentCategoryMutation,
  useDeleteLegalDocumentCategoryMutation,
} = legalDocumentsAPI;
