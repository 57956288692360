import { endpoints } from "constant";

import type { AdminsQueryParams } from "types";

export const adminUrlBuilder = {
  getAdmins: function ({
    page = 1,
    limit = 15,
    ordering = "",
  }: AdminsQueryParams = {}): string {
    return `${endpoints.admins}?page=${
      page + 1
    }&page_size=${limit}&ordering=${ordering}`;
  },
  updateAdmin: function (id: string | number): string {
    return `${endpoints.admins}${id}/`;
  },
  adminProfile: function (): string {
    return endpoints.adminProfile;
  },
};
