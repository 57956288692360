import { endpoints } from "constant";

import type { LegalDocumentsQueryParams } from "types";

export const legalDocumentUrlBuilder = {
  getLegalDocuments: function ({
    page,
    limit,
    ordering = "",
  }: LegalDocumentsQueryParams = {}): string {
    return `${endpoints.legalDocuments}?${
      page === undefined ? "" : `page=${page + 1}&page_size=${limit}&`
    }ordering=${ordering}`;
  },
  getLegalDocument: function (id: string | number): string {
    return `${endpoints.legalDocuments}${id}`;
  },
  createLegalDocument: function () {
    return endpoints.legalDocuments;
  },
  updateLegalDocument: function (id: string | number): string {
    return `${endpoints.legalDocuments}${id}/`;
  },
  deleteLegalDocument: function (id: string | number): string {
    return `${endpoints.legalDocuments}${id}/`;
  },
  getLegalDocumentCategories: function (): string {
    return endpoints.legalDocumentCategories;
  },
  createLegalDocumentCategory: function () {
    return endpoints.legalDocumentCategories;
  },
  deleteLegalDocumentCategory: function (slug: string): string {
    return `${endpoints.legalDocumentCategories}${slug}/`;
  },
};
