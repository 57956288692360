import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  opened: boolean;
}

const initialState: SidebarState = {
  opened: true,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
  },
});

export const { setSidebarOpened } = sidebarSlice.actions;
export const sidebarSliceReducer = sidebarSlice.reducer;
