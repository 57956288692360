import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/baseQuery";
import type { InspirationToolProductStudy } from "types";

import { inspirationToolProductStudyUrlBuilder } from "./urlBuilder/inspirationToolProductStudies";

export const inspirationToolProductStudiesAPI = createApi({
  reducerPath: "inspirationToolProductStudiesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["InspirationToolProductStudies"],
  endpoints: build => ({
    getInspirationToolProductStudies: build.query<
      InspirationToolProductStudy[],
      void
    >({
      query: () => ({
        url: inspirationToolProductStudyUrlBuilder.getInspirationToolProductStudies(),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductStudies"],
    }),
    getInspirationToolProductStudy: build.query<
      InspirationToolProductStudy,
      string
    >({
      query: args => ({
        url: inspirationToolProductStudyUrlBuilder.getInspirationToolProductStudy(
          args,
        ),
        method: "GET",
      }),
      providesTags: ["InspirationToolProductStudies"],
    }),
    createInspirationToolProductStudy: build.mutation<
      InspirationToolProductStudy,
      InspirationToolProductStudy
    >({
      query: body => ({
        url: inspirationToolProductStudyUrlBuilder.createInspirationToolProductStudy(),
        method: "POST",
        data: body,
      }),
      invalidatesTags: ["InspirationToolProductStudies"],
    }),
    deleteInspirationToolProductStudy: build.mutation<
      InspirationToolProductStudy,
      string
    >({
      query: args => ({
        url: inspirationToolProductStudyUrlBuilder.deleteInspirationToolProductStudy(
          args,
        ),
        method: "DELETE",
      }),
      invalidatesTags: ["InspirationToolProductStudies"],
    }),
  }),
});

export const {
  useGetInspirationToolProductStudiesQuery,
  useGetInspirationToolProductStudyQuery,
  useCreateInspirationToolProductStudyMutation,
  useDeleteInspirationToolProductStudyMutation,
} = inspirationToolProductStudiesAPI;
