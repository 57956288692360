import { styled, Typography } from "@mui/material";

import { BreakPointTheme, theme } from "theme";
import image from "images/tablet/porscheGreenMd.jpg";
import imageSm from "images/mobile/porscheGreenSm.jpg";

export const Wrapper = styled("div")({
  display: "flex",
  minHeight: "100vh",
  [BreakPointTheme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [BreakPointTheme.breakpoints.between("sm", "md")]: {
    padding: "20px 16px",
  },
});

export const Section = styled("div")<{
  background?: string;
  height?: string;
  padding?: string;
  isMobileHide?: boolean;
}>(({ background, height, padding, isMobileHide }) => ({
  padding: "60px 0",
  width: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [BreakPointTheme.breakpoints.down("lg")]: {
    width: "100%",
    padding: "12px 0 22px",
  },
  ...(background && {
    background: `url(${background}) bottom center/cover no-repeat`,

    [BreakPointTheme.breakpoints.down("lg")]: {
      background: `url(${image}) bottom center/cover no-repeat`,
      width: "100%",
      height: "206px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      background: `url(${imageSm}) bottom center/cover no-repeat`,
      height: "0",
      display: isMobileHide ? "none" : "flex",
    },
  }),

  ...(height && {
    height: height,
  }),

  ...(padding && {
    padding,
  }),
}));

export const Title = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.loginTitle,
  fontFamily: theme.fontFamily.SoehneSchmalLeicht,
  textTransform: "uppercase",
  lineHeight: "120%",
  color: theme.color.black,
  padding: "0 0 10px",
  [BreakPointTheme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
  [BreakPointTheme.breakpoints.between("sm", "md")]: {
    textAlign: "left",
    fontSize: theme.fontSize.loginTitleSM,
  },
});

export const Description = styled(Typography)({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.md,
  lineHeight: "140%",
  color: theme.color.grey[500],
  [BreakPointTheme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
  [BreakPointTheme.breakpoints.between("sm", "md")]: {
    textAlign: "left",
  },
});

export const TextBlock = styled("div")({
  width: "100%",
  margin: "0 0 40px",
});

export const Container = styled("div")({
  width: "400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [BreakPointTheme.breakpoints.down("lg")]: {
    width: "400px",
  },
  [BreakPointTheme.breakpoints.between("sm", "md")]: {
    width: "345px",
  },
});
