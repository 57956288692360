import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const MinusIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12C21 16.9703 16.9703 21 12 21C7.02975 21 3 16.9703 3 12C3 7.02975 7.02975 3 12 3C16.9703 3 21 7.02975 21 12Z"
      fill="#00B1A5"
    />
    <rect
      width="10"
      height="2"
      transform="matrix(1 0 0 -1 7 13)"
      fill="white"
    />
  </svg>
);
