import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const MultipleManIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.32812 17.8424C4.32813 15.2635 6.41877 13.1729 8.99771 13.1729C11.5766 13.1729 13.6673 15.2635 13.6673 17.8424H4.32812Z"
      stroke="currentColor"
    />
    <path
      d="M13.3301 8.54199C14.1039 9.06145 15.015 9.33831 15.947 9.33716C16.588 9.33769 17.2222 9.20716 17.8109 8.95358"
      stroke="currentColor"
    />
    <path
      d="M12.8398 7.50285C13.8355 6.33135 15.5835 6.16716 16.7799 7.13278C17.9762 8.09839 18.1847 9.84171 17.2497 11.0622C16.3147 12.2826 14.5772 12.5352 13.3335 11.6314C13.2153 11.5445 13.1038 11.4489 12.9999 11.3453"
      stroke="currentColor"
    />
    <path
      d="M13.668 13.3664C15.0813 12.9465 16.6099 13.2182 17.7919 14.0995C18.9739 14.9808 19.6707 16.3682 19.6717 17.8425H15.3357"
      stroke="currentColor"
    />
    <path
      d="M6.55469 7.89746C7.91536 9.30745 10.0067 9.72795 11.8066 8.95345"
      stroke="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99721 12.1722C10.563 12.1722 11.8323 10.9028 11.8323 9.33706C11.8323 7.77127 10.563 6.50195 8.99721 6.50195C7.43143 6.50195 6.16211 7.77127 6.16211 9.33706C6.16211 10.9028 7.43143 12.1722 8.99721 12.1722Z"
      stroke="currentColor"
    />
  </svg>
);
