import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const HyperlinkIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.314 16.0723L9.49423 18.8921C8.45568 19.9301 6.77244 19.9301 5.73389 18.8921L5.10749 18.2657C4.06964 17.2276 4.06964 15.5448 5.10749 14.5067L9.1807 10.4328C10.2193 9.39479 11.9025 9.39479 12.9411 10.4328L13.5674 11.0599"
      stroke="black"
    />
    <path
      d="M11.687 7.92628L14.5068 5.10718C15.0054 4.60837 15.6817 4.32812 16.387 4.32812C17.0922 4.32812 17.7686 4.60837 18.2672 5.10718L18.8935 5.73291C19.9314 6.771 19.9314 8.45383 18.8935 9.49192L14.8203 13.5658C14.3218 14.0646 13.6454 14.3449 12.9402 14.3449C12.2349 14.3449 11.5586 14.0646 11.06 13.5658L10.4336 12.9387"
      stroke="black"
    />
  </svg>
);
