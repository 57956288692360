import type { Photo } from "types";

export enum ImageCarouselVariants {
  eventDetails,
}

export interface IImageCarousel {
  images: Photo[];
  loop?: boolean;
  className?: string;
  onClick: (i: number) => void;
  variant?: ImageCarouselVariants;
}
