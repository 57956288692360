import React, { FC } from "react";

import type { TabPanelProps } from "./typed";

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...rest}
  >
    {value === index && children}
  </div>
);
