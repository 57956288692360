import React, { FC } from "react";

import { ApprovedIcon, CloseIcon } from "icons";
import { ButtonOutlined } from "styled";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  StyledButton,
} from "./styled";
import type { AdminModalProps } from "./typed";
import { LinearLoader } from "components";

export const AdminModalForm: FC<AdminModalProps> = ({
  children,
  isLoading,
  open,
  title,
  disabledApproveButton,
  cancelButton,
  approveButton,
  maxWidth,
  handleClose,
  handleConfirm,
}) => (
  <Dialog
    open={open}
    maxWidthStyle={maxWidth}
    fullWidth
    onClose={handleClose}
    onKeyUp={e => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleConfirm();
      }
    }}
  >
    <StyledButton onClick={handleClose}>
      <CloseIcon />
    </StyledButton>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <ButtonOutlined startIcon={<CloseIcon />} onClick={handleClose}>
        {cancelButton}
      </ButtonOutlined>
      <ButtonOutlined
        startIcon={<ApprovedIcon />}
        onClick={handleConfirm}
        disabled={disabledApproveButton}
      >
        {approveButton}
      </ButtonOutlined>
    </DialogActions>
    {isLoading && <LinearLoader />}
  </Dialog>
);

AdminModalForm.defaultProps = {
  disabledApproveButton: false,
  cancelButton: "Cancel",
  approveButton: "Approve",
  maxWidth: "600px",
  title: "",
};
