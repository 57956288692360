import React, { FC } from "react";
import { Table } from "@mui/material";

import { TableRow, TableCell } from "./styled";
import { TableReversedProps } from "./typed";

export const TableReversed: FC<TableReversedProps> = ({
  cells,
  item,
  width,
  type,
}) => {
  return (
    <Table>
      {cells.map(({ id, label, value, Wrapper }) => (
        <TableRow type={type} key={id}>
          <TableCell width={width}>{label}</TableCell>
          <TableCell>
            {Wrapper ? (
              <Wrapper value={item[value]} item={item} />
            ) : (
              item[value]
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
