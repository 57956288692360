import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { BlockDescriptionTypography, BlockTitleTypography } from "styled";

export const Container = styled("div")<{
  marginBot?: number;
  fullWidth?: boolean;
}>(({ marginBot, fullWidth }) => ({
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto 60px",

  ...(marginBot && {
    marginBottom: `${marginBot}px`,
  }),
  ...(fullWidth && {
    maxWidth: `auto`,
  }),
}));

export const TextContainer = styled("div")<{ reversed?: boolean }>(
  ({ reversed }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    width: "48%",
    boxSizing: "border-box",
    padding: "65px 10px 18px 10px",

    [BreakPointTheme.breakpoints.down(1439)]: {
      paddingLeft: "4%",
    },

    ...(reversed && {
      paddingLeft: "2%",

      [BreakPointTheme.breakpoints.down(1439)]: {
        paddingLeft: "1%",
      },

      [BreakPointTheme.breakpoints.down("lg")]: {
        paddingLeft: "5px",
      },
    }),

    [BreakPointTheme.breakpoints.down("lg")]: {
      paddingTop: "34px",
      paddingBottom: "10px",

      paddingLeft: "5px",
    },
  }),
);

export const TopicContainer = styled(Container)<{ reversed?: boolean }>(
  ({ reversed }) => ({
    display: "flex",
    maxWidth: "1390px",

    margin: "0 auto 140px",

    [BreakPointTheme.breakpoints.down("lg")]: {
      maxWidth: "800px",
      margin: "0 auto 95px",
    },

    [BreakPointTheme.breakpoints.down("md")]: {
      display: "none",
    },

    ...(reversed
      ? {
          flexDirection: "row-reverse",
        }
      : {
          flexDirection: "row",
        }),
    justifyContent: "space-between",
  }),
);

export const DescriptionContainer = styled("div")({
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "15px",
});

export const TopicDescription = styled(BlockDescriptionTypography)({
  lineHeight: "26px",
  maxWidth: "550px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    maxWidth: "334px",
    lineHeight: "22px",
  },
});

export const TopicTitle = styled(BlockTitleTypography)({
  fontSize: theme.fontSize["6xl"],
  fontWeight: theme.fontWeight.light,
  lineHeight: "62px",
  textAlign: "left",

  [BreakPointTheme.breakpoints.down("lg")]: {
    fontSize: theme.fontSize["4xl"],
    lineHeight: "33px",
  },
});
