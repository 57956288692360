import { styled, TableCell as MUITableCell } from "@mui/material";

import { TableRowStyled } from "components/Table/TableRow/styled";
import { theme } from "theme";

export const TableRow = styled(TableRowStyled)<{
  width?: number;
  type?: string;
}>(({ width, type }) => ({
  td: {
    textAlign: "left",
    wordBreak: "break-word",
    textOverflow: "inherit",
    overflow: "inherit",
    maxWidth: "inherit",
    whiteSpace: "inherit",
  },
  "td:first-of-type": {
    fontWeight: 700,
    width: width,
  },
  ...(type === "usual" && {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.color.rowBottom,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.color.rowTop,
    },
  }),
}));

export const TableCell = styled(MUITableCell)({
  verticalAlign: "top",
});
