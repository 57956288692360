import { ROLES } from "constant";
import { useSelector } from "react-redux";
import { getUserAdminRole, getUserClientRole } from "redux/selectors";

export const useUserRole = () => {
  const adminRole = useSelector(getUserAdminRole);
  const userRole = useSelector(getUserClientRole);

  return {
    isAdmin: adminRole === ROLES.Admin,
    isStaff: adminRole === ROLES.Staff,
    isGPN: adminRole === ROLES.GlobalPartner,
    isClient: userRole === ROLES.Client,
    isCollabborator: userRole === ROLES.Collaborator,
  };
};
