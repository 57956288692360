import { FC } from "react";
import { Button, styled } from "@mui/material";
import type { ButtonProps as Props } from "@mui/material/Button";
import type { LinkProps } from "react-router-dom";

import { BreakPointTheme, theme } from "theme";

interface ButtonProps {
  isMobileNone?: boolean;
  isGreen?: boolean;
  component?: string | FC;
  href?: string;
  target?: string;
}

export const ButtonOutlined = styled(Button)<ButtonProps & Props>(
  ({ isGreen, isMobileNone }) => ({
    border: `1px solid ${theme.color.black}`,
    borderRadius: "30px",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    color: theme.color.black,
    width: "fit-content",
    height: "fit-content",
    minHeight: "34px",
    padding: "3px 15px",
    "&.Mui-disabled": {
      color: theme.color.grey[500],
      border: `1px solid ${theme.color.grey[500]}`,
      "svg path": {
        stroke: theme.color.grey[500],
      },
    },
    ...(isGreen && {
      background: theme.color.primary[800],
    }),

    ...(isMobileNone && {
      [BreakPointTheme.breakpoints.down("md")]: {
        display: "none",
      },
    }),
  }),
);

export const ClientPortalButton = styled(ButtonOutlined)({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.md,
  letterSpacing: "0em",
});

export const LinkOutlined = styled(ButtonOutlined)<
  ButtonProps & LinkProps & Props
>({
  padding: "3px 15px",
});

export const LinkUsual = styled(ButtonOutlined)<ButtonProps & Props>({
  padding: "3px 15px",
});

export const NewButton = styled(Button)({
  border: `1px solid ${theme.color.borderDark}`,
  borderRadius: "4px",
  textTransform: "none",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "140%",
  color: theme.color["6D"],
  alignItems: "center",
  width: "fit-content",
  height: "fit-content",
  minHeight: "36px",
  padding: "3px 15px",
});
