import {
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions,
  DialogContent as MIUDialogContent,
  DialogProps,
  DialogTitle as MUIDialogTitle,
  styled,
  Typography,
} from "@mui/material";

import { theme } from "theme";

const border = `1px solid ${theme.color["6D"]}`;

interface Props extends DialogProps {
  maxWidthStyle?: string;
}

export const Dialog = styled(MUIDialog)<Props>(({ maxWidthStyle }) => ({
  background: "rgba(0, 0, 0, 0.6)",
  backdropFilter: "blur(5px)",
  ".MuiPaper-root": {
    borderRadius: "10px",
    maxWidth: maxWidthStyle,
  },
}));

export const DialogTitle = styled(MUIDialogTitle)({
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "24px",
  color: theme.color.black,
  textAlign: "center",
  textTransform: "uppercase",
  padding: "35px 24px 10px",
});

export const DialogContent = styled(MIUDialogContent)({
  padding: "0 24px 62px",
  borderBottom: border,
});

export const TextContent = styled(Typography)({
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "140%",
  color: theme.color.black,
  textAlign: "center",
});

export const DialogActions = styled(MUIDialogActions)({
  justifyContent: "stretch",
  flex: 1,
  padding: 0,
  button: {
    flex: 1,
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "140%",
    color: theme.color.black,
    textTransform: "none",
    padding: "12px 24px",
    background: theme.color.white,
    borderRight: border,
    borderTop: border,
    borderRadius: 0,

    "&:not(:first-of-type)": {
      marginLeft: 0,
      borderLeft: border,
      borderRight: "none",
    },
  },
});
