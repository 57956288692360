import { endpoints } from "constant";

export const notificationsURLBuilder = {
  getNotifications: (): string => {
    return endpoints.notifications;
  },
  deleteNotification: (id: number): string => {
    return `${endpoints.notifications}${id}/`;
  },
};
