import { Link } from "react-router-dom";
import { Grid, styled, Typography } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

export const Wrapper = styled("footer")({
  padding: "50px",

  [BreakPointTheme.breakpoints.down("lg")]: {
    padding: "16px",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    padding: "50px 16px 16px",
  },
});

export const GridContainer = styled(Grid)({
  flexWrap: "nowrap",

  [BreakPointTheme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
});

export const GridLinks = styled(Grid)({
  [BreakPointTheme.breakpoints.down("bg")]: {
    width: "30%",
  },

  [BreakPointTheme.breakpoints.down("md")]: {
    width: "auto",
    marginTop: "32px",
  },
});

export const FooterText = styled(Typography)({
  fontWeight: theme.fontWeight.light,
  fontSize: theme.fontSize.md,
  lineHeight: "24px",
  color: theme.color.black,
  marginBottom: "16px",
});

export const Container = styled("div")<{ textAlignCenter?: boolean }>(
  ({ textAlignCenter }) => ({
    width: "100%",
    maxWidth: "1460px",
    margin: "0 auto 65px ",

    ...(textAlignCenter && {
      textAlign: "center",
    }),
  }),
);

export const ContainerLogo = styled("div")({
  width: "100%",
  maxWidth: "1460px",
  margin: "0 auto 65px ",

  [BreakPointTheme.breakpoints.down("lg")]: {
    textAlign: "center",
    margin: "0 auto 47px",
    svg: {
      width: "154px",
    },
  },
});

export const ContainerCopyright = styled("div")({
  width: "100%",
  maxWidth: "1460px",
  margin: "0 auto 65px ",
  textAlign: "center",

  [BreakPointTheme.breakpoints.down("md")]: {
    margin: "-65px auto 65px",
    textAlign: "left",
  },
});

export const LinksTitle = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.xs,
  lineHeight: "120%",
  color: theme.color.grey[1100],
  marginBottom: "5px",
});

export const StyledLink = styled(Link)({
  position: "relative",
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "46px",
  textTransform: "capitalize",
  textDecoration: "none",
  color: theme.color.black,

  ":after": {
    content: '""',
    width: "15px!important",
    height: "2px",
    background: theme.color.black,
    position: "absolute!important",
    left: "-40px!important",
    top: "12px",
    opacity: "0",
    transition: "all 0.3s ease",
  },

  ":hover": {
    ":after": {
      left: "-27px!important",
      opacity: "1!important",
    },
  },
});

export const StyledLegalLink = styled("a")({
  position: "relative",
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "46px",
  textTransform: "capitalize",
  textDecoration: "none",
  color: theme.color.black,

  ":after": {
    content: '""',
    width: "15px!important",
    height: "2px",
    background: theme.color.black,
    position: "absolute!important",
    left: "-40px!important",
    top: "12px",
    opacity: "0",
    transition: "all 0.3s ease",
  },

  ":hover": {
    ":after": {
      left: "-27px!important",
      opacity: "1!important",
    },
  },
});

export const StyledFileDownload = styled("span")({
  position: "relative",
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "46px",
  textTransform: "capitalize",
  textDecoration: "none",
  cursor: "pointer",
  color: theme.color.black,

  ":after": {
    content: '""',
    width: "15px!important",
    height: "2px",
    background: theme.color.black,
    position: "absolute!important",
    left: "-40px!important",
    top: "12px",
    opacity: "0",
    transition: "all 0.3s ease",
  },

  ":hover": {
    ":after": {
      left: "-27px!important",
      opacity: "1!important",
    },
  },
});

export const Copyright = styled(Typography)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.lg,
  lineHeight: "46px",
  color: theme.color.grey[1100],
});
