import { styled } from "@mui/material";

import { BreakPointTheme, theme } from "theme";

import { PointAcceptIcon, PointCircleIcon } from "icons";

interface PointProps {
  isReversed: boolean;
}

export const PointContainer = styled("div")<PointProps>(({ isReversed }) => ({
  position: "relative",
  display: "flex",
  flexDirection: isReversed ? "column-reverse" : "column",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: isReversed ? 0 : "-5px",
}));

export const PointLineWrapper = styled("div")<{
  isReversed: boolean;
  isSelected: boolean;
}>(({ isReversed, isSelected }) => ({
  margin: isReversed ? "0px -0.25px -6px 0" : "-2px -0.25px 0 0",

  svg: {
    path: {
      stroke: isSelected ? theme.color.black : theme.color.black,
    },
  },

  [BreakPointTheme.breakpoints.down("lg")]: {
    svg: {
      height: "26px",
    },
  },
}));

export const PointWrapper = styled("div")<PointProps>(({ isReversed }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: isReversed ? "flex-start" : "flex-end",
  height: "100px",
}));

interface PointCircleProps {
  isCurrent: boolean;
  isCompleted: boolean;
  isSelected: boolean;
  pointName: boolean | string;
}

export const PointCircle = styled(PointCircleIcon)<PointCircleProps>(
  ({ isCurrent, isCompleted, isSelected, pointName }) => ({
    path: {
      fill: pointName
        ? theme.color.grey[1200]
        : isCurrent
        ? theme.color.orange
        : isSelected
        ? theme.color.primary[800]
        : isCompleted
        ? theme.color.grey[1200]
        : theme.color.white,

      stroke: isSelected ? theme.color.black : theme.color.black,
    },

    [BreakPointTheme.breakpoints.down("lg")]: {
      height: "30px",
      width: "30px",
    },
  }),
);

export const PointAccepted = styled(PointAcceptIcon)<PointProps>(
  ({ isReversed }) => ({
    position: "absolute",
    left: "50%",
    transform: " translateX(-50%)",

    ...(isReversed
      ? {
          bottom: "8px",
        }
      : {
          top: "10px",
        }),

    [BreakPointTheme.breakpoints.down("lg")]: {
      height: "19px",
      width: "16px",

      ...(isReversed
        ? {
            bottom: "4px",
          }
        : {
            top: "6px",
          }),
    },
  }),
);
