import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const TaskListEditIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0914 14.0932L14.0016 19.183L11.5 19.6833L12.0003 17.1818L17.0908 12.0919C17.6418 11.541 18.5351 11.541 19.0861 12.0919L19.0921 12.0979C19.6428 12.6491 19.6425 13.5424 19.0914 14.0932Z"
      stroke="black"
    />
    <path d="M7.49609 11.1777H13.4998" stroke="black" />
    <path d="M7.49609 13.6797H11.4986" stroke="black" />
    <path d="M7.49609 16.1807H9.49734" stroke="black" />
    <path
      d="M9.49922 19.6832H5.49672C4.94409 19.6832 4.49609 19.2352 4.49609 18.6826V8.17602C4.49609 7.62339 4.94409 7.17539 5.49672 7.17539H7.99895C7.99895 5.79382 9.11894 4.67383 10.5005 4.67383C11.8821 4.67383 13.0021 5.79382 13.0021 7.17539H15.5036C16.0563 7.17539 16.5043 7.62339 16.5043 8.17602V9.67695"
      stroke="black"
    />
    <path
      d="M10.5002 6.67481C10.6383 6.67481 10.7503 6.7868 10.7503 6.92496C10.7503 7.06312 10.6383 7.17512 10.5002 7.17512C10.362 7.17512 10.25 7.06312 10.25 6.92496C10.2498 6.85856 10.2761 6.79483 10.3231 6.74788C10.37 6.70093 10.4338 6.67463 10.5002 6.67481"
      stroke="black"
    />
  </svg>
);
