import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const CollapseLeftIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.6406 26.9209V7.0752" stroke="black" />
    <path
      d="M18.4213 13.6902L16.5312 16.5253H22.2015"
      stroke="black"
      strokeLinejoin="round"
    />
    <path
      d="M18.4213 19.3607L16.5312 16.5256"
      stroke="black"
      strokeLinejoin="bevel"
    />
  </svg>
);
